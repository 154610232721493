import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';

class EggNOGDataCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  get extractProps() {
    return {
      delimiter: '\t',
    };
  }

  renderData(data) {
    const keggLinkerProps = {
            url: 'http://www.genome.jp/dbget-bin/www_bget?{VALUE}',
            delimiter: ',',
            join: ' ',
          }
    const header = ['Query name', 'Seed ortholog', 'e-value', 'Score', 'best tax lvl', 'Preferred name', 'GO terms', 'EC number', 'KEGG KO', 'KEGG Pathway', 'KEGG Module', 'KEGG Reaction', 'KEGG rclass', 'BRITE', 'KEGG TC', 'CAZy', 'BiGG Reaction', 'annot lvl max', 'eggNOG OGs', 'BestOG', 'COG cat', 'Description'];
    return (
      <VirtualGrid
        data={data}
        header={header}
        alternateRowColors
        rowHeight={50}
        defaultWidht={100}
      >
        <Column index={0} width={400} name='Query Name' />
        <Column index={1} width={200} name='Seed Ortholog'
          linkerProps={{
            url: 'http://eggnog5.embl.de/#/app/results?seqid={VALUE}',
            extractRegex: /^\d+?\.(.*)$/,
          }}
				/>
				<Column index={2} width={90} name='e-value' /> 
        <Column index={6} width={90} name='GO Terms'
          linkerProps={{
            url: 'http://amigo.geneontology.org/amigo/term/{VALUE}',
            delimiter: ',', join: ' ',
          }}
        />
        <Column index={7} name='EC Number'
          linkerProps={{
            url: 'https://www.brenda-enzymes.org/enzyme.php?ecno={VALUE}',
            delimiter: ',', join: ' ',
          }}
        />
        <Column index={8} name='KEGG KO'
          linkerProps={keggLinkerProps}
        />
        <Column index={9} name='KEGG Pathway'
          linkerProps={keggLinkerProps}
        />
        <Column index={10} name='KEGG Module'
          linkerProps={keggLinkerProps}
        />
        <Column index={11} name='KEGG Reaction'
          linkerProps={keggLinkerProps}
        />
        <Column index={12} name='KEGG RClass'
          linkerProps={keggLinkerProps}
        />
        <Column index={13} name='BRITE'
          linkerProps={{
            url: 'https://www.genome.jp/kegg-bin/search_brite?catalog=brite.list&search_string={VALUE}',
            delimiter: ',', join: ' ',
          }}
        />
        <Column index={14}
          transform={ (v) => v.replaceAll(',', ', ') }
        />
        <Column index={16} name='BiGG Reaction'
          transform={ (v) => v.replaceAll(',', ', ') }
        />
        <Column index={18} name='eggNOG OGs'
          linkerProps={{
            url: 'http://eggnog5.embl.de/#/app/results?target_nogs={VALUE}',
            extractRegex: /^([^@]+)/,
            delimiter: ',', join: ' ',
          }}
        />
        <Column index={21} width={400} />
      </VirtualGrid>
    );
  }

}

export default EggNOGDataCard;

