import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';

class ReportCard extends ReportCardBase {

  renderData(mapData) {
    return this.renderDefaultReport(mapData);
  }

}

export default ReportCard;

