import SelectWithNew from '../presenters/SelectWithNew';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import * as helpers from '../support/Helpers';
// Connected
import { connect } from 'react-redux';

// SelectTrack onChange will returnCurrentItem. An object with keys:
//  - cgvID:
//    - cgv-id-#: for existing objects
//    - NEW: create a new item
//    - custom addtional items
//  - all other keys are passed to object for creation or updating
//    - name:
class SelectTrack extends SelectWithNew {

  static defaultProps = {
    title: 'Track',
    allowNewItem: true,
    defaultNewItemName: '',
    defaultItemID: 'NEW',
    newItemLabel: 'Track',
    additionalItems: [],
    help: 'Choose or create a track.',
    onChange: () => {},
  }
}

// Connected
const selectTrackMapStateToProps = (state) => ({ items: state.tracks });
const ConnectedSelectTrack = connect(selectTrackMapStateToProps)(SelectTrack);

// export default SelectTrack;
export { SelectTrack, ConnectedSelectTrack };

