import React from 'react';
import ImageButton from '../../../presenters/ImageButton';

// TODO:
// - Add a link to the help page with more dtails on importing GFF3, GTF, and BED files
// - Detail that comment lines and empty lines are ignored

export default function FeatureHelp() {
  return (
      <div className='feature-help'>
        <div>
          For tab-separated (TSV) and comma-separated (CSV) files, the following default column names are used.
          Note that the columns names are case-insensitive, can be arranged in any order, and can be modified in settings &nbsp;
          <ImageButton imageName='settings' inline size='small' disabled />
        </div>
        <table>
          <thead><tr><th>Column</th><th>Description</th></tr></thead>
          <tbody>
            <tr><td>name</td><td>Name of the feature</td></tr>
            <tr><td>type</td><td>Type of feature (e.g. 'CDS', 'rRNA', 'tRNA')</td></tr>
            <tr><td>start*†</td><td>Number between 1 and the length of the contig or map sequence</td></tr>
            <tr><td>stop*†</td><td>Number between 1 and the length of the contig or map sequence</td></tr>
            <tr><td>strand</td><td>'+' for the forward strand and '-' for the reverse strand [Default: +]</td></tr>
            <tr><td>contig</td><td>Name of the contig the feature is on. Start/stop positions are relative to the contig</td></tr>
            <tr><td>score</td><td>Score associated with the feature. A number between 0 and 1.</td></tr>
            <tr><td>legend</td><td>Legend to use for this feature. If it doesn't exist it will be created.</td></tr>
            {/* <tr><td>codonStart</td><td></td></tr> */}
          </tbody>
        </table>
        <p>* Required columns</p>
        <p>
          † The <em>start</em> should be less than or equal to the <em>stop</em>, regardless of the <em>strand</em>. 
          If the <em>start</em> is greater than the <em>stop</em>, the feature will wrap around the origin.
        </p>
        <strong><u>TSV Example</u></strong>
        <pre>
{`name  contig type start stop  strand
gene1 con1   CDS  103   1500  +
gene2 con1   CDS  2400  3623  -
gene2 con2   tRNA 1600  1700  +`}
        </pre>

        <strong><u>Notes and Tips</u></strong>
        <dl>
          <dt>GFF3, GTF, BED can be imported as a TSV file</dt>
          <dd>
            To select specfic columns from a GFF3, GTF, or BED file, click settings
            &nbsp;<ImageButton imageName='settings' inline size='small' disabled />,
            select the TSV file format, reparse the file to load the columns,
            select the desired columns and then reparse.
          </dd>
        </dl>
        <dl>
          <dt>BED Files</dt>
          <dd>Must be tab-separated (not space-separated) and contain 3-9, 12 or more columns.</dd>
        </dl>
        <dl>
          <dt>Comment Lines</dt>
          <dd>Lines starting with <strong><em>#</em></strong> and blank lines are ignored.</dd>
        </dl>
      </div>
  )
}