import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ProjectBadge.css';
import ImageButton from '../presenters/ImageButton';

ProjectBadge.propTypes = {
  message: PropTypes.node,
  onClick: PropTypes.func,
}

ProjectBadge.defaultProps = {
  onClick: () => {},
}

// NOTE: in css the width must be set to the same as the ImageButton below
// TODO: (future) props for width and imageType (e.g. warning, danger, etc)
function ProjectBadge(props) {

  const {
    message,
    onClick,
  } = props;

  const badgeClass = classNames(
    'ProjectBadge',
    {'open': message}
  );

  // Set key to Date to animate new messages
  // I tried using "message' but when an object it becomes "[object Object]"
  return (
    <div className={badgeClass}>
      <ImageButton
        imageName='alert'
        text={message}
        width={106}
        onClick={ () => onClick() }
      />
      <div className='badge-animate' key={Date.now()}></div>
    </div>
  );

}

export default ProjectBadge;

