import { CAPTIONS_ADD, CAPTIONS_REMOVE, CAPTIONS_UPDATE, CAPTIONS_MOVED } from '../constants/ActionTypes'

export const addCaptions = (captions) => ({
  type: CAPTIONS_ADD,
  payload: {
    captions,
  }
});

export const removeCaptions = (captions) => ({
  type: CAPTIONS_REMOVE,
  payload: {
    captions,
  }
});

export const updateCaptions = (captions, attributes) => ({
  type: CAPTIONS_UPDATE,
  payload: {
    captions,
    attributes,
  }
});

export const moveCaptions = (oldIndex, newIndex) => ({
  type: CAPTIONS_MOVED,
  payload: {
    oldIndex,
    newIndex,
  }
});

