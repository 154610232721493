import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Message.css';

class Message extends React.Component {
  static propTypes = {
    // spinner: PropTypes.bool,
    dimBackground: PropTypes.bool,
  }

  static defaultProps = {
    // spinner: true,
    dimBackground: true,
  }

  static get rendered() {
    return !!Message._singletonRef;
  }

  static open(...args) {
    if (Message.rendered) {
      Message._singletonRef._open(...args);
    }
  }

  static close() {
    if (Message.rendered) {
      Message._singletonRef._close();
    }
  }

  // static processing(...args) {
  //   Message._singletonRef._processing(...args);
  // }

  constructor(props) {
    super(props);
    Message._singletonRef = this;
    this.state = {
      content: props.content || '',
      status: props.status || 'reset',
      dimBackground: props.dimBackground,
    }
  }

  _open({content, dimBackground=true}) {
    this.setState({
      status: 'open',
      content,
      dimBackground,
    });
  }

  _close() {
    this.setState({
      status: 'closed',
    });
  }

  render() {
    const { content, status, className, dimBackground } = this.state;
    const messageClass = classNames(
      'Message', className, status,
    );
    const backgroundClass = classNames(
      'message-background', status,
    );
    const background = dimBackground ?  <div className={backgroundClass} /> : '';
    return (
      <div>
        {background}
        <div className={messageClass}>
          <span className= 'spinner'></span>
          <span className= 'content'>{content}</span>
        </div>
      </div>
    )
  }
}

export default Message;
