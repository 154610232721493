import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select, Option } from './Select';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import ImageButton from './ImageButton';
import NumericInput from '../presenters/NumericInput';
import './Font.css';
import iconBold from '../images/icon-bold.png';
import iconItalic from '../images/icon-italic.png';

Font.propTypes = {
  fontString:        PropTypes.string.isRequired,
  className:         PropTypes.string,
  onChange:          PropTypes.func,
  allowDefault:      PropTypes.bool,
  isDefault:         PropTypes.bool,
  onDefaultClick:    PropTypes.func,
  // disabled:    PropTypes.bool,
}

Font.defaultProps = {
  onClick:  () => {},
  // disabled: false,
}

function Font(props) {
  const {
    className,
    fontString,
    onChange,
    children,
    allowDefault,
    isDefault,
    onDefaultClick,
  } = props;

  const fontClass = classNames(
    'Font', className,
    {'default-on': isDefault}
  );

  const [family, style, size] = fontString.split(',')

  const bold = style.includes('bold');
  const italic = style.includes('italic');

  const handleOnChange = (value, attribute) => {
    // *plain*, *bold*, *italic*, or *bold-italic*
    let newStyle = style;
    let newFamily = family;
    let newSize = size;
    if (attribute === 'bold') {
      if (value) {
        newStyle = italic ? 'bold-italic' : 'bold';
      } else {
        newStyle = italic ? 'italic' : 'plain';
      }
    } else if (attribute === 'italic') {
      if (value) {
        newStyle = bold ? 'bold-italic' : 'italic';
      } else {
        newStyle = bold ? 'bold' : 'plain';
      }
    } else if (attribute === 'family') {
      newFamily = value;
    } else if (attribute === 'size') {
      newSize = value;
    }
    let newFontString = `${newFamily},${newStyle},${newSize}`;
    onChange(newFontString);
  }

  const fontFamilies = [ ['sans-serif', 'Sans Serif'], ['serif', 'Serif'], ['monospace', 'Monospace']];

  let renderedDefault;
  if (allowDefault) {
    renderedDefault = isDefault ?
      <div className='default-text'>Default</div> :
      <Button onClick={ onDefaultClick }>Default</Button>
  }

  return (
    <div className={fontClass} >
      <div className='font-family-container'>
        <Select value={family}
          onChange={(value) => handleOnChange(value, 'family')}>
          { fontFamilies.map( f => <Option key={f[0]}>{f[1]}</Option> ) }
        </Select>
      </div>
      <ButtonGroup separated={true}>
        <ImageButton
          title='Bold'
          image={iconBold}
          onClick={ () => handleOnChange(!bold, 'bold') }
          active={bold}
        / >
        <ImageButton
          title='Italic'
          image={iconItalic}
          onClick={ () => handleOnChange(!italic, 'italic') }
          active={italic}
        / >
      </ButtonGroup>
      <NumericInput value={Number(size)}
        className='font-size'
        precision={0}
        onChange={(value) => handleOnChange(value, 'size')} />
      {renderedDefault}

      {children}
    </div>
  )
}

Font.stringToCSS = function(string) {
  const [family, style, size] = string.split(',')
  let newStyle = '';
  if (style === 'bold') {
    newStyle = 'bold';
  } else if (style === 'italic') {
    newStyle = 'italic';
  } else if (style === 'bold-italic') {
    newStyle = 'italic bold';
  }
  let css = `${newStyle} ${size}px ${family}`;
  return css;
}

export default Font;

