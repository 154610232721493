import { RESET_STATE, SEQUENCE_UPDATE } from '../constants/ActionTypes'

export const resetState = () => ({
  type: RESET_STATE,
  payload: {}
});

export const updateSequence = (attributes) => ({
  type: SEQUENCE_UPDATE,
  payload: {
    attributes,
  }
});


