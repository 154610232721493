import React from 'react';
import Prism from 'prismjs';
import DataElement from '../../presenters/DataElement';
import Font from '../../presenters/Font';
import Switch from '../../presenters/Switch';
import * as helpers from '../../support/Helpers';



class CodeFileViewer {

  static defaultLanguage = 'shell';
  // Languages:
  static languages = [
    'html', 'xml', 'svg', 'javascript', 'shell', 'diff', 'perl', 'json',
    'latex', 'markdown', 'python', 'r', 'ruby', 'yaml'
  ];

  // File extensions for languages that aren't the same as the language name
  // e.g. no need to put html is html
  static altExtensionMap = {
    htm: 'html',
    js: 'javascript',
    diff: 'diff',
    tex: 'latex',
    md: 'markdown',
    mdown: 'markdown',
    py: 'python',
    rb: 'ruby',
    yml: 'yaml',
  }

  constructor(fileViewer, settings) {
    this._fileViewer = fileViewer;
    this._settings = settings;
  }

  get fileViewer() {
    return this._fileViewer;
  }

  languageForExtension(ext) {
    let lang;
    if (CodeFileViewer.languages.includes(ext)) {
      lang = ext;
    } else {
      lang = CodeFileViewer.altExtensionMap[ext];
    }
    if (!lang) {
      console.error(`No language for extenstion '${ext}', using Shell as default`);
      lang = CodeFileViewer.defaultLanguage;
    }
    return lang;
  }

  languageForFile(file) {
    const ext = ( file && file.name && file.name.replace(/.*\./,'') ) || CodeFileViewer.defaultLanguage;
    return this.languageForExtension(ext);
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'code';
  }

  static get displayFileType() {
    return 'Code';
  }

  get settingsHeight() {
    return 110;
  }

  defaultSettings() {
    return {
      font: 'monospace, plain, 12',
      lineWrap: false,
    }
  }

  onChangeSettings({value, attribute}) {
    this.fileViewer.onChangeSettings({value, attribute});
  }

  displaySettings(settings=this.defaultSettings()) {
    return (
      <div>
        <DataElement label='Font'>
          <Font
            fontString={settings.font}
            onChange={(value) => this.onChangeSettings({value, attribute: 'font'})}
          />
        </DataElement>
        <DataElement label='Wrap Lines'>
          <Switch
            value={settings.lineWrap}
            onChange={(value) => this.onChangeSettings({value, attribute: 'lineWrap'})}
          />
        </DataElement>
      </div>
    );
  }

  // I think line-numbers are not working because I use "highlight" here instead of highlightAll.
  // We could chagne this to work that way later.
  displayData(file, data, settings=this.defaultSettings()) {
    const style = {font: Font.stringToCSS(settings.font)};
    if (settings.lineWrap) {
      style.whiteSpace = 'pre-wrap';
      style.wordBreak = 'break-all';
    }
    const language = this.languageForFile(file);
    return (
      <pre className="file-data-text">
        <code
          style={style}
          className={`language-${language}`}
          dangerouslySetInnerHTML={{__html: Prism.highlight(data, Prism.languages[language], language)}}>
        </code>
      </pre>
    );
  }

}

export default CodeFileViewer;
