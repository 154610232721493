export default function add({inputs, mapData}) {

  // Filter mapData based on DialogAdd inputs

  if (!inputs.add_prophage) {
    mapData.features = mapData.features.filter( f => f.type !== 'Phigaro');
  }
  if (!inputs.add_genes) {
    // Genes will have type of "Phigoro - TYPE"
    mapData.features = mapData.features.filter( f => f.type === 'Phigaro');
  }

  return { ok: true, mapData };
}

