document.addEventListener('DOMContentLoaded', () => {
  const storage = window.localStorage;

  // dataset keys: admin, uuid
  // Only present for session projects (no user)
  const sessionProjectInfo = document.querySelector('#session-project-info');

  // Only provide sessionProjectInfo on project status and show page
  // Only provide if the project is a session project
  // Admin is provided so that other users session projects are not added to admins session projects
  // Only set session_project_ids if cookies are allowed
  const allowCookies = storage.getItem('allow_cookies') === 'true';
  if (allowCookies && sessionProjectInfo) {
    const data = sessionProjectInfo.dataset;
    // console.log(data);
    if (data.admin !== 'true') {
      const sessionProjectsString = storage.getItem('session_project_ids');
      let sessionProjects =  sessionProjectsString ? sessionProjectsString.split(',') : [];
      sessionProjects.push(data.uuid);
      sessionProjects = [...new Set(sessionProjects)];
      storage.setItem('session_project_ids', sessionProjects.join(','));
    }
  }

});

