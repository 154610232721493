import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElement from '../../../presenters/DataElement';
import DataElementGroup from '../../../presenters/DataElementGroup';
import Button from '../../../presenters/Button';
import ButtonGroup from '../../../presenters/ButtonGroup';
import { Select, Option } from '../../../presenters/Select';
import './DialogAdd.css'
import FeatureHelp from './FeatureHelp';
import classNames from 'classnames';

class DialogAdd extends DefaultDialog {

  onFileChange({file}) {
    this.onChange({attribute: 'file', value: file.details({includeText: true})});
    this.setState({
      fileName: file.fileName,
      fileRecords: file.delegate?.data || [], // data (ie. array of features) from FeatrueBuilder
      // FeatureBuilder status: 'success', 'failed', 'warnings', undefined (if parsing failed)
      builderStatus: file.delegate?.builderStatus || 'failed',
    });
    this.updateFeatures();
  }

  // This let's us store whether the settings have changed in the Dialog state
  onChangeSettings({attribute, value}) {
    // console.log('CHANGE SETTINGS', attribute, value);
    if (attribute === 'settingsHaveChanged') {
      this.setState({ settingsHaveChanged: value });
      if (value) {
        this.resetState();
      }
    }
  }

  // return the number of issues
  dataIssueCount() {
    const { unknownContigs, noContigFeatureIndices, startOverStopFeatureIndices, constrainedFeatureIndices, settingsHaveChanged } = this.state;
    let issueCount = 0;
    if (settingsHaveChanged) { return 0; }
    if ((unknownContigs?.length > 1) || (unknownContigs?.length === 1 && unknownContigs[0] !== undefined)) {
      issueCount++;
      console.log('ISSUE: unknown contigs', unknownContigs);
    }
    if ((noContigFeatureIndices?.length > 1) || (noContigFeatureIndices?.length === 1 && noContigFeatureIndices[0] !== undefined)) {
      issueCount++;
      console.log('ISSUE: no contig attribute', noContigFeatureIndices);
    }

    if (startOverStopFeatureIndices?.length > 0) {
      issueCount++;
      console.log('ISSUE: start > stop', startOverStopFeatureIndices);
    }

    if (constrainedFeatureIndices?.length > 0) {
      issueCount++;
      console.log('ISSUE: constrained', constrainedFeatureIndices);
    }

    return issueCount;
  }

  // Starts with the parsed records from the file
  updateFeatures() {
    const { fileRecords } = this.state;
    console.log('BUILDER FEATURES', fileRecords);
    let finalFileRecords = [];

    // No file records
    if (!fileRecords) {
      this.resetState()
      return;
    }

    let startOverStopFeatureIndices = [];
    let constrainedFeatureIndices = [];
    let noContigFeatureIndices, noContigFeatureAction, showConstrainedFeatures, showStartOverStopFeatures, showNoContigFeatures, showFinalFeatures;
    const cgv = this.cgv;

    // CHECKING FOR ISSUES

    // ISSUE: Unknown Contigs
    const mapContigNames = cgv.contigs().map( c => c.name );
    // const builderContigs = [... new Set(fileRecords.map( f => f.contig ))].filter( x => x !== undefined)
    // This will include undefined if any of the features have no contig
    const builderContigs = [... new Set(fileRecords.map( f => f.contig ))]
    const unknownContigs = builderContigs.filter(x => !mapContigNames.includes(x));
    let unknownContigsAction = this.state.unknownContigsAction || 'IGNORE';

    // ISSUE: Missing Contig Attrinbute and the map has multiple contigs
    if (cgv.sequence.hasMultipleContigs && builderContigs.includes(undefined)) {
      // noContigColumn = true;
      // noContigFeatureIndices = fileRecords.filter( f => f.contig === undefined);
      // Get the indices of the features with no contig
      noContigFeatureIndices = fileRecords.reduce((indices, f, idx) => {
        if (f.contig === undefined) indices.push(idx);
        return indices;
      }, []);
      noContigFeatureAction = this.state.noContigFeatureAction || 'IGNORE';
    }

    // Filter features:
    // - with unknown contigs
    // - that are constrained (i.e. start/stop > than contig/sequence length)
    // - that have start > stop
    const features = [];
    for (const [index, fileFeature] of fileRecords.entries()) {
      const feature = {...fileFeature};
      const start = Number(feature.start);
      const stop = Number(feature.stop);
      // ISSUE: constrained
      // Get the length of the contig or the map sequence
      let length = cgv.sequence.length;
      if (feature.contig) {
        if (mapContigNames.includes(feature.contig)) {
          const contig = cgv.contigs(feature.contig);
          length = contig.length;
        } else if (unknownContigsAction && unknownContigsAction != 'IGNORE' && mapContigNames.includes(unknownContigsAction)) {
          // ACTION: Unknown Contig -> Use Contig...
          feature.contig = unknownContigsAction;
          // Add the changed contig to the fileRecords, so we can access in the features issue table
          fileFeature.newContig = unknownContigsAction;
          const contig = cgv.contigs(feature.contig);
          length = contig.length;
        } else {
          // ISSUE: Unknown Contig -> ignore
          continue;
        }
      } else {
        // ISSUE: No contig attribute
        if (noContigFeatureAction && noContigFeatureAction != 'IGNORE' && mapContigNames.includes(noContigFeatureAction)) {
          // ACTION: No Contig -> Use Contig...
          feature.contig = noContigFeatureAction;
          // Add the changed contig to the fileRecords, so we can access in the features issue table
          fileFeature.newContig = noContigFeatureAction;
          const contig = cgv.contigs(feature.contig);
          length = contig.length;
        } else if (noContigFeatureAction === 'IGNORE') {
          // ACTION: No Contig -> ignore
          continue;
        }

      }

      // ISSUE: constrained
      if (start > length || stop > length) {
        constrainedFeatureIndices.push(index)
        // Ignore constrained features
        continue;
      }

      // ISSUE: startOverStop
      if (start > stop) {
        startOverStopFeatureIndices.push(index)
      }

      features.push(feature);
    }
    console.log('MAP DATA FEATURES', features);
    this.onValidate(features.length > 0);
    this.setState({
      unknownContigs,
      unknownContigsAction,
      startOverStopFeatureIndices,
      constrainedFeatureIndices,
      noContigFeatureIndices,
      noContigFeatureAction,
      showConstrainedFeatures,
      showStartOverStopFeatures,
      showFinalFeatures,
      mapData: { features },
    });

  }

  resetState() {
    this.setState({
      // ISSUE: Unknown Contigs
      unknownContigs: [],
      unknownContigsAction: 'IGNORE',
      // ISSUE: Start > Stop
      startOverStopFeatureIndices: [],
      showStartOverStopFeatures: false,
      // ISSUE: Constrained
      constrainedFeatureIndices: [],
      showConstrainedFeatures: false,
      // ISSUE: No Contig Attribute
      noContigFeatureIndices: [],
      noContigFeatureAction: 'IGNORE',
      showNoContigFeatures: false,

      showFinalFeatures: false,
      mapData: null,
      valid: false,

      // unknownContigs: [],
      // actionUnknownContigs: 'IGNORE',
      // startOverStopFeatureIndices: [],
      // constrainedFeatureIndices: [],
      // noContigFeatureIndices: [],
      // noContigFeatureAction: 'IGNORE',
      // showConstrainedFeatures: false,
      // showStartOverStopFeatures: false,
      // mapData: null,
      // valid: false,
    });
  }

  renderSettingsHaveChanged() {
    const { settingsHaveChanged, options } = this.state;
    // console.log('SETTINGS HAVE CHANGED', this.state);
    if (settingsHaveChanged && options?.file ) {
      return (
        <div className='file-issue'>
          <p className='heading'><strong>Settings have changed</strong></p>
          <p className='issue-text'>Reparse the file to see the updated features</p>
        </div>
      )
    } else {
      return <div className='file-issue closed'></div>;
    }
  }

  renderUnknownContigs() {
    const { unknownContigs, unknownContigsAction, settingsHaveChanged } = this.state;
    if (!settingsHaveChanged && unknownContigs?.length > 0) {
      // Undefined contig is a special case and is dealt with by the noContigFeatureIndices issue
      if (unknownContigs.length === 1 && unknownContigs[0] === undefined) { return; }
      const cgv = this.cgv;
      const mapContigNames = cgv.contigs().map( c => c.name );
      const selectedContig = mapContigNames.includes(unknownContigsAction) ? unknownContigsAction : mapContigNames[0];
      const contigSelect = unknownContigsAction && unknownContigsAction != 'IGNORE' && (
        <DataElement>
          <Select
            onChange={ (value) => this.setState({unknownContigsAction: value}, () => this.updateFeatures()) }
            value={selectedContig}
          >
            { cgv.contigs().map( c => <Option key={c.name}>{c.name}</Option> ) }
          </Select>
        </DataElement>
      );
      return (
        <div className='file-issue'>
          <div className='heading'><div><span className='label-badge label-issue'>Issue</span>The following contigs ({unknownContigs.length}) do not exist in the map:</div></div>
          <div className='contig-list scroll-skinny'>{unknownContigs.join(', ')}</div>
          <p className='action'><span className='label-badge label-action'>Action</span>Features with {(unknownContigs.length > 1) ? 'these contigs' : 'this contig'}:</p>
          {/* <DataElementGroup label={`Action: Features With ${(unknownContigs.length > 1) ? 'These Contigs' : 'This Contig'}`}> */}
          <DataElementGroup>
            <DataElement>
              <ButtonGroup>
                <Button onClick={() => {this.setState({unknownContigsAction: 'IGNORE'}, () => this.updateFeatures())}} active={unknownContigsAction == 'IGNORE'} width='100px'>Ignore</Button>
                <Button onClick={() => {this.setState({unknownContigsAction: mapContigNames[0]}, () => this.updateFeatures())}} active={unknownContigsAction != 'IGNORE'} width='100px'>Use Contig</Button>
              </ButtonGroup>
            </DataElement>
            {contigSelect}
          </DataElementGroup>
        </div>
      )
    } else {
      return <div className='file-issue closed'></div>;
    }
  }

  // Based on renderUnknownContigs
  renderFeaturesNoContig() {
    const { noContigFeatureIndices, showNoContigFeatures, noContigFeatureAction, settingsHaveChanged, fileRecords} = this.state;
    if (!settingsHaveChanged && noContigFeatureIndices?.length > 0) {
      // Undefined contig is a special case and is dealt with by the noContigFeatureIndices issue
      if (noContigFeatureIndices.length === 1 && noContigFeatureIndices[0] === undefined) { return; }
      const featuresWithIndices = noContigFeatureIndices.map( i => ({index: i, ...fileRecords[i]}));
      // const rows = noContigFeatureIndices;
      const cgv = this.cgv;
      const mapContigNames = cgv.contigs().map( c => c.name );
      const selectedContig = mapContigNames.includes(noContigFeatureAction) ? noContigFeatureAction : mapContigNames[0];
      const contigSelect = noContigFeatureAction && noContigFeatureAction != 'IGNORE' && (
        <DataElement className='element-full'>
          <Select
            onChange={ (value) => this.setState({noContigFeatureAction: value}, () => this.updateFeatures()) }
            value={selectedContig}
          >
            { cgv.contigs().map( c => <Option key={c.name}>{c.name}</Option> ) }
          </Select>
        </DataElement>
      ) || <DataElement className='element-empty' />;
      return (
        <div className='file-issue'>
          <div className='heading'>
            <div><span className='label-badge label-issue'>Issue</span>Features with no <em>contig</em> attribute:</div>
            { this.renderCountWithViewButton(noContigFeatureIndices.length, showNoContigFeatures, 'showNoContigFeatures') }
          </div>
          {/* { showNoContigFeatures && this.renderFeatureTable(featuresWithIndices)} */}
          { this.renderFeatureTable(featuresWithIndices, showNoContigFeatures, {footer: "These features had no contig attribute"}) }
          <p className='action'><span className='label-badge label-action'>Action</span>Features with no contigs:</p>
          {/* <DataElementGroup label='Action: Features With No Contigs'> */}
          <DataElementGroup>
            <DataElement>
              <ButtonGroup>
                <Button onClick={() => {this.setState({noContigFeatureAction: 'IGNORE'}, () => this.updateFeatures())}} active={noContigFeatureAction == 'IGNORE'} width='100px'>Ignore</Button>
                <Button onClick={() => {this.setState({noContigFeatureAction: mapContigNames[0]}, () => this.updateFeatures())}} active={noContigFeatureAction != 'IGNORE'} width='100px'>Use Contig</Button>
              </ButtonGroup>
            </DataElement>
            {contigSelect}
          </DataElementGroup>
        </div>
      )
    } else {
      return <div className='file-issue closed'></div>;
    }
  }


  renderCountWithViewButton(count, showState, keyName) {
    return (
      <div className='count-with-view-btn'>
        {count.toLocaleString()}
        <Button height={18} width={40}
          onClick={ () => this.setState({[keyName]: !showState}) }
        >{showState ? 'Hide' : 'View'}</Button>
      </div>
    );
  }

  renderStartOverStopFeatures() {
    const { startOverStopFeatureIndices, showStartOverStopFeatures, fileRecords, settingsHaveChanged } = this.state;
    if (!settingsHaveChanged && startOverStopFeatureIndices?.length > 0) {
    // const headings = this.csv.meta.fields;
      // const rows = startOverStopFeatureIndices.map( i => ({row: i, ...this.csv.data[i]}));
      const featuresWithIndices = startOverStopFeatureIndices.map( i => ({index: i, ...fileRecords[i]}));
      return (
        <div className='file-issue'>
          <div className='heading'>
            <div><span className='label-badge label-issue'>Issue</span>Features found with the <em>start</em> <strong>&gt;</strong> <em>stop</em>:</div> 
            { this.renderCountWithViewButton(startOverStopFeatureIndices.length, showStartOverStopFeatures, 'showStartOverStopFeatures') }
          </div>
          {/* { showStartOverStopFeatures && this.renderFeatureTable(featuresWithIndices)} */}
          { this.renderFeatureTable(featuresWithIndices, showStartOverStopFeatures, {footer: "These features have a start position greater than the stop"}) }
          <p className='issue-text'>By default, these features will wrap around the entire map.</p>
          <p className='action'><span className='label-badge label-action'>Action</span>Swap start/stop position for these feature?</p>
          {this.renderInput('swapStartStop', {label: ' '})}
        </div>
      )
    } else {
      return <div className='file-issue closed'></div>;
    }
  }

          // { showStartOverStopFeatures && <div className='feature-list my-test scroll-skinny'>
          //     {rows.map( r => <p key={r.row}><strong>{r.row + 1}</strong>: Data... </p>)}
          //     {/* {rows.map( r => <p key={r.row}><strong>{r.row + 1}</strong>: {headings.map( h => r[h] ).join(', ')}</p>)} */}
          //   </div>
          // }
  renderConstrainedFeatures() {
    const { constrainedFeatureIndices, showConstrainedFeatures, fileRecords, settingsHaveChanged } = this.state;
    if (!settingsHaveChanged && constrainedFeatureIndices?.length > 0) {
      const featuresWithIndices = constrainedFeatureIndices.map( i => ({index: i, ...fileRecords[i]}));
      return (
        <div className='file-issue'>
          <div className='heading'>
            <div><span className='label-badge label-issue'>Issue</span>Features out of range:</div>
            { this.renderCountWithViewButton(constrainedFeatureIndices.length, showConstrainedFeatures, 'showConstrainedFeatures') }
            {/* <a href='#'
              onClick={ () => this.setState({showConstrainedFeatures: !showConstrainedFeatures}) }
            >{constrainedFeatureIndices.length}</a></p> */}
          </div>
          {/* { showConstrainedFeatures && this.renderFeatureTable(featuresWithIndices) } */}
          { this.renderFeatureTable(featuresWithIndices, showConstrainedFeatures, {footer: "These features have a start/stop position beyond the length of their contig", includeContigLength: true}) }
          <p className='action'><span className='label-badge label-action'>Action</span>Ignore these features</p>
        </div>
      )
    } else {
      return <div className='file-issue closed'></div>;
    }
  }

  // Features (that have an index property)
  // - We add 1 to the index to make it 1-based
  // - options:
  //   - show: boolean
  //   - includeContigLength: boolean
  renderFeatureTable(features, show, options = {}) {
    const footer = options.footer;
    const includeContigLength = options.includeContigLength;
    const showIndexColumn = options.showIndexColumn === undefined ? true : options.showIndexColumn;
    if (!Array.isArray(features)) { return; } 
    const maxRows = 100;
    const klass = classNames(
      'feature-list', 'scroll-skinny',
      {'show-table': show}
    );
    return (
      <div>
        <div className={klass}>
          <table>
            <thead>
              <tr>
                {showIndexColumn && <th>#</th>}
                <th>Type</th>
                <th>Name</th>
                <th>Start</th>
                <th>Stop</th>
                <th>Contig</th>
                {includeContigLength && <th>ContigLength</th>}
              </tr>
            </thead>
            <tbody>
              {features.slice(0, maxRows).map( r => (
                <tr key={r.index}>
                  {showIndexColumn && <td>{r.index + 1}</td>}
                  <td>{r.type}</td>
                  <td>{r.name}</td>
                  <td>{r.start?.toLocaleString()}</td>
                  <td>{r.stop?.toLocaleString()}</td>
                  <td>{r.newContig || r.contig}</td>
                  {includeContigLength && <td>{this.cgv?.contigs(r.newContig || r.contig)?.length?.toLocaleString() || "-"}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {show && footer && <p className='table-footer'><em>{footer}</em></p>}
        {show && (features.length > maxRows) &&
          <p className='table-footer'><em>Only the first {maxRows} features are shown</em></p>
        }
      </div>
    );
  }

  renderFeatureCountToAdd() {
    const { mapData, builderStatus, showFinalFeatures } = this.state;
    if (mapData?.features) {
      console.log('STAUTS', builderStatus);
      if (builderStatus === 'failed') {
        // FAILED
        return (
          <div className='file-errors'>
            The file could not be parsed. Please check the file and try again.
          </div>
        );
      } else {
        // PASSED: success or warnings
        return (
          <div className='final-results'>
            <div className='file-note features-to-add'>
              <div>Features that will be added:</div>
              <div className='final-feature-count'>{`${mapData.features.length.toLocaleString()}`}
                <Button height={18} width={40}
                  disabled={mapData?.features.length === 0}
                  onClick={ () => this.setState((state) => ({showFinalFeatures: !state.showFinalFeatures})) }>View</Button>
              </div>
            </div>
            { this.renderFeatureTable(mapData.features, showFinalFeatures, {footer: "These features will be added to the map", showIndexColumn: false}) }
          </div>
        );
      }
    }
  }
  // Use of 'key' will cause the component to be recreated if the key changes.
  // This calls the constructor which internally will call returnCurrentItem.
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions
  renderContents() {
    const { tool } = this.props;
    const { options, fileName } = this.state;
    const newLegendName = (options.legend && options.legend.name) || fileName;
    const newTrackName = (options.track && options.track.name) || fileName;
    const legendID = (options.legend && options.legend.cgvID) || tool.inputs.get('legend').default;
    const trackID = (options.track && options.track.cgvID) || tool.inputs.get('track').default;
    console.log('DIALOG ADD PROPS', this.props)
    console.log('DIALOG ADD STATE', this.state)
    const issueCount = this.dataIssueCount() || undefined;
    return (
      <div>
        {this.renderHeader()}
        {this.renderInput('file', {
          helpMore: FeatureHelp(),
          onValidate: this.onValidate,
          onChange: (file) => this.onFileChange({file}),
          onChangeSettings: ({attribute, value}) => this.onChangeSettings({attribute, value}),
        })}
        {issueCount && <fieldset className='issues-section'><legend>{(issueCount > 1) ? `${issueCount} Issues` : '1 Issue'}</legend></fieldset>}
        {this.renderSettingsHaveChanged()}
        {this.renderUnknownContigs()}
        {this.renderFeaturesNoContig()}
        {this.renderConstrainedFeatures()}
        {this.renderStartOverStopFeatures()}
        {issueCount && <fieldset className='issues-section'></fieldset>}
        {this.renderFeatureCountToAdd()}
        {this.renderInput('track', {defaultNewItemName: newTrackName, default: trackID, key: fileName})}
        {this.renderInput('legend', {defaultNewItemName: newLegendName, default: legendID, key: fileName})}

        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;


  // renderNoContigColumn() {
  //   const { noContigColumn } = this.state;
  //   if (noContigColumn) {
  //     return (
  //       <div className='file-issue'>
  //         <p className='heading'>No <em>contig</em> attribute was found</p>
  //         <p>
  //           Your map has mulitple contigs.
  //           Features will be added to the Map Sequence and will be independent of the contigs.
  //           This means if contigs are later reordered, the features will not move.
  //           This may have odd side effects.
  //         </p>
  //       </div>
  //     )
  //   }
  // }