import React from 'react'
import PropTypes from 'prop-types'
import * as helpers from '../proksee-viewer/support/Helpers';

class JobStatus extends React.Component {

  static propTypes = {
    jobStatus: PropTypes.shape({
      status: PropTypes.string,
      step: PropTypes.string,
      position: PropTypes.number,
      finalized: PropTypes.bool,
      running: PropTypes.bool,
    }),
    large: PropTypes.bool,
  }

  static defaultProps = {
    large: false,
  }

  constructor(props) {
    super(props);
    this.displayStatus = this.displayStatus.bind(this);
    this.displayQueuePosition = this.displayQueuePosition.bind(this);
  }

  displaySpinner() {
    const {jobStatus, large} = this.props;
    const klass = large ? 'spinner-lg' : 'spinner';
    if (jobStatus.running) {
      return <span className={klass} />
    }
  }

  displayQueuePosition() {
    const position = this.props.jobStatus.position
    // console.log(position)
    // Important to use != and not !== as the position may be null
    if (position != undefined) {
      let nextPosition = position + 1;
      const submission = (nextPosition > 1) ? 'submissions' : 'submission';
      return `${nextPosition} ${submission} ahead of yours`
    } else {
      return "You're next!"
    }
  }

  displayStatus() {
    const {jobStatus, large} = this.props;
    let status = helpers.capitalize(jobStatus.status);
    if (status === 'Queued') {
      status += ` (${this.displayQueuePosition()})`;
    }
    if (jobStatus.step) {
      status += ` - ${jobStatus.step}`;
    }
    if (!jobStatus.finalized) {
      status += '...';
    }
    return <span style={{color: 'var(--theme-text-color)'}}> {status}</span>
  }

  render() {
    const { job, large } = this.props;

    return (
      <div className='JobStatus'>
        {this.displaySpinner()}
        {this.displayStatus()}
      </div>
    )
  }

}
export default JobStatus;

