import { FEATURES_ADD, FEATURES_UPDATE, FEATURES_REMOVE, FEATURES_UPDATE_STATS } from '../constants/ActionTypes'

export const addFeatures = (features) => ({
  type: FEATURES_ADD,
  payload: {
    features,
  }
});

export const removeFeatures = (features) => ({
  type: FEATURES_REMOVE,
  payload: {
    features,
  }
});

export const updateFeatures = (features, attributes, updates) => ({
  type: FEATURES_UPDATE,
  payload: {
    features,
    attributes,
    updates,
  }
});

export const updateFeatureStats = (cgv) => ({
  type: FEATURES_UPDATE_STATS,
  payload: { 
    cgv,
  }
});
