import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import Input from '../models/Input';
import DataElement from './DataElement';
import TextElement from './TextElement';
import DefaultSettingsManager from './DefaultSettingsManager';
import { CGViewContext } from '../app/CGViewContext';
import './DefaultDialog.css';
// import classNames from 'classnames';
import * as helpers from '../support/Helpers';

class DefaultDialog extends React.Component {
  static contextType = CGViewContext;

  static propTypes = {
    tool:        PropTypes.object,
    // Right now job is only available for add dialogs (server-based tools)
    job:         PropTypes.object,
    componentID: PropTypes.string,
    onClose:     PropTypes.func,
    mapData:     PropTypes.object,
  }

  constructor(props, context) {
    super(props, context);
    this.component = props.tool.components[props.componentID] || {};
    this.initializeState();
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.cgv = this.context.cgv;
    this.helpers = helpers;
    // this.defaultSettingsManagerRef = React.createRef();
  }

  // This puts the default values in state.options. Otherwise options
  // would be empty if the user changes nothing in the dialog before
  // pressing OK.
  initializeState() {
    const inputs = this.props.tool.inputsForTarget(this.component.id);
    const options = {};
    for (const input of inputs) {
      // console.log(input.id, input.default, input.defaultValue());
      options[input.id] = input.defaultValue();
    }
    this.state = {
      options,
      valid: this.component.valid,
      mapData: this.props.mapData,
      resetKey: 1,
    };
    // Get inputs targeted to this dialog.
  }

  onChange({attribute, value}, callback) {
    // console.log(attribute, value)
    this.setState((state) => ({
      options: {
        ...state.options,
        [attribute]: value,
      }
    }), callback);
  }

  // Add any options to the provided onClose function
  onClose({action, dialog} = {}) {
    const { options } = this.state;
    // console.log(options)
    this.saveDefaultSettings(action);
    const mapData = this.state.mapData && {...this.state.mapData};
    this.props.onClose({action, options, dialog, mapData});
  }

  onValidate(valid) {
    this.setState({valid});
  }

  renderInput(id, props) {
    const { tool } = this.props;
    // const value = this.state.options[id];
    // return tool.inputs.get(id).render(this.onChange, props, value);
    return tool.inputs.get(id).render(this.onChange, props);
  }

  renderInputsForTarget(target) {
    const { tool } = this.props;
    return tool.renderInputsForTarget(target, this.onChange);
  }

  renderHeader() {
    const { tool, mapData } = this.props;
    const summary =  (mapData && this.component.mapDataSummary && this.renderMapDataSummary())
    const description = this.renderDescription();
    return (
      <div className='dialog-header'>
        {summary}
        {description}
      </div>
    );
  }

  renderMapDataSummary() {
    const { tool, mapData } = this.props;
    const names = ['Features', 'Plots', 'Tracks', 'Captions'];
    const counts = {};
    let text = <ul><li>Nothing was found.</li></ul>;
    if (mapData) {
      for (const name of names) {
        const key = name.toLowerCase();
        counts[name] = mapData[key] && helpers.commaNumber(mapData[key].length) || 0;
      }
      const summary = names.filter( n => counts[n] ).map( n => <li key='{n}'>{n}: {counts[n]}</li> )
      if (summary.length > 0) {
        text = <ul>{summary}</ul>;
      }
    }

    const toolName = tool.short_name || tool.name;

    return (
      <DataElement label={`${toolName} found the following:`}>
        <TextElement>
          {text}
        </TextElement>
      </DataElement>
    )
  }

  renderDescription() {
    const { tool } = this.props;
    const description = (this.component.description) ? this.component.description : tool.description;
    return <div>{description}</div>
  }

  // Render a notice with a light bulb emoji
  // Used to show tips (e.g. don't forget to save the changes)
  renderTips(tip) {
    const defaultTip = <span>Don't forget to <span className='save-changes'>Save Changes</span> to your map</span>
    tip = tip || defaultTip;
    return (
      <div className='tip-alert'>
        💡- {tip}
      </div>
    )
  }

  // Render a notice with a stop sign emoji
  // Used to indicate that we can't continue (e.g. not valid).
  renderStop(msg) {
    return (
      <div className='tip-alert'>
        🛑 - {msg}
      </div>
    )
  }

  // **********************************************
  // Default Settings Manager
  // **********************************************
  resetOptions() {
    const { tool, componentID } = this.props;
    // Reset LocalStorage
    const storageKey = `dsm.${tool.id}.${componentID}`;
    localStorage.removeItem(storageKey);
    // Reset state
    const inputs = tool.inputsForTarget(componentID);
    const options = {};
    for (const input of inputs) {
      options[input.id] = input.default;
    }
    this.setState((prevState) => ({
      options,
      resetKey: prevState.resetKey + 1,
    }));
  }

  renderDefaultSettingsManager() {
    const { tool, componentID } = this.props
      //<DefaultSettingsManager testFunc={() => this.saveDefaultSettings('ok')} tool={tool} componentID={componentID} toolOptions={this.state.options} resetFunc={ () => this.resetOptions() }/>
    return (
      <DefaultSettingsManager tool={tool} componentID={componentID} toolOptions={this.state.options} resetFunc={ () => this.resetOptions() }/>
    );
  }

  saveDefaultSettings(action) {
    const { tool, componentID } = this.props;
    const storageKey = `dsm.${tool.id}.${componentID}`;
    if (action == 'ok') {
      const inputs = tool.inputsForTarget(this.component.id);
      const options = {};
      for (const input of inputs) {
        // console.log(input)
        if (!input.autoSave) { continue; }
        // REPLACED: should test
        // if (input.autoSave) {
        //   if (this.state.options[input.id] !== input.default) {
        //     options[input.id] = this.state.options[input.id];
        //   }
        // }
        if (Array.isArray(input.default)) {
          if (!helpers.arraysEqual(this.state.options[input.id], input.default)) {
            options[input.id] = this.state.options[input.id];
          }
        } else if (this.state.options[input.id] !== input.default) {
          options[input.id] = this.state.options[input.id];
        }
      }
      if (Object.keys(options).length > 0) {
        localStorage.setItem(storageKey, JSON.stringify(options));
      } else {
        localStorage.removeItem(storageKey);
      }
    }
  }
  // **********************************************
  // **********************************************

  // Override in subclasses
  renderContents() {
  }

  render() {
    const { valid } = this.state;
    this.cgv = this.context.cgv;
    return (
      <Dialog
        {...this.props}
        title={this.component.title}
        onClose={this.onClose}
        okButtonProps={{disabled: !valid}}
        key={this.state.resetKey}
      >
        {this.renderContents()}
      </Dialog>
    );
  }
}

// DefaultDialog.contextType = CGViewContext;

export default DefaultDialog;
