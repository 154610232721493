document.addEventListener('DOMContentLoaded', () => {

  const toggler = document.getElementById('ps-navbar-toggler-btn');
  const content = document.getElementById('toggled-content');

  toggler.addEventListener('click', (e) => {
    if (content.classList.contains('toggle-open')) {
      content.classList.remove('toggle-open');
      toggler.classList.remove('toggle-open');
    } else {
      content.classList.add('toggle-open');
      toggler.classList.add('toggle-open');
    }
  });

});

