import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';
import classNames from 'classnames';
import * as helpers from '../../../support/Helpers';
import './ReportCard.css';

class ReportCard extends ReportCardBase {

  get extractType() {
    return 'csv';
  }

  renderFastANI(csv) {
    const { job } = this.props;
    console.log(job)
    const comma = helpers.commaNumber;
    if (csv && csv[0]) {
      const row = csv[0];
      if (row.length === 1) {
        return this.renderAlert('ANI was not reported. NOTE: No ANI output is reported for a genome pair if the ANI value is below 80%.');
      } else if (row.length === 5) {
        const ani = row[2];
        const orthologous_matchs = row[3];
        const query_seq_fragments = row[4];
        const reference = this.cgv && this.cgv.name;
        const query = job && job.inputs && job.inputs.queryName || 'Name not found';
        return (
          <div>
            <table className='proksee-stats striped more-width'>
              <thead>
                <tr className='table-title'><th colSpan='3'>FastANI Results</th></tr>
              </thead>
              <tbody>
                <tr><td className='left first-column'>Average Nucleotide Identity (ANI)</td><td><strong>{ani}</strong></td></tr>
                <tr><td className='left'>Reference (Project Genome)</td><td>{reference}</td></tr>
                <tr><td className='left'>Query (Uploaded Genome)</td><td>{query}</td></tr>
                <tr><td className='left'>Query Sequence Fragments</td><td>{comma(query_seq_fragments)}</td></tr>
                <tr><td className='left'>Orthologous Matches</td><td>{comma(orthologous_matchs)}</td></tr>
              </tbody>
            </table>
            { this.renderImageForPath('output_fastani/fastani.out.svg') }
            { this.renderCaption('Each red line segment denotes a reciprocal mapping between the query and reference genome, indicating their evolutionary conserved regions.') }
          </div>
        );
      } else {
        return this.renderAlert('Could not read ANI results');
      }
    } else {
        return this.renderAlert('Could not read ANI results');
    }
  }

  renderData(csv) {
    return (
      <div className='report-card'>
        { this.renderReportHeader() }
        { this.renderReportFailed() }
        { this.renderFastANI(csv) }
        { this.renderFeaturedFilesTable() }
        { this.renderHelpfulLinks() }
        { this.renderCitation() }
      </div>
    );
  }

}

export default ReportCard;

