import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './TextElement.css';

TextElement.propTypes = {
  className: PropTypes.string,
  // children:  PropTypes.node.isRequired,
}

// TextElement.defaultProps = {
//   disabled: false,
// }

function TextElement(props) {
  const {
    className,
    children,
    clickable,
    // help,
    // disabled=false,
    ...other
  } = props;


  const elementClass = classNames(
    'TextElement', className,
    {clickable}
  );

  // The inner div is used to center the text vertically via flex
  return (
    <div {...other} className={elementClass}>
      <div>
        {children}
      </div>
    </div>
  )
}

export default TextElement;

