import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElement from '../../../presenters/DataElement';
import DataElementGroup from '../../../presenters/DataElementGroup';
import DataElementContainer from '../../../presenters/DataElementContainer';

class DialogAdd extends DefaultDialog {

  renderContents() {
    const { tool, mapData } = this.props;

    const features = mapData.features || [];
    const counts = {
      crisprs: features.filter( f => f.type === 'CRISPR').length,
      genes:    features.filter( f => f.type === 'CDS').length,
      clusters: features.filter( f => f.type === 'Cas Cluster').length
    };

    return (
      <div>
        {this.renderHeader()}

        <DataElementContainer label='Features to Add' maxHeight='130px'>
          <DataElementGroup>
            {this.renderInput('add_crisprs', {help: `Count: ${counts.crisprs}`, align: 'center'})}
            {this.renderInput('add_cas_genes', {help: `Count: ${counts.genes}`, align: 'center'})}
            {this.renderInput('add_cas_clusters', {help: `Count: ${counts.clusters}`, align: 'center'})}
          </DataElementGroup>
          <div style={{textAlign: 'center', width: '100%', fontSize: '0.65rem'}} className='help-text'>Cas clusters are single features enclosing a set of Cas genes</div>
        </DataElementContainer>

        {this.renderInput('track')}
        {this.renderInput('legend')}

        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}
          // {this.renderInput('add_crisprs', {width: '100px'})}
          // {this.renderInput('add_cas_clusters', {width: '100px'})}
          // {this.renderInput('add_cas_genes', {width: '100px'})}

export default DialogAdd;

