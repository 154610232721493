import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';

class DialogStart extends DefaultDialog {

  onFileChange({attribute, value}) {
    this.onChange({attribute, value});
    this.onChange({attribute: 'queryName', value: value.fileName});
  }

  renderContents() {
    const { tool } = this.props;
    const { valid } = this.state;

    return (
      <div>
        {this.renderHeader()}
        {this.renderInput('query', {
          onValidate: this.onValidate,
          onChange: (file) => this.onFileChange({attribute: 'query', value: file}),
        })}
        {this.renderInput('jobName')}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

