import { UI_SQUARE_LOCK_CHANGE, UI_THEME_UPDATE } from '../constants/ActionTypes'

const initialState = {
  squareLock: false,
  theme: 'light',
};

export default (state = initialState, action) => {
  switch(action.type) {
    case UI_SQUARE_LOCK_CHANGE: {
      return {
        ...state,
        squareLock: Boolean(action.payload.squareLock),
      }
    };
    case UI_THEME_UPDATE: {
      return {
        ...state,
        theme: action.payload.theme,
      }
    };
    default: return state;
  }
};
