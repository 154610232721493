import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';
import ImageButton from '../../../presenters/ImageButton';
import './ReportCard.css';

class ReportCard extends ReportCardBase {

  renderMitosWarnings(mapData) {
    if (!mapData) return;
    const expectedFeatures = [
      'OH', 'OL',
      'cox1', 'cox2', 'cox3', 'cob', 'atp6', 'atp8', 'nad1', 'nad2', 'nad3', 'nad4', 'nad4l', 'nad5', 'nad6',
      'rrnS', 'rrnL',
      'trnA', 'trnC', 'trnD', 'trnE', 'trnF', 'trnG', 'trnH', 'trnI',
      'trnK', 'trnL1', 'trnL2', 'trnM', 'trnN', 'trnP', 'trnQ', 'trnR',
      'trnS1', 'trnS2', 'trnT', 'trnW', 'trnY', 'trnV',
    ];
    const missingFeatures = [];
    const duplicateFeatures = [];
    const features = mapData.features
    const featureNames = features.map(f => f.name.split(/[_\(\-]/)[0])
    for (const expected of expectedFeatures) {
      // const foundCount = features.filter(f => f.name === expected).length;
      const foundCount = featureNames.filter(name => name === expected).length;
      if (foundCount === 0) {
        missingFeatures.push(expected);
      } else if (foundCount > 1) {
        duplicateFeatures.push(expected);
      }
    }
    // console.log("Missing", missingFeatures)
    // console.log("Duplicates", duplicateFeatures)
    // if (missingFeatures.length === 0 && duplicateFeatures.length === 0) return;
    if (missingFeatures.length === 0) { missingFeatures.push('-'); }
    if (duplicateFeatures.length === 0) { duplicateFeatures.push('-'); }
    return (
      <table className='p-table more-width striped mitos-warnings'>
        <thead>
          <tr className='table-title'><th colSpan="2">Mitos Warnings</th></tr>
        </thead>
        <tbody>
          <tr><td>Features not Found</td><td>{missingFeatures.join(', ')}</td></tr>
          <tr><td>Duplicate Features</td><td>{duplicateFeatures.join(', ')}</td></tr>
          <tr><td colSpan="2">
            <div className="check-log-row">
              <div>Check the log for other possible warnings</div>
              <ImageButton text='Open Log' imageName='log' width={105} className='failed-open-btns' onClick={() => {this.openLog()}} />
            </div>
          </td></tr>
        </tbody>
      </table>
    );
  }

  renderData(mapData) {
    return (
      <div className='report-card'>
        { this.renderReportHeader() }
        { this.renderReportFailed() }
        { this.renderMapResultsTable(mapData) }
        { this.renderMitosWarnings(mapData) }
        { this.renderFeaturedFilesTable() }
        { this.renderHelpfulLinks() }
        { this.renderCitation() }
      </div>
    );
  }

}

export default ReportCard;

