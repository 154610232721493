import React from 'react';
import PropTypes from 'prop-types';
import './JobTab.css';
import JobSummary from '../tabs/JobSummary';
import {JobCards} from '../tabs/JobCards';
import FavoriteButton from '../presenters/FavoriteButton';
import ServerAPI from '../models/ServerAPI';
import * as helpers from '../support/Helpers';

// Connected
import { connect } from 'react-redux';

// FIXME: This name will change to JobTab
class JobTab extends React.Component {

  static propTypes = {
    id: PropTypes.number,
    job: PropTypes.object,
    summaryOpen: PropTypes.bool,
    initialJobCard: PropTypes.string,
  }

  static defaultProps = {
    summaryOpen: true,
    initialJobCard: 'report',
  }

  // Error: At top of page only if there are any errors

  render() {
    const { job, summaryOpen, initialJobCard } = this.props;
    const Server = new ServerAPI();

    // const jobTabMessage =  (
    const jobTabMessage = job?.finalized ? '' : (
      <div className='job-tab-message-div'>
        <FavoriteButton favorite={job.favorite} type='plain' onClick={ (value) => Server.patchJob(job.id, {favorite: value}) } />
        <div style={{padding: '0 5px'}}>←</div>
        <div>Favorite this job to autoload the tab when returning to this project.</div>
      </div>
    );

    return (
      <div className='JobTab'>
        <JobSummary job={job} summaryOpen={summaryOpen} />
        {jobTabMessage}
        <JobCards job={job} initialKey={initialJobCard} />
      </div>
    );
  }
}

//Connected
const jobTabMapStateToProps = (state, ownProps) => ({ job: state.jobs.byID[ownProps.id] });
const ConnectedJobTab = connect(jobTabMapStateToProps, null, null, {forwardRef: true})(JobTab);

// export default JobTab;
export { JobTab, ConnectedJobTab};
