import React from 'react';
import './DownloadPane.css';
import { CGViewContext } from '../app/CGViewContext';
import { ConnectedImageDownload } from './download/ImageDownload';
import SVGDownload from './download/SVGDownload';
import JSONDownload from './download/JSONDownload';
import SequenceDownload from './download/SequenceDownload';

class DownloadPane extends React.Component {

  render() {
    const userRole = this.context.userRole;
    // Temporary testing of SVG
    // const svgDownload = ['tester', 'admin'].includes(userRole) ?  <SVGDownload /> : '';
    return (
      <div className='DownloadPane'>
        <ConnectedImageDownload />
        <SVGDownload />
        <JSONDownload />
        <SequenceDownload />
      </div>
    );
  }
}

DownloadPane.contextType = CGViewContext;

// export default SettingsPane;
export default DownloadPane;

