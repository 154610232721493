import React from 'react';
import BasicDialog from '../../presenters/BasicDialog';
import DataElement from '../../presenters/DataElement';
import DataElementGroup from '../../presenters/DataElementGroup';
import { Select, Option } from '../../presenters/Select';
import SelectWithNew from '../../presenters/SelectWithNew';
import { ConnectedSelectLegend } from '../../containers/SelectLegend';

// EXPECTED PROPS:
// - featureIDs: array of cgvIDs for the features to delete
// - featureTypes: array of featureTypes

class FeaturesEditDialog extends BasicDialog {

  static defaultProps = {
    options: {
      favorite: 'NO_CHANGE',
      visible: 'NO_CHANGE',
      legendItem: 'NO_CHANGE',
      tags: ['NO_CHANGE'],
      // source: 'NO_CHANGE',
      type: 'NO_CHANGE',
    },
    valid: true,
  }

  renderTitle() {
    const {featureIDs} = this.props;
    return `Edit Features: ${featureIDs.length} selected`;
  }

  onLocalClose({action, options}) {
    // console.log(action, options)
    const {featureIDs} = this.props;
    const cgv = this.cgv;

    const features = cgv.objects(featureIDs);
    const updates = {}
    if (action === 'ok') {
      // TYPE
      if (options.type && options.type.cgvID !== 'NO_CHANGE') {
        updates.type = (options.type.cgvID === 'NEW') ? options.type.name : options.type.cgvID;
      }
      // LEGEND
      const legendData = options.legendItem;
      if (legendData && legendData.cgvID !== 'NO_CHANGE') {
        let legend;
        if (legendData.cgvID === 'NEW') {
          legend = cgv.legend.addItems({
            name: legendData.name,
            swatchColor: legendData.swatchColor
          })[0];
        } else {
          legend = cgv.objects(legendData.cgvID);
        }
        updates.legendItem = legend;
      }
      // TAGS
      if (options.tags && !options.tags.includes('NO_CHANGE')) {
        console.log(options.tags)
        updates.tags = options.tags;
      // if (options.source && options.source.cgvID !== 'NO_CHANGE') {
        // updates.source = (options.source.cgvID === 'NEW') ? options.source.name : options.source.cgvID;
      }
      // SOURCE
      // if (options.source && options.source.cgvID !== 'NO_CHANGE') {
      //   updates.source = (options.source.cgvID === 'NEW') ? options.source.name : options.source.cgvID;
      // }
      // FAVORITE
      if (options.favorite && options.favorite !== 'NO_CHANGE') {
        updates.favorite = (options.favorite === 'yes');
      }
      // VISIBLE
      if (options.visible && options.visible !== 'NO_CHANGE') {
        updates.visible = (options.visible === 'yes');
      }
      // Update
      if (Object.keys(updates).length > 0) {
        cgv.updateFeatures(features, updates);
      }
    }
    cgv.draw();
  }

  renderContents() {
    const {featureIDs, featureTypes} = this.props;
    const { options } = this.state;
    const cgv = this.cgv;

    const features = cgv.objects(featureIDs);
    // Current attributes for these features
    const currentTypes = features.map( f => f.type).unique();
    const currentLegends = features.map( f => f.legendItem.name).unique();
    const currentTags = [...new Set(features.map( f => f.tags).flat())];
    // const currentSources = features.map( f => f.source).unique();
    const currentFavoritesCount = features.filter( f => f.favorite).length;
    const currentVisibleCount = features.filter( f => f.visible).length;
    // Creating objects to conform to SelectWithNew
    //    - We may want to make a general method to do this in the SelectWithNew class
    const types = {}
    featureTypes.forEach( t => types[t] = {cgvID: t, name: t});
    const featureTypesObject = {
      ids: featureTypes,
      byID: types,
    }
    // const sources = {}
    // cgv.sources().forEach( s => sources[s] = {cgvID: s, name: s});
    // const featureSourcesObject = {
    //   ids: Object.keys(sources),
    //   byID: sources,
    // }
    const yesNoObject = {
      NO_CHANGE: 'No Change',
      yes: 'Yes',
      no: 'No',
    }
    const yesNoOptions = Object.keys(yesNoObject).map( k => <Option key={k} value={k}>{yesNoObject[k]}</Option>);

    // Tags
    const tagOptions = cgv.tags().map( s => <Option key={s}>{s}</Option> );
    tagOptions.unshift(<Option key='no_change' value='NO_CHANGE'>No Change</Option>);
    // Remove NO_CHANGE option unless it's the only tag
    // console.log(options.tags)
    if (Array.isArray(options.tags) && options.tags.length !== 1) {
      // Last option added was NO_CHANGE, so lets remove others
      if (options.tags[options.tags.length - 1] === 'NO_CHANGE') {
        options.tags = ['NO_CHANGE'];
      } else {
      // Other options added, so lets remove NO_CHANGE
        options.tags = options.tags.filter( i => i !== 'NO_CHANGE');
      }
    }

    return (
      <div>
        <DataElementGroup label='' help='Choose whether all these features should be visible and or favorites.'>
          <DataElement label='Favorite' help={`Currently Favorited: ${currentFavoritesCount}`}>
            <Select value={options.favorite || 'NO_CHANGE'}
              onChange={(value) => this.onChange({attribute: 'favorite', value})}
            >
              { yesNoOptions }
            </Select>
          </DataElement>
          <DataElement label='Visible' help={`Currently Visible: ${currentVisibleCount}`}>
            <Select value={options.visible || 'NO_CHANGE'}
              onChange={(value) => this.onChange({attribute: 'visible', value})}
            >
              { yesNoOptions }
            </Select>
          </DataElement>
        </DataElementGroup>
        <DataElement label='' help={`Current Types: ${currentTypes.join(', ')}`}>
          <SelectWithNew value={options.type}
            callInitialOnChange
            help='Choose or create a type for these features.'
            title='Type'
            items={featureTypesObject}
            newItemLabel='Type'
            defaultNewItemName=''
            additionalItems={{NO_CHANGE: 'No Change'}}
            defaultItemID='NO_CHANGE'
            onChange={(value) => this.onChange({attribute: 'type', value})}
          />
        </DataElement>
        <DataElement label='' help={`Current Legends: ${currentLegends.join(', ')}`}>
          <ConnectedSelectLegend
            callInitialOnChange
            help='Choose or create a legend for these features.'
            additionalItems={{NO_CHANGE: 'No Change'}}
            defaultItemID='NO_CHANGE'
            defaultNewItemName=''
            onChange={(value) => this.onChange({attribute: 'legendItem', value})}
          />
        </DataElement>
        <DataElement label='Tags' help={`Current tags: ${currentTags.join(', ')}`}>
          <DataElementGroup help='Choose or create tags for these features.'>
            <Select mode='tags' value={options.tags || ['NO_CHANGE']}
              placeholder='All tags will be removed!'
              onChange={(value) => this.onChange({attribute: 'tags', value})}
            >
              { tagOptions }
            </Select>
          </DataElementGroup>
        </DataElement>
      </div>
    )
  }
        // <DataElement label='' help={`Current Sources: ${currentSources.join(', ')}`}>
        //   <SelectWithNew value={options.source}
        //     callInitialOnChange
        //     help='Choose or create a source for these features.'
        //     title='Source'
        //     items={featureSourcesObject}
        //     newItemLabel='Source'
        //     defaultNewItemName=''
        //     additionalItems={{NO_CHANGE: 'No Change'}}
        //     defaultItemID='NO_CHANGE'
        //     onChange={(value) => this.onChange({attribute: 'source', value})}
        //   />
        // </DataElement>
}

export default FeaturesEditDialog;
