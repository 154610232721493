import { LEGEND_UPDATE, LEGEND_ITEMS_ADD, LEGEND_ITEMS_REMOVE, LEGEND_ITEMS_UPDATE, LEGEND_ITEMS_MOVED } from '../constants/ActionTypes'

export const updateLegend = (attributes) => ({
  type: LEGEND_UPDATE,
  payload: {
    attributes,
  }
});

export const addLegendItems = (legendItems) => ({
  type: LEGEND_ITEMS_ADD,
  payload: {
    legendItems,
  }
});

export const removeLegendItems = (legendItems) => ({
  type: LEGEND_ITEMS_REMOVE,
  payload: {
    legendItems,
  }
});

export const updateLegendItems = (legendItems, attributes) => ({
  type: LEGEND_ITEMS_UPDATE,
  payload: {
    legendItems,
    attributes,
  }
});

export const moveLegendItems = (oldIndex, newIndex) => ({
  type: LEGEND_ITEMS_MOVED,
  payload: {
    oldIndex,
    newIndex,
  }
});
