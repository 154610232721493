import React from 'react';
// import File from './File';
import * as helpers from '../../support/Helpers';
import * as validations from '../../support/Validations';
import Papa from 'papaparse'

class CSVFile {

  constructor(file) {
    this._file = file;
  }

  get file() {
    return this._file;
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'csv';
  }

  get metaKeys() {
    return ['columns', 'count'];
  }

  get validationKeys() {
    return ['allowedColumns', 'requiredColumns', 'rowCount'];
  }

  processText(text) {
    // const csv = Papa.parse(this.file.text, {
    const csv = Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      // dynamicTyping: true,
      comments: '#',
      transformHeader: (h) => h.trim().toLowerCase(),
      transform: (d) => d.trim(),
      // error: (error, file) => {this.delegate.fileError = error}
      error: (error, file) => {console.log(error)}
    });
    this.columns = csv.meta.fields;
    this.count = csv.data.length;
    console.log(csv)
  }

  validate(rules={}) {
    const validationErrors = [];
    const { allowedColumns, requiredColumns, rowCount } = rules;
    const v = validations;
    const details = this.file.details();

    if (requiredColumns) {
      const columns = details.meta.columns;
      const missingColumns = [];
      for (const requiredColumn of requiredColumns) {
        if (!v.validateString(requiredColumn, columns)) {
          missingColumns.push(requiredColumn);
        }
      }
      if (missingColumns.length > 0) {
        validationErrors.push(`Missing Columns: ${missingColumns.join(', ')}`);
      }
    }

    if (rowCount) {
      const count = details.meta.count;
      if (!v.validateNumericRange(count, rowCount)) {
        validationErrors.push(`Row Count (${helpers.commaNumber(count)}) is not valid. ${v.rangeValidationString(rowCount)}`);
      }
    }

    this.file.validationErrors = validationErrors;
    return {ok: (validationErrors.length === 0), errors: validationErrors};
  }

  // Default:
  // - number of rows
  // - column names
  // Required/allowable columns provided:
  // - number of rows
  // - allowable/required columns
  // - ignored columns
  static detailsRenderer(details={}, rules={}) {
    const v = validations;
    const allColumns = details.meta.columns;
    let columns = [];
    const ignored = [];
    let ignoredRow;
    if (rules && rules.allowedColumns) {
      for (const column of allColumns) {
        v.validateString(column, rules.allowedColumns) ? columns.push(column) : ignored.push(column);
      }
      if (ignored.length > 0) {
        ignoredRow = <tr><td>Ignored Columns:</td><td>{ignored.join(', ')}</td></tr>;
      }
    } else {
      columns = allColumns;
    }
    return (
      <table>
        <tbody>
          <tr><td>Rows:</td><td>{details.meta.count}</td></tr>
          <tr><td>Columns:</td><td>{columns.join(', ')}</td></tr>
          {ignoredRow}
        </tbody>
      </table>
    )
  }

}

export default CSVFile;
