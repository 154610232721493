import React from 'react';
import PropTypes from 'prop-types';
import ServerAPI from '../models/ServerAPI';
import store from '../app/store';
import { updateLog } from '../actions/jobs';
import Progress from '../presenters/Progress';
import './LogCard.css';

// Connected
import { connect } from 'react-redux';

class LogCard extends React.Component {

  static propTypes = {
    id: PropTypes.number,
    job: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.logEndRef = React.createRef();
    this.logAreaRef = React.createRef();
    this.getLog();
    this.getLog = this.getLog.bind(this);
    this.state = {status: 'loading'};
  }

  componentDidUpdate () {
    this.scrollToBottom()
  }

  componentWillUnmount() {
    this.timerID && clearTimeout(this.timerID);
    // console.log('Clearing timeout:', this.timerID)
  }

  scrollToBottom() {
    const { liveScroll } = this.state;
    // console.log(liveScroll)
    liveScroll && this.logEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  getLog() {
    const { job } = this.props;
    const Server = new ServerAPI();
    const logArea = this.logAreaRef.current;
    Server.get(Server.jobLog(job), {offset: job.logOffset})
    .then( response => {
      if (response && response.ok) {
        const data = response.json;
        // TODO: consider moving this to a new dispatch file to keep redux events together
        this.timerID = undefined;

        // console.log(this.logAreaRef)
        // console.log(logArea)
        // console.log(logArea.scrollHeight, logArea.scrollTop, logArea.clientHeight)
        const liveScroll = logArea && Math.abs(logArea.scrollHeight - logArea.scrollTop - logArea.clientHeight) <= 3;

        this.setState({status: 'success', liveScroll})
        // console.log(response)
        store.dispatch(updateLog(job.id, data.log, data.offset));
        // FIXME: this needs to handle errors as well for status
      } else {
        this.setState({status: 'fail'})
      }
    });
  }

// TODO:
// - could have button to wrap / unwrap text

  render() {
    const { job } = this.props;
    const { status } = this.state;

    if (!job.finalized && !this.timerID) {
      this.timerID = setTimeout(this.getLog, 3000);
    }

    const loadingDots = job.finalized ? '' : <Progress type='dots' />;
    const logText = (status === 'success') ? job.log : ((status === 'loading') ? 'Loading...' : 'There was a problem with the live log.');
    return (
      <div className='LogCard'>
        <pre className='log-text' ref={this.logAreaRef}>{logText}<div ref={this.logEndRef} /></pre>
        <div className='loading-bottom'>{loadingDots}</div>
        <div className='fade-edges'/>
      </div>
    )
  }

}

//Connected
const logCardMapStateToProps = (state, ownProps) => ({ job: state.jobs.byID[ownProps.id] });
const ConnectedLogCard = connect(logCardMapStateToProps, null, null, {forwardRef: true})(LogCard);

// export default LogCard;
export { LogCard, ConnectedLogCard };

