import React from 'react';
import * as helpers from '../support/Helpers';
import * as validations from '../support/Validations';
import CSVFile from './file_delegates/CSVFile';
import SequenceFile from './file_delegates/SequenceFile';
import CGViewFile from './file_delegates/CGViewFile';
import FastqFile from './file_delegates/FastqFile';
import CGParseFile from './file_delegates/CGParseFile';
import CGFeatureFile from './file_delegates/CGFeatureFile';

// The ProkseeFile (or PFile for short) is a holder of files from various sources.
// The PFile typically doesn't save file text in the object for memory reasons.
//   **** NOT TRUE: we now save the text in the object so it can be easily added to #details for client-based tools
//   **** If this becomes a memory issue, we could add a prop to include text so
//        we only save the text if needed
// The file text for uploads is read to determine file details but is not saved
// as an attribute. We may change this in the future.
// TODO: we may add a text/string mode/source for a file, in which case the text
// would be saved.

// File Source describes where the file comes from:
//   uploads, ncbi accessions, myData (server files).
//
// NOTE: file details and validations will be empty until the file is parsed.
// The parse() is performed asynchronously and thus returns a promise.
//
// Example:
// const pfile = ProkseeFile({fileType: 'sequence', fileValue: file/accession})
// pFile.parse().then(f => console.log(f.details())
//

// File Delegates (based on fileType)
// Each file type has it's own delegate that is responsible for
// processing, validating and describing a file. Each delegate must
// have the following methods:
// - metaKeys
// - validationKeys
// - fileType
// - processText
// - validate
// - detailsRenderer
// Optional methods:
// - processAccession
// - hasModifiedFile: does this file type have a modified file for sending to server (default: false)
// - modifiedFile: returns modified file for sending to server (requires useUppy option in FileInput)

const _fileTypeMap = {
  'csv': CSVFile,
  'sequence': SequenceFile,
  'cgview': CGViewFile,
  'fastq': FastqFile,
  'parse': CGParseFile,
  'feature': CGFeatureFile,
}

class ProkseeFile {

  // Required options: fileType, fileValue
  // - TODO: fileSource is determined from the fileValue (unless possible future TEXT source is used
  //         In this case, you would have to provide source.
  constructor(options={}) {
    // If no fileName is provided, the default value will depend on the fileSource
    // - upload: JS File Object "name" attribute
    // - ncbi: accession
    // - myData: the file name from the server
    // - TODO: text: ""
    this.fileName = options.fileName || this.determineFileName(options.fileValue);
    // Used to assign delegate: CSV, Sequence
    this.fileType = options.fileType
    this.validationRules = options.validationRules || {};
    // e.g. GenBank, EMBL, etc
    this.fileFormat = options.fileFormat
    // File Source refers to how is the file accessed:
    // - upload: JS File object
    // - ncbi: accession id
    // - myData: DataFile on server
    // - TODO: we coud have another option for providing just Text as a file
    this.fileSource = options.fileSource || this.determineFileSource(options.fileValue);
    // File Value depends on the source
    this.fileValue = options.fileValue;
    // File size (currently only for upload files)
    this.size = (this.fileValue && this.fileSource === 'upload') && this.fileValue.size
    // Settings for processing the file
    this.settings = options.settings || {};
    this.toggleSettings = options.toggleSettings; // || () => {};
  }

  static get fileTypeMap() {
    return _fileTypeMap;
  }

  get delegate() {
    return this._delegate;
  }

  // List the names of the meta data keys for the delegate
  // this.meta() will return the data for all the metaKeys
  get metaKeys() {
    return this.delegate.metaKeys;
  }

  // List the name of the allowed validation keys
  get validationKeys() {
    return this.delegate.validationKeys;
  }

  set fileSource(value) {
    this._fileSource = value;
  }

  get fileSource() {
    return this._fileSource;
  }

  get text() {
    return this._text || '';
  }

  set text(value) {
    this._text = value;
  }

  get fileName() {
    return this._fileName || '';
  }

  set fileName(value) {
    this._fileName = value;
  }

  get fileType() {
    return this.delegate && this.delegate.fileType;
  }

  get hasModifiedFile() {
    return this.delegate?.hasModifiedFile || false;
  }

  saveSettings(settings={}) {
    if (this.delegate.saveSettings) {
      this.delegate.saveSettings(settings);
    }
  }

  // How to create a modified file for sending to the server
  // The modified content should be a string or a stringified JSON object
  // const modifiedContent = {json: cgvJSON, input: text, log: log};
  // const modifiedContentString = JSON.stringify(modifiedContent);
  // Create a blob from the modified content (set the type as needed: text/json, text/plain, etc.)
  // const blob = new Blob([modifiedContentString], { type: 'text/json' });
  // Create a new file from the blob
  // this._modifiedFile = new File([blob], "parse.json", {
    // type: blob.type,
  // });
  // See CGParseFile for a working example
  get modifiedFile() {
    return this.hasModifiedFile && this.delegate?.modifiedFile;
  }

  set fileType(value) {
    const fileTypes = Object.keys(ProkseeFile.fileTypeMap);
    if (fileTypes.includes(value)) {
      this._delegate = new ProkseeFile.fileTypeMap[value](this);
    } else {
      throw `File type '${value}' must be one of the following: ${fileTypes.join(', ')}`;
    }
  }

  get fileValue() {
    return this._fileValue;
  }

  set fileValue(value) {
    if (value) {
      this._fileValue = value;
    } else {
      throw '`File value must be provided';
    }
  }

  get fileFormat() {
    return this._fileFormat;
  }

  set fileFormat(value) {
    this._fileFormat = value;
  }

  get fileError() {
    return this._error;
  }

  set fileError(value) {
    this._error = value;
  }

  get validationRules() {
    return this._validationRules;
  }

  set validationRules(value) {
    const keys = Object.keys(value);
    const validationKeys = this.delegate.validationKeys;
    for (const key of keys) {
      if (!validationKeys.includes(key)) {
        console.error(`The key '${key}' is not one of the validation keys: ${validationKeys.join(', ')}`);
      }
    }
    this._validationRules = value;
  }

  get validationErrors() {
    return this._validationErrors;
  }

  set validationErrors(value) {
    this._validationErrors = value;
  }

  get sourceIsUpload() {
    return (this.fileSource === 'upload');
  }

  get sourceIsNCBI() {
    return (this.fileSource === 'ncbi');
  }

  get sourceIsMyData() {
    return (this.fileSource === 'myData');
  }

  // This is specific to the Proksee DataFile model which has 2 key attributes:
  // - attachment: for file uploads
  // - accession: for ncbi files
  modelAttribute() {
    const attributeMap = {upload: 'attachment', ncbi: 'external_id'};
    return attributeMap[this.fileSource];
  }

  determineFileSource(fileValue) {
    if (fileValue === undefined) {
      return;
    }
    if (fileValue.constructor.name === 'File') {
      return 'upload';
    } else if (fileValue.match(/^myData:/)) {
      // TODO:
      return 'myData';
    } else {
      // TODO: this will become 'external'
      return 'ncbi';
    }
    // TODO: handle possible future text source
  }

  // Temporary way of mapping fileSource to DataFile.rb source.
  // PFile fileSource will eventually be synced to DataFile.rb source
  dataFileSourceTemp() {
    const sourceMap = {
      'upload': 'upload',
      'myData': 'picker', // Not used yet
      'ncbi': 'external',
    }
    return sourceMap[this.fileSource];
  }

  determineFileName(fileValue) {
    if (fileValue.constructor.name === 'File') {
      return fileValue.name;
    } else if (fileValue.match(/^myData:/)) {
      // TODO:
      return '';
    } else {
      // ncbi:
      return fileValue;
    }
  }

  // Processes the fileValue to determine values for the metakeys.
  // After processing, the values are validated.
  async parse() {
    const value = this.fileValue;
    try {
      switch (this.fileSource) {
        case 'upload':
          if (!this.skipProcessing()) {
            await this.processUpload(value);
          }
          break;
        case 'ncbi':
          await this.processAccession(value);
          break;
        case 'myData':
          // TODO:
          break;
      }
    } catch(err) {
      console.log(err);
    }
    this.validate();
    return this;
  }

  // Used to reparse files with new settings
  // Also helpful in that it will resend Uppy file to server
  // Finds first file and reloads it
  // FIXME: This will need a way to idenifiy specific file to reload
  // - maybe by passing an id or class name
  static forceFileReload() {
      const fileInput = document.querySelector('.my-file-input');
      fileInput.dispatchEvent(new Event('change', {
        bubbles: true,
        cancelable: true,
      }));
  }

  // By default processing (reading of the file) will occur.
  // This can be overridden in the delegate.
  skipProcessing() {
    // If not defined in delegate this will return false
    return this.delegate.skipProcessing && this.delegate.skipProcessing();
  }

  processUpload(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.processText(fileReader.result)
        resolve(this.details());
      };
      fileReader.onerror = (e) => {
        console.log('Error reading file:', file)
        reject(e);
      }
      fileReader.readAsText(file);
    });
  }

  // Override with custom methods to get file details from file text
  // Read the file text and create the details
  processText(text) {
    // This could be optional using a includeText prop
    this.text = text;
    this.delegate.processText(text);
  }

  // Override with custom methods to get file details using the accession
  async processAccession(accession) {
    return this.delegate.processAccession(accession);
  }

  // Override with validations for specific file type
  validate(rules=this.validationRules) {
    try {
      this.delegate.validate(rules);
    } catch (error) {
      // TODO provide error to user
      console.error('- Failed: An error occurred while validating the records.');
      console.error(`- ERROR: ${error.message}`);
    }
  }

  renderDetails(details = this.details()) {
    return ProkseeFile.fileTypeMap[details.fileType].detailsRenderer(details, this.validationRules);
  }

  // NOTE: in the future this will be used to display file using
  //  details from the DB instead of having to read the file every time.
  static detailsRenderer(details={}, rules={}) {
    // console.log(details)
    const fileTypes = Object.keys(ProkseeFile.fileTypeMap);
    if (fileTypes.includes(details.fileType)) {
      return ProkseeFile.fileTypeMap[details.fileType].detailsRenderer(details, rules);
    } else {
      throw `ProkseeFile: File type '${details.fileType}' must be one of the following: ${fileTypes.join(', ')}`;
    }
  }

  // Returns an object where the keys are from this.metaKeys and
  // the values are generated from calling the getter for each metaKey
  meta() {
    const meta = {}
    for (const key of this.metaKeys) {
      meta[key] = this.delegate[key];
    }
    return meta;
  }

  details(options={}) {
    const json = {
      fileName: this.fileName,
      fileType: this.fileType,
      fileFormat: this.fileFormat,
      fileError: this.fileError,
      size: this.size,
      validationErrors: this.validationErrors,
      meta: this.meta(),
    }
    if (options.includeText) {
      json.text = this.text;
    }
    // console.log(json)
    return json;
  }

}

export default ProkseeFile;

