// HelpLinks create a little help icons that give help text popups and links to the help docs.
// Use HelpElements for text to appear below various elements and inputs.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import iconHelp from '../images/icon-help-link.png';
import HelpAPI from '../models/HelpAPI';
import Tooltip from './Tooltip';
import './HelpLink.css';

HelpLink.propTypes = {
  className: PropTypes.string,
  helpPath: PropTypes.string,
}

function HelpLink(props) {
  const { className, helpPath } = props;

  const Help = new HelpAPI();
  let helpText = 'No help found';
  let helpFound;
  if (Help.forPath(helpPath)) {
    helpText = Help.forPath(helpPath);
    helpFound = true;
  }

  const elementClass = classNames( 'HelpLink', className, {'help-error': !helpFound} );

  const prokseeLink = `/help#s.${encodeURIComponent(helpPath)}`
  return (
    <div className={elementClass}
      data-tip={helpText}
    >
      <a href={prokseeLink} target='_proksee_help'>
        <img src={iconHelp} />
      </a>
    </div>)
      // data-for='HelpLinkTip'
      //data-for='proksee-tooltips'
        {/* <img src={iconHelp} /> */}
      {/* data-place='top' */}
      {/* <Tooltip id='HelpLinkTip' /> */}
        {/* <img src={iconHelp} alt={helpText} title={helpText} /> */}
}

export default HelpLink;

