import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import './DialogAdd.css';

class DialogAdd extends DefaultDialog {

  renderContents() {
    const { tool } = this.props;
    // Get the default window and step based on the sequence length
    const windowStepDefaults = this.cgv.sequence.sequenceExtractor.getWindowStep();
    // Adjust Step & Window values
    const windowInput = tool.inputs.get('window');
    let windowValues = windowInput.values;
    const stepInput = tool.inputs.get('step');
    let stepValues = stepInput.values;
    if (this.cgv.sequence.length > 1000000) {
      stepValues = stepValues.filter( (s) => ((typeof s === 'string') || (s >= 100)));
      windowValues = windowValues.filter( (s) => ((typeof s === 'string') || (s >= 100)));
    } else if (this.cgv.sequence.length > 100000) {
      stepValues = stepValues.filter( (s) => ((typeof s === 'string') || (s >= 10)));
      windowValues = windowValues.filter( (s) => ((typeof s === 'string') || (s >= 50)));
    }
    // Set Default Window
    const windowDefault = `Auto (${windowStepDefaults.window})`;
    windowValues[0] = windowDefault;
    // Set Default Step
    const stepDefault = `Auto (${windowStepDefaults.step})`;
    stepValues[0] = stepDefault;

    // Initial default may come fomr LocalStorage
    const initialWindowDefault = windowInput.defaultValue().startsWith("Auto") ? windowDefault : windowInput.defaultValue();
    const initialStepDefault = stepInput.defaultValue().startsWith("Auto") ? stepDefault : stepInput.defaultValue();

    return (
      <div>
        <div className='dialog-header'>
          <div>GC content plots are  generated by calculating the GC content for each sliding window using the formula:</div>
          <div className="formula-line">
            <div className='formula'><span className="formula-top">G + C</span><span className="formula-bottom">Window Size</span></div>
            <div className='formula-equals'>=</div>
            <div><span className="">values between 0 and 1</span></div>
          </div>
          <div>The window moves by the step size, and the calculation repeats.</div>
        </div>

        {this.renderInput('trackName')}
        {this.renderInput('window', {values: windowValues, defaultValue: initialWindowDefault})}
        {this.renderInput('step', {values: stepValues, defaultValue: initialStepDefault})}

        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;

