import { RESET_STATE, PROJECT_UPDATE } from '../constants/ActionTypes'
// import * as helpers from '../support/Helpers';

const initialState = { };

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case PROJECT_UPDATE:  return updateProject(state, payload);
    default:              return state;
  }
};

const reset = () => { return (initialState) }

const updateProject = (state, { project }) => {
  // console.log(project)
  return ({
    ...state,
    ...project,
  });
}

