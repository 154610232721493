import { BACKBONE_UPDATE } from '../constants/ActionTypes'

const initialState = { };

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case BACKBONE_UPDATE: return updateBackbone(state, payload);
    default:              return state;
  }
};

const updateBackbone = (state,  { attributes }) => {
  return {...state, ...attributes}
}


