import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../presenters/Tabs';
import { ConnectedMapTab } from '../tabs/MapTab';
import { ConnectedAboutTab } from '../tabs/AboutTab';
import { ConnectedJobTab } from '../tabs/JobTab';
import { CGViewContext } from './CGViewContext';
import * as helpers from '../support/Helpers';

// Connected
import { connect } from 'react-redux';

class MainTabContainer extends Tabs {

  static propTypes = {
    divID:      PropTypes.string.isRequired,
    cgvHandler: PropTypes.func.isRequired,
    jobs: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
  }

  // static defaultProps = {
  //   jobs: [],
  // }

  constructor(props) {
    super(props);
    this.newTabIndex = 0;

    const aboutTab = React.createElement(ConnectedAboutTab);
    const mapTab = React.createElement(ConnectedMapTab, {divID: this.props.divID, cgvHandler: this.props.cgvHandler});

    const panes = [
      { title: 'About', id: 'about', content: aboutTab, key: '1', closable: false },
      { title: 'Map', id: 'map', content: mapTab, key: '2', closable: false, forceRender: true },
      // { title: 'Report', content: 'Step 3: Profit!', key: '3'},
    ];
    this.state = {
      activeKey: panes[1].key,
      panes,
    };

    // Assembly Projects
    // FIXME: replace as afavorite jobs when worked out
    // this.addAssemblyProjectReportTab(Date.now());

    // Add favorite jobs at start
    this.addFavoriteJobsReportTabs(Date.now());
  }

  // Assembly Projects
  // - Add assembly tab
  // - Close expanded details
  // - Open Report
  // FIXME: use FavoriteJobs instead
  // addAssemblyProjectReportTab(timeStart) {
  //   // TODO: if we start passing project source to ProkseeWrapper (i.e. context), then we can avoid calling this 
  //   // for other types of projects
  //   // if (this.props.source !== 'reads') return;
  //   const maxTimeout = 10000; // 10s
  //   const currentTimeout = Date.now() - timeStart;
  //   setTimeout( () => {
  //     const tabID = this.setTabOrCreateBySource('assemble-1.1', {active: false, summaryOpen: false, initialJobCard: '2'});
  //     if (!tabID && (currentTimeout < maxTimeout)) {
  //       this.addAssemblyProjectReportTab(timeStart);
  //     // } else {
  //     //   tabID ? console.log('TabID set') : console.log('timed out');
  //     }
  //   }, 1000);
  //
  // }

  // Favorite Jobs
  // - Add tab
  // - Close expanded details
  // - Open Report
  // Note: this will check for jobs every second for 10 seconds after loading the page
  addFavoriteJobsReportTabs(timeStart) {
    const maxTimeout = 10000; // 10s
    const currentTimeout = Date.now() - timeStart;
    setTimeout( () => {

      let jobs = helpers.itemsAsArray(this.props.jobs);
      // console.log(jobs)
      jobs = jobs.filter( j => j.favorite);
      for (const job of jobs) {
        this.setTabOrCreateByJob(job, {active: false, summaryOpen: false, initialJobCard: 'report'});
      }

      if (!jobs.length && (currentTimeout < maxTimeout)) {
        this.addFavoriteJobsReportTabs(timeStart);
      }
    }, 1000);

  }

  setTabByID(id) {
    const { panes } = this.state;
    const pane = panes.find( p => p.id === id );
    if (pane) {
      // Hide sidebar before showing tab, if the screen is narrow
      this.context.sidebarRef.autoCloseSideBar();
      // if (window.innerWidth < 576) {
      //   const sidebar = this.context.sidebarRef;
      //   sidebar.toggleSidebar(false);
      // }
      this.setState({activeKey: pane.key});
    }
  }

  tabExists(id) {
    const { panes } = this.state;
    const pane = panes.find( p => p.id === id );
    return Boolean(pane);
  }

  // Returns id of the tab currently open
  currentOpenTabID() {
    const { panes } = this.state;
    const currentKey = this.state.activeKey;
    const pane = panes.find( p => p.key === currentKey );
    return pane?.id
  }

  // Returns true if the map tab is selected
  mapTabOpen() {
    const currentTabID = this.currentOpenTabID();
    return (currentTabID === 'map');
  }


  // Job must be an object with the following properties: id, name
  // Return Tab ID if successful
  // New tab will be active unless active is false
  // Options;
  // - active: should tab be opened to the foreground (default: true)
  // - NOTE: following options are passed as props to JobTab
  //   - summaryOpen: have job summary (top) open (default: true)
  //   - initialJobCard: tab to have open by default in the details section (default: 'report')
  setTabOrCreateByJob(job, options = {}) {
    // console.log(job)
    if (job && job.id && job.name) {
      const tabID = `job-${job.id}`;
      if (this.tabExists(tabID)) {
        this.setTabByID(tabID);
      } else {
        const { active, ...tabOptions } = options;
        this.context.sidebarRef.autoCloseSideBar();
        this.add({title: job.name, id: tabID, content: <ConnectedJobTab id={job.id} {...tabOptions} />}, active);
      }
      return tabID;
    }
  }

  // Source examples:
  // From builder: genbank-features or embl-features
  // Other jobs: prokka-1.1
  // Return Tab ID if successful
  // New tab will be active unless active is false
  // Options;
  // - active: should tab be opened to the foreground (default: true)
  // - NOTE: following options are passed as props to JobTab
  //   - summaryOpen: have job summary (top) open (default: true)
  //   - initialJobCard: tab to have open by default in the details section (default: 'report')
  setTabOrCreateBySource(source, options = {}) {
    // console.log(source);
    const jobs = helpers.itemsAsArray(this.props.jobs);
    let querySourceID;
    if (/^(genbank|embl)-features$/.test(source)) {
      querySourceID = 'builder-features';
    } else if (/^(\w+?-\d+?)\.\d+$/.test(source)) {
      querySourceID = RegExp.$1;
    }
    const job = jobs.find( j => j.cgviewSourceId === querySourceID);
    return this.setTabOrCreateByJob(job, options);
  }

}
MainTabContainer.contextType = CGViewContext;

//Connected
const mainTabContainerMapStateToProps = (state) => ({ jobs: state.jobs });
const ConnectedMainTabContainer = connect(mainTabContainerMapStateToProps, null, null, {forwardRef: true})(MainTabContainer);

// export default MainTabContainer;
export { MainTabContainer, ConnectedMainTabContainer};

