export default function add({inputs}) {

  const window = inputs.window.match(/Auto/) ? undefined : Number(inputs.window);
  const step = inputs.step.match(/Auto/) ? undefined : Number(inputs.step);

  const mapData = {
    tracks: [{
      name: inputs.trackName,
      position: 'inside',
      thicknessRatio: 2,
      dataType: 'plot',
      dataMethod: 'sequence',
      dataKeys: 'gc-skew',
      dataOptions: { window, step }
    }],
  };

  return { ok: true, mapData };
}

