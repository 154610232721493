import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import Papa from 'papaparse'

class DialogAdd extends DefaultDialog {

  renderMoreHelp() {
    return (
      <div>
        <p>
          The file should contain tab-separated or comma-separated values, and
          should have the following column titles, in any order: 'start' and
          'score'. 'start' should be an integer between 1 and the length of the
          sequence. The 'score' value should be a value between 0 and 1.
        </p>
        <p>
          Example:
        </p>
        <pre>
{`start  score
1500 0.6
3623 1
1700 0.2`}
        </pre>
      </div>
    )
  }

  // value will be file.details()
  onFileChange({attribute, value}) {
    this.onChange({attribute, value});
    // TODO: only change state if file is valid. Can we do that?
    this.setState({fileName: value.fileName});
  }

  // Use of 'key' will cause the component to be recreated if the key changes.
  // This calls the constructor witch internally will call returnCurrentItem.
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#preferred-solutions
  renderContents() {
    const { tool } = this.props;
    const { options, fileName } = this.state;
    const newTrackName = (options.trackName) || fileName;
    const newLegendName = (options.legendPositive && options.legendPositive.name) || fileName;
    const legendPositiveID = (options.legendPositive && options.legendPositive.cgvID) || tool.inputs.get('legendPositive').default;
    // const legendNegativeID = (options.legendNegative && options.legendNegative.cgvID) || tool.inputs.get('legendNegative').default;
    return (
      <div>
        {this.renderHeader()}
        {this.renderInput('file', {
          helpMore: this.renderMoreHelp(),
          onValidate: this.onValidate,
          onChange: (file) => this.onFileChange({attribute: 'file', value: file.details({includeText: true})}),
        })}
        {this.renderInput('trackName', {value: newTrackName, key: fileName})}
        {this.renderInput('legendPositive', {defaultNewItemName: newLegendName, default: legendPositiveID, key: fileName})}
        {this.renderInput('legendNegative')}

        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;

