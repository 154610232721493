export default function add({tool, cgv, inputs, mapData}) {

  // Swap start/stop posisitions 
  if (inputs.swapStartStop) {
    for (const feature of mapData.features) {
      if (feature.start > feature.stop) {
        [feature.start, feature.stop] = [feature.stop, feature.start];
      }
    }
  }

  mapData = {
    ...mapData,
    sourcePrefix: `file-${inputs.file.fileName}-`,
  }

  return { ok: true, mapData };
}

