import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';

import TextElement from '../../../presenters/TextElement';
import DataElement from '../../../presenters/DataElement';
import DataElementGroup from '../../../presenters/DataElementGroup';
import ExternalLink from '../../../presenters/ExternalLink';
import classNames from 'classnames';
import * as helpers from '../../../support/Helpers';
import * as d3 from 'd3';
import './ReportCard.css';

class ReportCard extends ReportCardBase {

  // value: true or false (anything else will be N/A)
  // Options:
  //  - type: L50, N50, Contigs, Length
  passFail(value, options) {
    // console.log(value, options);
    if (options) {
      const { type } = options;
      const bounds = this.boundsForType(type);
      if (value > bounds.lowWarning && value < bounds.highWarning) {
        return <div className='test-common test-pass'>PASS</div>
      } else if (value > bounds.lowError && value < bounds.highError) {
        return <div className='test-common test-warning'>WARNING</div>
      } else {
        return <div className='test-common test-fail'>FAIL</div>
      }
    } else {
      // Pass/Fail based only on value: true/false
      if (value === true) {
        return <div className='test-common test-pass'>PASS</div>
      } else if (value === false) {
        return <div className='test-common test-fail'>FAIL</div>
      } else {
        return <div className='test-common test-na'>N/A</div>
      }
    }
  }

  speciesPlot(type) {
    const width = 250;
    let height = 23; // Height changes for type = legend
    let viewBoxY = 0; // ViewBoxY changes for type = legend
    const thresholds = this.data.thresholds;
    const assembly = this.data.assembly;

    // Set value and bounds from type
    let value, bounds;
    if (type === 'legend') {
      // Special Case
      value = 65;
      bounds = {lowError: 5, lowWarning: 20, median: 50, highWarning: 80, highError: 95};
      height = 30;
      viewBoxY = -7;
    } else {
      value = assembly[type];
      bounds = this.boundsForType(type);
    }

    // Domains and Scale
    const startDomain = this.calculateDomainMin(value, bounds.lowError, ( (bounds.lowWarning - bounds.lowError) / 2 ));
    const endDomain = this.calculateDomainMax(value, bounds.highError, ( (bounds.highError - bounds.highWarning) / 2 ));

    const scale = d3.scaleLinear()
      .domain([startDomain, endDomain])
      .range([0, width]);

    let key;
    if (type === 'legend') {
      const textY = 0;
      key = (
        <g className='species-key-labels'>
          <text x={scale(bounds.lowError)} y={textY}>{bounds.lowError}</text>
          <path className='graph-marker' d={`M${scale(bounds.lowError)} ${textY+1} V3`} strokeWidth="1" />
          <text x={scale(bounds.lowWarning)} y={textY}>{bounds.lowWarning}</text>
          <path className='graph-marker' d={`M${scale(bounds.lowWarning)} ${textY+1} V3`} strokeWidth="1" />
          <text x={scale(bounds.median)} y={textY}>{bounds.median}</text>
          <path className='graph-marker' d={`M${scale(bounds.median)} ${textY+1} V3`} strokeWidth="1" />
          <text x={scale(bounds.highWarning)} y={textY}>{bounds.highWarning}</text>
          <path className='graph-marker' d={`M${scale(bounds.highWarning)} ${textY+1} V3`} strokeWidth="1" />
          <text x={scale(bounds.highError)} y={textY}>{bounds.highError}</text>
          <path className='graph-marker' d={`M${scale(bounds.highError)} ${textY+1} V3`} strokeWidth="1" />
        </g>
      );
    }

    // Do not show marker in legend
    const marker = (type !== 'legend') && 
        <path className='graph-marker' d={`M${scale(value) - 4} 0 l4 4 v15 l-4 4 h8 l-4 -4 v-15 l4 -4`} strokeWidth="1" />;

    return (
      <svg width='100%' height={height} viewBox={`0 ${viewBoxY} ${width} ${height}`}>
        <path d={`M0 12 H${width}`} fill="transparent" stroke="rgb(186,106,106)" strokeWidth="3" />
        <path d={`M${scale(bounds.lowError)} 4 v15 v-7 H${scale(bounds.highError)} v-7 v15`} fill="transparent" stroke="rgb(200,200,50)" strokeWidth="3" />
        <rect x={scale(bounds.lowWarning)} y="4" width={scale(bounds.highWarning) - scale(bounds.lowWarning)} height="15" fill="rgb(106,186,106)" />
        <path d={`M${scale(bounds.median)} 4 v15`} fill="transparent" stroke="rgb(0,0,200)" strokeWidth="1" />
        {marker}
        {key}
      </svg>
    )
  }

  // Keeps doubling domainLength until value fits
  calculateDomainMax(value, domainStart, domainLength) {
    const domainEnd = domainStart + domainLength;
    if (value < domainEnd) {
      return domainEnd;
    } else {
      return this.calculateDomainMax(value, domainStart, domainLength * 2);
    }
  }

  // Keeps doubling domainLength until value fits
  // Zero is the absolute minimum
  calculateDomainMin(value, domainStart, domainLength) {
    const domainEnd = domainStart - domainLength;
    if (domainEnd < 0) {
      return 0
    } else if (value > domainEnd) {
      return domainEnd;
    } else {
      return this.calculateDomainMin(value, domainStart, domainLength * 2);
    }
  }

  // type: L50, N50, Contigs, Length
  boundsForType(type) {
    const boundsType = type.toLowerCase();
    const thresholds = this.data.thresholds || {};
    return {
      lowError:    thresholds[`${boundsType}LowError`],
      lowWarning:  thresholds[`${boundsType}LowWarning`],
      median:      thresholds[`${boundsType}Median`],
      highError:   thresholds[`${boundsType}HighError`],
      highWarning: thresholds[`${boundsType}HighWarning`],
    }
  }

  warningErrorBounds(type) {
    const comma = helpers.commaNumber;
    const thresholds = this.data.thresholds;
    if (!thresholds) return;
    const bounds = this.boundsForType(type);
    const assembly = this.data.assembly;
    const value = assembly[type];

    const marker = (
      <svg className='legend-marker' width='17' height='17' viewBox={'-1 -1 25 25'}>
        <path className='graph-marker' d='M5 0 l4 4 v15 l-4 4 h8 l-4 -4 v-15 l4 -4' strokeWidth="1" />;
      </svg>
    );

    const { graphOpenByID } = this.state;
    const klass = classNames('assembly-thresholds', {open: (graphOpenByID && graphOpenByID[type])})
    return (
      <div className={klass}>
        <div className='assembly-value'>{marker}{`Assembly ${type}: `}<strong>{comma(value)}</strong></div>
        <table className='proksee-stats'>
          <thead>
            <tr className='table-title'><th colSpan="3">{`Percentiles for ${type}`}</th></tr>
          </thead>
          <tbody>
            <tr><td>5</td><td className='left truncate'>(Low Error)</td><td>{comma(bounds.lowError, '0')}</td></tr>
            <tr><td>20</td><td className='left truncate'>(Low Warning)</td><td>{comma(bounds.lowWarning, '0')}</td></tr>
            <tr><td>50</td><td className='left truncate'>(Median)</td><td>{comma(bounds.median, '0')}</td></tr>
            <tr><td>80</td><td className='left truncate'>(High Warning)</td><td>{comma(bounds.highWarning, '0')}</td></tr>
            <tr><td>95</td><td className='left truncate'>(High Error)</td><td>{comma(bounds.highError, '0')}</td></tr>
            <tr className='species-key'><td>Key</td><td colSpan='2'>{this.speciesPlot('legend')}</td></tr>
          </tbody>
        </table>
      </div>
    )
  }

  handleGraphClicked(id) {
    this.setState({
      graphOpenByID: {
        ...this.state.graphOpenByID,
        [id]: !(this.state.graphOpenByID && this.state.graphOpenByID[id])
      }
    });
  }

  renderCutoffSummary() {
    const assembly = this.data.assembly;
    const cutoff = assembly.minContigLengthFilter;
    return this.renderAlert(<div>All statistics are based on contigs of size <strong>&ge;{cutoff} bp</strong>, unless otherwise noted.</div>);
  }

  renderTableAssemblyQuality() {
    const assembly = this.data.assembly;
    const comma = helpers.commaNumber;
    return (
      <table className='proksee-stats striped'>
        <thead>
          <tr className='table-title'><th colSpan='2'>Assembly Quality</th></tr>
        </thead>
        <tbody>
          <tr><td className='left'>Contig Filter (bp)</td><td>&ge;{comma(assembly.minContigLengthFilter)}</td></tr>
          <tr><td className='left'>N50</td><td><strong>{comma(assembly.N50)}</strong></td></tr>
          <tr><td className='left'>L50</td><td><strong>{comma(assembly.L50)}</strong></td></tr>
          <tr><td className='left'>Number of Contigs</td><td><strong>{comma(assembly.Contigs)}</strong></td></tr>
          <tr><td className='left'>Assembly Length (bp)</td><td><strong>{comma(assembly.Length)}</strong></td></tr>
        </tbody>
      </table>
    );
  }

  renderTableAssemblyUnfiltered() {
    const assembly = this.data.assembly;
    const comma = helpers.commaNumber;
    return (
      <table className='proksee-stats striped'>
        <thead>
          <tr className='table-title'><th colSpan='2'>Assembly Unfiltered (All Contigs)</th></tr>
        </thead>
        <tbody>
          <tr><td className='left'>Number of Contigs</td><td>{comma(assembly.unfilteredContigs)}</td></tr>
          <tr><td className='left'>Assembly Length (bp)</td><td>{comma(assembly.unfilteredLength)}</td></tr>
        </tbody>
      </table>
    );
  }

  renderRowSpeciesComparison(type) {
    const assembly = this.data.assembly;
    return (
      <tr className='clickable' onClick={ () => this.handleGraphClicked(type)}>
        <td className='left col-narrow'>{type}</td>
        <td className='left'>{this.passFail(assembly[type], {type})}</td>
        <td>{this.speciesPlot(type)}</td>
      </tr>
    );
  }

  renderRowSpeciesComparisonBoundsTable(type) {
    return (
      <tr className='hideable'>
        <td className='left' colSpan='3'>{this.warningErrorBounds(type)}</td>
      </tr>
    );
  }

  renderEvaluationSpeciesComparison() {
    const data = this.data;
    const heuristic = this.data.heuristic;
    const thresholds = heuristic.thresholds;
    const comma = helpers.commaNumber;
    if (heuristic.status === 'evaluated' && thresholds) {
      const numAssemblies = comma(thresholds.numAssembliesForSpecies);
      return (
        <table className='proksee-stats'>
          <thead>
            <tr className='table-title'><th colSpan='3'>Evaluation: Assembly Metric Distribution</th></tr>
          </thead>
          <tbody>
            <tr><td className='left'>Success</td><td colSpan='2' className='left'><strong>{this.passFail(heuristic.success)}</strong></td></tr>
            <tr><td className='left' colSpan='3'><div className='eval-report'>Assembly metrics compared to <strong>{numAssemblies}</strong> other assemblies for this species (<em>{data.species}</em>).</div></td></tr>
            { this.renderRowSpeciesComparison('N50') }
            { this.renderRowSpeciesComparisonBoundsTable('N50') }
            { this.renderRowSpeciesComparison('L50') }
            { this.renderRowSpeciesComparisonBoundsTable('L50') }
            { this.renderRowSpeciesComparison('Contigs') }
            { this.renderRowSpeciesComparisonBoundsTable('Contigs') }
            { this.renderRowSpeciesComparison('Length') }
            { this.renderRowSpeciesComparisonBoundsTable('Length') }
          </tbody>
        </table>
      );
    } else {
      const report = (data.species === 'Unknown') ? 'species unknown' : 'species not in DB';
      return (
        <table className='proksee-stats'>
          <thead>
            <tr className='table-title'><th colSpan='2'>Evaluation: Assembly Metric Distribution</th></tr>
          </thead>
          <tbody>
            <tr><td className='left col-narrow'>Success</td><td className='left'><strong>{this.passFail()}</strong></td></tr>
            <tr><td className='left' colSpan='2'><div className='eval-report'>Unable to compare assemblies: {report}</div></td></tr>
          </tbody>
        </table>
      );
    }
  }

  renderEvaluationMachineLearning() {
    const ml = this.data.ml;
    const species = this.data.species;

    let report, probability;
    if (ml.status === 'evaluated' && ml.report) {
      report = ml.report.replace(/:.*/, ': ');
      probability = ml.report.replace(/.*:/, '').replace(/\.$/, '');
      probability = probability || ml.probability; // Fallback to probability in JSON
    } else {
      report = (species === 'Unknown') ? 
        'Unable to evaluate: species unknown' :
        'Unable to evaluate: species not in DB';
    }
    //<tr><td className='left col-narrow'>Probability</td><td>{ml.probability}</td></tr>
    const tbody = (ml.status === 'evaluated') ? (
      <tbody>
        <tr><td className='left col-narrow'>Success</td><td><strong>{this.passFail(ml.success)}</strong></td></tr>
        <tr><td className='left' colSpan='2'><div className='eval-report'>{report}<strong>{probability}</strong></div></td></tr>
      </tbody>
    ) : (
      <tbody>
        <tr><td className='left col-narrow'>Success</td><td><strong>{this.passFail()}</strong></td></tr>
        <tr><td colSpan='2' className='left'><div className='eval-report'>{report}</div></td></tr>
      </tbody>
    );
    return (
      <table className='proksee-stats'>
        <thead>
          <tr className='table-title'><th colSpan='2'>Evaluation: Machine Learning of Assembly Metrics</th></tr>
        </thead>
        {tbody}
      </table>
    );
  }

  renderEvaluationNCBICutoffs() {
    const assembly = this.data.assembly;
    const ncbi = this.data.ncbi
    const thresholds = ncbi.thresholds;
    const comma = helpers.commaNumber;

    return (
      <table className='proksee-stats'>
        <thead>
          <tr className='table-title'><th colSpan='4'>Evaluation: NCBI Exclusion Criteria</th></tr>
        </thead>
        <tbody>
          <tr><td className='left'>Success</td><td colSpan='3' className='left'><strong>{this.passFail(ncbi.success)}</strong></td></tr>
          <tr className='table-heading-row'><td colSpan='2'></td><td className='subheading min-max center'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cutoff&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td><td className='subheading'>&nbsp;Assembly&nbsp;</td></tr>
          <tr><td className='left col-narrow nowrap'>N50</td><td>{this.passFail(ncbi.n50Pass)}</td><td className='min-max'><div>{comma(thresholds.minN50)}</div><div>Min</div></td><td>{comma(assembly.N50)}</td></tr>
          <tr><td className='left col-narrow nowrap'>L50</td><td>{this.passFail(ncbi.l50Pass)}</td><td className='min-max'><div>{comma(thresholds.maxL50)}</div><div>Max</div></td><td>{comma(assembly.L50)}</td></tr>
          <tr><td className='left col-narrow nowrap'>Contigs</td><td>{this.passFail(ncbi.contigsPass)}</td><td className='min-max'><div>{comma(thresholds.maxContigs)}</div><div>Max</div></td><td>{comma(assembly.Contigs)}</td></tr>
          <tr><td className='left col-narrow nowrap'>Length</td><td>{this.passFail(ncbi.lengthPass)}</td><td className='min-max'><div>{comma(thresholds.minLength)}</div><div>Min</div></td><td>{comma(assembly.Length)}</td></tr>
        </tbody>
      </table>
    );
  }

  renderTableReadSource() {
    const data = this.data;
    return (
      <table className='proksee-stats striped'>
        <thead>
          <tr className='table-title'><th colSpan='2'>Estimated Read Source</th></tr>
        </thead>
        <tbody>
          <tr><td className='left'>Species</td><td><em>{data.species}</em></td></tr>
          <tr><td className='left'>Technology</td><td>{data.technology}</td></tr>
        </tbody>
      </table>
    );
  }

  renderTableReadQuality() {
    const reads = this.data.reads;
    const comma = helpers.commaNumber;
    return (
      <table className='proksee-stats striped'>
        <thead>
          <tr className='table-title'><th colSpan='2'>Read Quality</th></tr>
        </thead>
        <tbody>
          <tr><td className='left'>Total Reads</td><td>{comma(reads.totalReads)}</td></tr>
          <tr><td className='left'>Total Bases</td><td>{comma(reads.totalBases)}</td></tr>
          <tr><td className='left'>Q20 Bases</td><td>{comma(reads.q20Bases)}</td></tr>
          <tr><td className='left'>Q20 Rate</td><td>{comma(reads.q20Rate, 3)}</td></tr>
          <tr><td className='left'>Q30 Bases</td><td>{comma(reads.q30Bases)}</td></tr>
          <tr><td className='left'>Q30 Rate</td><td>{comma(reads.q30Rate, 3)}</td></tr>
          <tr><td className='left'>GC Content</td><td>{comma(reads.gcContent, 3)}</td></tr>
        </tbody>
      </table>
    );
  }

  renderTableSoftwareVersion() {
    const version = this.data.version;
    return (
      <table className='proksee-stats striped'>
        <thead>
          <tr className='table-title'><th colSpan='2'>Software Versions</th></tr>
        </thead>
        <tbody>
          <tr><td className='left'>Proksee Assemble Version</td><td>{version.proksee}</td></tr>
          <tr><td className='left'>Model Date</td><td>{version.model}</td></tr>
          <tr><td className='left'>Database Date</td><td>{version.database}</td></tr>
          <tr><td className='left'><ExternalLink name='fastp' link='https://github.com/OpenGene/fastp' size={12} /></td><td>{version.fastp}</td></tr>
          <tr><td className='left'><ExternalLink name='SKESA' link='https://github.com/ncbi/SKESA' size={12} /></td><td>{version.skesa}</td></tr>
          <tr><td className='left'><ExternalLink name='QUAST' link='https://quast.sourceforge.net/' size={12} /></td><td>{version.quast}</td></tr>
          <tr><td className='left'><ExternalLink name='SPAdes' link='https://github.com/ablab/spades' size={12} /></td><td>{version.spades}</td></tr>
          <tr><td className='left'><ExternalLink name='Mash' link='https://github.com/marbl/Mash' size={12} /></td><td>{version.mash}</td></tr>
        </tbody>
      </table>
    );
  }

  processData(data) {
    // console.log(data)
    const quality = data['assemblyQuality'];
    const assembly = {
      N50: quality.n50,
      L50: quality.l50,
      Contigs: quality['numContigs'],
      Length: quality['assemblyLength'],
      unfilteredContigs: quality['unfilteredNumContigs'],
      unfilteredLength: quality['unfilteredAssemblyLength'],
      minContigLengthFilter: quality['minContigLengthFilter'],
    }
    return {
      assembly,
      version:    data['Version'],
      reads:      data['readQuality'],
      heuristic:  data['heuristicEvaluation'],
      thresholds: data['heuristicEvaluation'] && data['heuristicEvaluation']['thresholds'],
      ml:         data['machineLearningEvaluation'],
      ncbi:       data['NCBIFallbackEvaluation'],
      species:    data['species'],
      technology: data['technology'],
    }
  }

  renderData(data) {

    if (data) {
      this.data = this.processData(data);

      // Add the cutoff to the file description for filtered contigs.
      const { output } = this.props;
      const fileList = output.featuredFiles || [];
      const file = fileList.find( f => f.path === 'output_assemble/contigs.filtered.fasta');
      if (file) {
        const assembly = this.data.assembly;
        const cutoff = assembly.minContigLengthFilter;
        file.description = `FASTA file of filtered assembled contigs (> ${cutoff} bp). These are used to create the project map.`;
      }

      return (
        <div className='AssemblyReportCard report-card'>
          { this.renderReportHeader() }
          { this.renderReportFailed() }
          { this.renderCutoffSummary() }
          { this.renderTableAssemblyQuality() }
          { this.renderTableAssemblyUnfiltered() }
          { this.renderEvaluationSpeciesComparison() }
          { false && this.renderEvaluationMachineLearning() }
          { this.renderEvaluationNCBICutoffs() }
          { this.renderTableReadSource() }
          { this.renderTableReadQuality() }
          { this.renderTableSoftwareVersion() }
          { this.renderFeaturedFilesTable(fileList) }
          { this.renderHelpfulLinks() }
        </div>
      );
    } else {
      return (
        <div className='AssemblyReportCard report-card'>
          { this.renderReportHeader() }
          { this.renderReportFailed() }
          { this.renderFeaturedFilesTable() }
          { this.renderHelpfulLinks() }
        </div>
      );
    }
  }

}

export default ReportCard;

