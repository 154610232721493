import { ANNOTATION_UPDATE } from '../constants/ActionTypes'

const initialState = { };

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case ANNOTATION_UPDATE: return updateAnnotation(state, payload);
    default:              return state;
  }
};

const updateAnnotation = (state,  { attributes }) => {
  return {...state, ...attributes}
}


