import { RESET_STATE, LEGEND_UPDATE } from '../constants/ActionTypes'

const initialState = { };

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:          return reset(state, payload);
    case LEGEND_UPDATE:        return updateLegend(state, payload);
    default:                   return state;
  }
};

const reset = () => { return (initialState) }

const updateLegend = (state, { attributes }) => {
  return {...state, ...attributes}
}

