import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';

class ProkkaDataCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  renderData(data) {
    return (
      <VirtualGrid
        data={data}
        header
        alternateRowColors
        rowHeight={30}
        defaultColumnWidth={80}
      >
        <Column index={0} width={150} />
        <Column index={2} width={100} type='number' />
        <Column index={4} width={110} linkerProps={{ url: 'https://www.brenda-enzymes.org/enzyme.php?ecno={VALUE}'}} />
        <Column index={5} linkerProps={{ url: 'https://www.ncbi.nlm.nih.gov/research/cog/cog/{VALUE}/'}} />
        <Column index={6} width={300} />
      </VirtualGrid>
    );
  }

}

export default ProkkaDataCard;

