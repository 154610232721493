import { PLOTS_ADD, PLOTS_UPDATE, PLOTS_REMOVE, PLOTS_UPDATE_STATS } from '../constants/ActionTypes'

export const addPlots = (plots) => ({
  type: PLOTS_ADD,
  payload: {
    plots,
  }
});

export const removePlots = (plots) => ({
  type: PLOTS_REMOVE,
  payload: {
    plots,
  }
});

export const updatePlots = (plots, attributes, updates) => ({
  type: PLOTS_UPDATE,
  payload: {
    plots,
    attributes,
    updates,
  }
});

export const updatePlotStats = (cgv) => ({
  type: PLOTS_UPDATE_STATS,
  payload: { 
    cgv,
  }
});
