import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';

class DialogStart extends DefaultDialog {

  // Check if contigs names are short enough
  componentDidMount() {
    const contigs = this.cgv.contigs();
    const maxNameLength = 37;
    const longNames = contigs.map(c => c.name).filter(n => n.length > maxNameLength);
    if (longNames.length === 0) {
      this.onValidate(true)
    }
  }

  renderContents() {
    const { tool } = this.props;
    const { valid } = this.state;

    // Duplicate code, i know! Need beter way for this
    const contigs = this.cgv.contigs();
    const maxNameLength = 37;
    const longNames = contigs.map(c => c.name).filter(n => n.length > maxNameLength);

    const error = valid ? '' : (
      <div>
        <div className='ps-alert ps-alert-danger'>
          <div>Prokka requires contig names to be &le; 37 characters.</div>
          <div>Number of contigs with long names: <strong>{longNames.length}</strong></div>
          <div>E.g. <em>{longNames[0]}</em></div>
        </div>
        <br />
      </div>
    );
    return (
      <div>
        {error}

        {this.renderHeader()}

        {this.renderInput('jobName')}
        {this.renderInput('kingdom')}
        {this.renderInput('genus')}
        {this.renderInput('genetic_code')}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

