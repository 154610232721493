import Papa from 'papaparse'
import {utils} from '../../../CGView';

export default function add({cgv, inputs}) {

  // Read Feature data from csv text
  // TODO: create helper for reading csv with the following defaults
  const text = inputs.file.text;
  const csv = Papa.parse(text, {
    header: true,
    skipEmptyLines: true,
    comments: '#',
    transformHeader: (h) => h.trim().toLowerCase(),
    error: (error, file) => {console.log(error)}
  });
  // [start, stop, score]
  // console.log(csv)

  // TODO: Sort values

  // Create Plot
  const source = utils.uniqueId(`file-${inputs.file.fileName}-`, 1, cgv.sources());

  const plot = {
    name: inputs.file.fileName,
    source,
    positions: csv.data.map( d => Number(d.start)),
    scores: csv.data.map( d => Number(d.score)),
  };

  const mapData = {
    plots: [plot],
    tracks: [{
      name: inputs.trackName,
      position: 'inside',
      dataType: 'plot',
      dataMethod: 'source',
      dataKeys: source,
    }],
  }

  return { ok: true, mapData };
}

