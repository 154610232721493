import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';

class DialogStart extends DefaultDialog {

  componentDidMount() {
    const seqLength = this.cgv.sequence.length;
    const maxLength = 50000;
    const contigCount = this.cgv.sequence.contigs().length;
    if (seqLength < maxLength && contigCount == 1) {
      this.onValidate(true)
    }
  }

  renderContents() {
    const { tool } = this.props;
    const { valid } = this.state;
    return (
      <div>
        {this.renderHeader()}
        {this.renderInput('jobName')}
        {this.renderInput('detailed')}

        {valid || this.renderStop('Sequence must be a single contig and less than 50,000 bp.')}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

