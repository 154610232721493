import React from 'react';
import Tools from '../models/Tools';
import * as helpers from '../support/Helpers';

class Job {

  constructor(job) {
    const keys = ['id', 'runtime', 'error', 'status', 'favorite', 'inputs', 'created_at', 'updated_at', 'meta'];
    for (const key of keys) {
      this[key] = job[key];
    }
    // Add Tool
    const tools = new Tools();
    if (job.tool) {
      this.tool = tools.get(job.tool.public_id);
    }
  }

  // FIXME: Fails if input ids change in the yaml file
  //  - need fall back
  static inputsAsString(job) {
    // Replace select value ids with actual values if available in the Tool
    const tool = Tools.get(job.tool.publicID);
    const { ...modifiedInputs } = job.inputs;
    for (const id of Object.keys(job.inputs)) {
      const input = tool.inputs.get(id);
      const rawValue = job.inputs[id];
      if (input.type === 'select') {
        modifiedInputs[id] = input.values[rawValue] || job.inputs[id];
      }
    }
    // Convert to String
    const rawInputs = helpers.objectToString(modifiedInputs);
    // Remove brackets
    let inputs = rawInputs.replace(/^\{/, '').replace(/, }$/, '');
    return inputs;
  }

  // FIXME: Fails if input ids change in the yaml file
  //  - need fall back
  // Uses same CSS as used stats pages
  // - app/assets/stylesheets/stats.css
  // - also see app/helpers/application_helper.rb#display_inputs()
  // - input-row (css class) font-size is overridden in Job.css
  static inputsAsHTML(job) {
    // Replace select value ids with actual values if available in the Tool
    const tool = Tools.get(job.tool.publicID);
    const items = [];
    for (const id of Object.keys(job.inputs)) {
      const input = tool.inputs.get(id);
      const rawValue = job.inputs[id];
      let value = rawValue;
      if (input && input.type === 'select') {
        value = input.values[rawValue] || job.inputs[id];
      }
      const unknownInputClass = (input === undefined) ? 'unknown-input' : '';

      items.push(<span key={id} title={unknownInputClass} className={`inputs-helper ${unknownInputClass}`}><span className='input-key'>{id}</span><span style={{fontStyle: 'normal'}}>:</span> <span className='input-value'>{`${value}`}</span></span>);
    }

    return <div className='inputs-row-html'>{items.reduce((prev, curr) => [prev, ', ', curr])}</div>;
  }

}

export default Job;

