import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import './FeaturePane.css'; // Using same CSS as Features
import './ContigPane.css';
import '../support/CommonStyles.css';
import { VirtualTable, Column } from '../presenters/VirtualTable';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextEditable from '../presenters/TextEditable';
import NumericInput from '../presenters/NumericInput';
import TextElement from '../presenters/TextElement';
import ButtonGroup from '../presenters/ButtonGroup';
import ImageButton from '../presenters/ImageButton';
import Color from '../presenters/Color';
import VisibilityButton from '../presenters/VisibilityButton';
import { Select, Option } from '../presenters/Select';
import * as helpers from '../support/Helpers';
import iconGoto from '../images/icon-goto.png';

// Connected
import { connect } from 'react-redux';

class ContigPane extends Component {

  static propTypes = {
    contigs: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      virtualTableRef: React.createRef(),
    };

    this.infoBoxRenderer = this.infoBoxRenderer.bind(this);
    this.optionCellRenderer = this.optionCellRenderer.bind(this);
    this.onRowMouseOver = this.onRowMouseOver.bind(this);
    this.onRowMouseOut = this.onRowMouseOut.bind(this);
  }

  rowClass(contig) {
    if (contig) {
      return contig.visible ? '' : 'ps-fade';
    }
  }

  searchWithString(string) {
    this.state.virtualTableRef.current.searchWithString(string);
  }

  openInfoFor(id) {
    this.state.virtualTableRef.current.openInfoFor(id);
  }


  onClickVisibilityToggle(contig) {
    // const contig = helpers.itemForIndex(this.props.contigs, index);
    const visible = !contig.visible;
    this.onContigChange({cgvID: contig.cgvID, value: visible, attribute: 'visible'})
    // this.listRef.recomputeRowHeights(index);
  }

  onClickMoveTo(contigRedux) {
    const { cgv, tabsRef: tabs } = this.context;
    tabs.setTabByID('map');
    const contig = cgv.objects(contigRedux.cgvID);
    contig.moveTo(1500);
  }

  onContigChange({cgvID, attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    const contig = cgv.objects(cgvID);
    // Name: replace non-alphanumeric characters with '_'
    if (attribute === 'name') {
      value = value.replace(/[^a-zA-Z0-9_]+/g, '_');
    }
    cgv.sequence.updateContigs(contig, {[attribute]: value});
    redraw && cgv.draw();
  }

  onContigOrderChange({cgvID, value}) {
    const cgv = this.context.cgv;
    const contig = cgv.objects(cgvID);
    cgv.sequence.moveContig(contig.index-1, value-1);
    cgv.draw();
  }

  onClickViewFeatures(contig) {
    const cgv = this.context.cgv;
    const sidebar = this.context.sidebarRef;
    const featurePane = sidebar.selectPane('features');
    const searchString = cgv.sequence.hasMultipleContigs ? `=${contig.name}:contig` : '';
    featurePane.searchWithString(searchString);
  }

  // swatch(legendItem, size=10, style={}) {
  //   const divStyle = {
  //     display: 'inline-block',
  //     marginRight: '5px',
  //     backgroundColor: legendItem.swatchColor,
  //     width: size,
  //     height: size,
  //     ...style,
  //   }
  //   return (
  //     <div className='swatch' style={divStyle}></div>
  //   )
  // }

  orderCellRenderer({ rowData }) {
    return (
      <div className='column-value number'>{helpers.commaNumber(rowData.order)}</div>
    )
  }

  nameCellRenderer({ rowData }) {
    return (
      <div className='column-value'>{rowData.name}</div>
    )
  }

  lengthCellRenderer({ rowData }) {
    const orientation = (rowData.orientation === '+') ? 'Forward' : 'Reverse';
    return (
      <div className='column-value'>
        <div className='column-value-main number'>{helpers.commaNumber(rowData.length)}</div>
        <div className='column-value-sub'>{orientation}</div>
      </div>
    )
  }

  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  optionCellRenderer({ rowData }) {
    const contig = this.props.contigs.byID[rowData.cgvID];
    const lastContigVisible = this.props.contigs.ids.filter( id => this.props.contigs.byID[id].visible ).length === 1;
    return (
      <ButtonGroup className='option-buttons'>
        <ImageButton
          onClick={ () => this.onClickMoveTo(contig) }
          image={iconGoto}
          title='Move To'
          disabled={!contig.visible}
        / >
        <VisibilityButton
          visible={contig.visible}
          disabled={lastContigVisible && contig.visible}
          onClick={ () => this.onClickVisibilityToggle(contig) }
        />
      </ButtonGroup>
    )
  }

  infoBoxRenderer(contig) {
    const cgvID = contig.cgvID;
    const cgv = this.context.cgv;
    const maxContigOrder = cgv.sequence.contigs().length;

    // this.context.cgv.object(cgvID).features().length
    //  <TextInput value={contig.name}
    //       onChange={(value) => this.onContigChange({cgvID, value, attribute: 'name'})}/>

    return (
      <div>
        <DataElement label='Name' help='Warning: Changing contig names may affect past or running Job results' helpPath='help:sidebar:regions:contigs:name'>
          <TextEditable value={contig.name} onChange={ (value) => this.onContigChange({cgvID, value, attribute: 'name'})}/>
        </DataElement>
        <DataElementGroup>
          <DataElement label='Order' helpPath='help:sidebar:regions:contigs:order'>
            <NumericInput value={contig.order}
              min={1}
              max={maxContigOrder}
              onChange={(value) => this.onContigOrderChange({cgvID, value})}/>
          </DataElement>
          <DataElement label='Orientation' helpPath='help:sidebar:regions:contigs:orientation'>
            <Select value={`${contig.orientation}`}
              onChange={(value) => this.onContigChange({cgvID, value, attribute: 'orientation'})}>
              <Option value="+">+</Option>
              <Option value="-">-</Option>
            </Select>
          </DataElement>
        </DataElementGroup>
        <DataElement label='Color' helpPath='help:sidebar:regions:contigs:color'>
          <Color
            colorString={contig.color}
            allowDefault={true}
            undefinedString='Backbone Color'
            isDefault={contig.color === undefined}
            onDefaultClick={() => this.onContigChange({cgvID, value: undefined, attribute: 'color'})}
            onChange={(value) => this.onContigChange({cgvID, value, attribute: 'color'})}
          / >
        </DataElement>
        <DataElementGroup>
          <DataElement label='Features' helpPath='help:sidebar:regions:contigs:features'>
            <TextElement clickable={true} onClick={() => this.onClickViewFeatures(contig)} >{this.context.cgv.objects(cgvID).features().length}</TextElement>
          </DataElement>
        </DataElementGroup>
        <div className='action-buttons'>
          <ImageButton imageName='delete' text='Delete Contig' width={110}
          title='Delete Contig' onClick={ () => this.onDeleteContig(contig.cgvID) } />
        </div>
      </div>
    )
  }

  onRowMouseOver(e, item) {
    const cgv = this.context.cgv;
    const contig = item && item.cgvID && cgv.objects(item.cgvID);
    if (contig) {
      contig.highlight();
    }
  }

  onRowMouseOut() {
    const cgv = this.context.cgv;
    cgv.clear('background');
    cgv.clear('ui');
  }

  onDeleteContig(cgvID) {
    const cgv = this.context.cgv;
    const contig = cgv.objects(cgvID);
    contig.remove();
    this.state.virtualTableRef.current.tableRef.recomputeRowHeights();
    cgv.draw();
  }

  render() {
    const contigs = helpers.itemsAsArray(this.props.contigs);
        // initialSearch='>10000:length'
    return (
      <VirtualTable
        headerHeight={20}
        rowHeight={32}
        infoHeight={300}
        data={contigs}
        dataName='Contigs'
        idKey='cgvID'
        infoRenderer={this.infoBoxRenderer}
        selectColumn={true}
        rowClass={this.rowClass}
        ref={this.state.virtualTableRef}
        onRowMouseOver={this.onRowMouseOver}
        onRowMouseOut={this.onRowMouseOut}
       >
        <Column
          width={30}
          label='#'
          className='contig-order'
          dataKey='order'
          search='number'
          cellRenderer={this.orderCellRenderer}
        />
        <Column
          label='Name'
          dataKey='name'
          width={190}
          cellRenderer={this.nameCellRenderer}
        />
        <Column
          width={80}
          label='Length'
          dataKey='length'
          search='number'
          cellRenderer={this.lengthCellRenderer}
        />
        <Column
          label='Orientation'
          dataKey='orientation'
          hidden={true}
        />
        <Column
          width={55}
          dataKey=''
          search='nosearch'
          cellRenderer={this.optionCellRenderer}
        />
        <Column
          label='cgvID'
          dataKey='cgvID'
          hidden={true}
        />
      </VirtualTable>
    );
  }
}

ContigPane.contextType = CGViewContext;

//Connected
const contigPaneMapStateToProps = (state) => ({ contigs: state.contigs });
const ConnectedContigPane = connect(contigPaneMapStateToProps, null, null, {forwardRef: true})(ContigPane);

// export default ContigPane;
export { ContigPane, ConnectedContigPane};

