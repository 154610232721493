import React from 'react';
// import PropTypes from 'prop-types';
// import './JSONDownload.css';
import { CGViewContext } from '../../app/CGViewContext';
import { ListItem } from '../../presenters/ListItem';
import Button from '../../presenters/Button';
import ImageButton from '../../presenters/ImageButton';
import ServerAPI from '../../models/ServerAPI';
// import * as helpers from '../../support/Helpers';

class JSONDownload extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      infoOpen: false,
    }

    this.clickDownload = this.clickDownload.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    return (
      <div className='list-item-content'>
        <div className='list-item-title'>
          JSON (Reloadable Map Data)
        </div>
         <ImageButton
            imageName='download'
            title='Download JSON'
            className='download-button'
            onClick={ this.clickDownload }
          / >
      </div>
    );
  }

  infoRenderer() {

    return (
      <div className='info-download-image'>
        <div>
          Download the CGView JSON data. This file can be loaded later to view and edit the map.
        </div>

        <div className='download-image'>
          <ImageButton
            className='btn-download-image'
            imageName='download'
            title='Download JSON'
            size='large'
            text='Download'
            width={105}
            onClick={ this.clickDownload }
          />
        </div>
      </div>
    );
  }

  clickDownload() {
    const Server = new ServerAPI();
    Server.get(Server.URL.downloadAction, {source: 'cgview_json'});
    const cgv = this.context.cgv;
    cgv.io.downloadJSON('cgview.json')
  }

  render() {
    return (
      <div className='JSONDownload'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

JSONDownload.contextType = CGViewContext;

export default JSONDownload;

