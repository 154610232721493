import React from 'react'
import ReactDOM from 'react-dom'
// import './JSONProject.css';
import './GenomeProject.css';
import GenomeProject from './GenomeProjectOLD';
import PropTypes from 'prop-types'
import FileInput from "../../proksee-viewer/presenters/FileInput";
import Button from "../../proksee-viewer/presenters/Button";
import ImageButton from "../../proksee-viewer/presenters/ImageButton";
import ExternalLink from "../../proksee-viewer/presenters/ExternalLink";
// import * as helpers from "../proksee-viewer/support/Helpers";

class JSONProject extends React.Component {

  static propTypes = {
    active: PropTypes.bool,
  }

  static defaultProps = {
    active: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      valid: false
    };
    this.onValidate = this.onValidate.bind(this);
  }

  onValidate(valid) {
    console.log(valid)
    this.setState({valid});
  }

  renderExample(id, description, db='nuccore') {
    return (
      <div className='example-block'>
        <a className='example-btn-start' href={`/projects/example?source=json&id=${id}`}>Start</a>
        <div className='example-details'>
          <div className='example-name' title={id}>{id}</div>
          <div className='example-description'>{description}</div>
          <div className='example-genbank'>
            <ImageButton className='example-genbank-download' text='JSON' size='small' imageName='downloadSmall' title='Download Starting GenBank File' width={60}
              onClick={() => { GenomeProject.downloadURI(`/data/json/${id}`, `${id}`) }}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { valid } = this.state;
    const { active } = this.props;

    // Only retrun field name if active.
    // This prevent sending the file when inactive.
    const fieldName = active ? "project[jobs_attributes][0][files_attributes][0]" : '';
    const source = active ? "project[source]" : '';

    return (
      <div className='new-project-page'>
        <div>Map JSON</div>
        <FileInput
          sourcesAllowed={['upload']}
          fieldName={fieldName}
          help="Proksee accepts JSON map files created by Proksee and CGView."
          fileType='cgview'
          onValidate={this.onValidate}
        />
        <input id="source" name={source} type="hidden" value="json" />
        <input className='hidden-submit' type='submit' onClick={(e)=> {
          if (!valid) {e.preventDefault();}
        }}/>

        <div className='btn-submit-or-examples'>
          <Button
            type='primary'
            className='btn-submit-project'
            onClick={() => document.forms['new_project'].submit()}
            width={150}
            height={38}
            disabled={!valid}>
            Create Map
          </Button>

          <fieldset className='or-examples'><legend>OR</legend></fieldset>

          <div className='example-section'>
            <div className='run-example'>Start an Example</div>
            { this.renderExample('NC_000913.json', 'E. coli K-12 genome saved as JSON') }
            <div className='example-help'>Click <strong>Start</strong> to create a new example JSON project using the provided JSON file. The example has a button to download the JSON. For details on the CGView JSON format see the <ExternalLink className='json-link' link='https://js.cgview.ca/json.html' name='CGview.js JSON page' size={12} /></div>
          </div>

        </div>
      </div>
    )
  }

}
export default JSONProject;

