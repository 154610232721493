import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ProjectBadge from '../presenters/ProjectBadge';
// Connected
import { connect } from 'react-redux';
import { CGViewContext } from '../app/CGViewContext';

ProjectBadgeController.propTypes = {
  daysRemaining:   PropTypes.number,
  sessionProject:  PropTypes.bool,
  dataHasChanged:  PropTypes.bool,
}

ProjectBadgeController.defaultProps = {
  daysRemaining: 0,
}

function ProjectBadgeController(props) {
  const context = useContext(CGViewContext);

  const {
    daysRemaining,
    sessionProject,
    dataHasChanged,
  } = props;

  let message;

  // Session Project Badge
  if (sessionProject) {
  const daysLeftString = (daysRemaining === 1) ? '1 day left!' : `${daysRemaining} days left`;
    message = (
      <div className='badge-text'>
        <div className='badge-text-head'>Session Project</div>
        <div className='badge-text-sub'>{daysLeftString}</div>
      </div>
    );
  }

  // Unsaved Changes Badge (Takes priority over Sesion Project)
  if (dataHasChanged) {
    message = (
      <div className='badge-text badge-text-danger'>
         <div className='badge-text-sub'>Unsaved Map</div>
         <div className='badge-text-sub'>Changes</div>
      </div>
    );
  }

  return <ProjectBadge message={message} onClick={ () => context.tabsRef.setTabByID('about') } />

}

//Connected
const projectBadgeControllerMapStateToProps = (state) => ({
  sessionProject: state.project.sessionProject,
  daysRemaining: state.project.daysRemaining,
  dataHasChanged: state.viewer.dataHasChanged,
});
const ConnectedProjectBadgeController = connect(projectBadgeControllerMapStateToProps)(ProjectBadgeController);

// export default ProjectBadgeController;
export { ProjectBadgeController, ConnectedProjectBadgeController };

