import React from 'react'
import ReactDOM from 'react-dom'
import './UserMenu.css';
import PropTypes from 'prop-types'
import PopupBox from '../proksee-viewer/presenters/PopupBox';
import DataElement from '../proksee-viewer/presenters/DataElement';
import DataElementGroup from '../proksee-viewer/presenters/DataElementGroup';
import TextElement from '../proksee-viewer/presenters/TextElement';
import ServerAPI from '../proksee-viewer/models/ServerAPI';
import Button from "../proksee-viewer/presenters/Button";
import consumer from '../channels/consumer';
import { Progress } from 'antd';
// import 'antd/dist/antd.css';
import * as helpers from "../proksee-viewer/support/Helpers";

class UserMenu extends React.Component {

  static propTypes = {
    role:    PropTypes.string,
    name:     PropTypes.string,
    quota:    PropTypes.number,
    used:     PropTypes.number,
    jobCount: PropTypes.number,
    projectCount: PropTypes.number,
  }

  static defaultProps = {
    role: '',
    name: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      quota:    props.quota,
      used:     props.used,
      jobCount: props.jobCount,
      projectCount: props.projectCount,
    };
    this.refreshState = this.refreshState.bind(this);
  }

  componentDidMount() {
    const refreshState = this.refreshState;
    consumer.subscriptions.create({channel: "UserChannel"}, {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log('User Connected')
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(user) {
        // Called when there's incoming data on the websocket for this channel
        // TODO create debug variable and only print when in debug mode
        // console.log('User Data Received:')
        // console.log(user)
        refreshState(user);
      }
    });
  }

  refreshState(user) {
    this.setState({
      quota:    user.storage_quota,
      used:     user.storage_used,
      jobCount: user.job_count,
      projectCount: user.project_count,
    });
  }

  showPopup(show) {
    this.setState( (state) => {return {showPopup: show}} );
  }

  logout() {
    const Server = new ServerAPI();
    Server.delete('/users/sign_out')
    .then( response => {
      location.reload();
    });
  }

  renderAccountSection() {
    const { role, name } = this.props;
    const title = (role && role != '') ? helpers.capitalize(role) : 'User';
    const userSectionTitle = <div className={`section-title section-title-${role}`}>{title}</div>;

    return (
      <div>
        {userSectionTitle}
        <div className='popup-name'>{name}</div>
        <DataElementGroup>
          <Button
            width={115}
            onClick={ () => window.open('/users/edit', '_self') }>Account</Button>
          <Button
            width={115}
            onClick={ () => this.logout() }>Logout</Button>
        </DataElementGroup>
        { role === 'admin' && (
          <DataElementGroup className='grp-proksee-stats'>
            <Button
              width={240}
              height={48}
              onClick={ () => window.open('/stats', '_self') }>Proksee Stats</Button>
          </DataElementGroup>
        )}
      </div>
    )
  }

  displayPopup() {
    // const { quota, used, jobCount, projectCount } = this.props;
    const { quota, used, jobCount, projectCount } = this.state;

    const percent = used/quota*100; 
    const strokeColor = (percent >= 100) ? '#F44' : '#77F';

    return (
      <div>
        {this.renderAccountSection()}
        <hr />
        <div>
          <div className='section-title'>Storage</div>
          <div className='big-progress'>
            <Progress
              type='line'
              strokeWidth={10}
              strokeLinecap='square'
              strokeColor={strokeColor}
              trailColor='#BBB'
              percent={percent}
              status='normal'
              format={ p => (percent >= 100) ? 'FULL' : <span>{helpers.round(percent, 0)}<span style={{fontSize: 'smaller'}}>%</span></span> }
            />
          </div>
        </div>
        <DataElementGroup>
          <DataElement label='Storage Used'>
            <TextElement>{helpers.formatBytes(used)}B</TextElement>
          </DataElement>
          <DataElement label='Storage Quota'>
            <TextElement>{helpers.formatBytes(quota)}B</TextElement>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Projects'>
            <TextElement clickable onClick={ () => window.open('/projects', '_self')}>{projectCount}</TextElement>
          </DataElement>
          <DataElement label='Jobs'>
            <TextElement>{jobCount}</TextElement>
          </DataElement>
        </DataElementGroup>
      </div>
    );
  }

    // const settings = showSettings ? <PopupBox targetNode={this.settingsNode} boundaryNode={this.fileViewerNode} dismissOnBlur={() => this.showSettings(false)}>{this.displaySettings()}</PopupBox> : '';
  render() {
    // const { quota, used } = this.props;
    const { showPopup, quota, used } = this.state;
    const { role, name } = this.props;
    const percent = used/quota*100; 
    const strokeColor = (percent >= 100) ? '#F44' : '#77F';
    const height = (role === 'admin') ? 305 : 250;
    const popup = showPopup ?
      <PopupBox
        targetNode={this.progressNode}
        boundaryNode={document.documentElement}
        height={height}
        dismissOnBlur={() => this.showPopup(false)}>{this.displayPopup()}</PopupBox> : '';
    return (
      <div
        className='UserMenu'
        ref={(node) => this.progressNode = node}
        onClick={ () => this.showPopup(!showPopup) }
      >
        <div className='popup-button'>
          <div className='user-name'><a href='#'>{name}</a></div>
          <Progress
            type='circle'
            width={36}
            strokeWidth={12}
            strokeLinecap='square'
            strokeColor={strokeColor}
            trailColor='#BBB'
            percent={percent}
            status='normal'
            format={ p => (percent >= 100) ? 'FULL' : <span>{helpers.round(percent, 0)}<span style={{fontSize: 'smaller'}}>%</span></span> }
          />
        </div>
        {popup}
      </div>
    )
  }

}
export default UserMenu;

