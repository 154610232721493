import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';

class AlignmentsCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  renderData(data) {
    return (
      <VirtualGrid
        data={data}
        header
        alternateRowColors
        rowHeight={30}
        defaultColumnWidth={80}
        excludeMissingColumns
      >
        <Column name='Contig' index={32} width={150} />
        <Column name='ORF_Start_Stop_Strand' index={20} width={150} />
        <Column name='Pident' index={3} width={60} />
        <Column name='Bitscore' index={4} width={70} type='number' />
        <Column name='e-value' index={6} width={80} type='number' />
        <Column name='MobileOG ID' index={12} width={160} linkerProps={{ url: 'https://mobileogdb.flsi.cloud.vt.edu/entries?q={VALUE}'}}/>
        <Column name='Gene Name' index={13} width={100} />
        <Column name='Best Hit Accession' index={14} width={150} />
        <Column name='Major Category' index={15} width={200} />
        <Column name='Minor Category' index={16} width={200} />
        <Column name='Source DB' index={17} width={140} />
        <Column name='Evidence Type' index={18} width={100} />
      </VirtualGrid>
    );
    //<Column name='Best Hit Accession' index={14} width={150} linkerProps={{ url: 'https://www.ncbi.nlm.nih.gov/protein/{VALUE}'}} />
  }

}

export default AlignmentsCard;

