import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DataElement from '../presenters/DataElement';
import { Select, Option } from './Select';
import { CGViewContext } from '../app/CGViewContext';

SelectGeneticCode.propTypes = {
  title:             PropTypes.string,
  defaultItemID:     PropTypes.string,
  // Controlled component will use 'value'
  value:             PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mapDefaultOption:  PropTypes.bool,
  className:         PropTypes.string,
  onChange:          PropTypes.func,
  // Changes the help for when used to set map or feature genetics codes
  specialCase:       PropTypes.oneOf(['map', 'feature']),
  // disabled:    PropTypes.bool,
}

SelectGeneticCode.defaultProps = {
  title: 'Genetic Code',
  mapDefaultOption: true,
  onChange:  () => {},
  // disabled: false,
}

function SelectGeneticCode(props) {
  const {
    title,
    value,
    defaultItemID,
    mapDefaultOption,
    className,
    onChange,
    specialCase,
  } = props;

  const context = useContext(CGViewContext);
  const cgv = context.cgv;
  const tables = cgv.codonTables.names();

  const startingID = value || defaultItemID || (!mapDefaultOption && Object.keys(tables)[0]) || 'DEFAULT';
  const [selectedCode, setSelectedCode] = useState(startingID);

  const klass = classNames(
    'SelectGeneticCode', className,
  );

  const handleOnChange = ({value, attribute}) => {
    setSelectedCode(value);
    onChange(value);
  }


  // Help
  let help;
  switch(specialCase) {
    case 'map':
      help = 'Set the default genetic code for this map';
      break;
    case 'feature':
      help = (selectedCode === 'DEFAULT') ?
        'Map Default will use the map genetic codes' :
        'This will override the map genetic code';
      break;
    default:
      help = (selectedCode === 'DEFAULT') ?
        'Map Default will use the map and feature genetic codes' :
        'This will override the map and feature genetic codes';
  }

  return (
    <div className={klass} >
      <DataElement label={title} help={help}>
        <Select value={selectedCode}
          onChange={(value) => handleOnChange({value, attribute: 'geneticCode'})}>
          { mapDefaultOption && <Option key='default' value='DEFAULT'>Map Default [11]</Option> }
          { Object.entries(tables).map( ([code, name]) => <Option key={code} value={code}>{code}: {name}</Option> ) }
        </Select>
      </DataElement>
    </div>
  )
}

export default SelectGeneticCode;

