// NOTE: Typically features, legends, etc are added to MapData and returned
// by the add function. Then in Tool.js, the handleAddConfirmed funtion calls
// addToMap with the data to add. This tool directly works on the data using
// the CGView API.
// FIXME: we should have a better system using async/await for client tools that may run for a while
// - the way I do it here (via a simple timeout) is not ideal, if errors occur!!

import Message from '../../presenters/Message';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import {NCList} from '../../../CGView';

const getBlastFeatures = (cgv) => {
  const blastFeatures = [];
  for (const feature of cgv.features()) {
    if (/^blast-/.test(feature.source)) {
      blastFeatures.push(feature);
    }
  }
  return blastFeatures;
}

const getCutoffs = (inputs) => {
  //Extract cutoffs from string
  const cutoffs = inputs.cutoffs.split(',').map( i => Number(i.trim()) );

  const colors = cutoffs.map( (cutoff, index) => colorForInputs(inputs, index) );

  return cutoffs.map( (cutoff, i) => [cutoff, colors[i]] );
}

// Value is a number between 0 and 1
function colorForInputs(inputs, index) {
  const scheme = inputs.colorScheme;
  const cutoffs = inputs.cutoffs.split(',').map( i => Number(i.trim()) );
  const customColors = inputs.customColors?.split(';').map( i => i.trim() );
  const fraction = (index+1) / cutoffs.length;
  if (scheme === 'custom') {
    if (index < customColors.length) {
      return customColors[index];
    } else {
      // If there are more cutoffs than custom colors, use d3 Greys color scheme
      return d3ScaleChromatic.interpolateGreys(fraction);
    }
  } else {
    const schemeProperty = `interpolate${scheme}`;
    return d3ScaleChromatic[schemeProperty](fraction); ;
  }
}

// Find and returns any legends previously created by Blast Formatter (i.e have the meta tag {proksee: {blast_formatter: true}})
// The found legend items will temporarily have there names changed to their cgvID so they do not intefer with the new legend items
function findPreviousBlastFormatterLegends(cgv) {
  const legendItems = cgv.legend.items().filter( (i) => i.meta?.proksee?.blast_formatter );
  const tempUpdates = {};
  legendItems.forEach( i => tempUpdates[i.cgvID] = {name: i.cgvID} );
  cgv.legend.updateItems(tempUpdates);
  return legendItems;
}

// Returns the unique array of legend items for the blast features
function findPreviousLegendItems(blastFeatures) {
  const blastFeatureLegends = [];
  blastFeatures.forEach( f => {
    blastFeatureLegends.push(f.legend);
  });
  return [...new Set(blastFeatureLegends)];
}

function hideLowerScoredHits(cgv, blastFeatures) {
  // Sort features by start position and partition them by track/contig
  // This will speed up the check for features that enclose other features
  const featuresByTrackAndContig = {};
  for (const feature of blastFeatures) {
    const key = `${feature.tracks()[0].cgvID}-${feature.contig.cgvID}`
    if (Object.keys(featuresByTrackAndContig).includes(key)) {
      featuresByTrackAndContig[key].push(feature);
    } else {
      featuresByTrackAndContig[key] = [feature];
    }
  }
  // console.log('Keys Count:', Object.keys(featuresByTrackAndContig).length)

  // Hiding features that are enclosed by other features with better scores
  const featuresToHide = [];
  // Go through each feature in the partition and compare to overlapping features
  for (const key of Object.keys(featuresByTrackAndContig)) {
    const features = featuresByTrackAndContig[key];
    const ncFeatures = new NCList(features)
    for (let i=0, len=features.length; i < len; i++) {
      const feature = features[i];
      const overlappingFeatures = ncFeatures.find(feature.start, feature.stop);
      // Compare feature with overlapping features
      for (const compareFeature of overlappingFeatures) {
        if (compareFeature.stop < feature.stop) {
          continue;
        } else if (compareFeature.start > feature.start) {
          // continue vs break makes little difference in speed
          // And with the NC list the features look to be sorted by start so break should be faster
          // But just in case the NC list is not sorted by start, we will use continue
          continue;
          // break;
        } else if (compareFeature.score > feature.score) {
          // Here: compareFeatures start <= feature.start and compareFeature.stop >= feature.stop
          // Found compareFeature that encloses test feature
          featuresToHide.push(feature);
          break;
        }
      }
    }

  }
  // console.log('Features to Hide:', featuresToHide.length)
  cgv.updateFeatures(featuresToHide, {visible: false});
}

function sortTracks(cgv, tracks, mostSimilarFirst) {
  // Get Tracks with blast results
  // const tempTracks = blastFeatures.map( f => f.tracks() ).flat();
  // const tracks = [...new Set(tempTracks)];
  // console.log("Blast Tracks:", tracks.map( t => t.name ));
  const scores = {};
  const indices = [];
  tracks.forEach( track => {
    let totalLength = 0;
    let percentLengthSums = 0;
    const trackFeatures = track.features();
    trackFeatures.forEach( f => {
      if (f.meta?.identity) {
        percentLengthSums += (f.meta.identity * f.length);
        totalLength += f.length;
      }
    });
    const ani = percentLengthSums / totalLength;
    scores[track.cgvID] = ani;
    const index = cgv.tracks().indexOf(track);
    indices.push(index);
    // console.log(`ANI(like) - [${track.name}] [${index}]: ${ani})`)
  });

  // Sort with highest score first
  if (mostSimilarFirst) {
    tracks.sort( (a, b) => scores[b.cgvID] - scores[a.cgvID] );
  } else {
    tracks.sort( (b, a) => scores[b.cgvID] - scores[a.cgvID] );
  }


  // Sort track indices
  // This will use the same track indices but now the track will be sorted
  // (ie if tracks indices were 3,4,8,9, those same indices will be used
  indices.sort( (a, b) => a - b );

  for (let i=0, len=tracks.length; i < len; i++) {
    tracks[i].move(indices[i]);
    // console.log(`Track [${i} - ${indices[i]}] - Score: ${scores[tracks[i].cgvID]}`)
  }
}

function formatBLAST({cgv, inputs}) {
  const blastFeatures = getBlastFeatures(cgv);
  // console.log(inputs)
  // const cutoffs = getCutoffs(inputs.cutoffs);
  const cutoffs = getCutoffs(inputs);

  // Find previous Legend Items that were created with the BlastFormatter
  const blastLegendsToRemove = findPreviousBlastFormatterLegends(cgv);
  // Find all previous legend items for the blast features
  const previousBlastLegends = findPreviousLegendItems(blastFeatures);

  // Create a legend for each cutoff and apply to features
  for (const cutoff of cutoffs) {
    const cutoffValue = cutoff[0];
    const cutoffColor = cutoff[1];
    // console.log('CUTOFF:', cutoffValue)
    // console.log('Color:', cutoffColor)
    const comparison = (cutoffValue === 100) ? "=" : ">=";
    const legend = cgv.legend.addItems([{name: `BLAST ${comparison} ${cutoffValue}% Identical`, swatchColor: cutoffColor, meta: {proksee: {blast_formatter: {cutoff: cutoffValue}}}}])[0];
    const featuresToUpdate = [];
    for (const feature of blastFeatures) {
      if ( (feature.score * 100) >= cutoffValue ) {
        featuresToUpdate.push(feature);
      }
    }
    cgv.updateFeatures(featuresToUpdate, {legendItem: legend});
    // Add color scheme to main cgview meta data
    cgv.update({meta: {proksee: {blast_formatter: {colorScheme: inputs.colorScheme}}}})
  }

  // Check for unaccounted features if the first cutoff is not 0
  // const firstCutoffValue = cutoffs[0][0];
  // if (firstCutoffValue !== 0) {
  //   const featuresToUpdate = [];
  //   for (const feature of blastFeatures) {
  //     if ( (feature.score * 100) < firstCutoffValue ) {
  //       featuresToUpdate.push(feature);
  //     }
  //   }
  //   // Apply new legend that will be ignored by the tool
  //   if (featuresToUpdate.length > 0) {
  //     const legend = cgv.legend.addItems([{name: "BLAST >= 0% Identical", swatchColor: 'rgba(255,255,255,0)', meta: {proksee: {blast_formatter: {cutoff: 0, noZeroCutoff: true}}}}])[0];
  //     cgv.updateFeatures(featuresToUpdate, {legendItem: legend});
  //   }

  // }

  // Remove any previous legend items created by the tool
  cgv.legend.removeItems(blastLegendsToRemove)
  // Remove any previous legend items that are no longer used
  if (inputs.removePreviousLegends) {
    const previousLegendsToRemove = previousBlastLegends.filter( i => ((i.features().length === 0) && !(i.meta?.proksee?.blast_formatter))  );
    cgv.legend.removeItems(previousLegendsToRemove)
  }

  // TODO: Remove previous legend items (after setting them to the new)
  // const tempPreviousLegendItems = blastFeatures.map( f => f.legendItem );
  // const previousLegendItems = [...new Set(tempPreviousLegendItems)];
  // console.log('TEST', previousLegendItems.length)
  // previousLegendItems.forEach( i => console.log(i.name, i.features().length) );

  if (inputs?.hideLowerScoredEnclosedFeatures) {
    hideLowerScoredHits(cgv, blastFeatures);
  } else {
    cgv.updateFeatures(blastFeatures, {visible: true});
  }

  // Find blast tracks
  const tempTracks = blastFeatures.map( f => f.tracks() ).flat();
  const blastTracks = [...new Set(tempTracks)];

  // Sort tracks
  if (inputs?.sortTracks) {
    sortTracks(cgv, blastTracks, inputs?.sortOrderMostSimilarInside);
  }

  // Update drawOrder
  cgv.updateTracks(blastTracks, {drawOrder: 'score'});


  Message.close();
  cgv.drawFull();
}

export default function add({cgv, inputs}) {
  Message.open({content: "Formatting BLAST Results..."})
  // Using timeout so that the above message will be shown.
  setTimeout( () => formatBLAST({cgv, inputs}), 1000);

  // Return empty mapData: this will cause the "Results addd to the map" ToastMessage and also switch to the map tab.
  return { ok: true, mapData: {}};
}

