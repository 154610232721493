import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../app/CGViewContext';
import './ListPane.css';
import './CaptionPane.css';
import VirtualList from '../presenters/VirtualList';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextInput from '../presenters/TextInput';
import ImageButton from '../presenters/ImageButton';
import Font from '../presenters/Font';
import Color from '../presenters/Color';
import Anchor from '../presenters/Anchor';
import Position from '../presenters/Position';
import ButtonGroup from '../presenters/ButtonGroup';
import VisibilityButton from '../presenters/VisibilityButton';
import * as helpers from '../support/Helpers';
import iconGoto from '../images/icon-goto.png';
import iconAlignLeft from '../images/icon-align-left.png';
import iconAlignCenter from '../images/icon-align-center.png';
import iconAlignRight from '../images/icon-align-right.png';
import '../support/CommonStyles.css';
// Connected
import { connect } from 'react-redux';

class CaptionPane extends React.Component {

  static propTypes = {
    captions: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.onSortEnd = this.onSortEnd.bind(this);
    this.listItemID = this.listItemID.bind(this);
    this.listItemMainRenderer = this.listItemMainRenderer.bind(this);
    this.listItemInfoRenderer = this.listItemInfoRenderer.bind(this);
    this.onRowMouseOver = this.onRowMouseOver.bind(this);
    this.onRowMouseOut = this.onRowMouseOut.bind(this);
    this.setListRef = this.setListRef.bind(this);
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    const cgv = this.context.cgv;
    cgv.moveCaption(oldIndex, newIndex);
  };

  listItemID(index) {
    const caption = helpers.itemForIndex(this.props.captions, index);
    return caption.cgvID
  }

  // onClickVisibilityToggle(index) {
  //   const caption = helpers.itemForIndex(this.props.captions, index);
  //   const visible = !caption.visible;
  //   this.onCaptionChange({cgvID: caption.cgvID, value: visible, attribute: 'visible'})
  //   this.listRef.recomputeRowHeights(index);
  // }

  // Scrolls to the item with the id and opens the info
  openInfoFor(id) {
    const index = this.props.captions.ids.indexOf(id);
    this.listRef.scrollToRow(index);
    this.listRef.openInfoFor(id);
    this.listRef.recomputeRowHeights(index);
  }

  onClickMoveTo(captionRedux) {
    const { cgv, tabsRef: tabs } = this.context;
    tabs.setTabByID('map');
    const caption = cgv.objects(captionRedux.cgvID);
    caption.moveTo(1000);
  }

  listItemMainRenderer(index) {
    const caption = helpers.itemForIndex(this.props.captions, index);
    const name = caption.name.split('\n').reduce( (prev, curr, index) => [prev, <span className='line-separator' key={index}>-</span>, curr]);
    const titleClass = classNames('list-item-title', {fade: !caption.visible});
    const moveButton = (caption.on === 'map') ?
      <ImageButton
        onClick={ () => this.onClickMoveTo(caption) }
        image={iconGoto}
        title='Move To'
      / >
      : '';
    return (
      <div className='list-item-content'>
        <div className={titleClass}>
          {name}
        </div>
        <div className='list-item-options'>
          <ButtonGroup>
            {moveButton}
            <VisibilityButton visible={caption.visible}
              onClick={ (value) => this.onCaptionChange({cgvID: caption.cgvID, value, attribute: 'visible'}) } />
          </ButtonGroup>
        </div>
      </div>
    );
  }
            // <VisibilityButton visible={caption.visible} onClick={ () => this.onClickVisibilityToggle(index) } />

  onCaptionChange({cgvID, attribute, value, redraw=false, recomputeRowHeight=false}) {
    const cgv = this.context.cgv;
    const caption = cgv.objects(cgvID);
    cgv.updateCaptions(caption, {[attribute]: value});
    if (recomputeRowHeight) {
      this.listRef.recomputeRowHeights(0);
    }
    redraw && cgv.draw();
  }

  onClickedNamedPosition(cgvID, e) {
    const name = e.target.getAttribute('data-name');
    this.onCaptionChange({cgvID, value: name, attribute: 'position'});
  }


  listItemInfoRenderer(index) {
    const captionData = helpers.itemForIndex(this.props.captions, index);
    const cgvID = captionData.cgvID;
    const caption = this.context.cgv.objects(cgvID);
    const position = caption.position;
    const anchor = caption.anchor;

    return (
      <div>
        <DataElement label='Caption Text' helpPath='help:sidebar:display:captions:name'>
          <TextInput value={captionData.name} type='textarea' className='scroll-skinny'
            onChange={(value) => this.onCaptionChange({cgvID, value, attribute: 'name', recomputeRowHeight: true})}/>
        </DataElement>
        <Position
          position={position}
          onChange={(value) => this.onCaptionChange({cgvID, value, attribute: 'position'})}
          onRelativeToChange={(value) => this.onCaptionChange({cgvID, value, attribute: 'on'})}
        />
        <Anchor
          disabled={captionData.on === 'canvas'}
          auto={anchor.auto}
          xPercent={anchor.xPercent}
          yPercent={anchor.yPercent}
          name={anchor.name}
          onChange={(value) => this.onCaptionChange({cgvID, value, attribute: 'anchor'})}
        />
        <DataElement label='Font'helpPath='help:sidebar:display:captions:font'>
          <Font fontString={captionData.font}
            onChange={(value) => this.onCaptionChange({cgvID, value, attribute: 'font'})}
          >
            <ButtonGroup separated={true}>
              <ImageButton
                title='Align Left'
                image={iconAlignLeft}
                active={captionData.textAlignment === 'left'}
                onClick={() => this.onCaptionChange({cgvID, value: 'left', attribute: 'textAlignment'})}
              / >
              <ImageButton
                title='Align Center'
                image={iconAlignCenter}
                active={captionData.textAlignment === 'center'}
                onClick={() => this.onCaptionChange({cgvID, value: 'center', attribute: 'textAlignment'})}
              / >
              <ImageButton
                title='Align Right'
                image={iconAlignCenter}
                active={captionData.textAlignment === 'right'}
                onClick={() => this.onCaptionChange({cgvID, value: 'right', attribute: 'textAlignment'})}
              / >
            </ButtonGroup>
          </Font>
        </DataElement>
        <DataElementGroup>
          <DataElement label='Font Color' helpPath='help:sidebar:display:captions:color'>
            <Color
              colorString={captionData.fontColor}
              onChange={(value) => this.onCaptionChange({cgvID, value, attribute: 'fontColor'})}
            / >
          </DataElement>
          <DataElement label='Background Color' helpPath='help:sidebar:display:captions:background_color'>
            <Color
              colorString={captionData.backgroundColor}
              onChange={(value) => this.onCaptionChange({cgvID, value, attribute: 'backgroundColor'})}
            / >
          </DataElement>
        </DataElementGroup>
        <div className='action-buttons'>
          <ImageButton imageName='delete' text='Delete Caption' width={120}
          title='Delete Caption' onClick={ () => this.onDeleteCaption(caption.cgvID) } />
        </div>
      </div>
    );
  }

  onDeleteCaption(cgvID) {
    const cgv = this.context.cgv;
    const caption = cgv.objects(cgvID);
    caption.remove();
  }

  onAddCaption() {
    const cgv = this.context.cgv;
    const lastCaption = cgv.captions().last;
    const fontColor = lastCaption ? lastCaption.fontColor.rgbaString : cgv.settings.backgroundColor.copy().invert();
    const backgroundColor = lastCaption ? lastCaption.backgroundColor.rgbaString : cgv.settings.backgroundColor.rgbaString;
    const font = lastCaption ? lastCaption.font.string : "sans-serif,plain,24";
    const captions = cgv.addCaptions({
      name: 'New Caption',
      position: 'middle-center',
      font,
      fontColor,
      backgroundColor,
    });
    cgv.draw();
  }

  onRowMouseOver(index) {
    const cgv = this.context.cgv;
    const captionData = helpers.itemForIndex(this.props.captions, index);
    const caption = cgv.objects(captionData.cgvID);
    if (caption) {
      caption.highlight();
    }
  }

  onRowMouseOut() {
    const cgv = this.context.cgv;
    cgv.clear('background');
    cgv.clear('ui');
  }


  setListRef(ref) {
    this.listRef = ref;
  }

  render() {
    const captions = helpers.itemsAsArray(this.props.captions);

    return (
      <VirtualList
        itemCount={captions.length}
        items={captions}
        dataName={'Captions'}
        sortable={true}
        onSortEnd={this.onSortEnd}
        listItemMainRenderer={this.listItemMainRenderer}
        listItemInfoRenderer={this.listItemInfoRenderer}
        listItemID={this.listItemID}
        onRowMouseOver={this.onRowMouseOver}
        onRowMouseOut={this.onRowMouseOut}
        setRef={this.setListRef}
        addAction={(ids)=>{this.onAddCaption()}}
      />
    );
  }
}

CaptionPane.contextType = CGViewContext;

//Connected
const captionPaneMapStateToProps = (state) => ({ captions: state.captions });
const ConnectedCaptionPane = connect(captionPaneMapStateToProps, null, null, {forwardRef: true})(CaptionPane);

// export default CaptionPane;
export { CaptionPane, ConnectedCaptionPane };

