document.addEventListener('DOMContentLoaded', () => {
  // Add News badge to About if there are new posts
  // Also adds baddge to Proksee News in footer

  // Only need to show badge if not on the About or Posts page
  const path = window.location.pathname;
  const showBadge = !(/\/(posts|about)($|\/)/.test(path));

  if (showBadge) {
    // Get recent post ids
    const aboutEl = document.getElementById('nav-about-menu');
    const postIDsString = aboutEl.dataset.recentPostIds || '';
    const postIDs = postIDsString.split(',').filter(i => i != '').map( i => Number(i));
    //console.log(postIDs)


    // Proksee News in the footer
    const newsEl = document.getElementById('footer-news-link');

    // Get most recent seen id from local storage
    storage = window.localStorage;
    const last_id_seen = storage.getItem('last_news_id_checked');
    //console.log(last_id_seen)

    // Count how many are new
    let count = postIDs.findIndex( i => i <= last_id_seen);
    if (count == -1) {
      count = postIDs.length;
    }

    if (count > 0) {
      news_badge = document.createElement("div");
      news_badge.classList.add('about-menu-badge');
      news_badge.textContent = count;
      aboutEl.append(news_badge);
      if (newsEl) {
        newsEl.append(news_badge.cloneNode(true));
      }
    }

  }

});
