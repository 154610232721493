import React from 'react'
import ReactDOM from 'react-dom'
import './ThemeMode.css';
import { changeTheme } from '../proksee-viewer/support/Helpers';
import darkIcon from '../../assets/images/dark-mode.png';
import lightIcon from '../../assets/images/light-mode.png';
import PropTypes from 'prop-types'
import { Switch } from 'antd';
import CookieSwitch from './CookieSwitch';
// import 'antd/dist/antd.css';

class ThemeMode extends React.Component {

  static propTypes = {
    darkMode: PropTypes.bool,
  }

  static defaultProps = {
    darkMode: false,
  }

  constructor(props) {
    super(props);

    const storage = window.localStorage;
    const storedDarkMode = storage.getItem('theme') === 'dark';
    this.state = {
      darkMode: (storedDarkMode || props.darkMode || false),
    };
  }

  componentDidMount() {
    const { darkMode } = this.state;
    const theme = darkMode ? 'dark' : 'light';
    changeTheme(theme);
    this.changePrismTheme(theme);
  }

  changePrismTheme(name) {
    const prismDir = 'prism_themes';
    const dark = 'material_dark.css';
    const light = 'material_light.css';
    const style = document.getElementById('prism-theme');
    if (name === 'light') {
      style.href = `/${prismDir}/${light}`;
    } else {
      style.href = `/${prismDir}/${dark}`;
    }
  }

  onChange(lightMode) {
    const storage = window.localStorage;
    const theme = lightMode ? 'light' : 'dark';

    if (CookieSwitch.allowCookies) {
      storage.setItem('theme', theme);
    }

    changeTheme(theme);
    this.changePrismTheme(theme);
  }

  render() {
    const { darkMode } = this.state;

    return (
      <div className='ThemeMode'>
        <Switch
          unCheckedChildren={<img src={darkIcon} width='16' height='16'/>}
          checkedChildren={<img src={lightIcon} width='16' height='16'/>}
          onChange={ (checked) => this.onChange(checked) }
          defaultChecked={!darkMode}
        />
      </div>
    )
  }

}
export default ThemeMode;

