import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sortableElement, sortableHandle } from 'react-sortable-hoc';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import './ListItem.css';
import iconDragHandle from '../images/drag-handle.png';
import iconInfo from '../images/icon-info.png';


const DragHandle = sortableHandle(() => (
    <img className='drag-handle' height='12' width='16' alt='' src={iconDragHandle} />
));

const SortableItem = sortableElement(({value}) => value);
// const SortableItem = sortableElement(({value}) => (
//   <div className='sortable-item'>
//     {value}
//   </div>
// ));

class ListItem extends React.Component {

  static propTypes = {
    closed:              PropTypes.bool,
    className:           PropTypes.string,
    sortable:            PropTypes.bool,
    style:               PropTypes.object,
    infoOpen:            PropTypes.bool,
    disabled:            PropTypes.bool,
    infoClicked:         PropTypes.func,
    mainRenderer:        PropTypes.func,
    infoRenderer:        PropTypes.func,
    recomputeRowHeights: PropTypes.func,
    onRowMouseOver:      PropTypes.func,
    onRowMouseOut:       PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    recomputeRowHeights: () => {},
  }

  constructor(props) {
    super(props);

    this.handleInfoClicked = this.handleInfoClicked.bind(this);
    this.animate = this.animate.bind(this);
  }


  infoButton = (active) => {
    // NOTE: uncomment below to show 'i' in list items
    // const infoButtonClass = classNames(
    //   'info-button',
    //   {active}
    // );
    // return (
    //   <div className={infoButtonClass}>
    //     <img height='13' width='5' alt='' src={iconInfo} />
    //   </div>
    // )
  }

  handleInfoClicked() { 
    const startTime = new Date().getTime();
    this.props.infoClicked();
    this.animate(startTime);
  }

  // http://plnkr.co/edit/VanCAQmSkUejp3hbJUyJ?p=preview
  animate(startTime) {
    const now = new Date().getTime();
    const duration = 250;
    // console.log('ANIMATE')

    const diff = now - startTime;

    if ((diff) < duration) {
      setTimeout(this.animate, 20, startTime);
    }

    // console.log('ANIMATE')
    this.props.recomputeRowHeights();
  }

  renderMain() {
    return this.props.mainRenderer();
  }

  renderInfo() {
    // return this.props.infoRenderer();
    return this.props.infoOpen && this.props.infoRenderer();
  }

  renderItem() {
    const {
      className,
      sortable,
      style,
      infoOpen,
      disabled=false,
      onRowMouseOver,
      onRowMouseOut,
    } = this.props;

    const listItemClass = classNames(
      'ListItem', className,
      {open: infoOpen, disabled}
    );

    // FIXME: style issue with sortable
    // - When sortable is true, style from react-virutaulized cause issues
    // - Style is required when sortable is false (at least for large lists)
    //    - large lists: jump and flicker when scrolling
    // - For now just turn style on/off depending on sortable
    // - Will probably have to revisit this when dealing with large sortable lists
    // - Looks like the issue is with open items. Item style heights are staying
    //   in the same order rather then changing with the new order.
    //   - ie. item 3 stays with "open" height if the order changes so that
    //     item 3 (now different item) is not open
    // const sortStyle = sortable ? {} : style;
    // [2023-03-23] - Fixed
    const sortStyle = style;
    return (
      <div
        className={listItemClass}
        style={sortStyle}
        onMouseOver={(e) => onRowMouseOver && onRowMouseOver()}
        onMouseOut={onRowMouseOut}
      >
        <div className='list-item-main' onClick={ this.handleInfoClicked} >
          { sortable && <DragHandle /> }
          <div className='title'>
            { this.renderMain() }
          </div>
          { this.infoButton(infoOpen) }
        </div>
        <div className='list-item-info'>
          { this.renderInfo() }
        </div>
      </div>
    )
  }

  render() {
    const {sortable, index} = this.props;
    const value = this.renderItem();
    if (sortable) {
      return <SortableItem index={index} value={value} />;
    } else {
      return value;
    }
  }

}

class VirtualListItem extends React.Component {
  static propTypes = {
    parent:            PropTypes.any.isRequired,
    index:             PropTypes.number,
    cellMeasurerCache: PropTypes.instanceOf(CellMeasurerCache).isRequired,
  }

  render() {
    const { parent, index, cellMeasurerCache } = this.props;
    // console.log(cellMeasurerCache)

        // { console.log(this.props.item.cgvID, index, _testkey, cellMeasurerCache._cellHeightCache[_testkey], this.props.infoOpen) }
    return (
      <CellMeasurer
        columnIndex={0}
        cache={cellMeasurerCache}
        parent={parent}
        rowIndex={index}
      >
          <ListItem
            {...this.props}
          />
      </CellMeasurer>
    );
  }

}

export { ListItem, VirtualListItem };

// export default ListItem;
// CODE FROM OLD CGView SERVER
// <li>
//   <div id='<%= object.cgvID %>' class='data-row <%= klass %> <%= (visibility && !object.visible) ? "dim" : "" %>' data-cgv-id='<%= object.cgvID %>' data-cgv-accessor='<%= cgvAccessor %>'>
//     <% if (sortable) { %>
//       <div class='drag-handle'>
//         <img height='<%= imageSize %>' width='<%= imageSize %>' src='/my_assets/sidebar/drag_handle.png' />
//       </div>
//     <% } %>
//     <div class='name'><%= object.name %></div>
//     <div class='track-progress'></div>
//     <%= JST['sidebar/templates/controls/option_buttons']( { visibility: visibility, visibleState: object.visible }) %>
//   </div>
//   <%= infoTemplate %>
//   <div class='sub-list-placeholder'></div>
//   <%= addTemplate %>
// </li>


