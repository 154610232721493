import { RULER_UPDATE } from '../constants/ActionTypes'


export const updateRuler = (attributes) => ({
  type: RULER_UPDATE,
  payload: {
    attributes,
  }
});


