import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElementGroup from '../../../presenters/DataElementGroup';
import DataElementContainer from '../../../presenters/DataElementContainer';
import HelpElement from '../../../presenters/HelpElement';
import './DialogStart.css';

class DialogStart extends DefaultDialog {

  constructor(props, context) {
    super(props, context);

    this.setAvailableTypes();
    this.updateTypes(true);
    this.summaryMap = this.createSummaryMap();
  }

  setAvailableTypes() {
    const { tool } = this.props;
    this.allSubjectTypes = tool.inputs.get('subject_type').values
    this.allQueryTypes = tool.inputs.get('query_type').values;
    if ( !this.cgv.features().some( (f) => { return f.type == 'CDS' }) ) {
      delete this.allQueryTypes['cds']
    }
  }


  createSummaryMap() {
    var protein   = 'protein sequences';
    var dna       = 'DNA';
    var trans     = 'translated DNA';
    var cds       = 'CDS protein sequences';
    var orfs      = 'tranlated ORFs';
    var map = {
      'dna-dna':               `${dna} vs ${dna} (blastn)`,
      'translated-translated': `${trans} vs ${trans} (tblastx)`,
      'translated-cds':        `${trans} vs ${cds} (blastx)`,
      'translated-orfs':       `${trans} vs ${orfs} (blastx)`,
      'translated-protein':    `${trans} vs ${protein} (blastx)`,
      'cds-translated':        `${cds} vs ${trans} (tblastn)`,
      'cds-cds':               `${cds} vs ${cds} (blastp)`,
      'cds-orfs':              `${cds} vs ${orfs} (blastp)`,
      'cds-protein':           `${cds} vs ${protein} (blastp)`,
      'orfs-translated':       `${orfs} vs ${trans} (tblastn)`,
      'orfs-cds':              `${orfs} vs ${cds} (blastp)`,
      'orfs-orfs':             `${orfs} vs ${orfs} (blastp)`,
      'orfs-protein':          `${orfs} vs ${protein} (blastp)`
    };
    return map
  }

  // value is a pfile
  onFileChange({attribute, value}) {
    this.onChange({attribute, value}, this.updateTypes);
    this.setState({fileName: value.fileName});
  }

  // Types refer to what is used in the comparison (e.g. dna, translated, orfs, cds, protein)
  // This will update the query/subject select dropdown, and change the current selected value if needed
  updateTypes(initial=false) {
    const { options, resetKey } = this.state;

    const disabledSubjectTypes = this.disabledSubjectTypes(options);
    const displayedQueryTypes =  this.getDisplayedQueryTypes();
    const displayedSubjectTypes = this.getDisplayedSubjectTypes();
    const selectedQueryType = this.getValue(options.query_type, Object.keys(displayedQueryTypes));
    const selectedSubjectType = this.getValue(options.subject_type, Object.keys(displayedSubjectTypes), disabledSubjectTypes);

    // Changing the reset key messes up the default settings manager
    // So the dialog was being recretaed query for file changed
    // NOT sure why we had this?
    // const newResetKey = resetKey ? resetKey + 1 : 1;
    const state = {
      displayedQueryTypes,
      displayedSubjectTypes,
      // resetKey: newResetKey,
      resetKey,
      options: {
        ...options,
        query_type: selectedQueryType,
        subject_type: selectedSubjectType,
      }
    };
    if (initial) {
      this.state = state;
    } else {
      this.setState(state);
    }

  }

  getDisplayedQueryTypes() {
    const { ...displayedQueryTypes } = this.allQueryTypes;
    const options = this.state.options;
    const subjectMolType = options.subject && options.subject.meta().molType;
    if (subjectMolType === 'Protein') {
      delete displayedQueryTypes['dna']
    }
    return displayedQueryTypes;
  }

  getDisplayedSubjectTypes() {
    const { ...displayedSubjectTypes } = this.allSubjectTypes;
    const options = this.state.options;
    const subjectMolType = options.subject && options.subject.meta().molType;

    if (!options.subject || options.subject.fileError || options.subject.validationErrors.length > 0) {
      return {};
    }
    if (subjectMolType === 'Protein') {
      return {protein: displayedSubjectTypes.protein};
    } else {
      delete displayedSubjectTypes.protein;
    }
    if (options.subject) {
      const fileFormat = options.subject.meta().fileFormat;
      console.log(fileFormat)
      if (!['EMBL', 'GenBank'].includes(fileFormat)) {
        delete displayedSubjectTypes.cds;
      }
    }
    return displayedSubjectTypes;
  }

  disabledSubjectTypes() {
    const options = this.state.options;
    const subjectMolType = options.subject && options.subject.meta().molType;
    const selectedQueryType = options.query_type;

    if (subjectMolType === 'Protein') {
      return [];
    }

    let disabledKeys = [];
    if (selectedQueryType === 'dna') {
      disabledKeys = ['translated', 'cds', 'orfs', 'protein'];
    } else if (selectedQueryType === 'translated') {
      disabledKeys = ['translated', 'dna'];
    } else {
      disabledKeys = ['dna'];
    }

    return disabledKeys;
  }

  getValue(current, all=[], disabled=[]) {
    const allowed = all.filter( a => !disabled.includes(a) );
    return allowed.includes(current) ? current : allowed[0];
  }

  comparisionSummary(query, subject) {
    let summary = 'No Subject';
    if (subject) {
      const key = `${query}-${subject}`;
      summary = this.summaryMap[key] || 'Unknown';
    }
    return summary;
  }

  moreHelp() {
    return (
      <div>
        <ul>
          <li><strong>DNA</strong>: The full DNA sequence.</li>
          <li><strong>Translated DNA</strong>: The full DNA sequence is translated (via blastx or tblastn) in all 6 reading frames.</li>
          <li><strong>CDS</strong>: Translated amino acid sequence of CDS features.</li>
          <li><strong>ORFs</strong>: Translated open reading frames (&ge; 100 amino acids).</li>
        </ul>
        <div>Based on the selected sequence types, the appropriate BLAST program (e.g. blastn, blastp, blastx, tblastn) will be automatically chosen. Translated DNA vs translated DNA (tblastx) is unavailable due to extended processing times.</div>
      </div>
    );
  }

  renderContents() {
    const { tool } = this.props;
    const { options, displayedSubjectTypes, displayedQueryTypes, fileName } = this.state;
    // console.log(this.state.options);

    const disabledSubjectTypes = this.disabledSubjectTypes();
    const jobName = fileName || tool.inputs.get('jobName').default;


    const mapGeneticCode = this.cgv.geneticCode || "?"
    const geneticCodeInput = tool.inputs.get('genetic_code');
    let genticCodeValues = geneticCodeInput.values;
    genticCodeValues[0] = `Map Default [${mapGeneticCode}]`;



    console.log("KEY:", this.state.resetKey)
    return (
      <div>
        <div className='dialog-header'>Start a BLAST Job with the map sequence (Query) against an uploaded sequence file or GenBank Accession (Subject). Once the Job is complete the results can be added to the map as a new Track.</div>
        {this.renderInput('subject', {
          onValidate: this.onValidate,
          onChange: (file) => this.onFileChange({attribute: 'subject', value: file}),
        })}
        {this.renderInput('jobName', {defaultValue: jobName, key: jobName})}
        <DataElementContainer label='Comparison'>
          <DataElementGroup
            className='comparison-section'
            help={this.comparisionSummary(options.query_type, options.subject_type)}>
            {this.renderInput('query_type', {
              onChange: (value) => this.onChange({attribute: 'query_type', value}, this.updateTypes),
              value: options.query_type,
              values: displayedQueryTypes,
              align: 'center',
            })}
            <div className='vs-text'>vs</div>
            {this.renderInput('subject_type', {
              value: options.subject_type,
              values: displayedSubjectTypes,
              disabledValues: disabledSubjectTypes,
              align: 'center',
            })}
          </DataElementGroup>
        </DataElementContainer>
        <HelpElement className='comparison-help' text='Choose the sequence types to compare.' moreText={this.moreHelp()} />

        {this.renderInput('e_cutoff')}
        {this.renderInput('filter')}
        {this.renderInput('genetic_code', { values: genticCodeValues })}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

// Notes on the logic for setting the query/subject type

  // Query (from CGV):
  // - possible options:
  //   - dna, translated, cds, orfs
  // - Adjustments:
  //   - No features with Type of CDS:
  //     - remove cds
  //   - Subject is protein
  //     - remove dna
  //
  // Subject (from file/ncbi):
  // - possible options:
  //   - dna, translated, cds, orfs, protein
  // - Adjustments:
  //   - No file selected or file error
  //     - Empty
  //   - File is not Genbank/EMBL
  //     - remove cds
  //   - Moltype is protein
  //     - only option is protein
  //   - Moltype is NOT protein
  //     - remove protein
  //   - Query is DNA
  //     - only option is dna
  //   - Query is not DNA
  //     - remove dna
  //
  // Logic:
  //   - Set Query options
  //     - Set available types
  //       - based on presence of CDS and Subject is protein or not
  //     - No disabling
  //   - Set Subject options
  //     - Set available types
  //       - empty when no file or errors
  //       - based on subject FileType and MolType
  //     - Disabling
  //       - If query is DNA than only DNA highlighted
  //       - If query is Not DNA than only DNA disabled
