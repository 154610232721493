import { RESET_STATE, CAPTIONS_ADD, CAPTIONS_REMOVE, CAPTIONS_UPDATE, CAPTIONS_MOVED } from '../constants/ActionTypes'
import { removeItems } from './ReduxHelpers';
import * as helpers from '../support/Helpers';

const initialState = {
  byID: {},
  ids: [],
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:        return reset(state, payload);
    case CAPTIONS_ADD:       return addCaptions(state, payload);
    case CAPTIONS_REMOVE:    return removeItems(state, payload.captions);
    case CAPTIONS_UPDATE:    return updateCaptions(state, payload);
    case CAPTIONS_MOVED:     return moveCaptions(state, payload);
    default:                 return state;
  }
};

// TODO: CAPTION_REMOVE: see comments in https://hackernoon.com/redux-patterns-add-edit-remove-objects-in-an-array-6ee70cab2456
//  shows way to remove item with destructuring!

const reset = () => { return (initialState) }

const addCaptions = (state, { captions }) => {
  // Normalize caption data
  const captionData = {};
  const captionIDs = [];
  for (var i = 0, len = captions.length; i < len; i++) {
    const caption = captions[i];
    const json = caption.toJSON({includeDefaults: true});
    json.cgvID = caption.cgvID;
    json.on = caption.on;

    captionData[json.cgvID] = json;
    captionIDs.push(json.cgvID);
  }

  return ({
    byID: {...state.byID, ...captionData},
    ids: [...state.ids, ...captionIDs],
  })
}

const updateCaptions = (state, { captions, attributes }) => {
  // Normalize caption data
  const captionData = {};
  for (var i = 0, len = captions.length; i < len; i++) {
    const caption = captions[i];
    const json = caption.toJSON({includeDefaults: true});
    json.cgvID = caption.cgvID;
    // json.index = i;
    json.itemCount = caption.itemCount;
    json.on = caption.on;

    captionData[json.cgvID] = json;
  }

  return ({
    byID: {...state.byID, ...captionData},
    ids: state.ids,
  })
}

const moveCaptions = (state, { oldIndex, newIndex }) => {
  return ({
    byID: state.byID,
    ids: helpers.arrayMoveCopy(state.ids, oldIndex, newIndex),
  })
}



