import React from 'react';
import BasicDialog from '../../proksee-viewer/presenters/BasicDialog';

// EXPECTED PROPS:
// - projectIDs: array of cgvIDs for the projects to delete
// - projectTableRef: reference to the project Pane VirtualTable
//    - access underlying Table ref with: projectTableRef.current.tableRef

class ProjectsDeleteDialog extends BasicDialog {

  // static defaultProps = {
  //   valid: true,
  // }

  renderTitle() {
    const {projectIDs} = this.props;
    return `Delete Projects: ${projectIDs.length} selected`;
  }

  onLocalClose({action, options}) {
    const {projectIDs, projectTableRef} = this.props;
  }

  renderContents() {
    const { projectIDs } = this.props;

    const content = (projectIDs.length > 1) ?
      <p>Are you sure you want to delete the selected {projectIDs.length} projects?</p> :
      <p>Are you sure you want to delete this project?</p>;

    return (
      <div>
        {content}
      </div>
    );
  }
}

export default ProjectsDeleteDialog;
