import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';

class MitosDataCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  renderData(data) {
    return (
      <VirtualGrid
        data={data}
        alternateRowColors
        rowHeight={30}
        defaultColumnWidth={80}
        excludeMissingColumns
      >
        <Column index={0} name='Sequence ID' width={180} />
        <Column index={1} name='Type' width={80} />
        <Column index={2} name='Name' width={70} />
        <Column index={3} name='Method' width={70} />
        <Column index={4} name='Start' width={70} type='number' />
        <Column index={5} name='Stop' width={70} type='number' />
        <Column index={6} name='Strand' width={65} type='number'/>
        <Column index={9} name='Anticodon' width={85} align='center' />
        <Column index={13} name='Structure' width={300}  />
      </VirtualGrid>
    );
  }

}

export default MitosDataCard;

