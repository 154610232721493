import React from 'react';
import SequenceFile from './SequenceFile';
// import ImageButton from '../../presenters/ImageButton';
import * as helpers from '../../support/Helpers';
import * as validations from '../../support/Validations';
import CGParse from '../../../CGParse.esm';
import FileInput from '../../presenters/FileInput';
import './CGParseFile.css';
import defaultParseConfig from '../../../proksee-viewer/tools/cgview_builder/templates/default.js';
import Tools from '../../models/Tools.js';
import { initialSettings, settingsRenderer, defaultSettingsManagerKey, settingsAreNotDefault } from './CGParseFileSettings.js'

// This class handle taking a sequence file (e.g. GenBank, EMBL, FASTA) as the file input
// - parses the file and converts it to CGView JSON
// returns a modifed JSON file containing:
// - input: original file content
// - log:   log from the sequence file parser and the CGView JSON builder
// - json:  CGView JSON
// - settings: settings used to parse the file
class CGParseFile {

  constructor(file) {
    this._file = file;
  }

  get file() {
    return this._file;
  }

  get settings() {
    return this.file?.settings || {};
  }

  //////////////////////////////////////////////////////////////////////////
  // Static properties
  //////////////////////////////////////////////////////////////////////////

  static get initialSettings() {
    // From CGParseFileSettings.js
    return initialSettings();
  }

  //////////////////////////////////////////////////////////////////////////
  // Optional Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get hasModifiedFile() {
    return true
  }

  get modifiedFile() {
    return this._modifiedFile;
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'parse';
  }

  get metaKeys() {
    return [
      'fileSource', 'fileFormat', 'molType', 'length', 'count', 'definition', 'master',
      'version', 'name', 'id', 'featureCount', 'trackCount', 'contigCount',
      'parseStatus', 'log', 'errorCodes', 'builderStatus',
    ];
  }

  get validationKeys() {
    return ['validFileFormats', 'validLength', 'validCount'];
  }

  // TODO: this can become more general when needed for other file types
  saveSettings(settings={}) {
    const storageKey = defaultSettingsManagerKey();
    const parseTool = Tools.get('cgview_builder');
    const inputs = parseTool.inputsForTarget('New');
    const options = {};
    for (const input of inputs) {
      console.log(input)
      if (!input.autoSave) { continue; }
      if (Array.isArray(input.default)) {
        if (!helpers.arraysEqual(settings[input.id], input.default)) {
          options[input.id] = settings[input.id];
        }
      } else if (settings[input.id] !== input.default) {
        options[input.id] = settings[input.id];
      }
    }
    if (Object.keys(options).length > 0) {
      localStorage.setItem(storageKey, JSON.stringify(options));
    } else {
      localStorage.removeItem(storageKey);
    }
  }

  processText(text) {
    // console.log("Settings:", this.settings);
    // console.log("TEXT:", text);
    // Save the Setting in LocalStorage
    // NOTE: we may want to generalize this when we have settings in other file types
    this.saveSettings(this.settings);

    // TODO: this should automatically be set by the file input
    this.fileSource = 'upload';

    // Check if text looks like JSON
    if (text.startsWith('{')) {
      this.fileFormat = 'json';
      return;
    }

    // Process Sequence File
    const seqFile = new CGParse.SequenceFile(text, {nameKeys: this.settings?.nameKeys, maxLogCount: 5});
    // console.log(seqFile);
    this.fileFormat = seqFile.inputType;
    this.molType = seqFile.sequenceType;
    this.length = seqFile.summary?.totalLength;
    this.count = seqFile.summary?.sequenceCount;
    this.definition = seqFile.records[0]?.definition;
    this.parseStatus = seqFile.status;
    this.errorCodes = seqFile.errorCodes;
    this.log = seqFile.logger.history({showIcons: true});
    if (!seqFile.passed) { return; }

    // Setup CGViewBuilder Settings
    // Features Types Settings
    let includeFeatures = true;
    let excludeFeatures = [];
    if (this.settings.includeFeatures === 'none') {
      includeFeatures = false;
    } else if (this.settings.includeFeatures === 'include') {
      includeFeatures = this.settings.featureTypes || [];
    } else if (this.settings.includeFeatures === 'exclude') {
      excludeFeatures = this.settings.featureTypes || [];
    }
    // Qualifier Types Settings
    let includeQualifiers = true;
    let excludeQualifiers = [];
    if (this.settings.includeQualifiers === 'none') {
      includeQualifiers = false;
    } else if (this.settings.includeQualifiers === 'include') {
      includeQualifiers = this.settings.qualifierTypes || [];
    } else if (this.settings.includeQualifiers === 'exclude') {
      excludeQualifiers = this.settings.qualifierTypes || [];
    }

    // Create CGView JSON
    const builder = new CGParse.CGViewBuilder(seqFile, {
      config: defaultParseConfig,
      includeFeatures: includeFeatures,
      excludeFeatures: excludeFeatures,
      includeQualifiers: includeQualifiers,
      excludeQualifiers: excludeQualifiers,
      logger: seqFile.logger
    });
    const cgvJSON = builder.toJSON();
    this.builderStatus = builder.status;
    this.log = builder.logger.history({showIcons: true});
    // console.log(cgvJSON);

    if (cgvJSON) {
      const cgv = cgvJSON.cgview;
      this.version = cgv.version;
      this.id = cgv.id;
      this.name = cgv.name;
      this.featureCount = cgv.features ? cgv.features.length : 0;
      this.trackCount = cgv?.tracks?.length || 0;
      this.contigCount = cgv.sequence?.contigs?.length || 0;
    }

    // Create Modified file content
    // console.log(cgvJSON)
    const log = seqFile.logger.history({showIcons: true});
    const alteredSettings = {...this.settings};
    // Remove settingsHaveChanged
    delete alteredSettings.settingsHaveChanged;
    const modifiedContent = {json: cgvJSON, input: text, log: log, settings: alteredSettings};
    const modifiedContentString = JSON.stringify(modifiedContent);
    // Create a blob from the modified content
    const blob = new Blob([modifiedContentString], { type: 'text/json' });
    this._modifiedFile = new File([blob], "parse.json", {
      type: blob.type,
    });
    // Let add the json to the console if we want to inspect it
    window.cgvJSON = cgvJSON;
  }

  async processAccession(accession) {
    this.fileSource = 'ncbi';
    // this.processingAccession = true;
    const file = this.file;
    file.accession = accession;
    const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=nucleotide&id=${file.accession}&retmode=json`
    console.log(url)
    try {
      let response = await fetch(url);
      let data = await response.json();
      console.log(data)
      const uid = data.result && data.result.uids && data.result.uids[0]
      if (uid) {
        const seq = data.result[uid];
        this.definition = seq.title;
        this.length = seq.slen;
        this.molType = (seq.moltype == 'aa') ? 'Protein' : 'DNA';
        file.fileFormat = 'GenBank';
        this.fileFormat = 'GenBank';
        this.count = 1;
        if (seq.properties.master) {
          this.length = 0;
          this.count = seq.slen;
          this.master = true;
        }
      } else {
        file.fileError = `Accession "${file.accession}" not found`
      }
    }
    catch (error) {
      file.fileError = error.message;
    }
    return file;
  }


  validate(rules={}) {
    const validationErrors = [];
    const { validFileFormats, validLength, validCount } = rules;
    const details = this.file.details();
    const v = validations;

    const fileFormat = details.meta.fileFormat;
    if (fileFormat === 'json') {
      validationErrors.push(<span>This is looks like a JSON file. Try submitting it using the <strong>Map JSON</strong> tab.</span>);
      // Fail Early
      this.file.validationErrors = validationErrors;
      return {ok: (validationErrors.length === 0), errors: validationErrors};
    } else if (!v.validateString(fileFormat, validFileFormats)) {
      validationErrors.push(`File Format "${fileFormat}" is not valid. Must be ${helpers.toSentence([validFileFormats].flat(), {conjunction: 'or'})}`);
    }

    // Sequences must be DNA
    if (!v.validateString(details.meta.molType, 'DNA')) {
      validationErrors.push(`Sequence Type "${details.meta.molType}" is not valid. Must be DNA`);
    }

    // Sequence must have been successfully parsed (Only for uploaded files)
    if (details.meta.fileSource == 'upload' && details.meta.parseStatus === 'failed') {
      validationErrors.push(`Sequence could not be parsed. See log (below) for details.`);
    }

    const length = details.meta.length;
    if (!v.validateNumericRange(length, validLength)) {
      validationErrors.push(`Sequence Length (${helpers.commaNumber(length)}) is not valid. ${v.rangeValidationString(validLength)}`);
    }

    const count = details.meta.count;
    if (!v.validateNumericRange(count, validCount)) {
      validationErrors.push(`Sequence Count (${helpers.commaNumber(count)}) is not valid. ${v.rangeValidationString(validCount)}`);
    }

    this.file.validationErrors = validationErrors;
    return {ok: (validationErrors.length === 0), errors: validationErrors};
  }

  static settingsRenderer(settings = {}, onChange = () => {}, details = {}, fileText = '', source = '') {
  // static settingsRenderer(settings = {}, onChange = () => {}) {
    // From CGParseFileSettings.js
    // return settingsRenderer(settings, onChange);
    return settingsRenderer(settings, onChange, details, fileText, source);
  }

  static settingsAreNotDefault(settings = {}) {
    // From CGParseFileSettings.js
    return settingsAreNotDefault(settings);
  }

  // - CGParse.SequenceFile returns an arry of error codes that we can parse
  //   - To display for the details line instead of the sequence summary
  //   - Error code examples: binary, empty, mixed_types, unknown_type, sequence_zero, length_mismatch, unkwown_characters
  static detailsRenderer(details={}, rules={}) {
    const meta = details.meta || {};
    const lengthText = SequenceFile.formatLength(meta);
    const seqCountText = (meta.count == 1) ? "1 sequence" : `${helpers.commaNumber(meta.count)} sequences`;
    const featureCountText = (meta.featureCount == 1) ? "1 feature" : `${helpers.commaNumber(meta.featureCount)} features`;
    const definition = (meta.definition && meta.definition.length > 0) ?  <div>{meta.definition}</div> : '';
    const errorCodes = meta.errorCodes;

    // The log and button will only be present if the file was uploaded
    const logPresent = (meta.fileSource == 'upload');

    // console.log('DETAILS META', meta);
    const formatMap = {genbank: 'GenBank', fasta: 'FASTA', embl: 'EMBL', json: 'JSON'};
    const fileFormat = formatMap[meta.fileFormat] || helpers.capitalize(meta.fileFormat);
    const molTypeMap = {dna: 'DNA'};
    const molType = molTypeMap[meta.molType] || helpers.capitalize(meta.molType);
    let summary = <div>{fileFormat} ({lengthText}; {molType}; {seqCountText}; {featureCountText})</div>;
    if (meta.featureCount === undefined) {
      // Remove feature count if not available
      summary = <div>{fileFormat} ({lengthText}; {molType}; {seqCountText})</div>;
    }
    if (errorCodes && errorCodes.length > 0) {
      if (errorCodes.includes('binary')) {
        summary = <div>ERROR: The file contains non-text characters. Is it a binary file?</div>;
      } else if (errorCodes.includes('empty')) {
        summary = <div>ERROR: File is empty</div>;
      }
    }
    const statusMessage = FileInput.statusFor(meta.parseStatus, meta.builderStatus);
    console.log(statusMessage, meta.parseStatus, meta.builderStatus);
    const logBtn = FileInput.renderLogButton({statusMessage});
    return (
      <div className='parse-details'>
        <div className='summary-line'>{summary}<div className='parse-buttons'>{ logPresent && logBtn }
        </div></div>
        <div>{definition}</div>
        { logPresent && FileInput.renderLog({text: meta.log, open: (meta.parseStatus === 'failed' || meta.buildStatus === 'failed')}) }
      </div>
    );
  }
}

export default CGParseFile;
