import React, { useRef, useEffect, useState } from "react";
import PropTypes from 'prop-types';
// import './PieChart.css';
import * as d3 from "d3";

// Adapted from:
// https://ncoughlin.com/posts/d3-react-responsive-chart/
// https://observablehq.com/@d3/pie-chart
//   Copyright 2021 Observable, Inc.
//   Released under the ISC license.

PieChart.propTypes = {
  name:   PropTypes.func,
  value:  PropTypes.func,
}
PieChart.defaultProps = {
  name: (d) => d.name,    // given d in data, returns the (ordinal) label
  value: (d) => d.value, // given d in data, returns the (quantitative) value
  // name: ([x]) => x,    // given d in data, returns the (ordinal) label
  // value: ([, y]) => y, // given d in data, returns the (quantitative) value
}


function PieChart(props) {
//   name = ([x]) => x,  // given d in data, returns the (ordinal) label
//   value = ([, y]) => y, // given d in data, returns the (quantitative) value
//   title, // given d in data, returns the title text
//   width = 640, // outer width, in pixels
//   height = 400, // outer height, in pixels
//   innerRadius = 0, // inner radius of pie, in pixels (non-zero for donut)
//   outerRadius = Math.min(width, height) / 2, // outer radius of pie, in pixels
//   labelRadius = (innerRadius * 0.2 + outerRadius * 0.8), // center radius of labels
//   format = ",", // a format specifier for values (in the label)
//   names, // array of names (the domain of the color scale)
//   colors, // array of colors for names
//   stroke = innerRadius > 0 ? "none" : "white", // stroke separating widths
//   strokeWidth = 1, // width of stroke separating wedges
//   strokeLinejoin = "round", // line join of stroke separating wedges
//   padAngle = stroke === "none" ? 1 / outerRadius : 0, // angular separation between wedges
// } = {}) {

  const {
    data = [],
    name,
    value,
    // name = ([x]) => x,  // given d in data, returns the (ordinal) label
    // value = ([, y]) => y, // given d in data, returns the (quantitative) value
    // title, // given d in data, returns the title text
    // width = 640, // outer width, in pixels
    // height = 400, // outer height, in pixels
    innerRadius = 0, // inner radius of pie, in pixels (non-zero for donut)
    // outerRadius = Math.min(width, height) / 2, // outer radius of pie, in pixels
    // labelRadius = (innerRadius * 0.2 + outerRadius * 0.8), // center radius of labels
    format = ",", // a format specifier for values (in the label)
    // names, // array of names (the domain of the color scale)
    // colors, // array of colors for names
    stroke = innerRadius > 0 ? "none" : "white", // stroke separating widths
    strokeWidth = 1, // width of stroke separating wedges
    strokeLinejoin = "round", // line join of stroke separating wedges
    // padAngle = stroke === "none" ? 1 / outerRadius : 0, // angular separation between wedges
  } = props;

    let title; // given d in data, returns the title text
    let names; // array of names (the domain of the color scale)
    let colors; // array of colors for names


  // Element References
  const svgRef = useRef(null);
  const svgContainer = useRef(null); // The PARENT of the SVG

  // State to track width and height of SVG Container
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = svgContainer.current.clientWidth;
    setWidth(newWidth);

    const newHeight = svgContainer.current.clientHeight;
    setHeight(newHeight);

    console.log(`SVG Container: ${newWidth} x ${newHeight}`)
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();
    // listen for resize changes, and detect dimensions again when they change
    window.addEventListener("resize", getSvgContainerSize);
    // cleanup event listener
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, []);

  useEffect(() => {
    // D3 Code

    // Dimensions
    let dimensions = {
      width: width, // width from state
      height: height, // height from state
      margins: 0,
    };

    const outerRadius = Math.min(width, height) / 2; // outer radius of pie, in pixels
    const labelRadius = (innerRadius * 0.4 + outerRadius * 0.6); // center radius of labels
    const padAngle = stroke === "none" ? 1 / outerRadius : 0; // angular separation between wedges

    dimensions.containerWidth = dimensions.width - dimensions.margins * 2;
    dimensions.containerHeight = dimensions.height - dimensions.margins * 2;

    // Selections
    const svg = d3
      .select(svgRef.current)
      .classed("pie-chart-svg", true)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height)
      .attr("viewBox", [-dimensions.width / 2, -dimensions.height / 2, dimensions.width, dimensions.height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    // clear all previous content on refresh
    const everything = svg.selectAll("*");
    everything.remove();

    const container = svg
      .append("g")
      .classed("container", true)
      .attr("transform", `translate(${dimensions.margins}, ${dimensions.margins})`);

    // Compute values.
    const N = d3.map(data, name);
    const V = d3.map(data, value);
    const I = d3.range(N.length).filter(i => !isNaN(V[i]));

    // Unique the names.
    if (names === undefined) names = N;
    names = new d3.InternSet(names);

    // Chose a default color scheme based on cardinality.
    if (colors === undefined) colors = d3.schemeSpectral[names.size];
    if (colors === undefined) colors = d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), names.size);

    // Construct scales.
    const color = d3.scaleOrdinal(names, colors);

    // Compute titles.
    if (title === undefined) {
      const formatValue = d3.format(format);
      title = i => `${N[i]}\n${formatValue(V[i])}`;
    } else {
      const O = d3.map(data, d => d);
      const T = title;
      title = i => T(O[i], i, data);
    }

    // Construct arcs.
    const arcs = d3.pie().padAngle(padAngle).sort(null).value(i => V[i])(I);
    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
    const arcLabel = d3.arc().innerRadius(labelRadius).outerRadius(labelRadius);

    // const svg = d3.create("svg")
    //     .attr("width", width)
    //     .attr("height", height)
    //     .attr("viewBox", [-width / 2, -height / 2, width, height])
    //     .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

    // svg.append("g")
    container.append("g")
        .attr("stroke", stroke)
        .attr("stroke-width", strokeWidth)
        .attr("stroke-linejoin", strokeLinejoin)
      .selectAll("path")
      .data(arcs)
      .join("path")
        .attr("fill", d => color(N[d.data]))
        .attr("d", arc)
      .append("title")
        .text(d => title(d.data));

    // svg.append("g")
    container.append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .attr("text-anchor", "middle")
      .selectAll("text")
      .data(arcs)
      .join("text")
        .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
      .selectAll("tspan")
      .data(d => {
        const lines = `${title(d.data)}`.split(/\n/);
        return (d.endAngle - d.startAngle) > 0.25 ? lines : lines.slice(0, 1);
      })
      .join("tspan")
        .attr("x", 0)
        .attr("y", (_, i) => `${i * 1.1}em`)
        .attr("font-weight", (_, i) => i ? null : "bold")
        .text(d => d);

    // Object.assign(svg.node(), {scales: {color}});
    Object.assign(container.node(), {scales: {color}});
  }, [data, width, height]); // Redraw chart if data or dimensions change

  return (
    <div ref={svgContainer} className="pie-chart" style={{width: '100%', height: '100%'}}>
      <svg ref={svgRef} />
    </div>
  );
}

export default PieChart;

