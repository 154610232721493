import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import iconChevron from '../images/icon-chevron.png';
import './Tabs.css';
import { Tabs as AntTabs } from 'antd';

const TabPane = AntTabs.TabPane;

// NOTE: if children are provided
//   - panes prop will be ignored
//   - Also the methods to add, remove, rename will NOT work
class Tabs extends React.Component {

  static propTypes = {
    panes: PropTypes.array,
    initialKey: PropTypes.string,
    children: PropTypes.node,
  }

  static defaultProps = {
    panes: [],
  }

  constructor(props) {
    super(props);
    this.newTabIndex = 0;

    this.state = {
      panes: props.panes || [],
      activeKey: props.initialKey,
      tabBarClosed: false,
    };
  }

  onChange = (activeKey) => {
    this.setState({ activeKey });
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  }

  // By default new tabs will become active unless 'active' is false
  add = ({title='New Tab', content='New Content', id} = {}, active = true) => {
    const panes = this.state.panes;
    const activeKey = `newTab${this.newTabIndex++}`;
    panes.push({ title, content, key: activeKey, id: (id || activeKey)});
    active ? this.setState({ panes, activeKey }) : this.setState({ panes });
  }

  removeByID = (id) => {
    let activeKey = this.state.activeKey;
    const panes = this.state.panes.filter(pane => pane.id !== id);
    if (!panes.map(p => p.key).includes(activeKey)) {
      const allPanes = this.state.panes;
      const index = allPanes.findIndex(p => p.key === activeKey);
      console.log(index)
      for (let i = index; i >= 0; i--) {
        console.log(index, i, allPanes[i].id, id)
        if (allPanes[i].id !== id) {
          activeKey = allPanes[i].key;
          console.log(activeKey)
          break;
        }
      }
    }
    this.setState({ panes, activeKey });
  }

  remove = (targetKey) => {
    let activeKey = this.state.activeKey;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter(pane => pane.key !== targetKey);
    if (lastIndex >= 0 && activeKey === targetKey) {
      activeKey = panes[lastIndex].key;
    }
    this.setState({ panes, activeKey });
  }

  renameByID = (id, newName) => {
    let activeKey = this.state.activeKey;
    const panes = this.state.panes;
    panes.forEach( p => {
      if (p.id === id) {
        p.title = newName;
      }
    });
    this.setState({ panes });
  }

  // TESTING
  setTabByKey(key) {
    this.setState({ activeKey: key });
  }

  renderPanes() {
    const { children } = this.props;
    // const { panes } = this.props;

    if (children) {
      return children;
    } else {
      return this.state.panes.map(pane => <TabPane tab={pane.title} key={pane.key} forceRender={pane.forceRender} closable={pane.closable}>{pane.content}</TabPane>);
    }
  }

  // Meant to capture click in tab sidebar when tabs position is 'left'
  // This will open/close the tab sidebar
  handletabBarClick(e) {
    const { tabPosition } = this.props;
    if (tabPosition === 'left') {
      const target = e.target;
      // console.log(target.className)
      const klasses = ['tab-bar-chevron-img', 'tab-bar-chevron', 'ant-tabs-nav-wrap', 'ant-tabs-nav ant-tabs-nav-animated'];
      if (klasses.includes(target.className)) {
        this.setState((state) => ({tabBarClosed: !state.tabBarClosed}));
      }
    }
  }

  render() {
    const { tabPosition } = this.props;
    const tabsClass = classNames(
      'Tabs',

      this.props.className,


      {'tab-bar-closed': tabPosition === 'left' && this.state.tabBarClosed}
    );

    const { className, ...remainingProps } = this.props;

    return (
      <div className={tabsClass} onClick={(e) => this.handletabBarClick(e)}>
        <AntTabs
          onChange={this.onChange}
          activeKey={this.state.activeKey}
          type="editable-card"
          onEdit={this.onEdit}
          hideAdd={true}
          tabBarExtraContent={<div className='tab-bar-chevron'><img className='tab-bar-chevron-img' src={iconChevron} width='15' height='9' alt='open/close' /></div>}
          {...remainingProps}
        >
          {this.renderPanes()}
        </AntTabs>
      </div>
    );
  }
}
//{...this.props}

export default Tabs;

