import React from 'react';
import Linker from './Linker';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './VirtualGrid.css';
import { MultiGrid, AutoSizer } from 'react-virtualized';
import * as helpers from '../support/Helpers';

// Similar to VirtualColumn except we do not need to extend Column from React-Virtualized
// TODO:
// - add dataKey as it's used by search
// - set dataKey to be index
class GridColumn extends React.Component {
  static propTypes = {
    // Index refers to the position (base-0) of the value in the original data row array
    index: PropTypes.number.isRequired,
    width: PropTypes.number,
    // Used to replace column header name
    name: PropTypes.string,
    // Passed to Linker
    linkerProps: PropTypes.object,
    // Function to trasform the data in the column. Ignored for header row.
    // Note that columns of type 'number' will have a default transform to add commas to numbers.
    transform: PropTypes.func,
    // Used for formating and search
    type: PropTypes.oneOf(['string', 'number']),
    // Defaults:
    // - string: left
    // - number: right
    align: PropTypes.oneOf(['left', 'center', 'right']),
    // Search defaults to 'type'. This will override the default.
    search: PropTypes.string, // string, number, nosearch
  }
  static defaultProps = {
    type: 'string', // string, number
  }

}

// TODO:
// - add search
//    - create columns for every column and use header name if no column name exists
class VirtualGrid extends React.Component {
  static propTypes = {
    // Array of arrays
    data: PropTypes.array.isRequired,
    defaultColumnWidth: PropTypes.number,
    rowHeight: PropTypes.number,
    headerRowHeight: PropTypes.number,
    // First row (header) of data will be sticky
    // If header is an array, it's values will be used for the header values
    header: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
    children:  PropTypes.node,
    showSearch: PropTypes.bool,
    defaultSearch: PropTypes.string,
    // By default, columns will be shown for all the data (from data array of array),
    // even if no child Column component is provided.
    //  - Columns will be in the same order as the data.
    // If true, then only data for the provided columns will be shown.
    //  - Columns will be in the same order as the child Column components.
    excludeMissingColumns: PropTypes.bool,
    alternateRowColors: PropTypes.bool,
  }

  static defaultProps = {
    defaultColumnWidth: 100,
    rowHeight: 25,
    header: true,
    excludeMissingColumns: false,
  }

  constructor(props) {
    super(props);

    this.columns = {};

    // The key for the column will be depend if all the columns are being shown or not via excludeMissingColumns
    // All columns (excludeMissingColumns false): key will be the column prop index value
    // Select columns (excludeMissingColumns true): key will the index of the array of children components
    React.Children.toArray(props.children).map( (c,i) => {
      const key = props.excludeMissingColumns ? i : c.props.index;
      this.columns[key] = c;
    });


    const data = this.props.data
    if (Array.isArray(props.header)) {
      data.unshift(props.header);
    }

    this.state = {
      data,
    }
    this.cellRenderer = this.cellRenderer.bind(this);
    this.columnWidth = this.columnWidth.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
  }

  defaultTransformFor(column) {
    return column.props.transform || (column.props.type === 'number') && helpers.commaNumber;
  }

  defaultAlignFor(column) {
    // return align ? align : ( (type === 'number') ? 'right' : 'left' );
    return column && (column.props.align || (column.props.type === 'number') && 'right' || 'left') || 'left';
  }


  cellRenderer({
    columnIndex, // Horizontal (column) index of cell
    rowIndex,    // Vertical (row) index of cell
    style,       // Style object to be applied to cell (to position it): position, left, top, height, width
    key,         // Unique key within array of cells
  }) {
    const { data } = this.state;
    const { header, alternateRowColors, excludeMissingColumns } = this.props;
    const column = this.columns[columnIndex];

    const align = this.defaultAlignFor(column);
    const isHeaderRow = header && (rowIndex === 0);
    const klass = classNames({
      VirtualHeaderCell: isHeaderRow,
      VirtualCell: !isHeaderRow,
      'scroll-skinny': true,
      striped: alternateRowColors && (rowIndex !== 0) && !(rowIndex % 2),
      [`align-${align}`]: align !== 'left',
    });

    // Original content for data[row][column]
    // let content = data[rowIndex] && data[rowIndex][columnIndex] || '';
    // If were excluding columns, the data index needs to come from the column props
    const adjustedColumnIndex = excludeMissingColumns ? column.props.index  : columnIndex;
    let content = data[rowIndex] && data[rowIndex][adjustedColumnIndex] || '';

    // Optionally transform contents (skip header row)
    const transform = !isHeaderRow && column && this.defaultTransformFor(column);
    content = transform ? transform(content) : content;

    if (rowIndex === 0 && header) {
      // Handle Header Row
      const columnName = column && column.props.name;
      content = columnName || content;
    } else {
      // Optionally add Linker
      const linkerProps = column && column.props.linkerProps;
      content = linkerProps ? <Linker value={content} {...linkerProps} /> : content;
    }
    return (
      <div key={key} className={klass} style={style} >
        {content}
      </div>
    );
  }

  columnWidth({index}) {
    const { defaultColumnWidth } = this.props;
    return this.columns[index] && this.columns[index].props.width || defaultColumnWidth;
  }

  // Return the header row height (if available) for the header
  rowHeight({index}) {
    const { rowHeight, headerRowHeight } = this.props;
    return (index === 0) ? (headerRowHeight || rowHeight) : rowHeight;
  }

  render() {
    const { data } = this.state;
    const { header, excludeMissingColumns } = this.props;

    const columnCount = excludeMissingColumns ? Object.keys(this.columns).length : data[0].length;
    if (data && Array.isArray(data) && Array.isArray(data[0])) {
      return (
        <div className='VirtualGrid' >
					<AutoSizer>
						{({height, width}) => (
							<MultiGrid
								cellRenderer={this.cellRenderer}
								columnCount={columnCount}
								columnWidth={this.columnWidth}
								fixedColumnCount={0}
								fixedRowCount={header && 1}
								height={height}
								rowHeight={this.rowHeight}
								rowCount={data.length}
								width={width}
                classNameTopRightGrid='virtual-header'
							/>
						)}
					</AutoSizer>
				</div>
                // classNameTopLeftGrid='virtual-header'
                // classNameTopRightGrid='virtual-header'
      );
    } else {
      return (
        <div className={klass}>No Data to Show</div>
      );
    }
  }

}

export { GridColumn as Column, VirtualGrid };

