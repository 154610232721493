import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import * as helpers from '../../../support/Helpers';
import { message } from 'antd';

// TODO: 
// - test with/without enclosed features: change opacity and decorate by score

class DialogAdd extends DefaultDialog {

  renderContents() {
    const { tool, job, mapData } = this.props;
    const { options } = this.state;

    // Get numbers
    const totalFeatureCount = mapData?.features?.length || 0;
    const nonEnclosedFeatureCount = mapData?.features?.filter( f => !f.meta?.enclosed ).length || 0;
    const enclosedFeatures = totalFeatureCount - nonEnclosedFeatureCount;

    // Change the default label for skip_enclosed
    const skipEnclosedInput = tool.inputs.get('skip_enclosed');
    const skipEnclosedName = `${skipEnclosedInput.name} (${helpers.commaNumber(enclosedFeatures)})`;

    let enclosedMessage;
    if (enclosedFeatures > 0) {
      const addingMessage = options?.skip_enclosed ? <div>Adding visible features: <strong>{helpers.commaNumber(nonEnclosedFeatureCount)}</strong></div> :
        <div>Adding all features (including enclosed): <strong>{helpers.commaNumber(totalFeatureCount)}</strong></div>;
      enclosedMessage = (
        <div>
          <div className='muted'>
            Some of the features ({helpers.commaNumber(enclosedFeatures)}) are
            enclosed by higher scoring features and will not be visible on the
            map. By default, only visible features will be added.
          </div>
          <div style={{width: '100%', textAlign: 'center', fontSize: '16px', paddingTop: '10px'}}>{addingMessage}</div>
        </div>
      );
    }

    // Header
    const summary =  (mapData && this.component.mapDataSummary && this.renderMapDataSummary())
    const description = this.renderDescription();
    const header = (
      <div className='dialog-header'>
        {summary}
        {enclosedMessage}
        {description}
      </div>
    );


    return (
      <div>
        {header}

        {this.renderInput('track', {defaultNewItemName: job.name})}
        {this.renderInput('legend', {defaultNewItemName: job.name})}
        {this.renderInput('skip_enclosed', {label: skipEnclosedName})}
        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;
