import { SETTINGS_UPDATE, VIEWER_UPDATE, LOAD_JSON } from '../constants/ActionTypes'

const initialState = {
  format: 'circular',
  name: '',
  id: '-',
  geneticCode: '-',
  dataHasChanged: false,
  createdAt: '-',
  updatedAt: '-',
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case SETTINGS_UPDATE: return updateSettings(state, payload);
    case VIEWER_UPDATE:   return updateViewer(state, payload);
    case LOAD_JSON:       return loadJSON(state, payload);
    default:              return state;
  }
};

const updateSettings = (state,  { attributes }) => {
  // const newState = {};
  // const keys = Object.keys(attributes);
  // if (keys.includes('format')) {
  //   newState.format = attributes.format;
  // }
  // if (keys.includes('name')) {
  //   newState.name = attributes.name;
  // }
  // return {...state, ...newState}
  // FIXME?: Maybe if this remains simple it should simple be:
  return {...state, ...attributes}
}

const updateViewer = (state,  { attributes }) => {
  // console.log(attributes)
  return {...state, ...attributes}
}

const loadJSON = (state,  {json}) => {
  // console.log(json.geneticCode)
  return {
    ...state,
    id: json.id,
    geneticCode: json.geneticCode,
    createdAt: json.createdAt,
    updatedAt: json.updatedAt,
  }
}

