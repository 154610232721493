import { CONTIGS_ADD, CONTIGS_REMOVE, CONTIGS_UPDATE, CONTIGS_MOVED } from '../constants/ActionTypes'

export const addContigs = (contigs) => ({
  type: CONTIGS_ADD,
  payload: {
    contigs,
  }
});

export const removeContigs = (contigs) => ({
  type: CONTIGS_REMOVE,
  payload: {
    contigs,
  }
});

export const updateContigs = (contigs, attributes) => ({
  type: CONTIGS_UPDATE,
  payload: {
    contigs,
    attributes,
  }
});

export const moveContigs = (oldIndex, newIndex) => ({
  type: CONTIGS_MOVED,
  payload: {
    oldIndex,
    newIndex,
  }
});

