import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';
import searchCancel from '../images/search-cancel.png';
import classNames from 'classnames';
import './Select.css';

const AntOption = AntSelect.Option;

Select.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func,
}

Select.defaultProps = {
  disabled: false,
  size: 'small',
}

// Note that the default size 'small' is used directly by antd and has a height of 24px
// This happens to be our default grid size so it works out. But we may need to take more control of the dimension later.
function Select(props) {
  const {
    children,
    // disabled=false,
  } = props;

  const defaults = {
    style: {width: '100%'},
    size: 'small',
    dropdownMatchSelectWidth: false,
  }

  const size = (props.size === 'xsmall') ? 'small' : props.size;

  const klass = classNames('Select', {'select-xsmall': (props.size === 'xsmall')}, props.className);

  return (
    <AntSelect
      {...defaults}
      clearIcon={
        <div className='cancel-search'>
          <img src={searchCancel} alt='cancel' />
        </div>
      }
      {...props}
      size={size}
      className={klass}
    >
      { children }
    </AntSelect>
  )
}

const Option = (props) => {
  const {
    children,
    // disabled=false,
  } = props;

  return (
    <AntOption {...props}>
      { children }
    </AntOption>
  )
}

// This is added to prevent unhelpful warning in the console
// https://github.com/react-component/select/blob/master/src/Option.tsx
Option.isSelectOption = true;


export { Select, Option };
