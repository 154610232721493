import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import './Tooltip.css';

Tooltip.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
}

// TextElement.defaultProps = {
//   disabled: false,
// }

function Tooltip(props) {
  const {
    className,
    id,
  } = props;

  const elementClass = classNames(
    'Tooltip', className
  );

    {/* <ReactTooltip effect='solid' multiline={true} backgroundColor='yellow' {...props} place='top' backgroundColor='yellow' id={id} className={elementClass} /> */}

  // Setup up timeout to rebuild tooltips
  // Kinda annoying but it will work throughout the site
  // This should stop too many calls to rebuild
  clearTimeout(window.prokseeTooltipTimerID);
  window.prokseeTooltipTimerID = setTimeout(() => ReactTooltip.rebuild(), 100);

  return (
    <ReactTooltip
      effect='solid'
      html={true}
      multiline={true}
      {...props}
      id={id}
      className={elementClass}
      overridePosition={({ left, top }, _e, _t, node) => {
        return {
          // Top offset is based on NavBar height
          top: typeof node === 'string' ? top : Math.max(top, 55),
          // Left is basedon on window viewport
          left: typeof node === 'string' ? left : Math.max(left, 5),
        };
      }}
      arrowColor='var(--theme-border-color)' />
  )
  ReactTooltip.rebuild();
}

export default Tooltip;

