import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../../app/CGViewContext';
import './DividerSettings.css';
import { ListItem } from '../../presenters/ListItem';
import DataElementGroup from '../../presenters/DataElementGroup';
import DataElement from '../../presenters/DataElement';
import Color from '../../presenters/Color';
import Slider from '../../presenters/Slider';
import Switch from '../../presenters/Switch';
import VisibilityButton from '../../presenters/VisibilityButton';
import * as helpers from '../../support/Helpers';
// Connected
import { connect } from 'react-redux';

class DividerSettings extends React.Component {

  static propTypes = {
    dividers: PropTypes.shape({
      mirrored: PropTypes.bool,
      slot:   PropTypes.shape({
        color:             PropTypes.string,
        thickness:         PropTypes.numeric,
        spacing:           PropTypes.numberic,
      }),
      track:   PropTypes.shape({
        color:             PropTypes.string,
        thickness:         PropTypes.numeric,
        spacing:           PropTypes.numberic,
      }),
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {infoOpen: false}

    this.onChange = this.onChange.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    const { dividers } = this.props;
    const visible = (dividers.slot.visible || dividers.track.visible);
    const titleClass = classNames('list-item-title', {fade: !visible});
    return (
      <div className='list-item-content'>
        <div className={titleClass}>
          Dividers
        </div>
        <div className='list-item-options'>
          <VisibilityButton visible={visible} onClick={ (value) => this.onChange({dividerName: 'global', value, attribute: 'visible'}) } />
        </div>
      </div>
    );
  }

  renderDivider(divider, key) {
    const { dividers } = this.props;
    const mirrored = dividers.mirrored;
    const dividerName = divider.name;
    const title = mirrored ? 'Track and Slot Dividers (Mirrored)' : `${helpers.capitalize(dividerName)} Dividers`;
    return (
      <div key={key} >
        <div className='divider-title'>
          {title}
          <div className='list-item-options'>
            <VisibilityButton visible={divider.visible} size='small' onClick={ (value) => this.onChange({dividerName, value, attribute: 'visible'}) } />
          </div>
        </div>
        <DataElementGroup>
          <DataElement label='Color' helpPath='help:sidebar:display:settings:dividers:color'>
            <Color
              colorString={divider.color}
              onChange={(value) => this.onChange({dividerName, value, attribute: 'color'})}
            / >
          </DataElement>
          <DataElement label='Mirror' helpPath='help:sidebar:display:settings:dividers:mirror'>
             <Switch
               value={mirrored}
               onChange={(value) => this.onChange({dividerName, value, attribute: 'mirror'})}
             />
          </DataElement>
        </DataElementGroup>
        <DataElement label='Thickness' helpPath='help:sidebar:display:settings:dividers:thickness'>
          <Slider min={0.5} max={10} step={0.5} value={divider.thickness}
            onChange={(value) => this.onChange({dividerName, value, attribute: 'thickness'})} />
        </DataElement>
        <DataElement label='Spacing' helpPath='help:sidebar:display:settings:dividers:spacing'>
          <Slider min={1} max={10} step={1} value={divider.spacing}
            onChange={(value) => this.onChange({dividerName, value, attribute: 'spacing'})} />
        </DataElement>
      </div>
    );


  }

  infoRenderer() {
    const { dividers } = this.props;
    if (dividers.mirrored) {
      return (
        <div className='Dividers'>
          { this.renderDivider(dividers.track, 'mirrored') }
        </div>
      );
    } else {
      return (
        <div className='Dividers'>
          { this.renderDivider(dividers.track, 'track') }
          { this.renderDivider(dividers.slot, 'slot') }
        </div>
      );
    }
  }

  onChange({dividerName, attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    const name = (dividerName === 'mirrored') ? 'track' : dividerName;
    if (dividerName === 'global') {
      // Handle clicking main visibility button 
      cgv.dividers.slot.update({[attribute]: value});
      cgv.dividers.track.update({[attribute]: value});
    } else {
      cgv.dividers[name].update({[attribute]: value});
    }
    redraw && cgv.draw();
  }

  render() {
    return (
      <div className='DividerSettings'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

DividerSettings.contextType = CGViewContext;

// Connected
const dividerSettingsMapStateToProps = (state) => ({ dividers: state.dividers });
const ConnectedDividerSettings = connect(dividerSettingsMapStateToProps)(DividerSettings);

// export default DividerSettings;
export { DividerSettings, ConnectedDividerSettings };

