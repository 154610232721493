import React from 'react';
import BasicDialog from '../../presenters/BasicDialog';
import DataElement from '../../presenters/DataElement';
import DataElementGroup from '../../presenters/DataElementGroup';
import { Select, Option } from '../../presenters/Select';
import SelectWithNew from '../../presenters/SelectWithNew';
import { ConnectedSelectLegend } from '../../containers/SelectLegend';

// EXPECTED PROPS:
// - plotIDs: array of cgvIDs for the plots to delete

class PlotsEditDialog extends BasicDialog {

  static defaultProps = {
    options: {
      favorite: 'NO_CHANGE',
      visible: 'NO_CHANGE',
      legendItem: 'NO_CHANGE',
      source: 'NO_CHANGE',
    },
    valid: true,
  }

  renderTitle() {
    const {plotIDs} = this.props;
    return `Edit Plots: ${plotIDs.length} selected`;
  }

  getLegend(legendData) {
    // const legendData = options.legendItem;
    if (legendData && legendData.cgvID !== 'NO_CHANGE') {
      let legend;
      if (legendData.cgvID === 'NEW') {
        legend = cgv.legend.addItems({
          name: legendData.name,
          swatchColor: legendData.swatchColor
        })[0];
      } else {
        legend = cgv.objects(legendData.cgvID);
      }
      return legend;
    }
  }

  onLocalClose({action, options}) {
    // console.log(action, options)
    const {plotIDs} = this.props;
    const cgv = this.cgv;

    const plots = cgv.objects(plotIDs);
    const updates = {}
    if (action === 'ok') {
      // LEGEND
      const legendData = options.legendItem;
      updates.legendPositive = this.getLegend(options.legendItemPositive);
      updates.legendNegative = this.getLegend(options.legendItemNegative);
      // SOURCE
      if (options.source && options.source.cgvID !== 'NO_CHANGE') {
        updates.source = (options.source.cgvID === 'NEW') ? options.source.name : options.source.cgvID;
      }
      // FAVORITE
      if (options.favorite && options.favorite !== 'NO_CHANGE') {
        updates.favorite = (options.favorite === 'yes');
      }
      // VISIBLE
      if (options.visible && options.visible !== 'NO_CHANGE') {
        updates.visible = (options.visible === 'yes');
      }
      // Update
      if (Object.keys(updates).length > 0) {
        cgv.updatePlots(plots, updates);
      }
    }
    cgv.draw();
  }

  renderContents() {
    const {plotIDs} = this.props;
    const { options } = this.state;
    const cgv = this.cgv;

    const plots = cgv.objects(plotIDs);
    // Current attributes for these plots
    const currentLegendPositives = plots.map( p => p.legendPositive.name).unique();
    const currentLegendNegative = plots.map( p => p.legendNegative.name).unique();
    // const currentSources = plots.map( p => p.source).unique();
    const currentFavoritesCount = plots.filter( p => p.favorite).length;
    const currentVisibleCount = plots.filter( p => p.visible).length;
    // const baselines = plots.map( p => p.baseline);
    // const currentBaselineRange = `${Math.min(...baselines)}..${Math.max(...baselines)}`;
    // const minScores = plots.map( p => p.scoreMin);
    // const maxScores = plots.map( p => p.scoreMax);

    const sources = {}
    cgv.sources().forEach( s => sources[s] = {cgvID: s, name: s});
    const plotSourcesObject = {
      ids: Object.keys(sources),
      byID: sources,
    }
    const yesNoObject = {
      NO_CHANGE: 'No Change',
      yes: 'Yes',
      no: 'No',
    }
    const yesNoOptions = Object.keys(yesNoObject).map( k => <Option key={k} value={k}>{yesNoObject[k]}</Option>);

    return (
      <div>
        <DataElementGroup label='' help='Choose whether all these plots should be visible and or favorites.'>
          <DataElement label='Favorite' help={`Currently Favorited: ${currentFavoritesCount}`}>
            <Select value={options.favorite || 'NO_CHANGE'}
              onChange={(value) => this.onChange({attribute: 'favorite', value})}
            >
              { yesNoOptions }
            </Select>
          </DataElement>
          <DataElement label='Visible' help={`Currently Visible: ${currentVisibleCount}`}>
            <Select value={options.visible || 'NO_CHANGE'}
              onChange={(value) => this.onChange({attribute: 'visible', value})}
            >
              { yesNoOptions }
            </Select>
          </DataElement>
        </DataElementGroup>
        <DataElement label='' help={`Current Legends: ${currentLegendPositives.join(', ')}`}>
          <ConnectedSelectLegend
            title='Legend (Above Baseline)'
            help='Choose or create a legend for these plots.'
            additionalItems={{NO_CHANGE: 'No Change'}}
            defaultItemID='NO_CHANGE'
            defaultNewItemName=''
            onChange={(value) => this.onChange({attribute: 'legendItemPositive', value})}
          />
        </DataElement>
        <DataElement label='' help={`Current Legends: ${currentLegendNegative.join(', ')}`}>
          <ConnectedSelectLegend
            title='Legend (Below Baseline)'
            help='Choose or create a legend for these plots.'
            additionalItems={{NO_CHANGE: 'No Change'}}
            defaultItemID='NO_CHANGE'
            defaultNewItemName=''
            onChange={(value) => this.onChange({attribute: 'legendItemNegative', value})}
          />
        </DataElement>
      </div>
    )
    //    <DataElement label='' help={`Current Sources: ${currentSources.join(', ')}`}>
    //      <SelectWithNew value={options.source}
    //        help='Choose or create a source for these plots.'
    //        title='Source'
    //        items={plotSourcesObject}
    //        newItemLabel='Source'
    //        defaultNewItemName=''
    //        additionalItems={{NO_CHANGE: 'No Change'}}
    //        defaultItemID='NO_CHANGE'
    //        onChange={(value) => this.onChange({attribute: 'source', value})}
    //      />
    //    </DataElement>
    //   <DataElementGroup label='' help=''>
    //     <DataElement label='Baseline' help={`Curent Baseline Range: ${currentBaselineRange}`}>
    //       <NumericInput value={options.baseline}
    //         help='Change the baseline for these plots. The value must be between the minimum and maximum axis values.'
    //         min={Math.max(...minScores)}
    //         step={0.1}
    //         decimals={1}
    //         precision={1}
    //         max={Math.min(...maxScores)}
    //         onChange={(value) => this.onChange({cgvID, value, attribute: 'baseline'})}/>
    //     </DataElement>
    //   </DataElementGroup>
  }
}

export default PlotsEditDialog;
