import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../../app/CGViewContext';
// import './AnnotationSettings.css';
import { ListItem } from '../../presenters/ListItem';
import DataElement from '../../presenters/DataElement';
import Color from '../../presenters/Color';
import Font from '../../presenters/Font';
import Switch from '../../presenters/Switch';
import VisibilityButton from '../../presenters/VisibilityButton';
// import * as helpers from '../../support/Helpers';
// Connected
import { connect } from 'react-redux';

class AnnotationSettings extends React.Component {

  static propTypes = {
    annotation: PropTypes.shape({
      color:             PropTypes.string,
      font:              PropTypes.string,
      onlyDrawFavorites: PropTypes.bool,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {infoOpen: false}

    this.onChange = this.onChange.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    const { annotation } = this.props;
    const titleClass = classNames('list-item-title', {fade: !annotation.visible});
    return (
      <div className='list-item-content'>
        <div className={titleClass}>
          Labels
        </div>
        <div className='list-item-options'>
          <VisibilityButton visible={annotation.visible} onClick={ (value) => this.onChange({value, attribute: 'visible'}) } />
        </div>
      </div>
    );
  }

  infoRenderer() {
    const { annotation } = this.props;
    return (
      <div>
        <DataElement label='Color' helpPath='help:sidebar:display:settings:labels:color'>
          <Color
            colorString={annotation.color}
            allowDefault={true}
            undefinedString='Feature Color'
            isDefault={annotation.color === undefined}
            onDefaultClick={() => this.onChange({value: undefined, attribute: 'color'})}
            onChange={(value) => this.onChange({value, attribute: 'color'})}
          / >
        </DataElement>
        <DataElement label='Font' helpPath='help:sidebar:display:settings:labels:font'>
          <Font
            fontString={annotation.font}
            onChange={(value) => this.onChange({value, attribute: 'font'})}
          />
        </DataElement>
        <DataElement label='Only Favorites' helpPath='help:sidebar:display:settings:labels:only_favorites'>
          <Switch
            value={annotation.onlyDrawFavorites}
            onChange={(value) => this.onChange({value, attribute: 'onlyDrawFavorites'})}
          />
        </DataElement>
      </div>
    );
  }

  onChange({attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    cgv.annotation.update({[attribute]: value});
    redraw && cgv.draw();
  }

  render() {
    return (
      <div className='AnnotationSettings'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

AnnotationSettings.contextType = CGViewContext;

//Connected
const annotationSettingsMapStateToProps = (state) => ({ annotation: state.annotation });
const ConnectedAnnotationSettings = connect(annotationSettingsMapStateToProps)(AnnotationSettings);

// export default AnnotationSettings;
export { AnnotationSettings, ConnectedAnnotationSettings };

