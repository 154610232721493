import React from 'react';
import BasicDialog from '../../presenters/BasicDialog';
import DataElement from '../../presenters/DataElement';
import { Select, Option } from '../../presenters/Select';

// EXPECTED PROPS:
// - plotIDs: array of cgvIDs for the plots to delete
// - plotTableRef: reference to the Plot Pane VirtualTable
//    - access underlying Table ref with: plotTableRef.current.tableRef

class PlotsDeleteDialog extends BasicDialog {

  static defaultProps = {
    options: {deleteLegends: 'yes'},
    valid: true,
  }

  renderTitle() {
    const {plotIDs} = this.props;
    return `Delete Plots: ${plotIDs.length} selected`;
  }

  onLocalClose({action, options}) {
    const {plotIDs, plotTableRef} = this.props;
    const cgv = this.cgv;
    if (action === 'ok') {
      const plots = cgv.objects(plotIDs);
      const tracks = plots.reduce( (prev, plot) => [...prev, ...plot.tracks()], []);
      cgv.removePlots(plots)
      cgv.removeTracks(tracks);
      plotTableRef.current.tableRef.recomputeRowHeights();
      if (options.deleteLegends === 'yes') {
        const legendItems = cgv.legend.uniqueLegendsItemsFor({plots: plots});
        cgv.legend.removeItems(legendItems)
      }
      setTimeout( () => plotTableRef.current.refreshSelectedIDs());
    }
    cgv.draw();
  }

  renderContents() {
    const {plotIDs} = this.props;
    const { options } = this.state;
    const cgv = this.cgv;

    const plots = cgv.objects(plotIDs);
    const yesNoObject = {yes: 'Yes', no: 'No' };
    const yesNoOptions = Object.keys(yesNoObject).map( k => <Option key={k} value={k}>{yesNoObject[k]}</Option>);

    const legendsToDelete = cgv.legend.uniqueLegendsItemsFor({plots: plots});
    const legendNames = legendsToDelete.map( i => i.name );

    const deleteLegends = options.deleteLegends || 'yes';

    let content;
    if (legendNames.length > 0) {
      const details = (deleteLegends === 'yes') ? 
        <p>Legend items that <strong>will</strong> be deleted: {legendNames.join(', ')}</p> :
        <p>Legend items <strong>will not</strong> be deleted.</p>;

      content = (
        <div>
          <DataElement label='Delete legend items as well?' help='Only legend items exclusive to the selected plots are considered.'>
            <Select value={deleteLegends}
              onChange={(value) => this.onChange({attribute: 'deleteLegends', value})}
            >
              { yesNoOptions }
            </Select>
          </DataElement>
          {details}
        </div>
      )
    } else {
      content = 'No legend items will be deleted.';
    }

    return (
      <div>
        <p>Are you sure you want to delete the selected plots and their tracks?</p>
        {content}
      </div>
    );
  }
}

export default PlotsDeleteDialog;
