import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as helpers from '../support/Helpers';
// import './FeaturesByTypeTable.css';

// Only features or counts should be provided NOT both (features will take precedence)
FeaturesByTypeTable.propTypes = {
  className: PropTypes.string,
  // Features to summerize. Must have a 'type' property
  features:      PropTypes.array,
  // Counts object with type names as keys and counts as values
  counts:        PropTypes.object,
  // Types to show. If not provided, all types will be shown
  onlyTypes:     PropTypes.array,
  // Title for the table
  title:     PropTypes.string,
}

FeaturesByTypeTable.defaultProps = {
  features: [],
  counts: {},
  onlyTypes: [],
  title: 'Features by Type',
}

function FeaturesByTypeTable(props) {
  const { className, features, counts, onlyTypes, title } = props;
  if (!features.length && !Object.keys(counts).length) return null;
  console.log(features)
  console.log(counts)
  console.log(onlyTypes)

  const featureTableClass = classNames(
    'FeaturesByTypeTable', className,
    'p-table', 'more-width'
  );

  let typeCounts = counts;

  // Use features to get counts if features are provided
  if (features.length) {
    typeCounts = features.reduce((acc, feature) => {
      acc[feature.type] = (acc[feature.type] || 0) + 1;
      return acc;
    }, {});
  }

  const types = onlyTypes.length ? onlyTypes : Object.keys(typeCounts);

  const divs = types.map( (type) => {
    return (
      <div key={type} className='feature-count-pair'>
        <div className='feature-count-name'>{type}:</div>
        <div className='feature-count-number'>{helpers.commaNumber(typeCounts[type])}</div>
      </div>
    );
  });

  // If an odd number of types, add a blank div to center the last one
  // if (types.length % 2) {
  //   divs.push(<div key='blank' className='feature-count-pair feature-count-pair-blank'></div>);
  // }

  return (
    <table className={featureTableClass}>
      <thead>
        <tr className='table-title'><th>{title}</th></tr>
      </thead>
      <tbody>
        <tr><td><div className='feature-counts'>{divs}</div></td></tr>
      </tbody>
    </table>
  );

}

export default FeaturesByTypeTable;