// import { LOAD_JSON, SETTINGS_UPDATE } from '../constants/ActionTypes'
import { SETTINGS_UPDATE } from '../constants/ActionTypes'

const initialState = {
  backgroundColor: 'rgba(255,255,255,1)',
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case SETTINGS_UPDATE: return updateSettings(state, payload);
    // case LOAD_JSON:       return loadJSON(state, payload);
    default:              return state;
  }
};

const updateSettings = (state,  { attributes }) => {
  return {...state, ...attributes}
}


