import React from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import './RegionPane.css';
import "../support/react-tabs.sidebar.css";
import { ConnectedFeaturePane } from './FeaturePane';
import { ConnectedPlotPane } from './PlotPane';
import { ConnectedContigPane }  from './ContigPane';
import { ConnectedBookmarkPane }  from './BookmarkPane';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


class RegionPane extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: props.tabIndex || 1,
    }
  }

  setTabIndex(tabIndex) {
    if (tabIndex !== undefined) {
      this.setState({tabIndex});
    }
  }

  render() {
    const {registerPane} = this.props;
    return (
      <div className='RegionPane'>
        <Tabs className='sub-tabs' selectedIndex={this.state.tabIndex} onSelect={ tabIndex => this.setState({ tabIndex }) } forceRenderTabPanel >
          <TabList>
            <Tab>Contigs</Tab>
            <Tab>Features</Tab>
            <Tab>Plots</Tab>
            <Tab>Bookmarks</Tab>
          </TabList>
          <TabPanel><ConnectedContigPane ref={ref => registerPane('contigs', ref, 0, 'regions')} /></TabPanel>
          <TabPanel><ConnectedFeaturePane ref={ref => registerPane('features', ref, 1, 'regions')} /></TabPanel>
          <TabPanel><ConnectedPlotPane ref={ref => registerPane('plots', ref, 2, 'regions')} /></TabPanel>
          <TabPanel><ConnectedBookmarkPane ref={ref => registerPane('bookmarks', ref, 3, 'regions')} /></TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default RegionPane;


