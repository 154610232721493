import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../../app/CGViewContext';
// import './BackboneSettings.css';
import { ListItem } from '../../presenters/ListItem';
import DataElementGroup from '../../presenters/DataElementGroup';
import DataElement from '../../presenters/DataElement';
import Color from '../../presenters/Color';
import Slider from '../../presenters/Slider';
import VisibilityButton from '../../presenters/VisibilityButton';
import ButtonGroup from '../../presenters/ButtonGroup';
import ImageButton from '../../presenters/ImageButton';
import iconDecorationArrow from '../../images/icon-decoration-arrow.png';
import iconDecorationArc from '../../images/icon-decoration-arc.png';
// import * as helpers from '../../support/Helpers';
// Connected
import { connect } from 'react-redux';

class BackboneSettings extends React.Component {

  static propTypes = {
    backbone: PropTypes.shape({
      color:             PropTypes.string,
      colorAlternate:    PropTypes.string,
      thickness:         PropTypes.numeric,
      decoration:        PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {infoOpen: false}

    this.onChange = this.onChange.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    const { backbone } = this.props;
    const titleClass = classNames('list-item-title', {fade: !backbone.visible});
    return (
      <div className='list-item-content'>
        <div className={titleClass}>
          Backbone
        </div>
        <div className='list-item-options'>
          <VisibilityButton visible={backbone.visible} onClick={ (value) => this.onChange({value, attribute: 'visible'}) } />
        </div>
      </div>
    );
  }

  infoRenderer() {
    const { backbone } = this.props;
    return (
      <div>
        <DataElementGroup>
          <DataElement label='Color' helpPath='help:sidebar:display:settings:backbone:color'>
            <Color
              colorString={backbone.color}
              onChange={(value) => this.onChange({value, attribute: 'color'})}
            / >
          </DataElement>
          <DataElement label={<span>Alternate Color <span className='smaller-label'>(Contigs)</span></span>} helpPath='help:sidebar:display:settings:backbone:alternate_color'>
            <Color
              colorString={backbone.colorAlternate}
              onChange={(value) => this.onChange({value, attribute: 'colorAlternate'})}
            / >
          </DataElement>
        </DataElementGroup>
        <DataElement label='Thickness' helpPath='help:sidebar:display:settings:backbone:thickness'>
          <Slider min={1} max={50} step={1} value={backbone.thickness}
            onChange={(value) => this.onChange({value, attribute: 'thickness'})} />
        </DataElement>
        <DataElement label= 'Decoration' helpPath='help:sidebar:display:settings:backbone:decoration'>
          <ButtonGroup separated={true}>
            <ImageButton
              title='Arrow'
              image={iconDecorationArrow}
              active={backbone.decoration === 'arrow'}
              onClick={() => this.onChange({value: 'arrow', attribute: 'decoration'})}
            / >
            <ImageButton
              title='Arc'
              image={iconDecorationArc}
              active={backbone.decoration === 'arc'}
              onClick={() => this.onChange({value: 'arc', attribute: 'decoration'})}
            / >
          </ButtonGroup>
        </DataElement>
      </div>
    );
  }

  onChange({attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    cgv.backbone.update({[attribute]: value});
    redraw && cgv.draw();
  }

  render() {
    return (
      <div className='BackboneSettings'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

BackboneSettings.contextType = CGViewContext;

//Connected
const backboneSettingsMapStateToProps = (state) => ({ backbone: state.backbone });
const ConnectedBackboneSettings = connect(backboneSettingsMapStateToProps)(BackboneSettings);

// export default BackboneSettings;
export { BackboneSettings, ConnectedBackboneSettings };

