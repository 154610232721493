import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';

class DialogStart extends DefaultDialog {

  renderContents() {
    const { tool } = this.props;
    return (
      <div>
        {this.renderHeader()}
        <div className='ps-alert'>For performance reasons, Bakta in Proksee uses the <strong>light</strong> DB (not full).</div>
        <br />
        {this.renderInputsForTarget('DialogStart')}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

