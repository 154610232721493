import React from 'react';
import './DisplayPane.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../support/react-tabs.sidebar.css";
import { ConnectedTrackPane } from './TrackPane';
import { ConnectedCaptionPane } from './CaptionPane';
import { ConnectedLegendPane } from './LegendPane';
import { SettingsPane } from './SettingsPane';

class DisplayPane extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: props.tabIndex || 0,
      // tabIndex: props.tabIndex || 3, // Tab for current development
    }
  }

  setTabIndex(tabIndex) {
    if (tabIndex !== undefined) {
      this.setState({tabIndex});
    }
  }

  render() {
    const {registerPane} = this.props;
    return (
      <div className='DisplayPane'>
        <Tabs className='sub-tabs' selectedIndex={this.state.tabIndex} onSelect={ tabIndex => this.setState({ tabIndex }) } forceRenderTabPanel >
          <TabList>
            <Tab>Tracks</Tab>
            <Tab>Legend</Tab>
            <Tab>Captions</Tab>
            <Tab>Settings</Tab>
          </TabList>
          <TabPanel><ConnectedTrackPane ref={ref => registerPane('tracks', ref, 0, 'display')} /></TabPanel>
          <TabPanel><ConnectedLegendPane ref={ref => registerPane('legend', ref, 1, 'display')} /></TabPanel>
          <TabPanel><ConnectedCaptionPane ref={ref => registerPane('captions', ref, 2, 'display')} /></TabPanel>
          <TabPanel><SettingsPane ref={ref => registerPane('settings', ref, 3, 'display')} /></TabPanel>
        </Tabs>
      </div>
    );
  }
          // <TabPanel><SettingsPane /></TabPanel>
}

export default DisplayPane;


