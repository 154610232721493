export default function add({inputs, mapData}) {

  // Filter mapData based on DialogAdd inputs
  if (!inputs.add_phage) {
    mapData.features = mapData.features.filter( f => f.meta && f.meta.major_category !== 'phage');
  }
  if (!inputs.add_transfer) {
    mapData.features = mapData.features.filter( f => f.meta && f.meta.major_category !== 'transfer');
  }
  if (!inputs.add_ie) {
    mapData.features = mapData.features.filter( f => f.meta && f.meta.major_category !== 'integration/excision');
  }
  if (!inputs.add_rrr) {
    mapData.features = mapData.features.filter( f => f.meta && f.meta.major_category !== 'replication/recombination/repair');
  }
  if (!inputs.add_std) {
    mapData.features = mapData.features.filter( f => f.meta && f.meta.major_category !== 'stability/transfer/defense');
  }

  return { ok: true, mapData };
}

