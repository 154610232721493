import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../app/CGViewContext';
import TextInput from '../presenters/TextInput';
// Connected
import { connect } from 'react-redux';

ProjectName.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
}

ProjectName.defaultProps = {
  name: '',
}

function ProjectName(props) {
  const context = useContext(CGViewContext);
  const cgv = context.cgv;

  function updateName(name) {
    cgv && cgv.update({ name });
  }

  const {
    className,
    name,
  } = props;

  const elementClass = classNames(
    'ProjectName', className
  );

  return (
    <TextInput value={name} className={elementClass}
      style={{zIndex: 10}}
      placeholder='Enter Project Name...'
      onChange={(value) => updateName(value)} />
  )
}


//Connected
const projectNameMapStateToProps = (state) => ({ name: state.viewer.name });
const ConnectedProjectName = connect(projectNameMapStateToProps)(ProjectName);

// export default ProjectName;
export { ProjectName, ConnectedProjectName };

