import { RESET_STATE, LEGEND_ITEMS_ADD, LEGEND_ITEMS_REMOVE, LEGEND_ITEMS_UPDATE, LEGEND_ITEMS_MOVED } from '../constants/ActionTypes'
import { removeItems } from './ReduxHelpers';
import * as helpers from '../support/Helpers';

const initialState = {
  byID: {},
  ids: [],
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:          return reset(state, payload);
    case LEGEND_ITEMS_ADD:     return addLegendItems(state, payload);
    case LEGEND_ITEMS_REMOVE:  return removeItems(state, payload.legendItems);
    case LEGEND_ITEMS_UPDATE:  return updateLegendItems(state, payload);
    case LEGEND_ITEMS_MOVED:   return moveLegendItems(state, payload);
    default:                   return state;
  }
};

const reset = () => { return (initialState) }

const addLegendItems = (state, { legendItems }) => {
  // Normalize legendItem data
  const legendItemData = {};
  const legendItemIDs = [];
  for (var i = 0, len = legendItems.length; i < len; i++) {
    const legendItem = legendItems[i];
    const json = legendItem.toJSON({includeDefaults: true});
    json.cgvID = legendItem.cgvID;

    legendItemData[json.cgvID] = json;
    legendItemIDs.push(json.cgvID);
  }

  return ({
    byID: {...state.byID, ...legendItemData},
    ids: [...state.ids, ...legendItemIDs],
  })

}

const updateLegendItems = (state, { legendItems, attributes }) => {
  const itemData = {};
  for (var i = 0, len = legendItems.length; i < len; i++) {
    const legendItem = legendItems[i];
    const json = legendItem.toJSON({includeDefaults: true});
    json.cgvID = legendItem.cgvID;
    json.index = i;

    itemData[json.cgvID] = json;
  }

  return ({
    byID: {...state.byID, ...itemData},
    ids: state.ids,
  })
}


const moveLegendItems = (state, { oldIndex, newIndex }) => {
  return ({
    byID: state.byID,
    ids: helpers.arrayMoveCopy(state.ids, oldIndex, newIndex),
  })
}

