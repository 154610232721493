import { BOOKMARKS_ADD, BOOKMARKS_REMOVE, BOOKMARKS_UPDATE } from '../constants/ActionTypes'

export const addBookmarks = (bookmarks) => ({
  type: BOOKMARKS_ADD,
  payload: {
    bookmarks: bookmarks,
  }
});

export const removeBookmarks = (bookmarks) => ({
  type: BOOKMARKS_REMOVE,
  payload: {
    bookmarks,
  }
});

export const updateBookmarks = (bookmarks, attributes) => ({
  type: BOOKMARKS_UPDATE,
  payload: {
    bookmarks,
    attributes,
  }
});
