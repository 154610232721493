import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import ImageButton from '../presenters/ImageButton';
import { CGViewContext } from '../app/CGViewContext';
import './ControlBar.css';
import * as d3 from 'd3';
import * as helpers from '../support/Helpers';
import cbResetCircular from '../images/cb-reset-circular.png';
import cbResetLinear from '../images/cb-reset-linear.png';
import cbMoveCounterClockwise from '../images/cb-move-counter-clockwise.png';
import cbMoveClockwise from '../images/cb-move-clockwise.png';
import cbMoveLeft from '../images/cb-move-left.png';
import cbMoveRight from '../images/cb-move-right.png';
import cbZoomIn from '../images/cb-zoom-in.png';
import cbZoomOut from '../images/cb-zoom-out.png';
// Connected
import { connect } from 'react-redux';

class ControlBar extends Component {

  static propTypes = {
    format:   PropTypes.oneOf(['linear', 'circular']),
    location: PropTypes.shape({
      zoomFactor: PropTypes.number,
      bp:         PropTypes.number,
    }).isRequired,
  }

  static defaultProps = {
    format: 'circular',
  }

  constructor(props) {
    super(props);
    this.duration = 200;
    this.buttonActive; // Holds the currently clicked button
    this.buttonRepeat; // true when the button is held down
    window.addEventListener('mouseup', () => {
      this.buttonActive = undefined;
    });
  }

  onClickReset() {
    const cgv = this.context.cgv;
    cgv.reset(this.duration);
    // cgv.draw();
  }

  // OLD WAY
  // zoom(direction) {
  //   this.clearTimeout();
  //   const cgv = this.context.cgv;
  //   const zoomFactor = (direction === 'in') ? (cgv._zoomFactor * 2) : (cgv._zoomFactor / 2);
  //   const bp = helpers.constrain(cgv.canvas.bpForCanvasCenter(), 1, cgv.sequence.length);
  //   cgv.zoomTo(bp, zoomFactor, {duration: this.duration*0.85, ease: d3.easeLinear});
  //   if ( (direction === 'in' && zoomFactor < cgv.maxZoomFactor) || (direction === 'out' && zoomFactor > cgv.minZoomFactor) ) {
  //     this._timeoutID = setTimeout( () => {
  //       this.zoom(direction);
  //     }, this.duration);
  //   }
  //   window.addEventListener('mouseup', () => { this.clearTimeout(); });
  // }

  // OLD WAY
  // move(direction) {
  //   this.clearTimeout();
  //   const cgv = this.context.cgv;
  //   const currentBp = cgv.canvas.bpForCanvasCenter();
  //   const length = cgv.sequence.length;
  //   let bpChange = length * 0.2 / cgv._zoomFactor;
  //   if (direction !== 'right') {
  //     bpChange *= -1;
  //   }
  //   let newBp = currentBp + bpChange;
  //   if (cgv.format === 'linear') {
  //     newBp = (helpers.constrain((currentBp + bpChange), 1, cgv.sequence.length));
  //   }
  //   cgv.moveTo(newBp, null, {duration: this.duration, ease: d3.easeLinear});
  //   this._timeoutID = setTimeout( () => {
  //     this.move(direction);
  //   }, this.duration);
  //   window.addEventListener('mouseup', () => { this.clearTimeout(); });
  // }


  zoom(direction, options={}) {
    const cgv = this.context.cgv;
    // Stop quicker if the button has been held down (repeated)
    let moveFactor = options?.fastStop ? 1.1 : 2;
    if (cgv.zoomFactor < 1) {
      // Reduce zoomIn when zoomed all the way out
      // This makes it so, if at zoomfactor 0.5, pressing once will zoom to 1x
      moveFactor = Math.sqrt(2);
    }
    const moveDuration = options?.fastStop ? 50 : 250;
    const ease = options?.ease || d3.easeCubicOut;
    cgv[`zoom${direction}`](moveFactor, {
      duration: moveDuration,
      ease: ease,
      callback: () => {
        if (options?.noCallback) {
          cgv.drawFull();
          return;
        }
        // Check if button is still down, repeat
        if (this.buttonActive === direction) {
          this.buttonRepeat = true;
          this.zoom(direction, {ease: d3.easeLinear});
        } else {
          // Key no longer down, ease to a stop
          if (this.buttonRepeat) {
            // Ending repeat press
            this.zoom(direction, {ease: d3.easeLinear, noCallback: true, fastStop: true});
          } else {
            // Was single press
            this.zoom(direction, {ease: d3.easeCubicOut, noCallback: true});
          }
          this.buttonRepeat = false;
        }
      }
    });
  }

  // direction: Left, Right
  // options:
  // - ease
  // - fastStop: fast stop after repeating a key down
  // - noCallback: stop callbacks (key no longer pressed)
  move(direction, options={}) {
    const cgv = this.context.cgv;
    // Stop quicker if the button has been held down (repeated)
    if (cgv.zoomFactor < 2) return;
    const moveFactor = options?.fastStop ? 0.05 : 0.25;
    const moveDuration = options?.fastStop ? 50 : 250;
    const ease = options?.ease || d3.easeCubicOut;
    cgv[`move${direction}`](moveFactor, {
      duration: moveDuration,
      ease: ease,
      callback: () => {
        if (options?.noCallback) {
          cgv.drawFull();
          return;
        }
        // Check if button is still down, repeat
        if (this.buttonActive === direction) {
          this.buttonRepeat = true;
          this.move(direction, {ease: d3.easeLinear});
        } else {
          // Key no longer down, ease to a stop
          if (this.buttonRepeat) {
            // Ending repeat press
            this.move(direction, {ease: d3.easeLinear, noCallback: true, fastStop: true});
          } else {
            // Was single press
            this.move(direction, {ease: d3.easeCubicOut, noCallback: true});
          }
          this.buttonRepeat = false;
        }
      }
    });
  }

  // clearTimeout() {
  //   clearTimeout(this._timeoutID);
  //   this._timeoutID = undefined;
  // }

  render() {
    // Props
    const { format, location } = this.props;
    // const format = this.props.format;
    // const location = this.props.location;
    // Button Image Paths
    const resetImageName = (format === 'circular') ? 'resetCircular' : 'resetLinear';
    const moveLeftImageName = (format === 'circular') ? 'moveCounterClockwise' : 'moveLeft';
    const moveRightImageName = (format === 'circular') ? 'moveClockwise' : 'moveRight';
    // Button Titles
    const moveLeftText = (format === 'circular') ? 'Move Counter-Clockwise [←]' : 'Move Left [←]';
    const moveRightText = (format === 'circular') ? 'Move Clockwise [→]' : 'Move Right [→]';
    // Button Status
    const cgv = this.context.cgv;
    const zoomOutDisabled = (cgv && (location.zoomFactor <= cgv.minZoomFactor));
    const zoomInDisabled = (cgv && (location.zoomFactor >= cgv.maxZoomFactor));
    const moveRightDisabled = (format === 'circular') ? (location.zoomFactor < 2) : (location.bp >= cgv.sequence.length);
    const moveLeftDisabled = (format === 'circular') ? (location.zoomFactor < 2) : (location.bp <= 1);

    return (
          <div className='ControlBar'>
            <ImageButton
              onClick={ () => this.onClickReset() }
              imageName={resetImageName}
              title='Reset Map Position [ . ]'
              disabled={false}
            / >
            <ImageButton
              onMouseDown={ () => {
                this.buttonActive = 'In';
                this.zoom('In', {ease: d3.easeCubicIn});
              }}
              imageName='zoomIn'
              title='Zoom In [↓]'
              disabled={zoomInDisabled}
            / >
            <ImageButton
              onMouseDown={ () => {
                this.buttonActive = 'Out';
                this.zoom('Out', {ease: d3.easeCubicIn});
              }}
              imageName='zoomOut'
              title='Zoom Out [↑]'
              disabled={zoomOutDisabled}
            / >
            <ImageButton
              onMouseDown={ () => {
                this.buttonActive = 'Right';
                this.move('Right', {ease: d3.easeCubicIn});
              }}
              imageName={moveRightImageName}
              title={moveRightText}
              disabled={moveRightDisabled}
            / >
            <ImageButton
              onMouseDown={ () => {
                this.buttonActive = 'Left';
                this.move('Left', {ease: d3.easeCubicIn});
              }}
              imageName={moveLeftImageName}
              title={moveLeftText}
              disabled={moveLeftDisabled}
            / >
          </div>
    );
  }

}

ControlBar.contextType = CGViewContext;

// Connected
const controlBarMapStateToProps = (state) => ({
  format: state.viewer.format,
  location: state.location,
});
const ConnectedControlBar = connect(controlBarMapStateToProps)(ControlBar);

// export default ControlBar;
export { ControlBar, ConnectedControlBar };

