import React from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../../app/CGViewContext';
// import './GeneralSettings.css';
import { ListItem } from '../../presenters/ListItem';
import TextElement from '../../presenters/TextElement';
import DataElement from '../../presenters/DataElement';
import DataElementGroup from '../../presenters/DataElementGroup';
import Color from '../../presenters/Color';
import Switch from '../../presenters/Switch';
import Slider from '../../presenters/Slider';
// import * as helpers from '../../support/Helpers';
// Connected
import { connect } from 'react-redux';

class GeneralSettings extends React.Component {

  static propTypes = {
    // settings: PropTypes.object,
    settings: PropTypes.shape({
      backgroundColor:  PropTypes.string,
      showShading:      PropTypes.bool,
      arrowHeadLength:  PropTypes.numeric,
      // minArcLength:     PropTypes.numeric,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {infoOpen: false}

    this.onChange = this.onChange.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    return (
      <div className='list-item-content'>
        <div className='settings-title'>
          General
        </div>
      </div>
    );
  }

  infoRenderer() {
    const { settings } = this.props;
    return (
      <div>
        <DataElementGroup>
          <DataElement label='Background Color' helpPath='help:sidebar:display:settings:general:background_color'>
            <Color
              colorString={settings.backgroundColor}
              onChange={(value) => this.onChange({value, attribute: 'backgroundColor'})}
            / >
          </DataElement>
          <DataElement label='Show Feature Shading' helpPath='help:sidebar:display:settings:general:show_shading'>
            <Switch
              value={settings.showShading}
              onChange={(value) => this.onChange({value, attribute: 'showShading'})}
            />
          </DataElement>
        </DataElementGroup>
        <DataElement label='Arrow Head Length' helpPath='help:sidebar:display:settings:general:arrow_head_length'>
          <Slider min={0} max={1} step={0.1} value={settings.arrowHeadLength}
            onChange={(value) => this.onChange({value, attribute: 'arrowHeadLength'})} />
        </DataElement>
        <DataElement label='Minimum Arc Length'>
          <TextElement style={{color: "var(--theme-text-color-muted)"}}>Minimum Arc Length has been moved to the Legend Pane (Default Minimum Arc Length) and to individual legend items (Minimum Arc Length).</TextElement>
        </DataElement>
        <DataElement label='Initial Map Thickness' helpPath='help:sidebar:display:settings:general:initial_map_thickness'>
          <Slider min={0.02} max={0.4} step={0.02} value={settings.initialMapThicknessProportion}
            onChange={(value) => this.onChange({value, attribute: 'initialMapThicknessProportion'})} />
        </DataElement>
        <DataElement label='Maximum Map Thickness' helpPath='help:sidebar:display:settings:general:max_map_thickness'>
          <Slider min={0.1} max={0.5} step={0.05} value={settings.maxMapThicknessProportion}
            onChange={(value) => this.onChange({value, attribute: 'maxMapThicknessProportion'})} />
        </DataElement>
      </div>
    );
  }


  // onClickVisibilityToggle(index) {
  //   const legendItem = helpers.itemForIndex(this.props.legendItems, index);
  //   const visible = !legendItem.visible;
  //   this.onLegendItemChange({cgvID: legendItem.cgvID, value: visible, attribute: 'visible'})
  //   this.listRef.recomputeRowHeights(index);
  // }


  onChange({attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    cgv.settings.update({[attribute]: value});
    redraw && cgv.draw();
  }

  render() {
    return (
      <div className='GeneralSettings'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

GeneralSettings.contextType = CGViewContext;

//Connected
const generalSettingsMapStateToProps = (state) => ({ settings: state.settings });
const ConnectedGeneralSettings = connect(generalSettingsMapStateToProps)(GeneralSettings);

// export default GeneralSettings;
export { GeneralSettings, ConnectedGeneralSettings };

