import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputNumber } from 'antd';
import './NumericInput.css';

class NumericInput extends React.Component {

  static propTypes = {
    value:    PropTypes.number,
    precision: PropTypes.number,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    // disabled: PropTypes.func,
  }

  static defaultProps = {
    // disabled: false,
    precision: 0,
    prefix: '',
    suffix: '',
  }

  constructor(props) {
    super(props);
    // this.state = {start: 0, end: 0};
    this.ref = React.createRef();
  }

  render() {
    const { value, onChange, prefix, suffix, className } = this.props;

    // const formatter = (value) => `${prefix}${value}${suffix}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // The Regex below is my version for not adding commas to decimal. However,
    // it requires a backwards lookup which is not supported by most browers.
    // const formatter = (value) => `${prefix}${value}${suffix}`.replace(/(?<!\..*)\B(?=(\d{3})+(?!\d))/g, ',');
    // So try this:
    const formatter = (value) => {
      const [integer, ...rest] = `${value}`.split('.');
      const fraction = rest.join('').replace(/\D/, '');
      const deciamlPresent = rest.length > 0;
      const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      let newValue = formattedInteger;
      if (deciamlPresent) {
        newValue += `.${fraction}`;
      }
      // console.log(formattedInteger, fraction, newValue)
      return `${prefix}${newValue}${suffix}`;
    }
    // const parser = (value) => value.replace(/[^\d.]/g, '');
    // Doing this to remove extra periods
    const parser = (value) => value.replace(/[^\d.]/g, '').replace(/\./, 'X').replace(/\./g, '').replace('X', '.');

    const klass = classNames(
      'NumericInput', className,
    );
    return (
      <InputNumber
        {...this.props}
        className={klass}
        ref={this.ref}
        onChange={onChange}
        maxLength={25}
        formatter={formatter}
        parser={parser}
      />
    );
        // value={value}
  }
}

export default NumericInput;

