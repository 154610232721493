import React from 'react'
import PropTypes from 'prop-types'
import JobStatus from './JobStatus';
import ServerAPI from '../proksee-viewer/models/ServerAPI';

class ProjectStatus extends React.Component {

  static propTypes = {
    jobStatus: PropTypes.object, // contains: status, step, position, finalized, running
    url: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      jobStatus: props.jobStatus,
    }
    this.updateStatus = this.updateStatus.bind(this);
    this.updateStatus();
  }

  updateStatus() {
    const url = this.props.url; 
    const Server = new ServerAPI();
    Server.get(url)
    .then( response => {
      if (response.ok) {
        setTimeout(this.updateStatus, 1000)
        this.setState({jobStatus: response.json});
        if (['complete', 'failed'].includes(response.json.status)) {
          window.location.reload(true);
        }
      }
    })
  }

  render() {
    const { jobStatus } = this.state;
    console.log(jobStatus)

    return (
      <div className='CGViewBuilderStatus'>
        <div className='ps-card'>
          <div className='ps-card-header'>Proksee Status</div>
          <div className='ps-card-body'>
            <h5 className='status-text'><JobStatus jobStatus={jobStatus} large={true} /></h5>
            <p>
              You will be redirected to your results after the map is generated.
              You can also bookmark this page and check back later.
            </p>
          </div>
        </div>
      </div>
    )
  }

}
export default ProjectStatus;

