import React from 'react'
import PropTypes from 'prop-types'
import Switch from '../proksee-viewer/presenters/Switch';
// import * as helpers from '../proksee-viewer/support/Helpers';

class CookieSwitch extends React.Component {

  static propTypes = {
    allowCookies: PropTypes.bool,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    const storage = window.localStorage;
    const allowCookiesRaw = (props.allowCookies !== undefined) ? props.allowCookies : storage.getItem('allow_cookies');
    const allowCookies = allowCookiesRaw ? allowCookiesRaw !== 'false' : false;
    storage.setItem('allow_cookies', allowCookies);
    this.storage = storage;

    this.state = { allowCookies };

    this.removeOldCookies();
    this.prokseeCookieChanged(allowCookies);
  }

  static get allowCookies() {
    const storage = window.localStorage;
    const allowCookies = storage.getItem('allow_cookies') === 'true';
    return allowCookies;
  }

  // Remove local storage for old keys
  removeOldCookies() {
    // [2022-12-05]
    // Replaced by last_news_id_checked
    this.storage.removeItem('proksee_news_date');

    // [2022-12-08]
    // Remove old Google Cookies
    // - This could be removed in the new year
    document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  // Remove functional cookies
  removeFunctionalCookies() {
    this.storage.removeItem('session_project_ids');
    this.storage.removeItem('theme');
    this.storage.removeItem('last_news_id_checked');
    // this.storage.removeItem('cgv-colorpicker-favorites');
  }

  onChange(allowCookies) {

    const sessionProjectIds = this.storage.getItem('session_project_ids');
    if (!allowCookies) {
      // Confirm removing cookies if they have session project ids 
      if (sessionProjectIds) {
        const msg = 'You have session_project_ids saved on your browser. If you turn off functional cookies, your list of session projects will be removed from this browser. Are you sure?';
        if (window.confirm(msg)) {
          this.removeFunctionalCookies();
        } else {
          // Keep functional cookies and do nothing
          this.setState({ rerenderSwitch: Math.random() });
          return;
        }
      } else {
        this.removeFunctionalCookies();
      }
    } 
    this.storage.setItem('allow_cookies', allowCookies);
    this.setState({ allowCookies });
    this.prokseeCookieChanged(allowCookies);
  }

  // This is a custom method to mute/unmute the cookies text on the page
  // This could be extracted as a callback that CookieSwitch could perform
  prokseeCookieChanged(allowCookies) {
    const cookieEls = document.querySelectorAll(".cookie-name")
    for (const el of cookieEls) {
      allowCookies ? el.classList.remove('muted') : el.classList.add('muted');
    }
  }

  render() {
    // rerenderSwitch is used to stop the switch from turning off, if the user clicked "cancel" on the confirm alert.
    const { allowCookies, rerenderSwitch } = this.state;

    return (
      <div className='CookieSwitch'>
        <Switch
          value={allowCookies}
          key={rerenderSwitch}
          onString='Functional Cookies are ON'
          offString='Functional Cookies are OFF'
          onChange={(value) => this.onChange(value)}
        />
      </div>
    )
  }

}
export default CookieSwitch

