import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';

class FeaturesDataCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  renderData(data) {
    return (
      <VirtualGrid
        data={data}
        header
        alternateRowColors
        rowHeight={60}
        defaultColumnWidth={80}
        excludeMissingColumns
      >
        <Column name='Name' index={10} width={150} />
        <Column name='Start' index={1} width={50} />
        <Column name='Stop' index={2} width={50} />
        <Column name='%_Identity' index={4} width={70} type='number' />
        <Column name='%_Match_Length' index={7} width={70} type='number' />
        <Column name='Description' index={12} width={300} />
      </VirtualGrid>
    );
  }

}

export default FeaturesDataCard;

