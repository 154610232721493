import { DIVIDER_UPDATE } from '../constants/ActionTypes'

const initialState = {track: {name: 'track'}, slot: {name: 'slot'}, mirrored: false};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case DIVIDER_UPDATE: return updateDividers(state, payload);
    default:             return state;
  }
};

const updateDividers = (state,  { divider, attributes }) => {
  const updates = {};

  updates.mirrored = (divider.name === 'mirrored');

  if (divider.name === 'slot') {
    updates.slot = {...state.slot, ...attributes};
  } else if (divider.name === 'track') {
    updates.track = {...state.track, ...attributes};
  } else {
    updates.slot = {...state.slot, ...divider.toJSON()};
    updates.track = {...state.track, ...divider.toJSON()};
  }

  return {...state, ...updates}
}


