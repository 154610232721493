import { PROJECT_UPDATE } from '../constants/ActionTypes'

export const updateProject = (project) => ({
  type: PROJECT_UPDATE,
  payload: {
    project,
  }
});

export const updateLog = (id, log, offset) => ({
  type: LOG_UPDATE,
  payload: {
    id,
    log,
    offset,
  }
});

export const updateFileTree = (id, fileTree) => ({
  type: FILETREE_UPDATE,
  payload: {
    id,
    fileTree,
  }
});


