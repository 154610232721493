import React from 'react';
import DefaultDialog from './DefaultDialog';

class DefaultDialogStart extends DefaultDialog {

  renderContents() {
    const { tool } = this.props;
    return (
      <div>
        {this.renderHeader()}
        {this.renderInputsForTarget('DialogStart')}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }

}

        // {tool.renderDescription()}
export default DefaultDialogStart;
