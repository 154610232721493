import Tool from './Tool';
// definitions is the JSON version of the YAML for each tool
// import { definitions } from '../constants/ToolDefinitions';
import { definitions, categoryMap } from '../constants/ToolDefinitions';

// This Class contains all the available tools
class Tools {

  constructor() {
    this.createTools(definitions);
  }

  // Return the tool for the 'id'
  static get(id) {
    const definition = definitions.find( d => d.id === id );
    return new Tool(definition);
  }

  createTools(definitions) {
    const tools = {};
    for (const definition of definitions) {
      tools[definition.id] = new Tool(definition);
    }
    this._tools = tools;
  }

  get(id) {
    return this._tools[id]
  }

  // The order their returned will be the same as they appear in the ToolPanel
  // Sort by category display name (found in categoryMap)
  get categories() {
    // OLD Way
    // return [...new Set(this.list.map( t => t.category ))];
 
    const categories = [...new Set(this.list.map( t => t.category ))];
    return categories.sort( (a, b) => {
      if (categoryMap[a] > categoryMap[b]) {
        return 1
      }
      if (categoryMap[a] < categoryMap[b]) {
        return -1
      }
      return 0;
    });
  }

  // Returns an array of tools. Tools with hidden:true will not be returned.
  get list() {
    return Object.values(this._tools).filter( t => !t.hidden );
  }

  // Returns tools for the provided category
  // Sort in alphabetical order
  forCategory(category) {
    // OLD Way
    // return this.list.filter( t => t.category === category);

    return this.list.filter( t => t.category === category).sort( (a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    }); 
  }

}

export default Tools;
