import React from 'react';
import BasicDialog from '../../presenters/BasicDialog';
import DataElement from '../../presenters/DataElement';
import { ConnectedSelectLegend } from '../../containers/SelectLegend';
import { Select, Option } from '../../presenters/Select';

// EXPECTED PROPS:
// - legendItemID: cgvID for the legend item to delete

class LegendDeleteDialog extends BasicDialog {

  static defaultProps = {
    options: {deleteData: 'yes', changeToLegend: undefined},
    valid: true,
  }

  renderTitle() {
    const {legendItemID} = this.props;
    return `Delete Legend Item: ${this.cgv.objects(legendItemID).name}`;
  }

  onLocalClose({action, options}) {
    const {legendItemID} = this.props;
    const cgv = this.cgv;
    if (action === 'ok') {
      console.log(options)
      const legendItem = cgv.objects(legendItemID);
      const features = legendItem.features();
      const plots = legendItem.plots();
      // Delete or update features
      if (features.length > 0) {
        if (options.deleteData === 'yes') {
          cgv.removeFeatures(features);
        } else {
          const newLegend = cgv.objects(options.changeToLegend.cgvID);
          cgv.updateFeatures(features, {legendItem: newLegend})
        }
      }
      if (plots.length > 0) {
        if (options.deleteData === 'yes') {
          cgv.removePlots(plots);
        } else {
          // FIXME: DOES NOT UPDATE PLOTS YET!!!
          // cgv.updateFeatures(features, {legendItem: })
          // for (var i=0,len=plots.length; i < len; i++) {
          //   var plot = plots[i];
          //   if (plot.legendItemPositive == legendItem) {
          //     plot.legendItemPositive = newLegendItem;
          //   }
          //   if (plot.legendItemNegative == legendItem) {
          //     plot.legendItemNegative = newLegendItem;
          //   }
          // }
        }
      }
      cgv.legend.removeItems(legendItem);
      cgv.draw();
    }
  }

  renderContents() {
    const {legendItemID} = this.props;
    const { options } = this.state;
    const cgv = this.cgv;

    const legendItem = cgv.objects(legendItemID);
    const yesNoObject = {yes: 'Yes', no: 'No' };
    const yesNoOptions = Object.keys(yesNoObject).map( k => <Option key={k} value={k}>{yesNoObject[k]}</Option>);

    const features = legendItem.features();
    const plots = legendItem.plots();


    let deleteContent = <p>No feature or plots will be deleted.</p>;
    if (features.length > 0 || plots.length > 0) {
      let dataSummary;
      if (options.deleteData === 'yes') {
        dataSummary = (
          <div>
            <p>Features that <strong>will</strong> be deleted: {features.length}</p>
            <p>Plots that <strong>will</strong> be deleted: {plots.length}</p>
          </div>
        );
      } else {
        dataSummary = <p>Features/Plots <strong>will not</strong> be deleted.</p>;
      }

      deleteContent = (
        <div>
          <DataElement label='Delete features/plots as well?'>
            <Select value={options.deleteData}
              onChange={(value) => this.onChange({attribute: 'deleteData', value})}
            >
              { yesNoOptions }
            </Select>
          </DataElement>
          {dataSummary}
        </div>
      );
    }

    let changeLegendContent;
    if (options.deleteData === 'no') {
      changeLegendContent = (
        <DataElement label='Choose a different legend item for these features/plots:'>
          <ConnectedSelectLegend
            help=''
            title=''
            removeIDs={[legendItem.cgvID]}
            allowNewItem={false}
            onChange={(value) => this.onChange({attribute: 'changeToLegend', value})}
          />
        </DataElement>
      );
    }


    return (
      <div>
        <p>Are you sure you want to delete this legend item?</p>
        {deleteContent}
        {changeLegendContent}
      </div>
    );
  }
}

export default LegendDeleteDialog;
