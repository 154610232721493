import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../../app/CGViewContext';
// import './CenterLineSettings.css';
import { ListItem } from '../../presenters/ListItem';
import DataElement from '../../presenters/DataElement';
import TextEditable from '../../presenters/TextEditable';
import Slider from '../../presenters/Slider';
import Color from '../../presenters/Color';
import VisibilityButton from '../../presenters/VisibilityButton';
// import * as helpers from '../../support/Helpers';
// Connected
import { connect } from 'react-redux';
import TextElement from '../../presenters/TextElement';

// Initial default settings are set in MapTab.js
class CenterLineSettings extends React.Component {

  static propTypes = {
    centerLine: PropTypes.shape({
      color:             PropTypes.string,
      thickness:         PropTypes.number,
      dashes:            PropTypes.array,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {infoOpen: false}

    this.onChange = this.onChange.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    const { centerLine } = this.props;
    const titleClass = classNames('list-item-title', {fade: !centerLine.visible});
    return (
      <div className='list-item-content'>
        <div className={titleClass}>
          Center Line
        </div>
        <div className='list-item-options'>
          <VisibilityButton visible={centerLine.visible} onClick={ (value) => this.onChange({value, attribute: 'visible'}) } />
        </div>
      </div>
    );
  }

  infoRenderer() {
    const { centerLine } = this.props;
    const dashes = centerLine.dashes.map( v => parseInt(v) ).filter( v => !isNaN(v) );
    return (
      <div>
        <DataElement label='Color' helpPath='help:sidebar:display:settings:centerLine:color'>
          <Color
            colorString={centerLine.color}
            onChange={(value) => this.onChange({value, attribute: 'color'})}
          / >
        </DataElement>
        <DataElement label='Thickness' helpPath='help:sidebar:display:settings:centerLine:thickness'>
          <Slider min={1} max={10} step={1} value={centerLine.thickness}
            onChange={(value) => this.onChange({value, attribute: 'thickness'})} />
        </DataElement>
        <DataElement label='Dashes' helpPath='help:sidebar:display:settings:centerLine:dashes'>
          <TextEditable value={dashes.join(',')} onChange={ (value) => this.onChange({value, attribute: 'dashes'})}/>
        </DataElement>
        <div><sup>*</sup>Note that Center Line settings are not saved with the map.</div>
      </div>
    );
  }

  // NOTE: the setting are saved in Local Storage via the centerLine.js reducer
  onChange({attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    if (attribute === 'dashes') {
      value = value.split(',').map( v => parseInt(v) ).filter( v => !isNaN(v) );
    }
    console.log(value)
    cgv.centerLine.update({[attribute]: value});
    // redraw && cgv.draw();
    redraw && cgv.layout.drawForeground();
  }

  render() {
    return (
      <div className='CenterLineSettings'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

CenterLineSettings.contextType = CGViewContext;

//Connected
const centerLineSettingsMapStateToProps = (state) => ({ centerLine: state.centerLine });
const ConnectedCenterLineSettings = connect(centerLineSettingsMapStateToProps)(CenterLineSettings);

// export default CenterLineSettings;
export { CenterLineSettings, ConnectedCenterLineSettings };

