import React from 'react';
import DefaultDialog from './DefaultDialog';

class DefaultDialogAdd extends DefaultDialog {

  renderContents() {
    const { tool } = this.props;
    return (
      <div>
        {this.renderHeader()}
        {this.renderInputsForTarget('DialogAdd')}
        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DefaultDialogAdd;

