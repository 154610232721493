import React from 'react';
import PropTypes from 'prop-types';
// import { CGViewContext } from '../app/CGViewContext';
import './Anchor.css';
import DataElement from '../presenters/DataElement';
import DataElementContainer from '../presenters/DataElementContainer';
import NumericInput from '../presenters/NumericInput';
import Button from '../presenters/Button';
// import * as helpers from '../support/Helpers';

class Anchor extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    xPercent: PropTypes.number,
    yPercent: PropTypes.number,
    auto: PropTypes.bool,
    onChange: PropTypes.func,
    onManualEdit: PropTypes.func,
    allowAuto: PropTypes.bool,
    disabled: PropTypes.bool,
    opened: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    auto: false,
    opened: true,
    allowAuto: true,
    onChange: () => {},
    onManualEdit: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      opened: Boolean(props.opened),
    }
  }

  onClickedNamedPosition(e) {
    const name = e.target.getAttribute('data-name');
    this.onChange({value: name, attribute: 'name'});
  }

  onChange({value, attribute}) {
    const { xPercent, yPercent, onChange } = this.props;
    if (attribute === 'name') {
      onChange(value);
    } else if (attribute === 'xPercent') {
      onChange({xPercent: value, yPercent});
    } else if (attribute === 'yPercent') {
      onChange({xPercent, yPercent: value});
    }
  }

  onClickAuto() {
    const { onChange, auto, xPercent, yPercent } = this.props;
    const value = auto ? {xPercent, yPercent} : 'auto';
    onChange(value);
  }

  renderNamedPosition(selectedName) {
    const names = ['top-left', 'top-center', 'top-right', 'middle-left', 'middle-center', 'middle-right', 'bottom-left', 'bottom-center', 'bottom-right'];
    const gridItems = names.map( (name) => {
      let klass = (selectedName === name) ? `${name} selected` : name;
      return <div className={klass} key={name} data-name={name} onClick={(e) => this.onClickedNamedPosition(e)}></div>
    });

    const klass = this.props.disabled ? ' disabled' : '';
    return (
      <div className={`named-position-grid ${klass}`}>
        {gridItems}
      </div>
    )
  }

  renderSummary() {
    const {xPercent, yPercent, name, auto} = this.props;
    let summary = '';
    if (auto) {
      summary = 'auto';
      summary += name ? ` (${name})` : ` (${xPercent},${yPercent})`;
    } else {
      summary = name ? name : `(${xPercent},${yPercent})`;
    }
    return summary;
  }


  render() {
    const {xPercent, yPercent, name, disabled, auto} = this.props;

    const label = <span>Anchor (Attachment Point): <span className='summary'>{this.renderSummary()}</span></span>
    return (
      <DataElementContainer label={label} closable={true} closed={true} helpPath='help:sidebar:display:legend:anchor'>
        <div className='anchor-section'>
          <DataElement label='X Percent'>
            <NumericInput value={xPercent}
              disabled={disabled}
              className='anchor-number'
              precision={0}
              onChange={(value) => this.onChange({value, attribute: 'xPercent'})}/>
          </DataElement>
          <DataElement label='Y Percent'>
            <NumericInput value={yPercent}
              disabled={disabled}
              className='anchor-number'
              precision={0}
              onChange={(value) => this.onChange({value, attribute: 'yPercent'})}/>
          </DataElement>
          <DataElement label='&nbsp;'>
            <Button className='btn-auto' width={50}
              disabled={disabled}
              active={auto}
              onClick={() => this.onClickAuto()}
            ><div>Auto</div></Button>
          </DataElement>
          {this.renderNamedPosition(name)}
          <Button className='btn-manual-edit' height={34} width={60}
            disabled={disabled}
            onClick={() => console.log('EDIT MODE')}
          ><div>Manual Edit</div></Button>
        </div>
      </DataElementContainer>
    );
  }

}

export default Anchor;

