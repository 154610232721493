import React from 'react';
import SelectWithNew from '../presenters/SelectWithNew';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import DataElement from '../presenters/DataElement';
import TextInput from '../presenters/TextInput';
import Color from '../presenters/Color';
import { Option } from '../presenters/Select';
import * as helpers from '../support/Helpers';
import './SelectLegend.css';
// Connected
import { connect } from 'react-redux';

// SelectLegend onChange will returnCurrentItem. An object with keys
//  - cgvID:
//    - cgv-id-#: for existing objects
//    - NEW: create a new item
//    - custom addtional items
//  - all other keys are passed to object for creation or updating
//    - name:
//    - swatchColor: optional color to change 
class SelectLegend extends SelectWithNew {


  static defaultProps = {
    title: 'Legend',
    allowNewItem: true,
    defaultNewItemName: '',
    defaultNewItemColor: 'rgba(75, 150, 0, 1)',
    defaultItemID: 'NEW',
    additionalItems: [],
    // LegendItems with the following cgvIDs will be removed from select.
    removeIDs: [],
    newItemLabel: 'Legend',
    help: 'Choose or create a legend.',
    onChange: () => {},
    // DONE in SelectWithNew
    // // This value will cause an initial call to onChange in the constructor
    // // This is required when the initial value will need to be sent to onChange
    // // such as in DefaultDialogs.
    // callInitialOnChange: false,
  }

  constructor(props) {
    super(props);

    const { allowNewItem, defaultItemID, defaultNewItemName, defaultNewItemColor } = props;
    const items = helpers.itemsAsArray(this.props.items);
    const filteredItems = items.filter( i=> !props.removeIDs.includes(i.cgvID));
    const selectedItemID = ( allowNewItem || (defaultItemID !== 'NEW') ) ? defaultItemID : (filteredItems[0] && filteredItems[0].cgvID);

    this.state = {
      ...this.state,
      selectedItemID,
      newItemColor: defaultNewItemColor,
    };
    // Need to call again to set the defaults
    if (props.callInitialOnChange) {
      this.returnCurrentItem();
    }
  }

  // TODO: this should go with generic react comonents
  static renderSwatch(legendItem, size=10, style={}) {
    const divStyle = {
      display: 'inline-block',
      marginRight: '5px',
      backgroundColor: legendItem.swatchColor,
      width: size,
      height: size,
      ...style,
    }
    return (
      <div className='swatch' style={divStyle}></div>
    )
  }

  // Overriding returnCurrentItem to add swatch color
  returnCurrentItem() {
    const { selectedItemID, newItemName, newItemColor } = this.state;
    this.props.onChange({cgvID: selectedItemID, name: newItemName, swatchColor: newItemColor});
  }

  // Overriding renderNewItem to add swatch
  renderNewItem() {
    const { allowNewItem, newItemLabel, defaultNewItemName } = this.props;
    const { newItemName, selectedItemID, newItemColor } = this.state;
    const name = newItemName || defaultNewItemName;
    if (allowNewItem && selectedItemID === 'NEW') {
      return (
        <DataElement label={`New ${newItemLabel} Name`}>
          <div className='newItemSection'>
            <TextInput
              inputRef={ (ref) => {this.newInputRef = ref} }
              value={name}
              onChange={(value) => this.onChange({value, attribute: 'newItemName'})} />
            <Color
              colorString={newItemColor}
              showColorString={false}
              onChange={(value) => this.onChange({value, attribute: 'newItemColor'})}
            / >
          </div>
        </DataElement>
      );
    } else {
      return <DataElement />;
    }
  }

  // Overriding renderOptions to add swatches
  renderOptions() {
    const { allowNewItem, newItemLabel, removeIDs } = this.props;
    const items = helpers.itemsAsArray(this.props.items);
    const swatch = SelectLegend.renderSwatch;
    const options = items.filter( i=> !removeIDs.includes(i.cgvID)).map( i => <Option key={i.cgvID} value={i.cgvID}>{swatch(i)}{i.name}</Option> );
    this.addAdditionalItems(options);
    if (allowNewItem) {
      options.unshift(<Option key='NEW' value='NEW'>New {newItemLabel}</Option>)
    }
    return options
  }

}

// Connected
const selectLegendMapStateToProps = (state) => ({ items: state.legendItems });
const ConnectedSelectLegend = connect(selectLegendMapStateToProps)(SelectLegend);

// export default SelectLegend;
export { SelectLegend, ConnectedSelectLegend };

