import React from 'react';
import Prism from 'prismjs';
import DataElement from '../../presenters/DataElement';
import Font from '../../presenters/Font';
import * as helpers from '../../support/Helpers';



class JSONFileViewer {

  constructor(fileViewer, settings) {
    this._fileViewer = fileViewer;
    this._settings = settings;
  }

  get fileViewer() {
    return this._fileViewer;
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'json';
  }

  static get displayFileType() {
    return 'JSON';
  }

  get settingsHeight() {
    return 60;
  }

  defaultSettings() {
    return {
      font: 'monospace, plain, 12',
    }
  }

  onChangeSettings({value, attribute}) {
    this.fileViewer.onChangeSettings({value, attribute});
  }

  displaySettings(settings=this.defaultSettings()) {
    return (
      <div>
        <DataElement label='Font'>
          <Font
            fontString={settings.font}
            onChange={(value) => this.onChangeSettings({value, attribute: 'font'})}
          />
        </DataElement>
      </div>
    );
  }

  displayData(file, data, settings=this.defaultSettings()) {
    const style = {font: Font.stringToCSS(settings.font)};
    // Pretty Print Data
    let prettyData = data;
    try {
      prettyData = JSON.stringify(JSON.parse(data), null, 2);
    } catch(error) {
      console.log(error);
      console.log('Could not prettify JSON data.')
    }
    return (
      <pre className="file-data-text">
        <code
          style={style}
          className={`language-json`}
          dangerouslySetInnerHTML={{__html: Prism.highlight(prettyData, Prism.languages['json'], 'json')}}>
        </code>
      </pre>
    );
  }

}

export default JSONFileViewer;
