// Other names for this could be:
// - QuickBar, QuickPane

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FavoriteBar.css';
import iconChevron from '../images/icon-chevron.png';
// import ImageButton from '../presenters/ImageButton';

class FavoriteBar extends React.Component {

  static propTypes = {
    closed:   PropTypes.bool,
    onToggle: PropTypes.func,
  }

  static defaultProps = {
    closed: true,
  }

  constructor(props) {
    super(props);
    this.state = {
      open: !props.closed,
    }
    this.toggleBar = this.toggleBar.bind(this);
  }

  toggleBar() {
    const open = !this.state.open;
    this.setState({open});
    if (typeof this.props.onToggle === 'function') {
      this.props.onToggle(open);
    }
  }

  render() {
    const favoriteBarClass = classNames(
      'FavoriteBar',
      {open: this.state.open}
    );
    // const toggleImage = this.state.open ? './images/icon-chevron.png' : './images/sidebar-open.png';
    return (
      <div className={favoriteBarClass}>
        <div className='header' onClick={this.toggleBar}>
          <div className='toggle'>
            <img src={iconChevron} width='20' height='12' alt='open/close' />
          </div>
          Favorite Tools
        </div>
      </div>
    );
  }
}

export default FavoriteBar;

