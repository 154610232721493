import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.css';
// import ReactTooltip from 'react-tooltip';
import Tooltip from './Tooltip';

Button.propTypes = {
  className:   PropTypes.string,
  onClick:     PropTypes.func,
  onMouseDown: PropTypes.func,
  type:        PropTypes.oneOf(['primary', 'default', 'danger', 'info']),
  help:        PropTypes.string,
  size:        PropTypes.oneOf(['default', 'small']),
  // width can take a string as well. e.g. 'calc(100% / 4)'
  width:      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height:      PropTypes.number,
  disabled:    PropTypes.bool,
  active:      PropTypes.bool,
  title:       PropTypes.string,
  tip:         PropTypes.string,
  tipPosition: PropTypes.string,
}

Button.defaultProps = {
  type: 'default',
  help:    '',
  onClick:  () => {},
  size:     'default',
  width:    50,
  height:   24,
  disabled: false,
  active:   false,
  title:    undefined,
  tipPosition: 'top',
}

function Button(props) {
  const {
    className,
    onClick,
    onMouseDown,
    type,
    size,
    width,
    height,
    disabled,
    active,
    children,
    title,
    tip,
    tipPosition = 'top',
  } = props;

  const clickHandler = disabled ? () => {} : onClick;
  const mouseDownHandler = disabled ? () => {} : onMouseDown;
  // const style = { width: width, height: height, lineHeight: `${(height - 2)}px` };
  const style = { width: width, height: `${height}px`, lineHeight: `${(height - 2)}px` };

  const buttonClass = classNames(
    'Button', className, size, `btn-${type}`,
    {disabled, active}
  );
      // <div className='button-content'>{children}</div>
  return (
    <div className={buttonClass} style={style}
      title={title}
      onClick={(e) => {e.stopPropagation();clickHandler();}}
      onMouseDown={mouseDownHandler}
      onDragStart={(e) => { e.preventDefault(); }}
      data-tip={tip}
      data-place={tipPosition}
      data-for='Button'
    >
      {children}
      <Tooltip id='Button' />
    </div>
  )
}

export default Button;

