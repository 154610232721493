import { RESET_STATE, SETTINGS_UPDATE, VIEWER_UPDATE, LOAD_JSON } from '../constants/ActionTypes'


export const resetState = () => ({
  type: RESET_STATE,
  payload: {}
});

export const loadJSON = (json) => ({
  type: LOAD_JSON,
  payload: {
    json,
  }
});

export const updateSettings = (attributes) => ({
  type: SETTINGS_UPDATE,
  payload: {
    attributes,
  }
});

export const updateViewer = (attributes) => ({
  type: VIEWER_UPDATE,
  payload: {
    attributes,
  }
});

