import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElement from '../../../presenters/DataElement';
import DataElementGroup from '../../../presenters/DataElementGroup';
import DataElementContainer from '../../../presenters/DataElementContainer';
import TextElement from '../../../presenters/TextElement';

class DialogAdd extends DefaultDialog {

  renderContents() {
    const { tool, mapData } = this.props;

    const features = mapData.features || [];
    const counts = {
      phage: features.filter( f => f.meta && f.meta.major_category === 'phage').length,
      ie:    features.filter( f => f.meta && f.meta.major_category === 'integration/excision').length,
      rrr: features.filter( f => f.meta && f.meta.major_category === 'replication/recombination/repair').length,
      std: features.filter( f => f.meta && f.meta.major_category === 'stability/transfer/defense').length,
      transfer: features.filter( f => f.meta && f.meta.major_category === 'transfer').length,
    };

    return (
      <div>
        {this.renderHeader()}

        <DataElementContainer label='Features to Add (by mobileOG-db Categories)' maxHeight='300px'>
          <DataElementGroup>
            {this.renderInput('add_phage')}
            <DataElement label='Count' className='grow-tenth'><TextElement>{counts.phage}</TextElement></DataElement>
          </DataElementGroup>
          <DataElementGroup>
            {this.renderInput('add_transfer')}
            <DataElement label='Count' className='grow-tenth'><TextElement>{counts.transfer}</TextElement></DataElement>
          </DataElementGroup>
          <DataElementGroup>
            {this.renderInput('add_ie')}
            <DataElement label='Count' className='grow-tenth'><TextElement>{counts.ie}</TextElement></DataElement>
          </DataElementGroup>
          <DataElementGroup>
            {this.renderInput('add_rrr')}
            <DataElement label='Count' className='grow-tenth'><TextElement>{counts.rrr}</TextElement></DataElement>
          </DataElementGroup>
          <DataElementGroup>
            {this.renderInput('add_std')}
            <DataElement label='Count' className='grow-tenth'><TextElement>{counts.std}</TextElement></DataElement>
          </DataElementGroup>
        </DataElementContainer>

        {this.renderInput('track')}
        {this.renderInput('legend')}

        {this.renderTips()}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogAdd;

//   <DataElementContainer label='Features to Add' maxHeight='230px'>
//         <DataElementGroup>
//           {this.renderInput('add_phage', {help: `Count: ${counts.phage}`, align: 'center'})}
//           {this.renderInput('add_transfer', {help: `Count: ${counts.transfer}`, align: 'center'})}
//           {this.renderInput('add_ie', {help: `Count: ${counts.ie}`, align: 'center'})}
//         </DataElementGroup>
//         <DataElementGroup>
//           {this.renderInput('add_rrr', {help: `Count: ${counts.rrr}`, align: 'center'})}
//           {this.renderInput('add_std', {help: `Count: ${counts.std}`, align: 'center'})}
//         </DataElementGroup>
