import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import externalLinkIcon from '../images/icon-external-link.png';
import './ExternalLink.css';

ExternalLink.propTypes = {
  className: PropTypes.string,
  size:      PropTypes.number,
}

ExternalLink.defaultProps = {
  size: 14,
}

function ExternalLink(props) {
  const {
    className,
    children,
    clickable,
    // disabled=false,
    name,
    link,
    size,
  } = props;


  const elementClass = classNames(
    'ExternalLink', className,
  );

  return (
    <a target='_blank'
      title='Open in new Tab or Window'
      className={elementClass}
      href={link}
    >
      {name}
      {children}
      <img src={externalLinkIcon} alt='External link' width={size} height={size} />
    </a>
  )
}

export default ExternalLink;

