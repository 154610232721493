import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './LocationBar.css';
import { CGViewContext } from '../app/CGViewContext';
import Toast from '../presenters/Toast';
import NumericInput from '../presenters/NumericInput';
import Button from '../presenters/Button';
import ImageButton from '../presenters/ImageButton';
import fbBookmark from '../images/fb-bookmark.svg';
import * as helpers from '../support/Helpers';
import * as d3 from 'd3';
// Connected
import { connect } from 'react-redux';


class LocationBar extends Component {

  static propTypes = {
    location: PropTypes.shape({
      zoomFactor: PropTypes.number,
      bp:         PropTypes.number,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.onZoomChange = this.onZoomChange.bind(this);
    this.onBpChange = this.onBpChange.bind(this);
    this.onClickMark = this.onClickMark.bind(this);
  }

  onZoomChange(zoomFactor) {
    const cgv = this.context.cgv;
    const bp = helpers.constrain(cgv.canvas.bpForCanvasCenter(), 1, cgv.sequence.length);
    cgv.zoomTo(bp, zoomFactor, {duration: 0, ease: d3.easeLinear});
  }

  onBpChange(bp) {
    console.log('BP')
    const cgv = this.context.cgv;
    cgv.moveTo(bp, null, {duration: this.duration*0.85, ease: d3.easeLinear});
  }

  onClickMark() {
    console.log('MARK')
    const cgv = this.context.cgv;
    // Default bookmark is current map position
    const bookmark = cgv.addBookmarks({})[0];
    const shortcut = bookmark.shortcut &&
      <div className='shortcut-line'>Shortcut Key <span className='shortcut-value'>{bookmark.shortcut}</span></div>;
    const message = <div><div>Created <em>{bookmark.name}</em></div>{shortcut}</div>;
    Toast.create(message, 3000)
  }

  // Position (BP) will need 10 characters: 10,000,000
  // Zoom will need 9 characters at most: 123456.78
  render() {
    const { location } = this.props;

    // FIXME: this should be taken from redux
    // FIXME: this needs to take into account contings
    const cgv = this.context.cgv;
    const maxLength = cgv && cgv.sequence.length || 1000;
    const minZoomFactor = cgv && cgv.minZoomFactor;
    const maxZoomFactor = cgv && cgv.maxZoomFactor || 1;
    // console.log(minZoomFactor, maxZoomFactor, maxLength)
    // return (this.sequence.length * (this.sequence.bpSpacing + (this.sequence.bpMargin * 2))) / this.pixelLength; /
    // console.log(cgv.sequence.bpSpacing, cgv.sequence.bpMargin, cgv.backbone.pixelLength)
    // console.log(cgv.sequence)

    return (
      <div className='LocationBar'>
        <div className='bar-connector'></div>
        <NumericInput
          className='zoom'
          value={location.zoomFactor}
          min={minZoomFactor}
          max={maxZoomFactor}
          precision={2}
          decimals={2}
          onChange={this.onZoomChange}
        />
        <NumericInput
          className='bp'
          value={location.bp}
          max={maxLength}
          decimals={0}
          onChange={this.onBpChange}
        />
        <Button
          className='bookmark-button'
          width={24}
          height={16}
          title='Create Bookmark'
          onClick={this.onClickMark}
        >
          Mark
       </Button>
      </div>
    )
  }

}
//        <ImageButton
//         className='bookmark-button'
//         imageName='bookmarkWithText'
//         width={24}
//         title='Create Bookmark'
//         onClick={this.onClickMark}
//       />
//        <Button
//         className='bookmark-button'
//         width={24}
//         height={16}
//         title='Create Bookmark'
//         onClick={this.onClickMark}
//       >
//          Mark
//        </Button>

LocationBar.contextType = CGViewContext;

// Connected
const locationBarMapStateToProps = (state) => ({ location: state.location, });
const ConnectedLocationBar = connect(locationBarMapStateToProps)(LocationBar);

// export default LocationBar;
export { LocationBar, ConnectedLocationBar };

