import { CENTERLINE_UPDATE } from '../constants/ActionTypes'


export const updateCenterLine = (attributes) => ({
  type: CENTERLINE_UPDATE,
  payload: {
    attributes,
  }
});


