import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Toast.css';


class Toast extends React.Component {
  static propTypes = {
    duration: PropTypes.number,
  }

  static defaultProps = {
    duration: 3000,
  }

  static create(...args) {
    Toast._singletonRef._create(...args);
  }

  // static hide() {
  //   Toast._singletonRef._hide();
  // }

  constructor(props) {
    super(props);
    Toast._singletonRef = this;
    this.state = {
      status: 'reset',
      content: '',
    }
  }

  // I could check the status and have reset of half the animation
  // So if it's already open just go up a bit first.
  _create(text, duration = this.props.duration) {
    this.setState({
      status: 'reset',
      content: text,
    }, () => {
      setTimeout( () => {this.setState({status: 'open'});}, 0);
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        this.setState({status: 'closed'});
      }, duration);
    });
  }
  // _hide() {
  // }

  render() {
    const { content, status, className } = this.state;
    const toastClass = classNames(
      'Toast', className, status,
    );
    return (
      <div className={toastClass}>
        {content}
      </div>
    )
  }
}

export default Toast;
