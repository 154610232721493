import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageButton from './ImageButton';
// import iconVisibleOn from '../images/icon-visible-on.png';
// import iconVisibleOff from '../images/icon-visible-off.png';

VisibilityButton.propTypes = {
  className:   PropTypes.string,
  onClick:     PropTypes.func,
  visible:     PropTypes.bool,
  size:        PropTypes.oneOf(['default', 'small']),
}

VisibilityButton.defaultProps = {
  onClick:  () => {},
  visible: true,
  size:    'default',
}

function VisibilityButton(props) {
  const {
    className,
    visible,
    onClick,
    size,
  } = props;

  const buttonClass = classNames(
    'VisibilityButton', className
  );

  // const visiblePath = visible ? iconVisibleOn : iconVisibleOff;
  const visiblePath = visible ? 'visibleOn' : 'visibleOff';

  return (
    <ImageButton
      {...props}
      className={buttonClass}
      onClick={ () => onClick(!visible) }
      imageName={visiblePath}
      title='Visibility'
      size={size}
    / >
  )
}

export default VisibilityButton;

