import { RESET_STATE, BOOKMARKS_ADD, BOOKMARKS_REMOVE, BOOKMARKS_UPDATE } from '../constants/ActionTypes'
import { removeItems } from './ReduxHelpers';
import * as helpers from '../support/Helpers';

const initialState = {
  byID: {},
  ids: [],
}

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:      return reset(state, payload);
    case BOOKMARKS_ADD:    return addBookmarks(state, payload);
    case BOOKMARKS_REMOVE: return removeItems(state, payload.bookmarks);
    case BOOKMARKS_UPDATE: return updateBookmarks(state, payload);
    default:               return state;
  }
};

const reset = () => { return (initialState) }

const addBookmarks = (state, { bookmarks }) => {
  // Normalize bookmark data
  const data = {};
  const ids = [];
  for (var i = 0, len = bookmarks.length; i < len; i++) {
    const bookmark = bookmarks[i];
    const json = bookmark.toJSON({includeDefaults: true});
    // FIXME: pre round zoomFactor (here or in reducer, OR they should be stored to 2 digits in CGView.js)
    json.zoom = helpers.round(json.zoom);
    json.cgvID = bookmark.cgvID;
    data[json.cgvID] = json;
    ids.push(json.cgvID);
  }

  return ({
    byID: {...state.byID, ...data},
    ids: [...state.ids, ...ids],
  })
}

const updateBookmarks = (state, { bookmarks, attributes }) => {
  const bookmarkData = {};
  for (var i = 0, len = bookmarks.length; i < len; i++) {
    const bookmark = bookmarks[i];
    const json = bookmark.toJSON({includeDefaults: true});
    json.zoom = helpers.round(json.zoom);
    json.cgvID = bookmark.cgvID;
    bookmarkData[json.cgvID] = json;
  }

  return ({
    byID: {...state.byID, ...bookmarkData},
    ids: state.ids,
  })
}

