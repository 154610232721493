import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SearchBar.css';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import DataElement from './DataElement';
import PopupBox from './PopupBox';
import ContentEditable from 'react-contenteditable';
import searchBeginsWith from '../images/search-begins-with.png';
import searchEndsWith from '../images/search-ends-with.png';
import searchContains from '../images/search-contains.png';
import searchCancel from '../images/search-cancel.png';
import searchToken from '../images/search-token.png';

// TODO:
// - we could have the ability to access nested data attributes.
// For example (dataKey = tool.name) then the search would be for job.tool.name.
// We could do this by making dataKey access a function that checks if the dataKey as a period.
// However, this may slow the search so for now, for tool name,
// I will add it to the job object as job.toolName in redux.
// If more nested searches are required we can add this feature in the future.

// Map of modifiers to text or image to show in buttons
const modifierMap = {
  eq: '=',
  gt: '>',
  lt: '<',
  gte: '≥',
  lte: '≤',
  begins:   <img src={searchBeginsWith} alt='Starts With' />,
  ends:     <img src={searchEndsWith} alt='Ends With' />,
  contains: <img src={searchContains} alt='Contains' />,
};

const stringModifiers = ['contains', 'begins', 'ends', 'eq'];
const numberModifiers = ['lt', 'lte', 'eq', 'gte', 'gt'];

// ToolTips for the buttons
const tips = {
  eq: 'Equals',
  gt: 'Greater Than',
  lt: 'Lesser Than',
  gte: 'Greater Than Equal',
  lte: 'Lesser Than Equal',
  begins: 'Begins With',
  ends:   'Ends With',
  contains: 'Contains',
  not: 'Must Not Include this Value',
  and: 'Must Include this Value',
  or: 'Must Include One of the OR Values',
}

class SearchBar extends React.Component {

  static propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    onSearch: PropTypes.func.isRequired,
    initialSearch: PropTypes.string,
    // boundaryRef (if provided) will be used to constrain the search tokens setting popup
    boundaryRef: PropTypes.object,
  }

  // static defaultProps = {
  // }

  constructor(props) {
    super(props);

    // NOTE: the delay could be dependent on the data/filteredData length
    // more data = longer delay
    this.searchDelay = 300;
    this.searchTimeout = 0;
    this.contentEditableRef = React.createRef();

    this.state = {
      tokens: props.initialSearch ? this.tokenize(props.initialSearch) : [],
      tokenSelected: null,
    };

    // Create map of column dataKeys to label
    // TODO: this should update if the columns props shanges
    this._columnMap = {};
    this.props.columns.map( c => {
      this._columnMap[c.props.label.toLowerCase()] = {
        dataKey: c.props.dataKey,
        label: c.props.label,
        search: c.props.search,
      }
      return null;
    });

    this.search = this.search.bind(this);
    this.asyncSearch = this.asyncSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.decorateTokens = this.decorateTokens.bind(this);
    this.clickSearchBar = this.clickSearchBar.bind(this);
    this.clickCancelSearch = this.clickCancelSearch.bind(this);
    this.generateSearchString = this.generateSearchString.bind(this);
    this.updateToken = this.updateToken.bind(this);
    this.columnMap = this.columnMap.bind(this);
  }

  // Consider PureComponent Option
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
  // componentDidUpdate(prevProps) {
  //   if (prevProps.data !== this.props.data) {
  //     // console.log('SEARCH-UPDATE')
  //     // this.asyncSearch(this.state.tokens);
  //     // setTimeout( () => {
  //     // console.log(this.state.tokens);
  //     //   const filtered = this.search(this.state.tokens);
  //     // console.log('Search Update', filtered.length)
  //     //   this.props.onSearch(filtered);
  //     // }, 0)
  //   }
  // }

  columnMap(key='') {
    return this._columnMap && this._columnMap[key.toLowerCase()];
  }

  isNumber(n) {
    return !isNaN(n);
  }

  // Sort NOT > AND > OR
  logicSort(a, b) {
    const logicKey = { not: 1, and: 2, or: 3}
    return logicKey[a.logic] - logicKey[b.logic];
  }

  sanitize(string) {
    // Get rid of decoration divs
    let sanitized = string.replace(/<div class="token-(column|modifier)".*?>.*?<\/div>/g, '');
    // Change nbsp to ' '
    sanitized = sanitized.replace(/&nbsp;/g, ' ');
    // Trim spaces from begining of string
    sanitized = sanitized.replace(/^\s+/, '');
    // Remove styles (e.g. @font-face). This needs to be done before removing all style tags
    sanitized = sanitized.replace(/<style>[\s\S]*<\/style>/g, '');
    // Remove all tags
    // sanitized = sanitized.replace(/<\/?[^>]+?>/g, '');
    sanitized = sanitized.replace(/<\/?[^>]+(>|$)/g, '');
    // Remove all b, i, br tags
    // sanitized = sanitized.replace(/<\/?(b|i|br)>/g, '');
    // Remove all b, i, br, p tags and attributes (This fixes issue when pasting text from Word)
    // sanitized = sanitized.replace(/<\/?(b|i|br|p).*?>/g, '');
    // Remove extra tags: <o:p>
    // sanitized = sanitized.replace(/<\/?o:p.*?>/g, '');
    // Remove all div tags (not content between tags)
    // return sanitized.replace(/<\/?(div|span).*?>/g, '');
    return sanitized

  }

  decorateTokens(tokens) {
    const selectedToken = this.state.tokenSelected;
    return tokens.map( (t, i) => {
      const selected = (selectedToken === t) ? 'token-selected' : '';
      // const label = (this.columnMap[t.column] && this.columnMap[t.column].label) || 'Any';
      const label = (this.columnMap(t.column) && this.columnMap(t.column).label) || 'Any';
      // return `<div class="token ${selected} ${t.logic}" data-index="${i}"><div class="token-column" contenteditable="false"><img src=${searchToken} />${label}</div><div class="token-value">${t.raw}</div></div>`;
      return `<div class="token ${selected} ${t.logic}" data-index="${i}"><div class="token-column" contenteditable="false">${label}</div><div class="token-value">${t.raw}</div></div>`;

          
    });
  }

  regexify({ value, modifier, column }) {
    const escapeRegExp = /[-[\]/{}()*+?.\\^$|]/g;
    const escapedValue = value.replace(escapeRegExp, '\\$&');
    const columnRestricted = column && column.toLowerCase() !== 'any';
    let pattern;
    switch (modifier) {
      case 'ends':
        pattern = columnRestricted ? `.*${escapedValue}$` : `.*${escapedValue}(\\s|$)`;
        break;
      case 'begins':
        pattern = columnRestricted ? `^${escapedValue}.*` : `(\\s|^)${escapedValue}.*`;
        break;
      case 'eq':
        pattern = columnRestricted ? `^${escapedValue}$` : `(\\s|^)${escapedValue}(\\s|$)`;
        break;
      default:
        pattern = escapedValue;
    }

    return RegExp(pattern, 'i');
  }

  // Return an array of Columns for the string
  columnize(string) {
    const columns = [];
    // console.log(string)
    // let results = string.matchAll(/<div class="token-column".*?><img.*?\/?>(.*?)<\/div>/gi);
    let results = string.matchAll(/<div class="token-column".*?>(.*?)<\/div>/gi);
    for (let result of results) {
      console.log(result[1]);
      columns.push(result[1].toLowerCase());
      // columns.push(result[1]);
    }
    return columns;
  }

  tokenize(string) {
    const sanitizedString = this.sanitize(string);
    const columns = this.columnize(string);
    // console.log(`${string} --> '${sanitizedString}'`);
    const tokens = [];

    // OLD WAY was to split just by white space
    // const values = sanitizedString.split(/\s+/);
    
    // Split string by spaces.
    // Unless there are quotes (") 
    // Then group everything from one quote to the next or the end of the string
    // 'one two  three' => ['one', 'two', 'three']
    // 'one "two  three"' => ['one', '"two  three"']
    // 'one two  "three ' => ['one', 'two', 'three ']
    // 'one two  "three four" ' => ['one', 'two', 'three four', '']
    // const values = sanitizedString.match(/(?:[^\s"]+|"[^"]*"?|\s+$)+/g);
    const values = sanitizedString.match(/([^\s"]+|"[^"]*"?|\s+$)+/g);

    // for (const rawValue of values) {
    for (let [i, rawValue] of values.entries()) {

      // Column
      let  [value, typedColumn] = rawValue.split(':');

      // Remove quotes from value
      value = value.replace(/"/g, '');


      let column = typedColumn || columns[i];
      // Remove column name after ':', if it is a column name
      // if (this.columnMap && this.columnMap[typedColumn]) {
      if (this.columnMap(typedColumn)) {
        const re = new RegExp(`:${typedColumn}$`)
        rawValue = rawValue.replace(re, '');
      }

      // Logic
      let logic = 'or';
      if (value.startsWith('!')) {
        logic = 'not';
        value = value.replace(/^!/g, '');
      } else if (value.startsWith('+')) {
        logic = 'and';
        value = value.replace(/^\+/g, '');
      }

      // Modifiers
      let modifier;
      if (value.startsWith('*')) {
        modifier = 'ends';
        value = value.replace(/\*/g, '');
      } else if (value.endsWith('*')) {
        modifier = 'begins';
        value = value.replace(/\*/g, '');
      } else if (value.startsWith('=')) {
        modifier = 'eq';
        value = value.replace(/=/g, '');
      } else if (value.startsWith('&gt;=') || value.startsWith('>=')) {
        modifier = 'gte';
        value = value.replace(/(>|&gt;)=/g, '');
      } else if (value.startsWith('&lt;=') || value.startsWith('<=')) {
        modifier = 'lte';
        value = value.replace(/(<|&lt;)=/g, '');
      } else if (value.startsWith('&gt;') || value.startsWith('>')) {
        modifier = 'gt';
        value = value.replace(/(>|&gt;)/g, '');
      } else if (value.startsWith('&lt;') || value.startsWith('<')) {
        modifier = 'lt';
        value = value.replace(/(<|&lt;)/g, '');
      } else {
        modifier = 'contains';
      }

      // RegExp
      const regex = this.regexify({ value, modifier, column });

      // Type
      const type = (this.isNumber(value)) ? 'number' : 'string';

      tokens.push({ raw: rawValue, value, type, column, modifier, logic, regex });
    }
    console.log(tokens)
    return tokens;
  }

  // SearchBox text has changed
  onSearchChange(event) {
    if (event.type === 'blur') return; // Prevents the search closing Token Settings
    const value = (event && event.target) ? event.target.value : '';
    this.searchWithString(value);
    // const tokens = this.tokenize(value);
    // if (tokens[0] && tokens[0].raw === '') {
    //   tokens.shift();
    // }
    // this.setState({
    //   tokens: tokens,
    //   tokenSelected: null,
    // });
    // this.asyncSearch(tokens);
  }

  search(tokens=this.state.tokens, data=this.props.data) {
    // console.log(data, tokens)
    const { columns } = this.props;
    const startTime = new Date().getTime();
    let filtered = data;
    const sortedTokens = tokens.filter( t => t.raw !== '').sort(this.logicSort);
    // console.log('SEARCH')
    // if (typeof console.table === 'function') { console.table(sortedTokens) }
    if (sortedTokens.length > 0) {
      let pass, re, testRowString, token, logic;
      const dataKeys = columns.map( c => c.props.dataKey );
      filtered = data.filter( row => {
        pass = false;
        testRowString = dataKeys.map( k => row[k] ).join(' ');
        // console.log(testRowString)
        for (token of sortedTokens) {
          re = token.regex;
          logic = token.logic;
          if (token.column && token.column.toLowerCase() !== 'any') {

            const dataKey = this.columnMap(token.column).dataKey;
            if (token.modifier === 'gt') {
              // pass = row[token.column] > token.value;
              pass = row[dataKey] > token.value;
            } else if (token.modifier === 'lt') {
              // pass = row[token.column] < token.value;
              pass = row[dataKey] < token.value;
            } else if (token.modifier === 'gte') {
              // pass = row[token.column] >= token.value;
              pass = row[dataKey] >= token.value;
            } else if (token.modifier === 'lte') {
              // pass = row[token.column] <= token.value;
              pass = row[dataKey] <= token.value;
            // } else if (token.modifier === 'eq') {
            //   pass = row[token.column] <= token.value;
            } else {
              // pass = re.test(row[token.column]);
              pass = re.test(row[dataKey]);
            }
          } else {
            pass = re.test(testRowString);
          }
          if (logic === 'not') {
            if (pass) {
              pass = false;
              break;
            } else {
              pass = true;
            }
          } else if (logic === 'and') {
            if (!pass) break;
          } else {
            if (pass) break;
          }
        }
        return pass;
      });
    }
    const time = new Date().getTime() - startTime;
    // console.log(`Search [${time} ms] "${tokens.map( t => t.raw).join(' ')}": ${filtered.length} of ${data.length}`)
    return filtered;
  }

  asyncSearch(tokens=[]) {
    // console.log('ASYNC')
    const { onSearch } = this.props;
    if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
    this.searchTimeout = setTimeout( () => {
      const filtered = this.search(tokens);
      onSearch(filtered);
    }, this.searchDelay);
  }

  searchWithString(string = '') {
    const tokens = this.tokenize(string);
    if (tokens[0] && tokens[0].raw === '') {
      tokens.shift();
    }
    this.setState({
      tokens: tokens,
      tokenSelected: null,
    });
    this.asyncSearch(tokens);
  }

  generateSearchString(tokens) {
    tokens = tokens || [...this.state.tokens];
    const separatorDiv = '<div class="token-separator">&nbsp;</div>';
    let searchString;
    if (tokens.length > 0 && tokens[tokens.length - 1].raw === '') {
      tokens.pop();
      searchString = this.decorateTokens(tokens).join(separatorDiv);
      searchString += separatorDiv;
    } else {
      searchString = this.decorateTokens(tokens).join(separatorDiv);
    }
    return searchString;
  }

  // Bring up Token Settings if a token is clicked
  clickSearchBar(event) {
    const el = event.target;
    let tokenEl;
    if (el.classList.contains('token')) {
      tokenEl = el;
    } else if (el.parentNode && el.parentNode.classList.contains('token')) {
      tokenEl = el.parentNode;
    }
    if (tokenEl) {
      const { tokens } = this.state;
      const index = tokenEl.getAttribute('data-index');
      this.setState({ tokenSelected: tokens[index] })
    } else {
      this.setState({ tokenSelected: null })
    }
  }

  clickCancelSearch() {
    this.contentEditableRef.current.focus();
    this.setState({tokens: []});
    this.asyncSearch();
  }

  cancelSearchIcon() {
    if (this.state.tokens.length > 0) {
      return (
        <div className='cancel-search' onClick={this.clickCancelSearch}>
          <img src={searchCancel} alt='cancel' />
        </div>
      )
    }
  }

  // Return the raw value for a token based on the logic/modifier.
  rawifyToken(token) {
    let raw = token.value;
    switch (token.modifier) {
      case 'begins':
        raw = `${raw}*`; break;
      case 'ends':
        raw = `*${raw}`; break;
      case 'contains':
        raw = `${raw}`; break;
      case 'eq':
        raw = `=${raw}`; break;
      case 'gt':
        raw = `&gt;${raw}`; break;
      case 'gte':
        raw = `&gt;=${raw}`; break;
      case 'lt':
        raw = `&lt;${raw}`; break;
      case 'lte':
        raw = `&lt;=${raw}`; break;
      default:
    }
    switch (token.logic) {
      case 'and':
        raw = `+${raw}`; break;
      case 'not':
        raw = `!${raw}`; break;
      default:
    }

    return raw;
  }

  updateToken(token, values={}) {
    const newToken = {...token, ...values};
    // Update modifier if column changes
    if (Object.keys(values).includes('column')) {
      // const column = this.columnMap[values.column];
      const column = this.columnMap(values.column);
      const searchType = (column && column.search) || 'string';
      const modifier = newToken.modifier;
      // console.log(values.column)
      // console.log(column)
      // console.log(searchType, modifier)
      if (searchType === 'string' && numberModifiers.includes(modifier)) {
        newToken.modifier = 'contains';
      }
      if (searchType === 'number' && stringModifiers.includes(modifier)) {
        newToken.modifier = 'eq';
      }
    }

    // Update RegEx
    newToken.regex = this.regexify(newToken);
    // console.log(newToken.regex)

    // Update Raw Value to include modifier/logic characters
    newToken.raw = this.rawifyToken(newToken);

    // let newTokens;
    this.setState( ( {tokens} ) => { 
      const newTokens = [...tokens];
      const index = newTokens.indexOf(token);
      newTokens[index] = newToken;
      this.asyncSearch(newTokens);
      return {
        tokens: newTokens,
        tokenSelected: newToken,
      }
    });

    return newToken;
  }

  modifierButton(type, value, width, onClick) {
    const name = modifierMap[type] || type.toUpperCase()[0];
    return (
      <Button title={tips[type]} key={type} width={width} active={type === value} onClick={onClick}>{name}</Button>
    )
  }

  tokenSettings() {
    const { tokenSelected } = this.state;
    const { columns, boundaryRef } = this.props;
    const columnButtonWidth = 115;
    const logicButtonWidth = 30;
    const modifierButtonWidthNumbers = 28;
    const modifierButtonWidthStrings = 35;
    const buttonHeight = 24;
    const columnMap = this.columnMap;

    const update = (values) => () => this.updateToken(tokenSelected, values);

    if (tokenSelected) {
      const logic = tokenSelected.logic;
      const modifier = tokenSelected.modifier;
      let modifiers;
      // const column = columnMap[tokenSelected.column];
      const column = columnMap(tokenSelected.column);
      const modifierTypes = (column && column.search   === 'number') ? numberModifiers : stringModifiers;
      const modifierButtonWidth = (column && column.search   === 'number') ? modifierButtonWidthNumbers : modifierButtonWidthStrings;
      modifiers = (
        <DataElement label='Modifiers' align='center'>
          <ButtonGroup>
            {modifierTypes.map( type => (
              this.modifierButton(type, modifier, modifierButtonWidth, update({modifier: type}))
            ))}
          </ButtonGroup>
        </DataElement>
      )

      // Add 1 for 'Any' Column
      const columnRowCount = Math.ceil((columns.length + 1) / 2);
      // TODO: margin should come from app settings
      const margin = 5;
      const labelHeight = 16;
      const settingsHeight = (margin * 5) + buttonHeight + (labelHeight * 2) + (margin + buttonHeight) * columnRowCount + 3;

      const tokenIndex = this.state.tokens.indexOf(tokenSelected);
      const tokenEl = this.contentEditableRef.current.getElementsByClassName("token")[tokenIndex];
      console.log(tokenSelected)
      return (
        <PopupBox
          height={settingsHeight}
          width={250}
          targetNode={tokenEl}
          boundaryNode={boundaryRef}
          dismissOnBlur={() => this.setState({ tokenSelected: null })} >
          <div className='token-settings-header'>
            <DataElement label='Logic' align='center'>
              <ButtonGroup className='logic-group'>
                {['or', 'and', 'not'].map( value => (
                  <Button width={logicButtonWidth} key={value}
                    title={tips[value]}
                    active={logic === value}
                    onClick={ update({logic: value}) }>{value.toUpperCase()}</Button>
                ))}
              </ButtonGroup>
          </DataElement>
            {modifiers}
          </div>
          <DataElement label='Columns' align='center'>
            <div className='token-settings-columns'>
              <Button width={columnButtonWidth}
                active={tokenSelected.column === 'any'}
                onClick={ update({column: 'any'}) }>
                Any
                <div className='column-type'>abc</div>
              </Button>
              { columns.map(c => (
                <Button key={c.props.label} width={columnButtonWidth}
                  active={tokenSelected.column.toLowerCase() === c.props.label.toLowerCase()}
                  onClick={ update({column: c.props.label}) }>
                  {c.props.label}
                  <div className='column-type'>{c.props.search === 'number' ? '#' : 'abc'}</div>
                </Button>
              ))}
            </div>
          </DataElement>
        </PopupBox>
      )
      //                <Button key={c.props.dataKey} width={columnButtonWidth}
      //           active={tokenSelected.column === c.props.dataKey}
      //           onClick={ update({column: c.props.dataKey}) }>
    }
  }

  render() {
    const searchBarClass = classNames( 'SearchBar', this.props.className );
    const searchString = this.generateSearchString();
    return (
      <div className='SearchContainer'>
        <ContentEditable
          innerRef= {this.contentEditableRef}
          spellCheck={false}
          className={searchBarClass}
          html={searchString}
          onChange={this.onSearchChange}
          onClick={this.clickSearchBar}
          data-placeholder='Search...' />
        {this.cancelSearchIcon()}
        {this.tokenSettings()}
      </div>
    )
  }

}
export default SearchBar;

