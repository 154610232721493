import consumer from "./consumer"

// The handling of channel subscriptions has been move to individual components
// (e.g. UserMenu) or for proksee-viewer to ProkseeServerDispatch

// consumer.subscriptions.create("ProjectChannel", {
//   connected() {
//     // Called when the subscription is ready for use on the server
//     console.log('CONNECTED')
//   },
//
//   disconnected() {
//     // Called when the subscription has been terminated by the server
//   },
//
//   received(data) {
//     // Called when there's incoming data on the websocket for this channel
//     console.log(data)
//   }
// });
