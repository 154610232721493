import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Table, Column, AutoSizer, defaultTableRowRenderer, defaultTableHeaderRowRenderer} from 'react-virtualized';
import ItemBar from './ItemBar';
import SearchBar from './SearchBar';
import * as helpers from '../support/Helpers';
import './VirtualTable.css';
import 'react-virtualized/styles.css';
// import iconInfo from '../images/icon-info.png';

// I would prefer to use composition but that is not possible with React-Virualized,
// so instead we extend Column
// https://github.com/bvaughn/react-virtualized/issues/898
class VirtualColumn extends Column {
  static defaultProps = {
    ...Column.defaultProps,
    search: 'string', // string, number, nosearch
    hidden: false,
    width: 0,
  }
}

export { VirtualColumn as Column };

// Table Goals
// - Sorting
// - Have a spinner indicator when search is in progress
// - Advanced Options: case sensitive
// - ability for checkboxes - DONE
// - advanced filtering - DONE
// - Show results at bottom of table (number of records, etc) - Moved to ItemBar - DONE
// - Auto expand to fill container - DONE
// - Ability for info boxes DONE
// - Maybe TRY REACT-WINDOW as a replacment

export class VirtualTable extends React.Component {

  static propTypes = {
    // NOTE: all records must have an ID, so we can keep track of rows when data is filtered
    // - This requirement could be removed by having a map of filtered indices to unfiltered indices
    // - ie when filtering data create state: filteredDataIndexMap with keys as filtered data index and values as unfiltered data index
    idKey: PropTypes.string,
    data: PropTypes.array.isRequired,
    infoHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    dataName: PropTypes.string,
    initialSearch: PropTypes.string,
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    otherActions: PropTypes.array,
    onRowMouseOver: PropTypes.func,
    onRowMouseOut: PropTypes.func,
  }

  static defaultProps = {
    idKey: 'id',
    infoHeight: 50,
    dataName: 'Records',
  }

  constructor(props) {
    super(props);

    this.state = {
      filteredData: props.data,
      infoOpenByID: {},
      selectedIDs: new Set(),
      selectedStatus: 'none', // none, some, all
      visibleColumnMask: this.getVisibleColumnMask(props),
      searchBarRef: React.createRef(),
    };

    // this.searchBarRef = React.createRef();

    this.defaultHeight = props.rowHeight;

    this.rowHeight = this.rowHeight.bind(this);
    this.selectColumn = this.selectColumn.bind(this);
    this.rowCheckbox = this.rowCheckbox.bind(this);
    this.headerCheckbox = this.headerCheckbox.bind(this);
    this.infoButtonColumn = this.infoButtonColumn.bind(this);
    this.infoButton = this.infoButton.bind(this);
    this.counterColumn = this.counterColumn.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.headerRowRenderer = this.headerRowRenderer.bind(this);
    this.handleInfoClicked = this.handleInfoClicked.bind(this);
    this.registerRef = this.registerRef.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onClickHeaderCheckbox = this.onClickHeaderCheckbox.bind(this);
    this.onClickRowCheckbox = this.onClickRowCheckbox.bind(this);
    this.noRowsRenderer = this.noRowsRenderer.bind(this);

    this.addSelectedID = this.addSelectedID.bind(this);
    this.removeSelectedID = this.removeSelectedID.bind(this);
    this.idSelected = this.idSelected.bind(this);
    this.selectedStatusFor = this.selectedStatusFor.bind(this);

  }

  // Consider PureComponent Option
  // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
  // componentDidUpdate(prevProps) {
  //   if (prevProps.data !== this.props.data) {
  //     const filteredData = this.searchBarRef.current.search();
  //     this.setState({filteredData: filteredData});
  //     // console.log('Table Update - this is reseting the filteredData')
  //   }
  // }

  // This lets us keep track of when the data has changed/edited.
  // When this happens we need to refilter data.
  // I had used componentDidUpdate in the past but his caused a double render
  // since it was changing the state. This led to Text Input Caret jumping when
  // editing in an input.
  static getDerivedStateFromProps(props, state) {
    if (props && props.data !== state.data) {
      let filteredData = props.data || [];
      if (state.searchBarRef.current) {
        // Since getDerivedStateFromProps is called before render, SearchBar will not
        // have the most recent data yet. So we provide it here.
        filteredData = state.searchBarRef.current.search(undefined, filteredData);
      }
      return {
        filteredData: filteredData,
        data: props.data
      };
    }
    return null;
  }

  getVisibleColumnMask(props) {
    const mask = [];
    if (props.selectColumn) {
      mask.push(true);
    }
    if (props.counterColumn) {
      mask.push(true);
    }
    for (let c of React.Children.toArray(props.children)) {
      mask.push(!c.props.hidden);
    }
    if (props.infoRenderer) {
      mask.push(true);
    }
    return mask;
  }

  handleInfoClicked(id) {
    this.setState({
      infoOpenByID: {
        ...this.state.infoOpenByID,
        [id]: !this.state.infoOpenByID[id]
      }
    });
    this.tableRef.recomputeRowHeights();
  }

  openInfoFor(id) {
    this.setState({
      infoOpenByID: {
        ...this.state.infoOpenByID,
        [id]: true,
      }
    });
    // this.tableRef.scrollToRow(100)
    this.tableRef.recomputeRowHeights();
  }

  rowHeight({ index }) {
    const { idKey, infoRenderer, infoHeight } = this.props;
    const dataItem = this.state.filteredData[index];
    const rowID = dataItem[idKey];
    const isOpen = this.state.infoOpenByID[rowID];
    const infoHeightResult = (typeof infoHeight === 'function') ? infoHeight(dataItem) : infoHeight;
    return (infoRenderer && isOpen) ? this.defaultHeight + infoHeightResult : this.defaultHeight;
  }

  // If id='all' then all Filtered IDs are added
  addSelectedID(id) {
    const { idKey } = this.props;
    this.setState( ({ selectedIDs: prevSelectedIDs, filteredData }) => {
      const filteredIDs = new Set( filteredData.map( (d) => d[idKey] ) );
      let selectedIDs =  new Set(prevSelectedIDs);
      if (id === 'all') {
        for (let filteredID of filteredIDs) {
          selectedIDs.add(filteredID);
        }
      } else {
        selectedIDs.add(id);
      }
      const selectedStatus = this.selectedStatusFor(selectedIDs, filteredIDs);
      return { selectedIDs, selectedStatus }
    });
  }

  selectedStatusFor(selectedIDs, filteredIDs) {
    let selectedStatus = 'none';
    if (selectedIDs.size > 0) {
      const intersection = helpers.intersection(filteredIDs, selectedIDs);
      if (intersection.size === filteredIDs.size) {
        selectedStatus = 'all';
      } else if (intersection.size > 0) {
        selectedStatus = 'some';
      }
    }
    return selectedStatus;
  }

  removeSelectedID(id) {
    const { idKey } = this.props;
    this.setState( ({ selectedIDs: prevSelectedIDs, filteredData }) => {
      const filteredIDs = new Set( filteredData.map( (d) => d[idKey] ) );
      const selectedIDs = new Set(prevSelectedIDs);

      if (id === 'all') {
        for (let filteredID of filteredIDs) {
          selectedIDs.delete(filteredID);
        }
      } else {
        selectedIDs.delete(id);
      }

      const selectedStatus = this.selectedStatusFor(selectedIDs, filteredIDs);
      return { selectedIDs, selectedStatus };
    });
  }

  idSelected(id) {
    return this.state.selectedIDs.has(id);
  }

  // Removes any selected ID that no longer exist in the data set
  // Should be called after a delete action
  refreshSelectedIDs() {
    const { idKey, data } = this.props;
    this.setState( ({ selectedIDs: prevSelectedIDs }) => {
      const dataIDs = new Set( data.map( (d) => d[idKey] ) );
      const selectedIDs = new Set(prevSelectedIDs);
      for (let id of selectedIDs) {
        if (!dataIDs.has(id)) {
          selectedIDs.delete(id);
        }
      }
      const selectedStatus = this.selectedStatusFor(selectedIDs, dataIDs);
      return { selectedIDs, selectedStatus };
    });

  }

  onClickHeaderCheckbox(e) {
    e.stopPropagation();
    const selectedStatus = this.state.selectedStatus;
    (selectedStatus === 'all') ?  this.removeSelectedID('all') :  this.addSelectedID('all');
  }

  onClickRowCheckbox(e, id) {
    e.stopPropagation();
    this.idSelected(id) ? this.removeSelectedID(id) : this.addSelectedID(id);
  }

  // HeaderRenderer parameters
  // columnData, dataKey, disableSort, label, sortBy, sortDirection
  headerCheckbox() {
    // console.log('HEADER CHECKBOX')
    const checkboxClass = classNames(
      'select-header-checkbox',
      // {active}
    );
    const { selectedStatus } = this.state;
    const inter = selectedStatus === 'some';
    const checked = selectedStatus === 'all';
    return (
      <div onClick={ (e) => this.onClickHeaderCheckbox(e) }  >
        <input type='checkbox' className={checkboxClass}
          checked= {checked}
          onChange={()=>{}}
          ref={ (el) => el && (el.indeterminate = inter)} />
      </div>
    )
  }

  // CellRenderer parameters
  // cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex
  rowCheckbox({ rowIndex }) {
    const { idKey } = this.props;
    const rowID = this.state.filteredData[rowIndex][idKey];
    const checkboxClass = classNames(
      'select-row-checkbox',
      // {active}
    );
    return (
      <div onClick={ (e) => this.onClickRowCheckbox(e, rowID) }>
        <input type='checkbox' className={checkboxClass} checked={this.idSelected(rowID)} onChange={()=>{}}/>
      </div>
    )
  }

  selectColumn() {
    const { selectColumn } = this.props;
    if (selectColumn) {
      return (
        <Column
          width={20}
          headerRenderer={ () => this.headerCheckbox() }
          label=''
          dataKey='info'
          className='checkbox-column'
          headerClassName='checkbox-column'
          cellRenderer={ (index) => this.rowCheckbox(index) }
        />
      )
    }
  }

  infoButton() {
    const infoButtonClass = classNames(
      'info-button',
      // {active}
    );
    return (
      <div className={infoButtonClass}>
        <img height='13' width='5' alt='' src={iconInfo} />
      </div>
    )
  }

  infoButtonColumn() {
    const { infoRenderer } = this.props;
    if (infoRenderer) {
      return (
        <Column
          width={10}
          label=''
          dataKey='info'
          cellRenderer={this.infoButton}
        />
      )
    }
  }

  counterColumn() {
    const { counterColumn } = this.props;
    if (counterColumn) {
      return (
        <Column
          width={70}
          label='#'
          dataKey='counter'
          cellRenderer={({rowIndex}) => rowIndex}
        />
      )
    }
  }

  headerRowRenderer(props) {
    // Intercept hidden columns
    const columns = props.columns.filter( (c,i) => this.state.visibleColumnMask[i] );

    const propsStyled = {
      ...props,
      columns: columns,
      // style: {
      // },
    };
    const header =  defaultTableHeaderRowRenderer({
      ...propsStyled,
    });
    return header;
  }

  rowRenderer(props) {
    const { infoRenderer, idKey, rowClass, onRowMouseOver, onRowMouseOut } = this.props;
    // Intercept hidden columns
    const columns = props.columns.filter( (c,i) => this.state.visibleColumnMask[i] );

    const propsStyled = {
      ...props,
      columns: columns,
      onRowClick: () => this.handleInfoClicked(rowID),
      className: `${props.className} virtual-row-main`,
      style: {
        height: this.defaultHeight,
      },
    };
    const row = defaultTableRowRenderer({
      ...propsStyled,
    });
    const dataItem = this.state.filteredData[props.index];
    const rowID = dataItem[idKey];
    let infoStuff = '';
    if (infoRenderer && this.state.infoOpenByID[rowID]) {
      infoStuff = infoRenderer(dataItem);
    }
    const classForRow = rowClass ? rowClass(dataItem) : '';
    const rowClassName = classNames(
      'virtual-row', 
      classForRow,
      {open: Boolean(infoStuff)}
    );
    return (
      <div className={rowClassName}
        onMouseOver={(e) => onRowMouseOver && onRowMouseOver(e, dataItem)}
        onMouseOut={onRowMouseOut}
        key={rowID}
        style={props.style}
      >
        {row}
      <div className='info-box'>{infoStuff}</div></div>);
  }

  noRowsRenderer() {
    const { data, dataName } = this.props;
    const message = (data.length > 0) ? `No Matching ${dataName} Found` : `No ${dataName}`;
    return <div className='table-empty-message'>{message}</div> 
  }

  registerRef(ref) {
    this.tableRef = ref;
  };

  deleteAction() {
    const { selectedIDs } = this.state;
    const { deleteAction } = this.props;
    deleteAction([...selectedIDs]);
    // this.setState({
    //   selectedIDs: new Set(),
    //   selectedStatus: 'none',
    // });
  }

  editAction() {
    const { selectedIDs } = this.state;
    const { editAction } = this.props;
    editAction([...selectedIDs]);
  }

  itemBar() {
    const { filteredData, selectedIDs } = this.state;
    const { data, dataName, addAction, deleteAction, editAction, otherActions } = this.props;
    return (
      <ItemBar title={dataName}
        totalItemCount={data.length}
        filteredItemCount={filteredData.length}
        selectedItemCount={selectedIDs.size}
        deleteAction={ deleteAction && (() => this.deleteAction()) }
        editAction={ editAction && (() => this.editAction()) }
        addAction={ addAction }
        otherActions={ otherActions }
      />
    )
  }

  onSearchChange(filteredData) {
    const { idKey } = this.props;
    this.setState( ({ selectedIDs }) => {
      const filteredIDs = new Set( filteredData.map( (d) => d[idKey] ) );
      const selectedStatus = this.selectedStatusFor(selectedIDs, filteredIDs);
      return { filteredData, selectedStatus }
    });
    this.tableRef.recomputeRowHeights();
  }

  searchBar() {
    const { data, children, initialSearch } = this.props;
    const { searchBarRef } = this.state;
    const searchableColumns = React.Children.toArray(children).filter( c => c.props.search !== 'nosearch' )
    return (
      <SearchBar boundaryRef={this.containerRef} ref={searchBarRef} data={data} columns={searchableColumns} onSearch={this.onSearchChange} initialSearch={initialSearch} />
    )
  }

  searchWithString(string) {
    this.state.searchBarRef.current.searchWithString(string);
  }

  render() {
    // console.log('TABLE RENDERED')
    const filteredData = this.state.filteredData;

    // FIXME: the (height - 50) takes into account the itemBar and searchBar height.
    // This should change based on if itemBar/searchBar are shown and their actual height.

    return (
      <div style={{width: '100%', height: '100%'}} ref={ (ref) => this.containerRef = ref }>
        {this.itemBar()}
        {this.searchBar()}
        <AutoSizer>
          {({ width, height }) => (
              <Table
                {...this.props}
                rowCount={filteredData.length}
                rowGetter={({ index }) => filteredData[index]}
                ref={this.registerRef}
                width={this.props.width || width}
                height={this.props.height || height - 50 }
                rowHeight={this.rowHeight}
                headerClassName='virtual-header-column'
                rowRenderer={this.rowRenderer}
                headerRowRenderer={this.headerRowRenderer}
                noRowsRenderer={this.noRowsRenderer}
              >
                {this.selectColumn()}
                {this.counterColumn()}
                {this.props.children}
              </Table>
          )}
        </AutoSizer>
      </div>
    )
    // Remove Info 'i' button to save space
    //{this.infoButtonColumn()}
  }
}



