// HelpElements appear below various elements and inputs as faded text.
// Optional moreText will appear when "more..." is pressed
// Use HelpLinks for help icons that give help text popups and links to the help docs.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './HelpElement.css';

HelpElement.propTypes = {
  className: PropTypes.string,
  text:     PropTypes.string,
  moreText:     PropTypes.node, // Could also be 'node/element' for react component
  closed:    PropTypes.bool,
  // children:  PropTypes.element,
}

HelpElement.defaultProps = {
  closed: true,
}

function HelpElement(props) {
  const { className, text, moreText, closed } = props;

  const [isClosed, setIsClosed] = useState(closed);

  const elementClass = classNames(
    'HelpElement', className,
    {closed: isClosed},
  );

  const moreInfoText = isClosed ? 'More info...' : 'Less info...';
  const moreInfoLink = moreText ? <a href='#' onClick={ ()=> setIsClosed(!isClosed) }>{moreInfoText}</a> : '';

  return (
    <div className={elementClass}>
      <div>{text} {moreInfoLink}</div>
      <div className='more-info'>{moreText}</div>
    </div>
  )
}

export default HelpElement;

