import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';
import DataElementGroup from '../../../presenters/DataElementGroup';
import DataElementContainer from '../../../presenters/DataElementContainer';

class DialogStart extends DefaultDialog {

  renderContents() {
    const { tool } = this.props;
    return (
      <div>
        {this.renderHeader()}

        {this.renderInput('jobName')}
        {this.renderInput('e_score')}

        <hr />
        <DataElementContainer className='advanced-section' label='Advanced Options' maxHeight='260px' closable={true} closed={true}>
          {this.renderInput('p_ident_value')}
          {this.renderInput('query_score')}
          {this.renderInput('k_value')}
        </DataElementContainer>
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

