import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Position.css';
import DataElement from './DataElement';
import NumericInput from './NumericInput';
import Button from './Button';
import ButtonGroup from '../presenters/ButtonGroup';

class Position extends React.Component {

  static propTypes = {
    position:           PropTypes.object.isRequired,
    onChange:           PropTypes.func,
    onRelativeToChange: PropTypes.func,
    onManualEdit:       PropTypes.func,
    disabled:           PropTypes.bool,
    className:          PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
    onChange: () => {},
    onRelativeToChange: () => {},
    onManualEdit: () => {},
  }

  onClickedNamedPosition(e) {
    const name = e.target.getAttribute('data-name');
    this.props.onChange(name);
  }

  renderNamedPosition(position) {
    const selectedName = (typeof position === 'string') ? position : position.name;
    const names = ['top-left', 'top-center', 'top-right', 'middle-left', 'middle-center', 'middle-right', 'bottom-left', 'bottom-center', 'bottom-right'];
    const gridItems = names.map( (name) => {
      let klass = (selectedName === name) ? `${name} selected` : name;
      return <div className={klass} key={name} data-name={name} onClick={(e) => this.onClickedNamedPosition(e)}></div>
    });

    const klass = this.props.disabled ? ' disabled' : '';
    return (
      <div className={`named-position-grid ${klass}`}>
        {gridItems}
      </div>
    )
  }

  renderCanvasPosition(position) {
    const {onChange} = this.props;
    const xPercent = position.xPercent;
    const yPercent = position.yPercent;
    return (
      <div className='input-container'>
        <DataElement label='' className='grid-container'>
        {this.renderNamedPosition(position)}
        </DataElement>
        <DataElement label='X Percent'>
          <NumericInput value={xPercent}
            className='xy-percent'
            precision={1}
            onChange={(value) => onChange({xPercent: value, yPercent})}/>
        </DataElement>
        <DataElement label='Y Percent'>
          <NumericInput value={yPercent}
            className='xy-percent'
            precision={1}
            onChange={(value) => onChange({xPercent, yPercent: value})}/>
        </DataElement>
      </div>
    )
  }

  renderMapPosition(position) {
    const {onChange} = this.props;
    const lengthPercent = position.value.lengthPercent;
    const offsetType = position.offsetType;
    const offset = (offsetType === 'map') ? position.value.mapOffset : position.value.bbOffsetPercent;
    const offsetLabel = (offsetType === 'map') ? 'Offset (Map)' : 'Offset (Backbone)';
    return (
      <div className='input-container'>
        <DataElement label='Length Percent'>
          <NumericInput value={lengthPercent}
            className='length-percent'
            precision={6}
            onChange={(value) => onChange({...position.value, lengthPercent: value})}/>
        </DataElement>
        <DataElement label={offsetLabel}>
          <NumericInput value={offset}
            className='offset'
            precision={0}
            onChange={(value) => onChange({...position.value, mapOffset: value})}/>
        </DataElement>
      </div>
    )
  }

  render() {
    const {position, onRelativeToChange, className} = this.props;

    const renderedPosition = (position.on === 'canvas') ?
      this.renderCanvasPosition(position) :
      this.renderMapPosition(position);

    const klass = classNames(
      'Position', className,
    );

    return (
      <div className={klass}>
        <DataElement label='Position: On' helpPath='help:sidebar:display:legend:position' >
          <ButtonGroup>
            <Button
              width={47}
              onClick={() => onRelativeToChange('canvas')}
              active={position.on === 'canvas'}>Canvas</Button>
            <Button
              width={47}
              onClick={() => onRelativeToChange('map')}
              active={position.on === 'map'}>Map</Button>
          </ButtonGroup>
        </DataElement>
        {renderedPosition}
        {/* FIXME: Add back manual edit */}
        {/* <Button className='manual-edit' height={34} width={50} */}
        {/*   onClick={() => console.log('EDIT MODE')} */}
        {/* ><div>Manual Edit</div></Button> */}
    </div>
    );
  }

}

export default Position;

