import React from 'react';
import BasicDialog from '../../presenters/BasicDialog';
import DataElement from '../../presenters/DataElement';
import { Select, Option } from '../../presenters/Select';

// EXPECTED PROPS:
// - trackID: cgvID for the track to delete

class TrackDeleteDialog extends BasicDialog {

  static defaultProps = {
    options: {deleteFeatures: 'yes', deleteLegends: 'yes'},
    valid: true,
  }

  renderTitle() {
    const {trackID} = this.props;
    return `Delete Track: ${this.cgv.objects(trackID).name}`;
  }

  onLocalClose({action, options}) {
    const {trackID} = this.props;
    const cgv = this.cgv;
    if (action === 'ok') {
      const track = this.cgv.objects(trackID);
      if (track.type === 'feature') {
        // Feature Track
        const featuresToDelete = track.uniqueFeatures();
        const legendsToDelete = cgv.legend.uniqueLegendsItemsFor({features: featuresToDelete});
        if (options.deleteFeatures === 'yes') {
          cgv.removeFeatures(featuresToDelete);
          if (options.deleteLegends === 'yes') {
            cgv.legend.removeItems(legendsToDelete);
          }
        }
      } else {
        // Plot Track
        console.log('bob1')
        if (track.plot && track.plot.tracks().length === 1) {
        console.log('bob2')
          const legendsToDelete = cgv.legend.uniqueLegendsItemsFor({plots: [track.plot]});
          cgv.removePlots(track.plot);
          if (options.deleteLegends === 'yes') {
        console.log('bob3')
            cgv.legend.removeItems(legendsToDelete);
          }
        }
      }
      cgv.removeTracks(track);
      cgv.draw();
    }
  }

  renderContents() {
    const {trackID} = this.props;
    const { options } = this.state;
    const cgv = this.cgv;

    const track = cgv.objects(trackID);
    const yesNoObject = {yes: 'Yes', no: 'No' };
    const yesNoOptions = Object.keys(yesNoObject).map( k => <Option key={k} value={k}>{yesNoObject[k]}</Option>);

    let legendsToDelete;
    let featuresToDelete = [];
    const featureTrack = (track.type === 'feature');
    if (featureTrack) {
      // Determine Features only on this track
      featuresToDelete = track.uniqueFeatures();
      legendsToDelete = cgv.legend.uniqueLegendsItemsFor({features: featuresToDelete});
    } else {
      legendsToDelete = cgv.legend.uniqueLegendsItemsFor({plots: [track.plot]});
    }


    // Feature/Plot Data
    let dataContent;
    if (featureTrack) {
      if (featuresToDelete.length > 0) {
        const dataContentSummary = (options.deleteFeatures === 'yes') ?
          <p>Features that <strong>will</strong> be deleted: {featuresToDelete.length}</p> :
          <p>Features <strong>will not</strong> be deleted.</p>
        dataContent = (
          <div>
            <DataElement label='Delete features as well?' help='Only features exclusive to this track are considered.'>
              <Select value={options.deleteFeatures}
                onChange={(value) => this.onChange({attribute: 'deleteFeatures', value})}
              >
                { yesNoOptions }
              </Select>
            </DataElement>
            {dataContentSummary}
          </div>
        );
      } else {
        dataContent = <p>No features will be deleted.</p>;
      }
    } else {
      if (track.plot && track.plot.tracks().length === 1) {
        dataContent = <p>Plot data <strong>will</strong> be deleted.</p>;
      } else {
        dataContent = <p>Plot data <strong>will not</strong> be deleted.</p>;
      }
    }

    // Legend Item Data
    let legendContent = <p>No legend items will be deleted</p>;
    // TODO: plot logic
    if (legendsToDelete.length > 0 && (options.deleteFeatures === 'yes' || track.type === 'plot')) {
      const legendNames = legendsToDelete.map( i => i.name );
      const legendContentSummary = (options.deleteLegends === 'yes') ?
        <p>Legend items that <strong>will</strong> be deleted: {legendNames.join(', ')}</p> :
        <p>Legend items <strong>will not</strong> be deleted.</p>
      legendContent = (
        <div>
          <DataElement label='Delete legend items as well?' help='Only legend items exclusive to this track are considered.'>
            <Select value={options.deleteLegends}
              onChange={(value) => this.onChange({attribute: 'deleteLegends', value})}
            >
              { yesNoOptions }
            </Select>
          </DataElement>
          {legendContentSummary}
        </div>
      );
    }

    return (
      <div>
        <p>Are you sure you want to delete this track?</p>
        {dataContent}
        {legendContent}
      </div>
    );
  }
}

export default TrackDeleteDialog;
