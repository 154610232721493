import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import ServerAPI from '../models/ServerAPI';
import './FeaturePane.css'; // Using same CSS as Features
import './JobPane.css'; 
import Toast from '../presenters/Toast';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextElement from '../presenters/TextElement';
import TextEditable from '../presenters/TextEditable';
import ImageButton from '../presenters/ImageButton';
import ButtonGroup from '../presenters/ButtonGroup';
import Button from '../presenters/Button';
import FavoriteButton from '../presenters/FavoriteButton';
import JobsDeleteDialog from './dialogs/JobsDeleteDialog';
import {ConnectedAddJobResultsButton} from '../containers/AddJobResultsButton';
import { VirtualTable, Column } from '../presenters/VirtualTable';
import * as helpers from '../support/Helpers';
import consumer from '../../channels/consumer';
import Job from '../models/Job';
import Message from '../presenters/Message';
import '../support/CommonStyles.css';
import { ConnectedJobTab } from '../tabs/JobTab';

import store from '../app/store';
import { updateJobs } from '../actions/jobs';

import Tools from '../models/Tools';

// Connected
import { connect } from 'react-redux';

class JobPane extends Component {

  static propTypes = {
    jobs: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
    sessionProject: PropTypes.bool,
  }

  // static defaultProps = {
  //   jobs: {},
  // }

  constructor(props) {
    super(props);
    this.state = {
      virtualTableRef: React.createRef(),
      bulkDeleteIDs: [],
    }
    this.infoBoxRenderer = this.infoBoxRenderer.bind(this);
    this.nameCellRenderer = this.nameCellRenderer.bind(this);
    this.statusCellRenderer = this.statusCellRenderer.bind(this);
    this.optionCellRenderer = this.optionCellRenderer.bind(this);
    // this.onAddToMapClosed = this.onAddToMapClosed.bind(this);
  }

  // Get the Job list on mounting component
  // This should already be done (in ProkseeServerDispatch.js) but with large projects
  // it get skipped sometimes. If this does not work when can add a timer.
  componentDidMount() {
    setTimeout( () => {
      this.refreshJobs(false);
      // console.log('GET JOBS')
    }, 5000);
  }

  refreshJobs(withMsg=true) {
    const Server = new ServerAPI();
    Server.get(Server.URL.jobList)
    .then( response => {
      if (response.ok) {
        store.dispatch(updateJobs(response.json))
        if (withMsg) {
          Toast.create('Jobs Refreshed', 3000)
        }
      }
    });
  }

  nameCellRenderer({ rowData:job }) {
    return (
      <div className='column-value'>
        <div className='column-value-main'>{job.name}</div>
        <div className='column-value-sub'>
          <div className='format'>
            {job.tool.shortName} [{job.toolVersion}]
          </div>
        </div>
      </div>
    )
  }

  statusCellRenderer({ rowData:job }) {
    return (
      <div className='column-value'>
        <div className='column-value-main'>{job.status}</div>
        <div className='column-value-sub'>
          <div className='format'>
            {helpers.friendlyDateTime(job.createdAt)}
          </div>
        </div>
      </div>
    )
  }

  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  optionCellRenderer({ rowData:job }) {
    return (
      <ButtonGroup className='option-buttons'>
        <ConnectedAddJobResultsButton  id={job.id} />
        <ImageButton
          imageName='expand'
          title='Expand to Tab'
          onClick={ () => this.expandJobToTab(job) }
        / >
        <FavoriteButton
          favorite={job.favorite}
          onClick={ (value) => this.onFavoriteJob(job, value) }
        />
      </ButtonGroup>
    )
  }

  infoBoxRenderer(job) {
    const deleteButton = job.canDelete ?
      (<ImageButton imageName='delete' text='Delete Job' width={100} size='default'
        title='Delete Job' onClick={ () => this.onDeleteJobs([job.id]) } />) : '';
    const daysRemainingElement = (job.daysRemaining !== false) &&
       <DataElement label='Days Left' helpPath='help:jobs:days_left'><TextElement>{job.daysRemaining}</TextElement></DataElement>
    return (
      <div>
        <DataElement label='Name' helpPath='help:jobs:name'>
          <TextEditable value={job.name}
            onChange={(value) => this.onChangeJob({job, attribute: 'name', value})}/>
        </DataElement>
        <DataElementGroup>
          <DataElement label='Size' helpPath='help:jobs:size'>
            <TextElement>{helpers.formatBytes(job.size)}</TextElement>
          </DataElement>
          {daysRemainingElement}
          <DataElement label='Run Time' helpPath='help:jobs:run_time'>
            <TextElement>{job.displayRuntime}</TextElement>
          </DataElement>
        </DataElementGroup>
        <DataElement label='Inputs' helpPath='help:jobs:inputs'>
          <TextElement className='scrollable scroll-skinny'>{Job.inputsAsHTML(job)}</TextElement>
        </DataElement>
        <div className='action-buttons'>
          <ConnectedAddJobResultsButton  id={job.id} text='Add to Map' width={100} />
          <ImageButton
            imageName='expand'
            text='Expand Tab'
            width={100}
            size='default'
            title='Expand to Tab'
            onClick={ () => this.expandJobToTab(job) }
          / >
          {deleteButton}
        </div>
      </div>
    )
  }

  // This could be moved to MainTabContainer
  expandJobToTab(job) {
    const tabs = this.context.tabsRef;
    this.context.sidebarRef.autoCloseSideBar();
    tabs.add({title: job.name, id: `job-${job.id}`, content: <ConnectedJobTab id={job.id} summaryOpen={false} />});
  }

  onFavoriteJob(job, favorite) {
    if (favorite) {
      const tabs = this.context.tabsRef;
      tabs.setTabOrCreateByJob(job, {active: false, summaryOpen: false, initialJobCard: 'report'});
    }
    this.onChangeJob({job, attribute: 'favorite', value: favorite});
  }

  onChangeJob({job, attribute, value}) {
    const Server = new ServerAPI();
    Server.patchJob(job.id, {[attribute]: value});
  }

  onDeleteJobs(ids) {
    this.setState({bulkDeleteIDs: ids});
    // const tabs = this.context.tabsRef;
    // const Server = new ServerAPI();
    // Server.deleteJob(job.id)
    // .then( response => {
    //   if (response.ok) {
    //     tabs.removeByID(`job-${job.id}`);
    //     Toast.create(`Job Deleted: ${job.name}`, 1000);
    //   }
    // });
  }

  renderDeleteDialog() {
    const { bulkDeleteIDs, virtualTableRef } = this.state;
    if (bulkDeleteIDs.length > 0) {
      return (
        <JobsDeleteDialog
          jobIDs={bulkDeleteIDs}
          jobTableRef={virtualTableRef}
          jobsByID={this.props.jobs.byID}
          onClose={() => this.setState({bulkDeleteIDs: []})}
        />
      );
    }
  }

  render() {
    const { sessionProject } = this.props;

    const jobs = helpers.itemsAsArray(this.props.jobs);

    const daysRemainingColumn = (sessionProject) && (
      <Column
        label='Days Left'
        dataKey='daysRemaining'
        search='number'
      />
    );

    const sessionJobsWarning = sessionProject &&
        <div className='ps-alert ps-alert-notice session-jobs-header'>Session project jobs are deleted after 7 days</div>;

    // const jobs = this.state.jobs.list;
    // console.log(this.state.jobs)
    // console.log(jobs)
    return (
      <div style={{width: '100%', height: '100%'}} className='JobPane'>
        {sessionJobsWarning}
        <VirtualTable
          headerHeight={20}
          rowHeight={32}
          infoHeight={225}
          data={jobs}
          dataName='Jobs'
          idKey='id'
          infoRenderer={this.infoBoxRenderer}
          selectColumn={true}
          ref={this.state.virtualTableRef}
          deleteAction={(ids)=>{this.onDeleteJobs(ids)}}
          otherActions={[{
            title: 'Refresh Jobs',
            imageName: 'refresh',
            position: 'start',
            onClick: () => {this.refreshJobs()}
          }]}
        >
          <Column
            label='Job'
            dataKey='name'
            width={170}
            search='nosearch'
            cellRenderer={this.nameCellRenderer}
          />
          <Column
            label='Name'
            dataKey='name'
            hidden={true}
          />
          <Column
            label='Tool'
            dataKey='toolName'
            hidden={true}
          />
          <Column
            label='Size'
            dataKey='size'
            search='number'
            hidden={true}
          />
          {daysRemainingColumn}
          <Column
            width={100}
            label='Status'
            dataKey='status'
            cellRenderer={this.statusCellRenderer}
          />
          <Column
            width={90}
            dataKey=''
            search='nosearch'
            cellRenderer={this.optionCellRenderer}
          />
        </VirtualTable>
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

          // cellRenderer={this.statusCellRenderer}
JobPane.contextType = CGViewContext;

//Connected
const jobPaneMapStateToProps = (state) => ({ jobs: state.jobs, sessionProject: state.project.sessionProject });
const ConnectedJobPane = connect(jobPaneMapStateToProps, null, null, {forwardRef: true})(JobPane);

// export default JobPane;
export { JobPane, ConnectedJobPane};

