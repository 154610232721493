// Remove items from state
// items must have cgvID property
export function removeItems(state, items) {
  // NOTE: if this is slow we could make a copy of byIDs and then use delete to remove features
  //       and then use filter on the ids array.
  const data = {};
  const ids = [];
  const idsToRemove = items.map( i => i.cgvID );
  for (var i = 0, len = state.ids.length; i < len; i++) {
    const id = state.ids[i];
    if (!idsToRemove.includes(id)) {
      ids.push(id);
      data[id] = state.byID[id];
    }
  }
  return ({
    byID: data,
    ids: ids,
  })
}
