// This class will let me load Proksee react elements for the help document. They will not have any function.
import React from 'react'
import { LocationBar } from "../proksee-viewer/containers/LocationBar";
import Font from "../proksee-viewer/presenters/Font";
import Color from "../proksee-viewer/presenters/Color";

class ProxyReactDisplay extends React.Component {

  render() {
    const { element } = this.props;
    let renderElement
    if (element === 'LocationBar') {
      // NOTE: location is not used as this element is not contented to a cgv context
      renderElement = <LocationBar location={{zoomFactor: 10, bp: 12345}} / >
    } else if (element === 'Font') {
      renderElement = <Font fontString='monospace, bold, 14' / >
    } else if (element === 'Color') {
      renderElement = <Color colorString='rgba(200, 100, 250, 0.8)' onChange={()=>{}}/ >
    }
    return renderElement;
  }

}
export default ProxyReactDisplay;

