import { RESET_STATE, PLOTS_ADD, PLOTS_REMOVE, PLOTS_UPDATE, PLOTS_UPDATE_STATS } from '../constants/ActionTypes'
import { removeItems } from './ReduxHelpers';

const initialState = {
  byID: {},
  ids: [],
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:        return reset(state, payload);
    case PLOTS_ADD:          return addPlots(state, payload);
    case PLOTS_REMOVE:       return removeItems(state, payload.plots);
    case PLOTS_UPDATE:       return updatePlots(state, payload);
    case PLOTS_UPDATE_STATS: return updatePlotStats(state, payload);
    default:                 return state;
  }
};

const reset = () => { return (initialState) }

const addPlots = (state, { plots }) => {
  // Normalize plot data
  const data = {};
  const ids = [];
  for (var i = 0, len = plots.length; i < len; i++) {
    const plot = plots[i];
    const json = plot.toJSON({includeDefaults: true, excludeData: true});
    json.cgvID = plot.cgvID;
    // json.itemCount = plot.itemCount;
    json.legendPositiveID = plot.legendPositive.cgvID;
    json.legendNegativeID = plot.legendNegative.cgvID;
    json.length = plot.length;
    json.scoreMin = plot.scoreMin;
    json.scoreMax = plot.scoreMax;
    json.scoreMean = plot.scoreMean;
    json.scoreMedian = plot.scoreMedian;
    // json.tracks = plot.tracks().map( t => t.name );
    json.tracks = plot.tracks().map( t => t.name ).join(', ')
    console.log(json.tracks)

    data[json.cgvID] = json;
    ids.push(json.cgvID);
  }

  return ({
    byID: {...state.byID, ...data},
    ids: [...state.ids, ...ids],
  })
}

const updatePlots = (state, { plots, attributes, updates }) => {
  // Normalize Plot Data
  const plotData = {};
  for (var i = 0, len = plots.length; i < len; i++) {
    const plot = plots[i];
    const json = plot.toJSON({includeDefaults: true, excludeData: true});
    json.cgvID = plot.cgvID;
    json.legendPositiveID = plot.legendPositive.cgvID;
    json.legendNegativeID = plot.legendNegative.cgvID;
    json.length = plot.length;
    json.scoreMin = plot.scoreMin;
    json.scoreMax = plot.scoreMax;
    json.scoreMean = plot.scoreMean;
    json.scoreMedian = plot.scoreMedian;
    json.tracks = plot.tracks().map( t => t.name ).join(', ')
    console.log(json.tracks)

    plotData[json.cgvID] = json;
  }

  return ({
    byID: {...state.byID, ...plotData},
    ids: state.ids,
  })
}

// Hopefully this won't be too slow with tons of plots
const updatePlotStats = (state, { cgv }) => {
  const newStateByID = {...state.byID};
  for (const [key, value] of Object.entries(newStateByID)) {
    const plot = cgv.objects(value.cgvID);
    newStateByID[key].tracks = plot.tracks().map( t => t.name ).join(', ')
  }
  return ({
    byID: newStateByID,
    ids: state.ids,
  })
}

