import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ButtonGroup.css';

// Note: this could be changed to InputGroup to more generally group inputs

ButtonGroup.propTypes = {
  className: PropTypes.string,
  children:  PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']), // NOT IMPLEMENTED YET
  separated: PropTypes.bool,
}

ButtonGroup.defaultProps = {
  direction: 'horizontal',
  separated: false,
}

function ButtonGroup(props) {
  const {
    className,
    separated,
    children,
  } = props;

  const elementClass = classNames(
    'ButtonGroup', className,
    {'bg-separated': separated},
  );

  return (
    <div className={elementClass}>
      {children}
    </div>
  )
}

export default ButtonGroup;

