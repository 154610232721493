import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';
import FeaturesByTypeTable from '../../../cards/FeaturesByTypeTable';
// import * as helpers from '../../../support/Helpers';

class ReportCard extends ReportCardBase {

  get extractType() {
    return 'text';
  }

  get featureTypes() {
    const featureTypes = [
      {name: 'CDS', key: 'CDSs'},
      {name: 'tRNA', key: 'tRNAs'},
      {name: 'tmRNA', key: 'tmRNAs'},
      {name: 'rRNA', key: 'rRNAs'},
      {name: 'ncRNA', key: 'ncRNAs'},
      {name: 'ncRNA Regions', key: 'ncRNA regions'},
      {name: 'CRISPR', key: 'CRISPR arrays'},
      {name: 'sORF', key: 'sORFs'},
      {name: 'gap', key: 'gaps'},
      {name: 'oriC', key: 'oriCs'},
      {name: 'oriV', key: 'oriVs'},
      {name: 'oriT', key: 'oriTs'},
    ]
    return featureTypes;
  }

  // Annotation:
  // tRNAs: 0
  // tmRNAs: 0
  // rRNAs: 0
  // ncRNAs: 4
  // ncRNA regions: 1
  // CRISPR arrays: 0
  // CDSs: 108
  // sORFs: 1
  // gaps: 0
  // oriCs: 1
  // oriVs: 0
  // oriTs: 0
  // The following features are not including in the total
  // pseudogenes: 0
  // hypotheticals: 14
  // signal peptides: 0
  processText(text) {
    const data = {};
    for (const type of this.featureTypes) {
      data[type.name] = this.extractCount(text, type.key);
    }
    data.total = Object.values(data).reduce((s, v) => s + (v || 0), 0);

    return data;
  }

  extractCount(text, name) {
    if (!text) return;
    const re = new RegExp(`${name}:\\s*(\\d+)`);
    const match = text.match(re);
    if (match) {
      return Number(match[1]);
    }
  }

  extractText(text, name) {
    if (!text) return;
    const re = new RegExp(`${name}:\\s*(.+)`);
    const match = text.match(re);
    if (match) {
      return match[1];
    }
  }

  // renderBaktaTable(data) {
  //   if (!data) return null;
  //   console.log(data)
  //   const divs = this.featureTypes.map( (type) => {
  //     return (
  //       <div key={type.name} className='feature-count-pair'>
  //         <div className='feature-count-name'>{type.name}:</div>
  //         <div className='feature-count-number'>{helpers.commaNumber(data[type.name])}</div>
  //       </div>
  //     );
  //   });
  //   return (
  //     <table className='p-table more-width'>
  //       <thead>
  //         <tr className='table-title'><th>OLD Features by Type</th></tr>
  //       </thead>
  //       <tbody>
  //         <tr><td><div className='feature-counts'>{divs}</div></td></tr>
  //       </tbody>
  //     </table>
  //   );
  // }

  renderBaktaVersion(text) {
    if (!text) return;
    const version = this.extractText(text, 'Software');
    const db = this.extractText(text, 'Database');

    return (
      <div className='ps-alert feature-counts'>
        <div className='feature-count-pair software-pair'>
          <div className='feature-count-name'>Software:</div>
          <div className='feature-count-text'>{version}</div>
        </div>
        <div className='feature-count-pair software-pair'>
          <div className='feature-count-name'>Database:</div>
          <div className='feature-count-text'>{db}</div>
        </div>
      </div>
    );
  }

  renderData(text) {
    // console.log(text)
    // return this.renderDefaultReport(mapData);
    const data = this.processText(text);
    console.log(data)
    const features = {features: Array(data.total).fill({})}
    return (
      <div className='report-card'>
        { this.renderReportHeader() }
        { this.renderReportFailed() }
        { this.renderBaktaVersion(text) }
        { this.renderMapResultsTable(features) }
        <FeaturesByTypeTable counts={data} onlyTypes={this.featureTypes.map(i => i.name)} />
        {/* { this.renderBaktaTable(data) } */}
        { this.renderFeaturedFilesTable() }
        { this.renderHelpfulLinks() }
        { this.renderCitation() }
      </div>
    );
  }

}

export default ReportCard;

