import { TRACKS_ADD, TRACKS_REMOVE, TRACKS_UPDATE, TRACKS_MOVED, TRACKS_UPDATE_STATS } from '../constants/ActionTypes'

export const addTracks = (tracks) => ({
  type: TRACKS_ADD,
  payload: {
    tracks,
  }
});

export const removeTracks = (tracks) => ({
  type: TRACKS_REMOVE,
  payload: {
    tracks,
  }
});

export const moveTracks = (oldIndex, newIndex) => ({
  type: TRACKS_MOVED,
  payload: {
    oldIndex,
    newIndex,
  }
});

export const updateTracks = (tracks, attributes) => ({
  type: TRACKS_UPDATE,
  payload: {
    tracks,
    attributes,
  }
});

export const updateTrackStats = (cgv) => ({
  type: TRACKS_UPDATE_STATS,
  payload: { 
    cgv,
  }
});

