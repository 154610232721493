import React from 'react';
// import File from './File';
import * as helpers from '../../support/Helpers';
import * as validations from '../../support/Validations';

class FastqFile {

  constructor(file) {
    this._file = file;
  }

  get file() {
    return this._file;
  }

  // Override to prevent reading file 
  skipProcessing() {
    return true;
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'fastq';
  }

  get metaKeys() {
    return [];
  }

  get validationKeys() {
    return ['validMaxSize', 'validExtension'];
  }

  // No text processing done
  processText(text) {
    console.log('SHOULD NOT SEE THIS')
  }

  // No validation done at this time
  validate(rules={}) {
    const validationErrors = [];
    const { validMaxSize, validExtensions } = rules;
    const v = validations;

    if (validMaxSize && this.file.size && this.file.size > validMaxSize) {
      validationErrors.push(`File size is too large (${helpers.formatBytes(this.file.size)}). Max size: ${helpers.formatBytes(validMaxSize)}`);
    }

    const extension = this.file.fileName.split('.').pop();
    if (!v.validateString(extension, validExtensions)) {
      validationErrors.push(`File Extension "${extension}" is not valid. Must be ${helpers.toSentence([validExtensions].flat(), {conjunction: 'or'})}`);
    }

    this.file.validationErrors = validationErrors;
    return {ok: (validationErrors.length === 0), errors: validationErrors};
  }

  static detailsRenderer(details={}, rules={}) {
    // console.log(details)
    const v = validations;
    return (
      <table>
        <tbody>
          <tr><td>File Size: {helpers.formatBytes(details.size)}</td></tr>
        </tbody>
      </table>
    )
  }

}

export default FastqFile;
