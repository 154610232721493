import { BACKBONE_UPDATE } from '../constants/ActionTypes'


export const updateBackbone = (attributes) => ({
  type: BACKBONE_UPDATE,
  payload: {
    attributes,
  }
});


