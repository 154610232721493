import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { Select, Option } from '../presenters/Select';
import TextElement from '../presenters/TextElement';
import DataElementContainer from '../presenters/DataElementContainer';
import { CGViewContext } from '../app/CGViewContext';
import HelpLink from '../presenters/HelpLink'
import './TrackDataSelector.css';
import * as helpers from '../support/Helpers';
import * as CGView from '../../CGView.js';

// Connected
import { connect } from 'react-redux';

class TrackDataSelector extends React.Component {

  static propTypes = {
    cgvID: PropTypes.string,
    types: PropTypes.array,
    methods: PropTypes.array,
    track: PropTypes.object, // Connected through cgvID
  }

  static defaultProps = {
    types: ['features'],
    methods: ['source', 'type', 'tag'],
    onChange: () => {},
  }

  renderMethods() {
    const { track } = this.props;
    const type = (track.dataType === 'plot') ? 'Plot' : 'Features';
    if (track.dataMethod === 'sequence') {
      return <p className='label'>Contains {type} extracted from</p>
    } else {
      //<p className='label'>Contains {type} with the following <HelpLink  helpPath='help:sidebar:display:tracks:contains' /></p>
      //<p className='label'>Contains {type} with the following</p>
      return (
        <div className='label-line'>
          <div className='label'>{type} with the following</div>
          <div className='label-arrow'>→</div>
          <div className='method-select'>
            <Select size='xsmall' value={track.dataMethod}
              onChange={(value) => this.props.onChange({value, attribute: 'dataMethod'})}>
              <Option value="source">Sources</Option>
              <Option value="tag">Tags</Option>
              <Option value="type">Types</Option>
            </Select>
          </div>
        </div>
      )
    }
  }

  // Keys only appear for features
  renderKeys() {
    const { track } = this.props;
    // FIXME: keys for sources, types, tags should come from redux
    let selectedDataKeys = track.dataKeys || [];

    let availableDataKeys = [];
    switch(track.dataMethod) {
      case 'source':
        availableDataKeys = this.context.cgv.sources().filter( s => s !== '');
        break;
      case 'tag':
        availableDataKeys = this.context.cgv.tags().filter( t => t !== '');
        break;
      case 'type':
        availableDataKeys = this.context.cgv.featureTypes().filter( t => t !== '');
        break;
    }

    // console.log(selectedDataKeys)
    let dataKeySelect;
    if (track.dataMethod === 'sequence') {
      dataKeySelect = (
        <TextElement>
          Sequence - {selectedDataKeys.join(', ')}
        </TextElement>
      )
    } else {
      dataKeySelect = (
        <Select mode = 'tags' value={selectedDataKeys}
          onChange={(value) => this.props.onChange({value, attribute: 'dataKeys'})}>
          { availableDataKeys.map( (s) => <Option key={s}>{s}</Option>) }
        </Select>
      )
    }
    return dataKeySelect;
  }

  render() {
    return (
      <div className='TrackDataSelector'>
        <DataElementContainer label='Track Contents' helpPath='help:sidebar:display:tracks:contains'>
          {this.renderMethods()}
          {this.renderKeys()}
        </DataElementContainer>
      </div>
    );
  }

}

TrackDataSelector.contextType = CGViewContext;

// Connected
const trackDataSelectorMapStateToProps = (state, ownProps) => ({ track: state.tracks.byID[ownProps.cgvID] });
const ConnectedTrackDataSelector = connect(trackDataSelectorMapStateToProps)(TrackDataSelector);

export default ConnectedTrackDataSelector;

