import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import './IDBar.css';
// Connected
import { connect } from 'react-redux';

IDBar.propTypes = {
  id: PropTypes.string,
}

IDBar.defaultProps = {
  id: '-',
}

function IDBar({ id }) {
  const context = useContext(CGViewContext);
  const userRole = context.userRole;
  // Only provide project link if user is an admin
  const statsLink = `/projects/${id}/stats`
  const linkedID = userRole === 'admin' ? <a href={statsLink}>{id}</a> : id;
  return (
    <div className='IDBar'>
      <strong>ID:</strong>
      <span className='map-id'>{linkedID}</span>
    </div>
  )
}

//Connected
const idBarMapStateToProps = (state) => ({ id: state.viewer.id });
const ConnectedIDBar = connect(idBarMapStateToProps)(IDBar);

// export default IDBar;
export { IDBar, ConnectedIDBar };

