// Ditching Cookie Banners:
// https://www.koalati.com/blog/cookie-banner

document.addEventListener('DOMContentLoaded', () => {

  storage = window.localStorage;

  // Sets global 'allowCookies' variable
  // allowCookies = storage.getItem('allow_cookies');

  // Default is to allow cookies
  if (storage.getItem('allow_cookies') === null) {
    storage.setItem('allow_cookies', 'true');
  }

  // Cookie Banner
  cookieBannerSeen = storage.getItem('cookie_banner_seen');
  if (!cookieBannerSeen) {
    const banner = document.getElementById('cookie-banner');
    if (banner) {
      banner.classList.add('show');

      closeBtn = document.getElementsByClassName('banner-close')[0];
      closeBtn.addEventListener('click', () => {
        banner.classList.remove('show');
        storage.setItem('cookie_banner_seen', true);
      });

      manageBtn = document.getElementsByClassName('banner-manage')[0];
      manageBtn.addEventListener('click', () => {
        banner.classList.remove('show');
        storage.setItem('cookie_banner_seen', true);
        window.location.pathname = '/privacy';
      });
    }
  }

});
