import React from 'react';
import './SettingsPane.css';
import { ConnectedGeneralSettings } from './settings/GeneralSettings';
import { ConnectedAnnotationSettings } from './settings/AnnotationSettings';
import { ConnectedRulerSettings } from './settings/RulerSettings';
import { ConnectedBackboneSettings } from './settings/BackboneSettings';
import { ConnectedDividerSettings } from './settings/DividerSettings';
import { ConnectedSequenceSettings } from './settings/SequenceSettings';
import { ConnectedCenterLineSettings } from './settings/CenterLineSettings';

class SettingsPane extends React.Component {

  render() {
    return (
      <div className='SettingsPane'>
        <ConnectedGeneralSettings />
        <ConnectedAnnotationSettings />
        <ConnectedRulerSettings />
        <ConnectedCenterLineSettings />
        <ConnectedBackboneSettings />
        <ConnectedDividerSettings />
        <ConnectedSequenceSettings />
      </div>
    );
  }
}

// export default SettingsPane;
export { SettingsPane };

