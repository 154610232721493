import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';
import classNames from 'classnames';
import PieChart from '../../../charts/PieChart';
import * as helpers from '../../../support/Helpers';
import * as d3 from 'd3';
// import './ReportCard.css';

class ReportCard extends ReportCardBase {

  constructor(props) {
    super(props);

    this.majorCats = ['integration/excision', 'replication/recombination/repair', 'phage', 'stability/transfer/defense', 'transfer'];
    this.majorCatsShort = {
      'integration/excision': 'IE',
      'replication/recombination/repair': 'RRR',
      'phage': 'P',
      'stability/transfer/defense': 'STD',
      'transfer': 'T',
    };
  }

  get extractType() {
    return 'csv';
  }

  processCSV(csv) {

    // JRG - lets return empty features for now
    // if (!csv) return;
    if (!csv) {
      return {features: [], majorCatCounts: []}
    }


    // See columns at the bottom of this page
    let processedQuery;
    // Hold the top hit for each query with important stats
    const features = [];
    for (const row of csv) {
      const query = row[2];
      // Skip header row and rows with processed queryies
      // There can be mulitple hits for each query but we only take the top/first one
      if ((query === 'Query Title') || (query === processedQuery)) {
        continue;
      }
      processedQuery = query;
      features.push({
        majorCategory: row[15],
        minorCategory: row[16],
        sourceDB: row[17],
        evidence: row[18],
        contig: row[32],
      })
    }

    const majorCatCounts = this.majorCats.map((cat) => {
      const count = features.filter( f => f.majorCategory === cat).length;
      return {name: this.majorCatsShort[cat], value: count, fullName: cat};
    }).filter((d) => d.value > 0);

    return {features, majorCatCounts}
  }

  renderTableMajorCatogories() {
    if (!this.data) return null;
    const features = this.data.features;
    const majorCatCounts = this.data.majorCatCounts;
    const comma = helpers.commaNumber;

    if (features.length === 0) return;

    return (
      <table className='proksee-stats striped '>
        <thead>
          <tr className='table-title'><th colSpan='3'>Features by Major Category</th></tr>
        </thead>
        <tbody>
          <tr><td colSpan='3'>
            <div style={{width: '100%', height: '150px', margin: '10px'}}>
              <PieChart data={majorCatCounts} innerRadius='40' />
            </div>
          </td></tr>
          { majorCatCounts.map( c => <tr key={c.name}><td className='left'>{c.name}</td><td className='left'>{c.fullName}</td><td><strong>{comma(c.value)}</strong></td></tr> ) }
          <tr style={{fontStyle: 'italic'}}><td colSpan='2'>Total</td><td><strong>{features.length}</strong></td></tr>
        </tbody>
      </table>
    );
  }

  renderData(csv) {
    // console.log(csv)
    this.data = this.processCSV(csv);
    return (
      <div className='report-card'>
        { this.renderReportHeader() }
        { this.renderReportFailed() }
        { this.renderMapResultsTable(this.data) }
        { this.renderTableMajorCatogories() }
        { this.renderFeaturedFilesTable() }
        { this.renderHelpfulLinks() }
        { this.renderCitation() }
      </div>
    );
  }

}

export default ReportCard;

// COLUMNS:
// 0  [NO TITLE - count]
// 1  Sequence Title
// 2  Query Title
// 3  Pident
// 4  Bitscore
// 5  Subject Sequence Length
// 6  e-value
// 7  Query Sequence Length
// 8  Start of Alignment in Subject
// 9  End of Alignment in Query
// 10 Start of Alignment in Query
// 11 End of Alignment in Query
// 12 mobileOG ID
// 13 Gene Name
// 14 Best Hit Accession ID
// 15 Major mobileOG Category
// 16 Minor mobileOG Category
// 17 Source Database
// 18 Evidence Type
// 19 Contig/ORF Name
// 20 ORF_Start_Stop_Strands
// 21 ORF_Start
// 22 ORF_End
// 23 Sense or Antisense Strand
// 24 Prodigal ID
// 25 Prodigal Designated Contigs
// 26 Unique_ORF
// 27 Partial Tag
// 28 Start Codon
// 29 RBS Motif
// 30 RBS Spacer
// 31 GC Content
// 32 Specific Contig
// 33 Final Sample Name
//
    // const majorCatMap = {
    //   'integration/excision': {display: 'Integration/Excision', short: 'IE'},
    //   'replication/recombination/repair': {display: 'Replication/Recombination/Repair', short: 'RRR'},
    //   'phage': {display: 'Phage', short: 'P'},
    //   'stability/transfer/defense': {display: 'Stability/Transfer/Defense', short: 'STD'},
    //   'transfer': {display: 'Transfer', short: 'T'},
    // };
