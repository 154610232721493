import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './TextInput.css';

// TODO:
// - Add clear icon option (e.g. allowClear) that clears all input from element
class TextInput extends React.Component {

  static propTypes = {
    type:        PropTypes.oneOf(['text', 'textarea']),
    value:       PropTypes.string,
    rows:        PropTypes.number,
    onChange:    PropTypes.func,
    placeHolder: PropTypes.string,
    className:   PropTypes.string,
    // This value will cause an initial call to onChange in the constructor
    // This is required when the initial value will need to be sent to onChange
    // such as in DefaultDialogs.
    callInitialOnChange: PropTypes.bool,
  }

  static defaultProps = {
    type: 'text',
    rows: 1,
    // value: ''
  }

  constructor(props) {
    super(props);
    const value = this.props.value || this.props.defaultValue || '';
    const rows = this.rowsFor(value);
    this.state = { value, rows };
    // TODO: change height of control based on rows
    // this.ref = React.createRef();
    this.onChange = this.onChange.bind(this);
    // FIXME: this should come from (--app-grid-dimension)
    this.rowHeight = 24;
    if (props.callInitialOnChange) {
      props.onChange && props.onChange(value);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {value: nextProps.value}
  }

  get height() {
    return this.rowHeight * this.state.rows;
  }

  rowsFor(value) {
    return value.split('\n').length;
  }

  onChange(e) {
    const {onChange} = this.props;
    const value = e.target.value;
    const rows = this.rowsFor(value);
    this.setState({value, rows});
    onChange && onChange(value);
  }

  render() {
    const {
      placeholder,
      className,
      type,
      rows,
    } = this.props;

    const elementClass = classNames( 'TextInput', className);

    const {inputRef, callInitialOnChange, ...remainingProps} = this.props;

        // ref={this.ref}
    if (type === 'text') {
          // {...this.props}
      return (
        <input
          {...remainingProps}
          ref={inputRef}
          className={elementClass}
          onChange={this.onChange}
          placeholder={placeholder}
          value={this.state.value}
        />
      );
    } else if (type === 'textarea') {
      return (
        <textarea
          {...remainingProps}
          ref={inputRef}
          style={{height: this.height}}
          rows={rows}
          className={elementClass}
          onChange={this.onChange}
          placeholder={placeholder}
          value={this.state.value}
        />
      );
    }
  }
}

export default TextInput;

