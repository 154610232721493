import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Option  } from './Select';
import { Tag } from 'antd';
// import searchCancel from '../images/search-cancel.png';
import classNames from 'classnames';
import './DragTagSelect.css';


DragTagSelect.propTypes = {
  // disabled: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func,
}

DragTagSelect.defaultProps = {
  // disabled: false,
  // size: 'small',
}

// Note that the default size 'small' is used directly by antd and has a height of 24px
// This happens to be our default grid size so it works out. But we may need to take more control of the dimension later.
function DragTagSelect(props) {
  const {
    children,
    value,
    onChange,
    // disabled=false,
  } = props;


  const defaults = {
    // style: {width: '100%'},
    // size: 'small',
    // dropdownMatchSelectWidth: false,
  }

  function handleDragStart(event) {
    // console.log("move", event.target.dataset.index)
    event.dataTransfer.setData('text/plain', event.target.dataset.index);
    event.dataTransfer.dropEffect = 'move';
    // This is added but the cursor does not change to grabbing. Shrug?
    event.target.classList.add('dragging');
  }

  function handleDragEnd(event) {
    console.log("END")
    handleDragLeaveSelect(event);
    event.target.classList.remove('dragging');
  }

  function handleDragLeaveSelect(event) {
    const container = event.target.closest('.ant-select-selector');
    const items = Array.from(container.getElementsByClassName('drag-tag'));
    items.forEach( item => {
        item.classList.remove('highlight-left');
        item.classList.remove('highlight-right');
        item.classList.remove('drag-over');
    });
  }

  function handleDragDrop(event) {
    event.preventDefault();
    const tagName = event.dataTransfer.getData('text/plain');
    let dropIndex = getDropIndex(event);

    const container = event.target.closest('.ant-select-selector');
    const items = Array.from(container.getElementsByClassName('drag-tag')).map( item => item.dataset.index);
    const tagIndex = items.findIndex( item => item === tagName);

    if (tagIndex > dropIndex) {
      dropIndex += 1;
    }
    // console.log("DROP", tagIndex, dropIndex)

    const item = items.splice(tagIndex, 1)[0];
    items.splice(dropIndex, 0, item);

    onChange(items)
  }

  function getDropIndex(event) {
    const container = event.target.closest('.ant-select-selector');
    if (!container) { return; }

    // Get the position where the item is dragged over
    const dropX = event.clientX;
    const dropY = event.clientY;

    const items = Array.from(container.getElementsByClassName('drag-tag'));
    let dropIndex;
    let lastRowItemIndex = 0;
    for (const [index, item] of items.entries()) {
      const rect = item.getBoundingClientRect();
      if (dropY > rect.bottom) {
        lastRowItemIndex = index;
        continue
      } else if (dropY <= rect.bottom && dropY >= rect.top) {
        if (dropX < rect.x + rect.width / 2) {
          dropIndex = index - 1;
          break;
        } else {
          lastRowItemIndex = index;
        }
      }
    };
    if (dropIndex === undefined) {
      dropIndex = lastRowItemIndex
    }
    return dropIndex;
  }

  function handleDragOverSelect(event) {
    event.preventDefault();
    // event.persist();
    // console.log(event);
    event.dataTransfer.dropEffect = 'move';

    const container = event.target.closest('.ant-select-selector');
    if (!container) { return; }
    const items = Array.from(container.getElementsByClassName('drag-tag'));

    const dropIndex = getDropIndex(event);
    console.log("DROP", dropIndex)

    items.forEach( item => {
        item.classList.remove('highlight-left');
        item.classList.remove('highlight-right');
        item.classList.remove('drag-over');
    });
    if (dropIndex < 0) {
      items[0].classList.add('drag-over');
      items[0].classList.add('highlight-left');
    } else {
      items[dropIndex].classList.add('drag-over');
      items[dropIndex].classList.add('highlight-right');
    }

  }

  function onCloseTag(e) {
      e.preventDefault();
      e.stopPropagation();
      e.persist()
      console.log(e)
      const item = e.target.closest('.drag-tag');
      const newValue = value.filter( v => v !== item?.dataset?.index);
      onChange(newValue);
  }

  const klass = classNames('DragTagSelect', props.className);

  return (
    <Select {...defaults}
      allowClear
      onDragOver={handleDragOverSelect}
      onDragLeave={handleDragLeaveSelect}
      onDrop={handleDragDrop}
      // clearIcon={
      //   <div className='cancel-search'>
      //     <img src={searchCancel} alt='cancel' />
      //   </div>
      // }
      mode='tags'
      tagRender={(props) => {
        // console.log(props) // {value, label, closable, onClose, disabled}
        return (
          <Tag draggable className='drag-tag' data-index={props.value}
            closable
            onClose={onCloseTag}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onMouseDown={e => {e.stopPropagation();}}
          >{props.value}</Tag>
        )
      }}
      {...props}
      className={klass}
    >
      { children }
    </Select>
  )
}

export { DragTagSelect };
