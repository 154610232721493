import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageButton from '../presenters/ImageButton';
import iconStarFull from '../images/icon-star-full.png';
import iconStarEmpty from '../images/icon-star-empty.png';

FavoriteButton.propTypes = {
  className:   PropTypes.string,
  onClick:     PropTypes.func,
  favorite:    PropTypes.bool,
  size:        PropTypes.oneOf(['default', 'small']),
  type:        PropTypes.oneOf(['plain', 'button']),
}

FavoriteButton.defaultProps = {
  onClick:  () => {},
  favorite: false,
  type:     'button',
  size:     'default',
}

function FavoriteButton(props) {
  const {
    className,
    onClick,
    favorite,
    type,
    size,
  } = props;

  const favoriteClass = classNames(
    'FavoriteButton', className
  );

  const favoritePath = favorite ? iconStarFull : iconStarEmpty;

  return (
    <ImageButton
      {...props}
      className={favoriteClass}
      onClick={ () => onClick(!favorite) }
      image={favoritePath}
      title='Favorite'
      type={type}
      size={size}
    / >
  )
      // onClick={(e) => {e.stopPropagation();clickHandler();}}
}

export default FavoriteButton;

