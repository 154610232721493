// https://medium.com/geckoboard-under-the-hood/how-we-made-our-product-more-personalized-with-css-variables-and-react-b29298fde608
// https://www.npmjs.com/package/react-custom-properties
// Use this site for converting black to a color with css filter
// https://codepen.io/sosuke/pen/Pjoqqp
const themes = {
  light: {
    // Main
    // '--theme-navbar-color': '#657E93',
    '--theme-color-dark': 'black',
    '--theme-color-light': 'white',
    '--theme-color-red': '#F08080', // LightCoral
    '--theme-color-green': 'limegreen',
    '--theme-navbar-color': '#292b2c',
    '--theme-primary-color': 'blue',
    '--theme-text-color': '#222',
    '--theme-text-color-muted': '#868E96',
    '--theme-link-color': '#007bff',
    '--theme-background-color': 'white',
    '--theme-background-color-transparent': 'rgba(255, 255, 255, 0)',
    '--theme-border-color': '#B4B4B4',
    '--theme-border-primary': '1px solid var(--theme-border-color)',
    '--theme-border-bold': '',
    '--theme-border-color-faint': '#E4E4E4',
    '--theme-border-faint': '1px solid var(--theme-border-color-faint)',
    // '--theme-highlight-color': '#FFB',
    '--theme-highlight-color': '#EFEFF1',
    '--theme-text-element-color': '#F3F3F3',

    // Sidebar
    // This should be some sort of faint highlight color. Could be used for tabs as well.
    // '--theme-sidebar-background': '#F3F3F3',
    // '--theme-sidebar-background': 'white',
    '--theme-sidebar-background': '#E9FAFA',
    '--theme-list-header-color': '#C9DFEC',
    '--theme-list-subheader-color': '#F1F1F5',
    // '--theme-list-item-color': '#E9FAFA',
    '--theme-list-item-color': 'white',

    // Search
    '--theme-search-red': '#FF8E8E',
    '--theme-search-green': '#8EFF8E',

    // Buttons
    '--theme-button-background': 'var(--theme-background-color)',
    '--theme-button-active': 'invert(0%)',
    '--theme-button-disabled': 'invert(80%)',
    '--theme-button-default': 'invert(40%)',

    // Interactions
    '--theme-hover-color': '#40A9FF', // Color from Ant
    '--theme-focus-shadow': '0 0 0 2px rgba(24, 144, 255, 0.3)',
    // TESTING
    // '--theme-hover-color': 'yellow',
    // '--theme-focus-shadow': '0 0 0 2px rgba(24, 144, 255, 1)',

    // Button Pulse (used in save button)
    // '--theme-pulse-1': '0 0 0 0 rgba(255, 255, 255, 1)',
    '--theme-pulse-1': '0 0 0 0 rgba(0, 0, 0, 1)',
    '--theme-pulse-2': '0 0 0 0 rgba(0, 0, 0, 0.7)',
    '--theme-pulse-3': '0 0 0 10px rgba(0, 0, 0, 0)',
    '--theme-pulse-4': '0 0 0 0 rgba(0, 0, 0, 0)',
    // Other
    // '--theme-box-shadow': '1px 1px 4px darkgrey'
    '--theme-box-shadow': '0 0 0 1px rgba(16,22,26,.1),0 2px 4px rgba(16,22,26,.2),0 8px 24px rgba(16,22,26,.2)'
    // '--theme-box-shadow-narrow': '0 0 0 1px rgba(16,22,26,.1),0 2px 4px rgba(16,22,26,.2),0 2px 6px rgba(16,22,26,.2)'
  },
  dark: {
    // Check out some dark apps: Affinity designer, Things, iTunes, etc
    // Main
    // '--theme-navbar-color': '#292b2c',
    '--theme-color-dark': 'white',
    '--theme-color-light': 'black',
    // '--theme-color-red': '#B22222', // Firebrick
    '--theme-color-red': 'red',
    '--theme-color-green': 'green',
    '--theme-navbar-color': '#222324',
    '--theme-primary-color': 'blue',
    '--theme-text-color': '#F1F1F1',
    '--theme-text-color-muted': '#868E96',
    '--theme-link-color': '#ff7b00',
    // '--theme-link-color': '#007bff',
    '--theme-background-color': '#333', // rgb(20%,20%,20%)
    // '--theme-background-color': 'rgba(26,32,44,1)', // Plasuible
    '--theme-background-color-transparent': 'rgba(20%, 20%, 20%, 0)',
    '--theme-border-color': '#848484',
    '--theme-border-primary': '1px solid var(--theme-border-color)',
    '--theme-border-bold' : '',
    '--theme-border-color-faint': '#646464',
    '--theme-border-faint': '1px solid var(--theme-border-color-faint)',
    // '--theme-highlight-color': '#551',
    '--theme-highlight-color': '#446',
    '--theme-text-element-color': '#3d3d3d',

    // Sidebar
    // This should be some sorto of faint highlight color. Could be used for tabs as well.
    '--theme-sidebar-background': '#404040',
    '--theme-list-header-color': '#091F2C',
    '--theme-list-subheader-color': '#29292F',
    '--theme-list-item-color': '#293A3A',

    // Search
    '--theme-search-red': '#FF0000',
    '--theme-search-green': '#009500',

    // Buttons
    '--theme-button-background': 'var(--theme-background-color)',
    '--theme-button-active': 'invert(90%)',
    '--theme-button-disabled': 'invert(35%)',
    '--theme-button-default': 'invert(60%)',

    // Interactions
    '--theme-hover-color': '#40A9FF', // Color from Ant
    '--theme-focus-shadow': '0 0 0 2px rgba(24, 144, 255, 0.3)',

    // Button Pulse (used in save button)
    '--theme-pulse-1': '0 0 0 0 rgba(255, 255, 255, 1)',
    '--theme-pulse-2': '0 0 0 0 rgba(255, 255, 255, 0.7)',
    '--theme-pulse-3': '0 0 0 10px rgba(255, 255, 255, 0)',
    '--theme-pulse-4': '0 0 0 0 rgba(255, 255, 255, 0)',

    // Other
    // '--theme-box-shadow': '1px 1px 4px black'
    '--theme-box-shadow': '0 0 0 1px rgba(16,22,26,.4),0 2px 4px rgba(16,22,26,.8),0 8px 24px rgba(16,22,26,.8)'
    // '--theme-box-shadow-narrow': '0 0 0 1px rgba(16,22,26,.2),0 2px 4px rgba(16,22,26,.4),0 2px 6px rgba(16,22,26,.8)'
  }
}

export default themes;

/* :root { */
  /* --theme-primary-color: blue; */
  /* --theme-text-color: white; */
  /* --theme-background-color: white; */
  /*  */
  /* --theme-button-background: var(--theme-background-color); */
  /* --theme-button-active: invert(0%); */
  /* --theme-button-disabled: invert(80%); */
  /* --theme-button-default: invert(40%); */
  /* --theme-button-background: rgb(50,50,50); */
  /* --theme-button-active: invert(100%); */
  /* --theme-button-disabled: invert(20%); */
  /* --theme-button-default: invert(60%); */
/* } */

/* Ant Design */
/* https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less */
/* @primary-color: #1890ff;                         // primary color for all components */
/* @link-color: #1890ff;                            // link color */
/* @success-color: #52c41a;                         // success state color */
/* @warning-color: #faad14;                         // warning state color */
/* @error-color: #f5222d;                           // error state color */
/* @font-size-base: 14px;                           // major text font size */
/* @heading-color: rgba(0, 0, 0, .85);              // heading text color */
/* @text-color: rgba(0, 0, 0, .65);                 // major text color */
/* @text-color-secondary : rgba(0, 0, 0, .45);      // secondary text color */
/* @disabled-color : rgba(0, 0, 0, .25);            // disable state color */
/* @border-radius-base: 4px;                        // major border radius */
/* @border-color-base: #d9d9d9;                     // major border color */
/* @box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);  // major shadow for layers */
