import React from 'react';
import ReportCardBase from '../../../cards/ReportCardBase';
import ImageButton from '../../../presenters/ImageButton';
import ServerAPI from '../../../models/ServerAPI';
import FeaturesByTypeTable from '../../../cards/FeaturesByTypeTable';
// import * as helpers from '../../../support/Helpers';

class ReportCard extends ReportCardBase {

  getLog() {
    const { log } = this.state;
    const { job } = this.props;
    if (job && !log) {
      const Server = new ServerAPI();
      Server.get(Server.jobLog(job))
      .then( response => {
        if (response && response.ok) {
          const data = response.json;
          const downloadedLog = data?.log ? data.log : 'NO LOG';
          this.setState({log: downloadedLog});
        }
      });
    }
  }

  // renderFeatureTable(data) {
  //   const features = data?.cgview?.features || [];
  //   // console.log(features)
  //   if (!features.length) return null;

  //   const typeCounts = features.reduce((acc, feature) => {
  //     acc[feature.type] = (acc[feature.type] || 0) + 1;
  //     return acc;
  //   }, {});

  //   // console.log(typeCounts)
  //   const divs = Object.keys(typeCounts).map( (type) => {
  //     return (
  //       <div key={type} className='feature-count-pair'>
  //         <div className='feature-count-name'>{type}:</div>
  //         <div className='feature-count-number'>{helpers.commaNumber(typeCounts[type])}</div>
  //       </div>
  //     );
  //   });
  //   return (
  //     <table className='p-table more-width'>
  //       <thead>
  //         <tr className='table-title'><th>Features by Type</th></tr>
  //       </thead>
  //       <tbody>
  //         <tr><td><div className='feature-counts'>{divs}</div></td></tr>
  //       </tbody>
  //     </table>
  //   );
  // }

  renderLogIssues() {
    const { log } = this.state;
    if (!log) return null;
    const lines = log.split('\n');
    const warnings = lines.filter(line => line.startsWith('⚠️') && !line.includes("Status:"));
    if (!warnings.length) return null;

    const warningsHeader = (
      <div className='ps-space-between'>
        <div>Log Warnings</div>
        <ImageButton text='Log' imageName='log' width={70} className='warnings-log-btn'
          onClick={() => {this.openLog()}}
        />
      </div>
    );

    return (
      <div className='ps-card report'>
        <div className='ps-card-header report'>{warningsHeader}</div>
        <div className='ps-card-body report'>
          <div className='report-log-warnings'><pre className='scroll-skinny'>{warnings.join("\n")}</pre></div>
        </div>
      </div>
    );
  }

  renderData(mapData) {
    this.getLog();
    // False is passed through so that so map data table is shown
    // return this.renderDefaultReport(false);
    const msg = "Map Builder takes your provided genome sequence and generates the JSON used by CGView.js to view your map."
    const features = mapData?.cgview?.features || [];
    return (
      <div className='report-card'>
        { this.renderReportHeader("Map Builder Report") }
        { this.renderAlert(msg) }
        { this.renderReportFailed() }
        {/* { this.renderFeatureTable(mapData) } */}
        <FeaturesByTypeTable features={features} />
        { this.renderLogIssues() }
        { this.renderFeaturedFilesTable() }
        { this.renderHelpfulLinks() }
      </div>
    );
  }

}

export default ReportCard;

