import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';

class DialogStart extends DefaultDialog {

  // Check if contigs names are short enough
  componentDidMount() {
    const contigCount = this.cgv.contigs().length;
    const length = this.cgv.sequence.length;
    const maxContigCount = 10;
    const maxLength = 50000;
    if (maxContigCount >= contigCount && maxLength >= length) {
      this.onValidate(true)
    }
    if (contigCount > 1) {
      this.onChange({attribute: 'linear', value: true})
    }
  }

  renderContents() {
    const { tool } = this.props;
    const { valid } = this.state;
    let linearProps = {};
    const hasMultipleContigs = this.cgv.contigs().length > 1;
    if (hasMultipleContigs) {
      linearProps = {
        disabled: true,
        defaultValue: true,
        help: "Genomes with multiple contigs have to be treated as linear.",
      };
    }

    return (
      <div>

        {this.renderHeader()}

        {this.renderInput('jobName')}
        {this.renderInput('reference')}
        {this.renderInput('genetic_code')}
        {this.renderInput('linear', linearProps)}
        {this.renderInput('keep')}
        {/* {this.renderInputsForTarget('DialogStart')} */}

        {valid || this.renderStop('Sequence must less than 50 kbp and have 10 or less contigs.')}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

