import store from './store';
import { resetState, loadJSON } from '../actions/viewer';
import { changeLocation } from '../actions/location';
import { addContigs, removeContigs, updateContigs, moveContigs } from '../actions/contigs';
import { addTracks, removeTracks, updateTracks, moveTracks, updateTrackStats } from '../actions/tracks';
import { addCaptions, removeCaptions, updateCaptions, moveCaptions } from '../actions/captions';
import { addFeatures, removeFeatures, updateFeatures, updateFeatureStats } from '../actions/features';
import { addPlots, removePlots, updatePlots, updatePlotStats } from '../actions/plots';
import { addBookmarks, removeBookmarks, updateBookmarks } from '../actions/bookmarks';
import { updateLegend, addLegendItems, removeLegendItems, updateLegendItems, moveLegendItems } from '../actions/legend';
import { updateAnnotation } from '../actions/annotation';
import { updateRuler } from '../actions/ruler';
import { updateCenterLine } from '../actions/centerLine';
import { updateBackbone } from '../actions/backbone';
import { updateDivider } from '../actions/divider';
import { updateSequence } from '../actions/sequence';
// import { changeFormat } from '../actions/format';
import { updateSettings, updateViewer } from '../actions/viewer';
import Toast from '../presenters/Toast';
import * as helpers from '../support/Helpers';

// This file contains all the CGView event handlers.
// Each handler dispatches the events and data to keep the Redux store in sync.
const initializedCGViewDispatchEvents = (cgv, sidebar) => {
  // console.log(cgv)
  // console.log(sidebar)

  const typeToPaneMap = {
    feature:    'features',
    label:      'features',
    contig:     'contigs',
    legendItem: 'legend',
    caption:    'captions',
  }
  cgv.on('click.proksee', (e) => {
    const clickableElements = Object.keys(typeToPaneMap);
    const elementsWithSearch = ['feature', 'label', 'contig'];
    const element = e.element;
    const elementType = e.elementType;
    if (clickableElements.includes(elementType)) {
      const pane = sidebar.selectPane(typeToPaneMap[elementType]);
			const cgvID = (elementType === 'label') ? element.feature.cgvID : element.cgvID;
      if (elementsWithSearch.includes(elementType)) {
        pane.searchWithString(`=${cgvID}:cgvID`);
      }
      pane.openInfoFor(cgvID);
    }
  });

  cgv.on('mousemove.proksee', (e) => {
    // const elements = ['caption', 'legendItem', 'label'];
    const elements = ['caption', 'legendItem'];
    if (elements.includes(e.elementType)) {
      e.element.highlight();
    }
    if (e.elementType === 'label') {
      const label = e.element;
      label.feature.highlight();
    }
    if (e.elementType === 'feature') {
      // Check if feature pane is active
      // if so send highlight signal
      // const feature = e.element;
      // console.log(feature.name)
      // const featurePane = sidebar.selectPane('features');
      // featurePane.searchWithString(`=${feature.cgvID}:cgvID`);
    }
  });

  cgv.on('cgv-json-load.proksee', (json) => {
    store.dispatch(resetState());
    store.dispatch(loadJSON(json));
  });

  cgv.on('zoom.proksee', () => {
    const bp = helpers.constrain(cgv.canvas.bpForCanvasCenter(), 1, cgv.sequence.length);
    store.dispatch(changeLocation(bp, cgv.zoomFactor));
  });

  cgv.on('settings-update.proksee', ({ attributes }) => {
    console.log('Updating Settings: ' + Object.keys(attributes).length);
    store.dispatch(updateSettings(attributes));
    if (attributes.format) {
      // FIXME: Should this instead be from a new update from layout/move/traslate.
      const bp = helpers.constrain(cgv.canvas.bpForCanvasCenter(), 1, cgv.sequence.length);
      store.dispatch(changeLocation(bp, cgv.zoomFactor));
    }
  });

  cgv.on('viewer-update.proksee', ({ attributes }) => {
    console.log('Updating Viewer: ' + Object.keys(attributes).length);
    // console.log(attributes);
    store.dispatch(updateViewer(attributes));
  });


  ////////////// CONTIGS ////////////////////
  cgv.on('contigs-add.proksee', (contigs) => {
    console.log('Adding Contigs: ' + contigs.length);
    store.dispatch(addContigs(contigs));
  });

  cgv.on('contigs-remove.proksee', (contigs) => {
    console.log('Removing Contigs: ' + contigs.length);
    store.dispatch(removeContigs(contigs));
  });

  cgv.on('contigs-update.proksee', ({ contigs, attributes }) => {
    console.log('Updating Contigs: ' + contigs.length);
    store.dispatch(updateContigs(contigs, attributes));
  });

  cgv.on('contigs-moved.proksee', ({ oldIndex, newIndex }) => {
    console.log(`Moving Contigs: ${oldIndex} -> ${newIndex}`);
    store.dispatch(moveContigs(oldIndex, newIndex));
  });


  ////////////// TRACKS ////////////////////
  cgv.on('tracks-add.proksee', (tracks) => {
    console.log('Adding Tracks: ' + tracks.length);
    store.dispatch(addTracks(tracks));
    store.dispatch(updateFeatureStats(cgv));
    store.dispatch(updatePlotStats(cgv));
  });

  cgv.on('tracks-remove.proksee', (tracks) => {
    console.log('Removing Tracks: ' + tracks.length);
    store.dispatch(removeTracks(tracks));
    store.dispatch(updateFeatureStats(cgv));
    store.dispatch(updatePlotStats(cgv));
  });

  cgv.on('tracks-update.proksee', ({ tracks, attributes }) => {
    console.log('Updating Tracks: ' + tracks.length);
    // console.log(attributes);
    store.dispatch(updateTracks(tracks, attributes));
    const keys = attributes && Object.keys(attributes) || [];
    // Do not update stats everytime the progress is updated
    if (!(keys.length === 1 && keys[0] == 'loadProgress')) {
      store.dispatch(updateFeatureStats(cgv));
      store.dispatch(updatePlotStats(cgv));
      // console.log('UPDATING STATS')
    }
  });

  cgv.on('tracks-moved.proksee', ({ oldIndex, newIndex }) => {
    console.log(`Moving Tracks: ${oldIndex} -> ${newIndex}`);
    store.dispatch(moveTracks(oldIndex, newIndex));
  });


  ////////////// CAPTIONS ////////////////////
  cgv.on('captions-add.proksee', (captions) => {
    console.log('Adding captions: ' + captions.length);
    store.dispatch(addCaptions(captions));
  });

  cgv.on('captions-remove.proksee', (captions) => {
    console.log('Removing Captions: ' + captions.length);
    store.dispatch(removeCaptions(captions));
  });

  cgv.on('captions-update.proksee', ({ captions, attributes }) => {
    console.log('Updating captions: ' + captions.length);
    store.dispatch(updateCaptions(captions, attributes));
  });

  cgv.on('captions-moved.proksee', ({ oldIndex, newIndex }) => {
    console.log(`Moving captions: ${oldIndex} -> ${newIndex}`);
    store.dispatch(moveCaptions(oldIndex, newIndex));
  });


  ////////////// FEATURES ////////////////////
  cgv.on('features-add.proksee', (features) => {
    console.log('Adding Features: ' + features.length);
    store.dispatch(addFeatures(features));
    store.dispatch(updateTrackStats(cgv));

    // const contigs = features.map( f => f.contig ).unique();
    // FIXME: This causes a loop for ORFS
    // cgv.sequence.updateContigs(contigs)
  });

  cgv.on('features-update.proksee', ({ features, attributes, updates }) => {
    console.log('Updating Features: ' + features.length);
    store.dispatch(updateFeatures(features, attributes, updates));
    store.dispatch(updateTrackStats(cgv));

    // FIXME: this should only update contigs if the contig changed
    // General method to take records, attributes, updates, keyAttributes?? Array
    // Return new CGArray of records where the keyAttibutes changed
    // const featuresWithContigUpdates = cgv.recordsWithChangedAttributes('contig', features, attributes, updates);
    // const contigs = featuresWithContigUpdates.map( f => f.contig ).unique();
    // if (contigs.length > 0) {
    //   cgv.sequence.updateContigs(contigs)
    // }
  });

  cgv.on('features-remove.proksee', (features) => {
    console.log('Removing Features: ' + features.length);
    store.dispatch(removeFeatures(features));
    store.dispatch(updateTrackStats(cgv));
  });

  ////////////// PLOTS ////////////////////
  cgv.on('plots-add.proksee', (plots) => {
    console.log('Adding Plots: ' + plots.length);
    store.dispatch(addPlots(plots));
    store.dispatch(updateTrackStats(cgv));
  });

  cgv.on('plots-update.proksee', ({ plots, attributes, updates }) => {
    console.log('Updating Plots: ' + plots.length);
    store.dispatch(updatePlots(plots, attributes, updates));
    store.dispatch(updateTrackStats(cgv));
  });

  cgv.on('plots-remove.proksee', (plots) => {
    console.log('Removing Plots: ' + plots.length);
    store.dispatch(removePlots(plots));
    store.dispatch(updateTrackStats(cgv));
  });

  ////////////// BOOKMARKS ////////////////////
  cgv.on('bookmarks-add.proksee', (bookmarks) => {
    console.log('Adding Bookmarks: ' + bookmarks.length);
    store.dispatch(addBookmarks(bookmarks));
  });

  cgv.on('bookmarks-remove.proksee', (bookmarks) => {
    console.log('Removing Bookmarks: ' + bookmarks.length);
    store.dispatch(removeBookmarks(bookmarks));
  });

  cgv.on('bookmarks-update.proksee', ({ bookmarks, attributes }) => {
    console.log('Updating Bookmarks: ' + bookmarks.length);
    store.dispatch(updateBookmarks(bookmarks, attributes));
  });

  cgv.on('bookmarks-shortcut.proksee', (bookmark) => {
    Toast.create('Bookmark: ' + bookmark.name, 1000)
  });


  ////////////// LEGEND ////////////////////
  // cgv.on('legend-add.proksee', (legend) => {
  //   console.log('Adding Legend');
  //   store.dispatch(addLegend(legend));
  // });

  cgv.on('legend-update.proksee', ({ attributes }) => {
    console.log('Updating Legend: ' + (attributes ? Object.keys(attributes).length : 'Trigger'));
    store.dispatch(updateLegend(attributes));
  });

  cgv.on('legendItems-add.proksee', (legendItems) => {
    console.log('Adding Legend Items: ' + legendItems.length);
    store.dispatch(addLegendItems(legendItems));
  });

  cgv.on('legendItems-remove.proksee', (legendItems) => {
    console.log('Removing Legend Items: ' + legendItems.length);
    store.dispatch(removeLegendItems(legendItems));
  });

  cgv.on('legendItems-update.proksee', ({ items, attributes }) => {
    console.log('Updating Legend Items: ' + items.length);
    store.dispatch(updateLegendItems(items, attributes));
  });

  cgv.on('legendItems-moved.proksee', ({ oldIndex, newIndex }) => {
    console.log(`Moving Legend Items: ${oldIndex} -> ${newIndex}`);
    store.dispatch(moveLegendItems(oldIndex, newIndex));
  });

  ////////////// ANNOTATION ////////////////////
  cgv.on('annotation-update.proksee', ({ attributes }) => {
    console.log('Updating Annotation: ' + (attributes ? Object.keys(attributes).length : 'Trigger'));
    store.dispatch(updateAnnotation(attributes));
  });

  ////////////// RULER ////////////////////
  cgv.on('ruler-update.proksee', ({ attributes }) => {
    console.log('Updating Ruler: ' + (attributes ? Object.keys(attributes).length : 'Trigger'));
    store.dispatch(updateRuler(attributes));
  });

  ////////////// CENTERLINE ////////////////////
  cgv.on('centerLine-update.proksee', ({ attributes }) => {
    console.log('Updating CenterLine: ' + (attributes ? Object.keys(attributes).length : 'Trigger'));
    store.dispatch(updateCenterLine(attributes));
  });


  ////////////// SEQUENCE ////////////////////
  cgv.on('sequence-update.proksee', ({ attributes }) => {
    console.log('Updating Sequence: ' + (attributes ? Object.keys(attributes).length : 'Trigger'));
    store.dispatch(updateSequence(attributes));
  });

  ////////////// BACKBONE ////////////////////
  cgv.on('backbone-update.proksee', ({ attributes }) => {
    console.log('Updating Backbone: ' + (attributes ? Object.keys(attributes).length : 'Trigger'));
    store.dispatch(updateBackbone(attributes));
  });

  ////////////// DIVIDERS ////////////////////
  cgv.on('divider-update.proksee', ({divider, attributes }) => {
    // Only one divider is ever updated at a time
    console.log(`Updating Divider (${divider.name}): ` + (attributes ? Object.keys(attributes).length : 'Trigger'));
    store.dispatch(updateDivider(divider, attributes));
  });

  ////////////// CENTERLINE ////////////////////
  // cgv.on('centerLine-update.proksee', ({ attributes }) => {
  //   console.log('Updating CenterLine: ' + (attributes ? Object.keys(attributes).length : 'Trigger'));
  //   store.dispatch(updateCenterLine(attributes));
  // });


}

export { initializedCGViewDispatchEvents }
