import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextElement from './TextElement';
import TextInput from './TextInput';
// import Button from './Button';
import ImageButton from '../presenters/ImageButton';
import iconOK from '../images/icon-ok.png';
import iconCancel from '../images/icon-cancel.png';
import './TextEditable.css';

// NOTE:
// This could be made into a General editing control
//   - text element or input (text, number, slider, etc) + ok/cancel buttons
//   - this could be wrapped around any input control

class TextEditable extends React.Component {

  static propTypes = {
    value:       PropTypes.string,
    editMode:    PropTypes.bool,
    onChange:    PropTypes.func,
    placeHolder: PropTypes.string,
    className:   PropTypes.string,
  }

  static defaultProps = {
    editMode: false,
  }

  constructor(props) {
    super(props);
    const value = this.props.value || '';
    this.state = {
      value,
      editValue: value,
      editMode: props.editMode,
      cancelClicked: false,
    };
    this.onChangeToEditMode = this.onChangeToEditMode.bind(this);
    this.onOK = this.onOK.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeEditValue = this.onChangeEditValue.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  onChangeToEditMode() {
    this.setState({editMode: true, editValue: this.state.value, cancelClicked: false});
  }

  onChangeEditValue(value) {
    this.setState({editValue: value});
  }

  onOK() {
    const {onChange} = this.props;
    this.setState((state) => {
      if (state.cancelClicked) {
        return {editMode: false};
      } else {
        if (state.editValue != state.value) {
          onChange && onChange(state.editValue);
        }
        return {editMode: false, value: state.editValue};
      }
    });
  }

  onCancel() {
    this.setState({editMode: false, cancelClicked: true, editValue: this.state.value});
  }

  onBlur(e) {
    // console.log(e.relatedTarget)
    // HACK: this allows the click on the Cancel button to work...UGLY
    setTimeout(this.onOK, 200)
  }

  onKeyDown(e) {
    // console.log(e.key)
    if (e.key === 'Enter') {
      this.onOK();
    } else if (e.key === 'Escape') {
      this.onCancel();
    }
  }

  render() {
    const { placeholder, className, } = this.props;
    const { editMode, editValue, value } = this.state;

    const elementClass = classNames( 'TextEditable', className);
    if ( editMode ) {
      return (
        <div className={elementClass}>
          <TextInput
            autoFocus
            value={editValue}
            onChange={this.onChangeEditValue}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
          />
          <ImageButton
            image={iconOK}
            onClick={this.onOK}
          />
          <ImageButton
            image={iconCancel}
            onClick={this.onCancel}
          />
        </div>
      );
    } else {
      return (
        <div className={elementClass}>
          <TextElement onClick={this.onChangeToEditMode}>{value}</TextElement>
        </div>
      );
    }
  }
}

export default TextEditable;

