import React from 'react'
import PropTypes from 'prop-types'

import ImageButton from '../proksee-viewer/presenters/ImageButton';
// import * as helpers from '../proksee-viewer/support/Helpers';

class LogButton extends React.Component {

  static propTypes = {
    showLog: PropTypes.bool,
  }

  static defaultProps = {
    showLog: false,
  }

  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.state = { showLog: props.showLog };
  }

  toggleLog() {
     // Access the button's parent row and find the next sibling (the log-row)
     const buttonRow = this.buttonRef.current.closest('tr');
     const logRow = buttonRow.nextElementSibling;
 
     // Check if the next sibling exists and has the 'log-row' class
     if (logRow && logRow.classList.contains('log-row')) {
       // Toggle the visibility of the log row
      //  logRow.style.display = logRow.style.display === 'none' ? '' : 'none';
       logRow.classList.toggle('show-log');
     }
    this.setState((state) => ({ showLog: !state.showLog }));
  }

  render() {
    const { showLog } = this.state;

    return (
      <div className='LogButton'>
        <ImageButton
          size='small'
          ref={this.buttonRef}
          imageName='log'
          active={showLog}
          onClick={() => this.toggleLog()}
        />
      </div>
    )
  }

}
export default LogButton

