import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './JobCards.css';
import {ConnectedLogCard} from '../cards/LogCard';
import {ConnectedFilesCard, FilesCard} from '../cards/FilesCard';
import Tools from '../models/Tools';
import Tabs from '../presenters/Tabs';

// Connected
import { connect } from 'react-redux';

class JobCards extends Tabs {

  static propTypes = {
    // id: PropTypes.number,
    job: PropTypes.object,
    initialKey: PropTypes.string,
  }

  static defaultProps = {
    initialKey: 'report',

    type: 'line',
    tabPosition: 'left',
    className: 'JobCards',
  }

  componentDidMount() {
    const { job } = this.props;
    this.filesCard = React.createRef();
    if (job?.finalized) {
      FilesCard.getFiles(this.props.job);
    }
    this.updatePanes();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.job.finalized && this.props.job.finalized) {
      FilesCard.getFiles(this.props.job);
      this.updatePanes();
      this.forceUpdate();
    }
  }

  updatePanes() {
    const { job, initialKey } = this.props;
    let panes = [];
    const logCard = React.createElement(ConnectedLogCard, {id: job.id});
    const filesCard = React.createElement(ConnectedFilesCard, {id: job.id, ref: this.filesCard});
    if (job.finalized) {
      panes.push({ title: 'Files', content: filesCard, key: 'files'});
      panes = panes.concat(this.outputPanes());
    }
    panes.push( { title: 'Log', content: logCard, key: 'log' } );

    // When Panes are updated, open the report or first tab
    const activeKey = panes.find(p => p.key === 'report') ? 'report' : panes[0].key;

    this.setState({panes, activeKey});
  }

  outputPanes() {
    const { job } = this.props;
    const panes = [];
    const tool = Tools.get(job.tool.publicID);
    for (const output of tool.dataCardOutputs()) {
      const ReactComponent = output.component.react;
      const content =  <ReactComponent jobCards={this} tool={tool} job={job} output={output} componentID={output.id} />
      // console.log(output.id)
      panes.push( { title: output.displayName(), content, key: output.id } );
    }
    return panes;
  }

  openFiles(paths=[], options) {
    console.log(this.filesCard)
    this.setTabByKey('files');
    setTimeout(() => this.filesCard.current.openFiles(paths, options), 200);
  }

  // TODO:
  // Error: At top of page only if there are any errors
}


//Connected
const jobCardsMapStateToProps = (state, ownProps) => ({ job: state.jobs.byID[ownProps.id] });
const ConnectedJobCards = connect(jobCardsMapStateToProps, null, null, {forwardRef: true})(JobCards);

// export default JobCards;
export { JobCards, ConnectedJobCards};
