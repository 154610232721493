import React from 'react';
// import PropTypes from 'prop-types';
import './SVGDownload.css';
import { CGViewContext } from '../../app/CGViewContext';
import { ListItem } from '../../presenters/ListItem';
import Button from '../../presenters/Button';
import ImageButton from '../../presenters/ImageButton';
import ServerAPI from '../../models/ServerAPI';
import Message from '../../presenters/Message';
import ExternalLink from '../../presenters/ExternalLink';
// import * as helpers from '../../support/Helpers';

class SVGDownload extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      infoOpen: false,
    }

    this.clickDownload = this.clickDownload.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    return (
      <div className='list-item-content'>
        <div className='list-item-title'>
          Image - SVG
        </div>
         <ImageButton
            imageName='download'
            title='Download SVG'
            className='download-button'
            onClick={ this.clickDownload }
          / >
      </div>
    );
  }

  infoRenderer() {

    return (
      <div className='info-download-svg'>
        <div>
          Download the map image as a SVG text file.
          This file can be edited in vector drawing software such as 
          <ExternalLink name='Inkscape (free)' link='https://inkscape.org' size={12} /> or 
          <ExternalLink name='Adobe Illustrator' link='https://www.adobe.com/ca/products/illustrator.html' size={12} />.
          Generating the SVG can be <strong><em>very slow</em></strong> for maps with plots (e.g GC Content/Skew).
        </div>

        <div className='download-image'>
          <ImageButton
            className='btn-download-image'
            imageName='download'
            title='Download SVG'
            size='large'
            text='Download'
            width={105}
            onClick={ this.clickDownload }
          />
        </div>
      </div>
    );
  }

  clickDownload() {
    const Server = new ServerAPI();
    Server.get(Server.URL.downloadAction, {source: 'image_svg'});
    const cgv = this.context.cgv;
    Message.open({content:'Generating SVG...'});
    setTimeout(() => {
      cgv.io.downloadSVG('proksee.svg')
      console.log('AFTER DOWNLOAD')
      Message.close();
    }, 100);
      console.log('OUt of timout')
  }

  render() {
    return (
      <div className='SVGDownload'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

SVGDownload.contextType = CGViewContext;

export default SVGDownload;

