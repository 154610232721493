import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import './FeaturePane.css'; // Using same CSS as Features
import './BookmarkPane.css'; 
import Toast from '../presenters/Toast';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import TextInput from '../presenters/TextInput';
import NumericInput from '../presenters/NumericInput';
import ImageButton from '../presenters/ImageButton';
import ButtonGroup from '../presenters/ButtonGroup';
import FavoriteButton from '../presenters/FavoriteButton';
import { Select, Option } from '../presenters/Select';
import { VirtualTable, Column } from '../presenters/VirtualTable';
import BasicDialog from '../presenters/BasicDialog';
import * as helpers from '../support/Helpers';
import iconGoto from '../images/icon-goto.png';
import '../support/CommonStyles.css';

// Connected
import { connect } from 'react-redux';

class BookmarkPane extends Component {

  static propTypes = {
    bookmarks: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      virtualTableRef: React.createRef(),
      bulkDeleteIDs: [],
    };
    this.infoBoxRenderer = this.infoBoxRenderer.bind(this);
    this.nameCellRenderer = this.nameCellRenderer.bind(this);
    this.bpCellRenderer = this.bpCellRenderer.bind(this);
    this.zoomCellRenderer = this.zoomCellRenderer.bind(this);
    this.optionCellRenderer = this.optionCellRenderer.bind(this);
  }

  shortcutPressed(bookmark) {
    Toast.create('Bookmark: ' + bookmark.name, 1000)
  }

  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  zoomCellRenderer({ rowData }) {
    return (
      <div className='column-value'>
        <div className='column-value-main'>{helpers.commaNumber(rowData.zoom, 2)}</div>
      </div>
    )
  }

  bpCellRenderer({ rowData }) {
    return (
      <div className='column-value'>
        <div className='column-value-main'>{helpers.commaNumber(rowData.bp)}</div>
      </div>
    )
  }

  nameCellRenderer({ rowData }) {
    return (
      <div className='column-value'>
        <div className='column-value-main'>{rowData.name}</div>
        <div className='column-value-sub'>
          <div className='format'>
            {rowData.format}
          </div>
        </div>
      </div>
    )
  }

  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  optionCellRenderer({ rowData }) {
    const bookmark = this.props.bookmarks.byID[rowData.cgvID];
    return (
      <ButtonGroup className='option-buttons'>
        <FavoriteButton
          favorite={bookmark.favorite}
          onClick={ (favorite) => this.onBookmarkChange({cgvID: bookmark.cgvID, value: favorite, attribute: 'favorite'}) }
        />
        <ImageButton
          onClick={ () => this.onClickMoveTo(bookmark) }
          image={iconGoto}
          title='Move To'
        / >
      </ButtonGroup>
    )
  }

  onBookmarkChange({cgvID, attribute, value}) {
    const cgv = this.context.cgv;
    const bookmark = cgv.objects(cgvID);
    cgv.updateBookmarks(bookmark, {[attribute]: value});
  }

  onClickMoveTo(bookmarkRedux) {
    const { cgv, tabsRef: tabs } = this.context;
    tabs.setTabByID('map');
    const bookmark = cgv.objects(bookmarkRedux.cgvID);
    bookmark.moveTo(1500);
  }

  infoBoxRenderer(bookmark) {
    const cgvID = bookmark.cgvID;
    const cgv = this.context.cgv;
    // FIXME: this should be taken from redux
    // FIXME: this needs to take into account contigs. We'll also need to handle the length change when contigs are hidden.
    const maxLength = cgv.sequence.length;
    const minZoom = cgv.minZoomFactor;
    const maxZoom = cgv.maxZoomFactor;
    // Use the canvas maxDimension or the current value as the absolute min/max
    const maxOffset = Math.max(cgv.maxDimension, Math.abs(bookmark.bbOffset));

    return (
      <div>
        <DataElement label='Name' helpPath='help:sidebar:regions:bookmarks:name'>
          <TextInput value={bookmark.name}
            onChange={(value) => this.onBookmarkChange({cgvID, value, attribute: 'name'})}/>
        </DataElement>
        <DataElementGroup>
          <DataElement label='Shortcut Key' helpPath='help:sidebar:regions:bookmarks:shortcut'>
            <TextInput value={bookmark.shortcut || ''}
              maxLength='1'
              onChange={(value) => this.onBookmarkChange({cgvID, value, attribute: 'shortcut'})}/>
          </DataElement>
          <DataElement label='Format' helpPath='help:sidebar:regions:bookmarks:format'>
            <Select value={`${bookmark.format}`}
              onChange={(value) => this.onBookmarkChange({cgvID, value, attribute: 'format'})}>
              <Option value="circular">Circular</Option>
              <Option value="linear">Linear</Option>
            </Select>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Position' helpPath='help:sidebar:regions:bookmarks:position'>
            <NumericInput value={bookmark.bp}
              min={1}
              max={maxLength}
              suffix=' bp'
              onChange={(value) => this.onBookmarkChange({cgvID, value, attribute: 'bp'})}/>
          </DataElement>
          <DataElement label='Zoom' helpPath='help:sidebar:regions:bookmarks:zoom'>
            <NumericInput value={bookmark.zoom}
              min={minZoom}
              max={maxZoom}
              precision={2}
              suffix=' x'
              onChange={(value) => this.onBookmarkChange({cgvID, value, attribute: 'zoom'})}/>
          </DataElement>
          <DataElement label='Offset' helpPath='help:sidebar:regions:bookmarks:backbone_offset'>
            <NumericInput value={bookmark.bbOffset}
              min={-maxOffset}
              max={maxOffset}
              onChange={(value) => this.onBookmarkChange({cgvID, value, attribute: 'bbOffset'})}/>
          </DataElement>
        </DataElementGroup>
        <div className='action-buttons'>
          <ImageButton imageName='delete' text='Delete Bookmark' width={130}
          title='Delete Bookmark' onClick={ () => this.onDeleteBookmark(bookmark.cgvID) } />
        </div>
      </div>
    )
  }

  onDeleteBookmark(cgvID) {
    const cgv = this.context.cgv;
    const bookmark = cgv.objects(cgvID);
    bookmark.remove();
    this.state.virtualTableRef.current.tableRef.recomputeRowHeights();
  }

  onAddBookmark() {
    const cgv = this.context.cgv;
    cgv.addBookmarks({});
  }

  onDeleteBookmarks(cgvIDs) {
    this.setState({bulkDeleteIDs: cgvIDs});
  }

  renderDeleteDialog() {
    const { bulkDeleteIDs, virtualTableRef } = this.state;
    if (bulkDeleteIDs.length > 0) {
      const bookmarks = cgv.objects(bulkDeleteIDs);
      return (
        <BasicDialog
          title={`Delete Bookmarks: ${bulkDeleteIDs.length} selected`}
          contents='Are you sure you want to delete the selected bookmarks?'
          onClose={({action}) => {
            if (action === 'ok') {
              cgv.removeBookmarks(bookmarks);
              virtualTableRef.current.tableRef.recomputeRowHeights();
              setTimeout( () => virtualTableRef.current.refreshSelectedIDs());
            }
            this.setState({bulkDeleteIDs: []})
          }}
        />
      );
    }
  }

  render() {
    const bookmarks = helpers.itemsAsArray(this.props.bookmarks);
    // FIXME: pre round zoomFactor (here or in reducer, OR they should be stored to 2 digits in CGView.js)
    return (
      <div style={{width: '100%', height: '100%'}} className='BookmarkPane'>
        <VirtualTable
          headerHeight={20}
          rowHeight={32}
          infoHeight={220}
          data={bookmarks}
          dataName='Bookmarks'
          idKey='cgvID'
          infoRenderer={this.infoBoxRenderer}
          selectColumn={true}
          ref={this.state.virtualTableRef}
          addAction={()=>{this.onAddBookmark()}}
          deleteAction={(ids)=>{this.onDeleteBookmarks(ids)}}
        >
          <Column
            width={15}
            label='🔑'
            dataKey='shortcut'
            search='nosearch'
            className='shortcut'
            headerStyle={{fontSize: '0.65em'}}
          />
          <Column
            label='Name'
            dataKey='name'
            width={135}
            cellRenderer={this.nameCellRenderer}
          />
          <Column
            width={100}
            label='Position'
            dataKey='bp'
            search='number'
            cellRenderer={this.bpCellRenderer}
          />
          <Column
            width={70}
            label='Zoom'
            dataKey='zoom'
            search='number'
            cellRenderer={this.zoomCellRenderer}
          />
          <Column
            width={55}
            dataKey=''
            search='nosearch'
            cellRenderer={this.optionCellRenderer}
          />
          <Column
            label='Format'
            dataKey='format'
            hidden={true}
          />
          <Column
            label='Shortcut'
            dataKey='shortcut'
            hidden={true}
          />
        </VirtualTable>
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

BookmarkPane.contextType = CGViewContext;

//Connected
const bookmarkPaneMapStateToProps = (state) => ({ bookmarks: state.bookmarks });
const ConnectedBookmarkPane = connect(bookmarkPaneMapStateToProps, null, null, {forwardRef: true})(BookmarkPane);

// export default BookmarkPane;
export { BookmarkPane, ConnectedBookmarkPane};

