import { LOCATION_CHANGE } from '../constants/ActionTypes'

const initialState = {
  bp: 0,
  zoomFactor: 1,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        bp: action.payload.bp || state.bp,
        zoomFactor: action.payload.zoomFactor || state.zoomFactor,
      }
    }
    default: return state;
  }
};
