import React from 'react'
import ReactDOM from 'react-dom'
// import './ToastMessage.css';
import PropTypes from 'prop-types'
import Toast from "../proksee-viewer/presenters/Toast";

class ToastMessage extends React.Component {

  static propTypes = {
    // message:    PropTypes.string,
    flash:    PropTypes.array,
    duration: PropTypes.number,
  }

  static defaultProps = {
    duration: 3,
    flash: [],
  }

  constructor(props) {
    super(props);
    // flash is an array of arrays where each array has 2 values: the message type and the message
    // e.g. [['notice', 'my notice message'], ['alert', 'my alert message']]
    // There are 2 main types of message: 'notice' and 'alert'
    // For now ToastMessage will only display the first message.
    // Todo:
    //  - change color based on message type
    //  - alerts should stay until dismissed
  }

  componentDidMount() {
    const { flash, duration } = this.props;
    if (flash.length > 0) {
      Toast.create(flash[0][1], duration);
    }
  }

  render() {
    return (
      <Toast />
    )
  }

}
export default ToastMessage;

