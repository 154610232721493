import { RESET_STATE, TRACKS_ADD, TRACKS_REMOVE, TRACKS_UPDATE, TRACKS_MOVED, TRACKS_UPDATE_STATS } from '../constants/ActionTypes'
import { removeItems } from './ReduxHelpers';
import * as helpers from '../support/Helpers';

const initialState = {
  byID: {},
  ids: [],
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:         return reset(state, payload);
    case TRACKS_ADD:          return addTracks(state, payload);
    case TRACKS_REMOVE:       return removeItems(state, payload.tracks);
    case TRACKS_UPDATE:       return updateTracks(state, payload);
    case TRACKS_MOVED:        return moveTracks(state, payload);
    case TRACKS_UPDATE_STATS: return updateTrackStats(state, payload);
    default:                  return state;
  }
};

// TODO: TRACK_REMOVE: see comments in https://hackernoon.com/redux-patterns-add-edit-remove-objects-in-an-array-6ee70cab2456
//  shows way to remove item with destructuring!

const reset = () => { return (initialState) }

const addTracks = (state, { tracks }) => {
  // Normalize track data
  const trackData = {};
  const trackIDs = [];
  for (var i = 0, len = tracks.length; i < len; i++) {
    const track = tracks[i];
    const json = track.toJSON({includeDefaults: true});
    json.cgvID = track.cgvID;
    json.itemCount = track.itemCount;

    json.dataKeys = Array.isArray(json.dataKeys) ? json.dataKeys : [json.dataKeys];

    trackData[json.cgvID] = json;
    trackIDs.push(json.cgvID);
  }

  return ({
    byID: {...state.byID, ...trackData},
    ids: [...state.ids, ...trackIDs],
  })
}

const updateTracks = (state, { tracks, attributes }) => {
  // Normalize track data
  const trackData = {};
  for (var i = 0, len = tracks.length; i < len; i++) {
    const track = tracks[i];
    const json = track.toJSON({includeDefaults: true});
    json.cgvID = track.cgvID;
    json.index = i;
    json.itemCount = track.itemCount;

    json.dataKeys = Array.isArray(json.dataKeys) ? json.dataKeys : [json.dataKeys];

    trackData[json.cgvID] = json;
  }

  return ({
    byID: {...state.byID, ...trackData},
    ids: state.ids,
  })
}

// TODO: should have array of cgvID for track order
const moveTracks = (state, { oldIndex, newIndex }) => {
  return ({
    byID: state.byID,
    ids: helpers.arrayMoveCopy(state.ids, oldIndex, newIndex),
  })
}

const updateTrackStats = (state, { cgv }) => {
  const newStateByID = {...state.byID};
  for (const [key, value] of Object.entries(newStateByID)) {
    const track = cgv.objects(value.cgvID);
    newStateByID[key].itemCount = track.itemCount;
  }
  return ({
    byID: newStateByID,
    ids: state.ids,
  })
}

