import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CGViewContext } from '../app/CGViewContext';
import './FeaturePane.css'; // Using same CSS as Features
// import './PlotPane.css';
import { ConnectedSelectLegend } from '../containers/SelectLegend';
import DataElement from '../presenters/DataElement';
import DataElementGroup from '../presenters/DataElementGroup';
import DataElementContainer from '../presenters/DataElementContainer';
import TextInput from '../presenters/TextInput';
import NumericInput from '../presenters/NumericInput';
import TextElement from '../presenters/TextElement';
import ImageButton from '../presenters/ImageButton';
import VisibilityButton from '../presenters/VisibilityButton';
import FavoriteButton from '../presenters/FavoriteButton';
import ButtonGroup from '../presenters/ButtonGroup';
import PlotsDeleteDialog from './dialogs/PlotsDeleteDialog';
import PlotsEditDialog from './dialogs/PlotsEditDialog';

import { Select, Option } from '../presenters/Select';
import { VirtualTable, Column } from '../presenters/VirtualTable';
import * as helpers from '../support/Helpers';
import '../support/CommonStyles.css';
import './PlotPane.css';
import * as d3 from 'd3';

// Connected
import { connect } from 'react-redux';

class PlotPane extends Component {

  static propTypes = {
    plots: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
    legendItems: PropTypes.shape({
      ids:  PropTypes.array,
      byID: PropTypes.object,
    }).isRequired,
    // Tracks is not actually used. But if it changes,
    // the plots are rendered to update the track list for each plot.
    tracks: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      virtualTableRef: React.createRef(),
      bulkEditIDs: [],
      bulkDeleteIDs: [],
    };
    this.onRowMouseOver = this.onRowMouseOver.bind(this);
    this.onRowMouseOut = this.onRowMouseOut.bind(this);
    this.infoBoxRenderer = this.infoBoxRenderer.bind(this);
    this.nameCellRenderer = this.nameCellRenderer.bind(this);
    this.legendCellRenderer = this.legendCellRenderer.bind(this);
    this.valuesCellRenderer = this.valuesCellRenderer.bind(this);
    this.optionCellRenderer = this.optionCellRenderer.bind(this);
    this.rowClass = this.rowClass.bind(this);
  }

  rowClass(plot) {
    if (plot) {
      return plot.visible ? '' : 'ps-fade';
    }
  }

  searchWithString(string) {
    this.state.virtualTableRef.current.searchWithString(string);
  }

  openInfoFor(id) {
    this.state.virtualTableRef.current.openInfoFor(id);
  }

  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  valuesCellRenderer({ rowData: plot }) {
    const scoreFormatter = this.scoreFormatterForPlot(plot);
        //<div className='column-value-sub'>{helpers.commaNumber(plot.scoreMin)}..{helpers.commaNumber(plot.scoreMax)}</div>
    return (
      <div className='column-value'>
        <div className='column-value-main'>{helpers.commaNumber(plot.length)}</div>
        <div className='column-value-sub'>{scoreFormatter(plot.scoreMin)}..{scoreFormatter(plot.scoreMax)}</div>
      </div>
    )
  }

  legendCellRenderer({ rowData: plot }) {
    const swatchSize = 8;
    const legendPositive = this.props.legendItems.byID[plot.legendPositiveID];
    const legendNegative = this.props.legendItems.byID[plot.legendNegativeID];
    // console.log(legendPositive, legendNegative)
    if (legendPositive === legendNegative) {
      return (
        <div className='column-value'>
          <div className='column-value-main'>{this.swatch(legendPositive, swatchSize)}{legendPositive.name}</div>
        </div>
      );
    } else {
      return (
        <div className='column-value'>
          <div className='column-value-sub'>{this.swatch(legendPositive, swatchSize)}{legendPositive.name}</div>
          <div className='column-value-sub'>{this.swatch(legendNegative, swatchSize)}{legendNegative.name}</div>
        </div>
      );
    }
  }

  nameCellRenderer({ rowData: plot }) {
    return (
      <div className='column-value'>
        <div className='column-value-main'>{plot.name}</div>
        <div className='column-value-sub'>{plot.type || ' '}</div>
      </div>
    )
  }

  // Params: cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex, 
  optionCellRenderer({ rowData: plot }) {
    // const plot = this.props.plots.byID[rowData.cgvID];
    return (
      <ButtonGroup className='option-buttons'>
        <FavoriteButton
          favorite={plot.favorite}
          onClick={ (favorite) => this.onPlotChange({cgvID: plot.cgvID, value: favorite, attribute: 'favorite'}) }
        />
        <VisibilityButton
          visible={plot.visible}
          onClick={ (visible) => this.onPlotChange({cgvID: plot.cgvID, value: visible, attribute: 'visible'}) }
        />
      </ButtonGroup>
    )
  }

  onPlotChange({cgvID, attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    const plot = cgv.objects(cgvID);
    if (plot[attribute] !== value) {
      cgv.updatePlots(plot, {[attribute]: value});
    }
    redraw && cgv.draw();
  }

  onPlotLegendChange({cgvID, attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    const plot = cgv.objects(cgvID);
    // console.log(value)
    // console.log(plot.legendPositive.cgvID, plot.legendNegative.cgvID)
    if (value === 'mirrored') {
      if (plot.legendPositive !== plot.legendNegative) {
        cgv.updatePlots(plot, {legendNegative: plot.legendPositive})
      }
    } else if (plot[attribute].cgvID !== value) {
      if ((attribute === 'legendPositive') && (plot.legendPositive === plot.legendNegative)) {
        cgv.updatePlots(plot, {legend: value});
      } else {
        cgv.updatePlots(plot, {[attribute]: value});
      }
    }
    redraw && cgv.draw();
  }

  swatch(legendItem, size=10, style={}) {
    return ConnectedSelectLegend.renderSwatch(legendItem, size, style);
  }

  scoreFormatterForPlot(plot) {
    const scoreStep = this.scoreStepForPlot(plot);
    const precision = d3.precisionRound(scoreStep, plot.scoreMax);
    const formatter = d3.format(`,.${precision+1}r`);
    return formatter;
  }

  scoreStepForPlot(plot) {
    const scoreDiff = plot.scoreMax - plot.scoreMin; 
    const scoreStep = scoreDiff / 100;
    return scoreStep;
  }

  infoBoxRenderer(plot) {
    const cgv = this.context.cgv;
    const cgvID = plot.cgvID;
    const legendPositive = this.props.legendItems.byID[plot.legendPositiveID];
    const legendNegative = this.props.legendItems.byID[plot.legendNegativeID];

    const scoreStep = this.scoreStepForPlot(plot);
    const scoreFormatter = this.scoreFormatterForPlot(plot);
    const precision = d3.precisionRound(scoreStep, plot.scoreMax);
              // step={0.1}
              // decimals={1}
              // precision={1}
    const negativeLegendDefaultItemID = (legendNegative.cgvID === legendPositive.cgvID) ? 'mirrored' : legendNegative.cgvID;
    return (
      <div>
        <DataElement label='Name' helpPath='help:sidebar:regions:plots:name'>
          <TextInput value={plot.name}
            onChange={(value) => this.onPlotChange({cgvID, value, attribute: 'name', redraw: false})}/>
        </DataElement>
        <DataElementGroup>
          <ConnectedSelectLegend
              defaultItemID={legendPositive.cgvID}
              title={<span>Legend <span className='smaller-label'>(Above Baseline)</span></span>}
              allowNewItem={false}
              onChange={(value) => this.onPlotLegendChange({cgvID, value: value.cgvID, attribute: 'legendPositive'})}
              help=''
              helpPath='help:sidebar:regions:plots:legend_above'
           />
          <ConnectedSelectLegend key={negativeLegendDefaultItemID}
              defaultItemID={negativeLegendDefaultItemID}
              title={<span>Legend <span className='smaller-label'>(Below Baseline)</span></span>}
              allowNewItem={false}
              additionalItems={{mirrored: 'Same as Above'}}
              onChange={(value) => this.onPlotLegendChange({cgvID, value: value.cgvID, attribute: 'legendNegative'})}
              help=''
              helpPath='help:sidebar:regions:plots:legend_below'
           />
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Baseline' helpPath='help:sidebar:regions:plots:baseline'>
            <NumericInput value={plot.baseline}
              min={plot.axisMin}
              step={scoreStep}
              precision={precision}
              max={plot.axisMax}
              onChange={(value) => this.onPlotChange({cgvID, value, attribute: 'baseline'})}/>
          </DataElement>
          <DataElement label='Axis Min' helpPath='help:sidebar:regions:plots:axis_min'>
            <NumericInput value={plot.axisMin}
              max={plot.scoreMin}
              step={scoreStep}
              precision={precision}
              onChange={(value) => this.onPlotChange({cgvID, value, attribute: 'axisMin'})}/>
          </DataElement>
          <DataElement label='Axis Max' helpPath='help:sidebar:regions:plots:axis_max'>
            <NumericInput value={plot.axisMax}
              min={plot.scoreMax}
              step={scoreStep}
              precision={precision}
              onChange={(value) => this.onPlotChange({cgvID, value, attribute: 'axisMax'})}/>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='# of Values' helpPath='help:sidebar:regions:plots:value_count'>
            <TextElement>
              {helpers.commaNumber(plot.length)}
            </TextElement>
          </DataElement>
          <DataElement label='Min Value' helpPath='help:sidebar:regions:plots:value_min'>
            <TextElement>
              {scoreFormatter(plot.scoreMin)}
            </TextElement>
          </DataElement>
          <DataElement label='Max Value' helpPath='help:sidebar:regions:plots:value_max'>
            <TextElement>
              {scoreFormatter(plot.scoreMax)}
            </TextElement>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Mean Value' helpPath='help:sidebar:regions:plots:value_mean'>
            <TextElement>
              {scoreFormatter(plot.scoreMean)}
            </TextElement>
          </DataElement>
          <DataElement label='Median Value' helpPath='help:sidebar:regions:plots:value_median'>
            <TextElement>
              {scoreFormatter(plot.scoreMedian)}
            </TextElement>
          </DataElement>
        </DataElementGroup>
        {this.renderPlotOptions(plot)}
        <DataElementGroup>
          <DataElement label='Source' helpPath='help:sidebar:regions:plots:source'>
            <TextElement>
              {plot.source}
            </TextElement>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Tracks' helpPath='help:sidebar:regions:plots:tracks'>
            <TextElement>
              {
                plot.tracks.split(', ').map( (t, i) => (
                  <span key={t} onMouseOver={ () => cgv.objects(plot.cgvID).tracks()[i].highlight() } >{t}</span>
                ))
                .reduce((prev, curr) => [prev, ', ', curr])
              }
            </TextElement>
          </DataElement>
          <DataElement label='Plot Type' helpPath='help:sidebar:regions:plots:type'>
            <TextElement>
              {plot.type}
            </TextElement>
          </DataElement>
        </DataElementGroup>
        <div className='action-buttons'>
          <ImageButton imageName='delete' text='Delete Plot' width={95}
          title='Delete Plot' onClick={ () => this.onDeletePlots([plot.cgvID]) } />
        </div>
      </div>
    )
  }

  renderPlotOptions(plot) {
    if (['gc-skew', 'gc-content'].includes(plot.source)) {
      const sequenceExtractor = this.context.cgv.sequence.sequenceExtractor;
      const track = plot.tracks[0];
      const options = track?.dataOptions || {};
      const window = options.window || sequenceExtractor.getWindowStep().window;
      const step = options.step || sequenceExtractor.getWindowStep().step;
      return (
        <DataElementContainer className='plot-options' label='Plots Options'>
          <DataElementGroup>
            <DataElement label='Window'>
              <TextElement>
                {window || '-'}
              </TextElement>
            </DataElement>
            <DataElement label='Step'>
              <TextElement>
                {step || '-'}
              </TextElement>
            </DataElement>
          </DataElementGroup>
        </DataElementContainer>
    )}
  }

  onDeletePlots(cgvIDs) {
    this.setState({bulkDeleteIDs: cgvIDs});
    // const cgv = this.context.cgv;
    // const plots = cgvIDs.map( i => cgv.objects(i));
    // this.context.cgv.removePlots(plots)
    // cgv.draw();
  }

  renderDeleteDialog() {
    const { bulkDeleteIDs, virtualTableRef } = this.state;
    if (bulkDeleteIDs.length > 0) {
      return (
        <PlotsDeleteDialog
          plotIDs={bulkDeleteIDs}
          plotTableRef={virtualTableRef}
          onClose={() => this.setState({bulkDeleteIDs: []})}
        />
      );
    }
  }

  renderEditDialog() {
    const { bulkEditIDs } = this.state;
    if (bulkEditIDs.length > 0) {
      return (
        <PlotsEditDialog
          plotIDs={bulkEditIDs}
          plotTypes={this.props.plotTypes}
          onClose={() => this.setState({bulkEditIDs: []})}
        />
      );
    }
  }

  onEditPlots(cgvIDs) {
    this.setState({bulkEditIDs: cgvIDs});
  }

  onAddPlot() {
    const sidebar = this.context.sidebarRef;
    const toolPane = sidebar.getPane('tools');
    toolPane.clickOpenTool({id: 'plots'});
  }

  // item = {event, index, rowData}
  onRowMouseOver(e, item) {
    const cgv = this.context.cgv;
    const plot = item && item.cgvID && cgv.objects(item.cgvID);
    if (plot) {
      plot.highlight();
    }
    // console.log(item)
  }

  onRowMouseOut() {
    const cgv = this.context.cgv;
    cgv.clear('background');
    cgv.clear('ui');
  }

  infoHeightForRowData(rowData) {
    if (['gc-skew', 'gc-content'].includes(rowData.source)) {
      return 520;
    } else {
      return 435;
    }
  }

  render() {
    const plots = helpers.itemsAsArray(this.props.plots);
    return (
      <div style={{width: '100%', height: '100%'}} className='PlotPane'>
        <VirtualTable
          headerHeight={20}
          rowHeight={32}
          infoHeight={this.infoHeightForRowData}
          data={plots}
          dataName='Plots'
          idKey='cgvID'
          infoRenderer={this.infoBoxRenderer}
          selectColumn={true}
          rowClass={this.rowClass}
          ref={this.state.virtualTableRef}
          onRowMouseOver={this.onRowMouseOver}
          onRowMouseOut={this.onRowMouseOut}
          deleteAction={(ids)=>{this.onDeletePlots(ids)}}
          editAction={(ids)=>{this.onEditPlots(ids)}}
          addAction={()=>{this.onAddPlot()}}
        >
          <Column
            label='Name'
            dataKey='name'
            width={110}
            cellRenderer={this.nameCellRenderer}
          />
          <Column
            width={80}
            label='Legend'
            dataKey='legend'
            cellRenderer={this.legendCellRenderer}
          />
          <Column
            width={110}
            label='Values'
            dataKey='length'
            search='number'
            cellRenderer={this.valuesCellRenderer}
          />
          <Column
            width={55}
            dataKey=''
            search='nosearch'
            cellRenderer={this.optionCellRenderer}
          />
          <Column
            label='Legend'
            dataKey='legend'
            hidden={true}
          />
          <Column
            label='Source'
            dataKey='source'
            hidden={true}
          />
          <Column
            label='cgvID'
            dataKey='cgvID'
            hidden={true}
          />
          <Column
            label='Track'
            dataKey='tracks'
            hidden={true}
          />
        </VirtualTable>
        {this.renderEditDialog()}
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

PlotPane.contextType = CGViewContext;

//Connected
const plotPaneMapStateToProps = (state) => ({ plots: state.plots, legendItems: state.legendItems, plotTypes: state.stats.plotTypes, tracks: state.tracks });
const ConnectedPlotPane = connect(plotPaneMapStateToProps, null, null, {forwardRef: true})(PlotPane);

// export default PlotPane;
export { PlotPane, ConnectedPlotPane};

