import React from 'react';
import ServerAPI from '../../models/ServerAPI';
import * as helpers from '../../support/Helpers';

class HTMLFileViewer {

  constructor(fileViewer) {
    this._fileViewer = fileViewer;
    // this._settings = settings;
    // SET settings with provided settings or default
  }

  get fileViewer() {
    return this._fileViewer;
  }

  // get settings() {
  //   return this._settings;
  // }

  //////////////////////////////////////////////////////////////////////////
  // Optional Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  // Default is false
  get isEmbedded() {
    return true;
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'html';
  }

  static get displayFileType() {
    return 'HTML';
  }

  // get settingsHeight() {
  //   return 100;
  // }

  defaultSettings() {
    return {
    }
  }

  // onChangeSettings({value, attribute}) {
  //   this.fileViewer.onChangeSettings({value, attribute});
  // }
  //
  // displaySettings(settings=this.defaultSettings()) {
  //   return (
  //     <div>
  //       No settings available.
  //     </div>
  //   );
  // }

  displayData(file, data, settings=this.defaultSettings()) {
    return (
      <object
        width="100%"
        height="100%"
        data={this.fileViewer.embedURL}>
      </object>
    );
  }

}

export default HTMLFileViewer;
