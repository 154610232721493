import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import './Linker.css';

// NOTE: I tried using ExternalLink, but the images make it slow.

Linker.propTypes = {
  className: PropTypes.string,
  value:  PropTypes.string,
  url: PropTypes.string,
  delimiter: PropTypes.string,
  extractRegex: PropTypes.object,
  join: PropTypes.string,
}

// Linker.defaultProps = {
//   separated: false,
// }

function Linker(props) {
  const {
    className,
    value,
    url,
    delimiter,
    extractRegex,
    join
  } = props;

  const elementClass = classNames(
    'Linker', className
  );

  // Split values using delimiter
  let splits = [value];
  if (delimiter) {
    splits = value.split(delimiter);
  }
  // Trim values of white space
  splits = splits.map( v => v.trim() );

  // Extract the value using a RegExp
  if (extractRegex) {
    // const re = new RegExp(extractRegex);
    splits = splits.map( (v) => {
      const result = v.match(extractRegex);
      return result ? result[1] : v;
    });
  }

  // Insert values into the URL
  let urls = [...splits];
  if (url) {
    urls = splits.map( v => url.replace('{VALUE}', v) );
  }

  // Join the links together using join or delimiter
  const links = splits
    .map( (v,i) => <a target='_blank' title='Open in new Tab or Window' key={v} href={urls[i]}>{v}</a>)
    .reduce( (prev, curr) => [prev, (join || delimiter), curr] );

  return (
    <div className={elementClass}>
      {links}
    </div>
  )
}

export default Linker;

