import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './FeatureSequence.css';
import classNames from 'classnames';
import DataElement from '../presenters/DataElement';
import TextElement from '../presenters/TextElement';
import ImageButton from '../presenters/ImageButton';
import ButtonGroup from '../presenters/ButtonGroup';
import Button from '../presenters/Button';
import Toast from '../presenters/Toast';
import { CGViewContext } from '../app/CGViewContext';
// Connected
import { connect } from 'react-redux';

FeatureSequence.propTypes = {
  className: PropTypes.string,
  cgvID: PropTypes.string,
  feature: PropTypes.object, // Connected through cgvID
}

FeatureSequence.defaultProps = {
}

function FeatureSequence(props) {
  const context = useContext(CGViewContext);
  const [seqType, setSeqType] = React.useState('dna')
  const cgv = context.cgv;

  const {
    className,
    feature,
  } = props;

  const elementClass = classNames(
    'FeatureSequence', className
  );

  let seq = 'No sequence available';
  const cgvFeature = cgv.objects(feature.cgvID);
  let fullSeq = (seqType == 'aa') ? cgvFeature.translate() : cgvFeature.seq;
  if (fullSeq) {
    seq = fullSeq;
    // Take up to 1000 characters
    if (seq.length > 1000) {
      seq = seq.slice(0, 1000);
      seq += '...';
    }
  }

  function copySequence() {
    navigator.clipboard.writeText(fullSeq);
    Toast.create('Copied sequence to the Clipboard', 1500)
  }

  const aaButtonDecoration = (cgvFeature.translation) ? <sup>*</sup> : undefined;

  const aaTypes = ['cds', 'orf'];

  const buttons = (
    <div className='feature-sequence-btns'>
      { (aaTypes.includes(feature.type?.toLowerCase())) && (
        <ButtonGroup>
          <Button className='btn-sequence-dna' size='small' width={40} height={18}
          onClick={() => setSeqType('dna')}
          active={seqType == 'dna'}>DNA</Button>
          <Button className='btn-sequence-aa' size='small' width={40} height={18}
            onClick={() => setSeqType('aa')}
            active={seqType == 'aa'}>AA{aaButtonDecoration}</Button>
        </ButtonGroup>
      )}
      <ImageButton className='btn-copy-sequence' width="55px" size='small'
        onClick={() => copySequence()}
        imageName='copy' text='Copy' />
    </div>
  )

  let help = ''
  if (seqType == 'aa') {
    help = 'Amino acid sequence of the feature (translated from DNA)';
    if (cgvFeature.translation) {
      help = '* Translation from feature qualifier (NOT translated from DNA)';
    }
  }

  // 3 buttons: AA, DNA, FULL (Full is only present if the feature has locations)
  return (
    <div className={elementClass}>
      <DataElement label='Sequence' labelRight={buttons} help={help}
        helpPath='help:sidebar:regions:features:sequence'>
        <TextElement className='scroll-skinny'>{seq}</TextElement>
      </DataElement>
    </div>
  );
}

// Connected
const featureSequenceMapStateToProps = (state, ownProps) => ({ feature: state.features.byID[ownProps.cgvID] });
const ConnectedFeatureSequence = connect(featureSequenceMapStateToProps)(FeatureSequence);

// export default FeatureSequence;
export default ConnectedFeatureSequence;




