import ServerAPI from './ServerAPI';

let instance = null;

class HelpAPI {

  constructor() {
    if(!instance){
      instance = this;
      // Get help documentation from server once
      this.helpPaths = {};
      const Server = new ServerAPI();
      Server.get(Server.URL.helpPaths)
      .then( response => {
        if (response.ok) {
          this.helpPaths = response.json['help']
          // console.log(this.helpPaths)
        } else {
          console.error('Proksee did not return the Help API Paths Hash!')
        }
      });
    }
    return instance;
  }

  forPath(key) {
    return this.helpPaths[key];
  }

}

export default HelpAPI;
