import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ReportCardBase.css';
import DataCard from './DataCard';
import ImageButton from '../presenters/ImageButton';
import ExternalLink from '../presenters/ExternalLink';
import {ConnectedFeaturedFile} from './FeaturedFile';
import Tools from '../models/Tools';
import {ConnectedAddJobResultsButton} from '../containers/AddJobResultsButton';
import * as helpers from '../support/Helpers';
import { map } from 'd3';

class ReportCardBase extends DataCard {

  static propTypes = {
    // id: PropTypes.number.isRequired,
    job: PropTypes.object.isRequired,
    jobCards: PropTypes.object,
    output: PropTypes.shape({
      // path to the data for this job
      data: PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      data: 'Downloading...',
      status: 'waiting', // 'waiting', 'downloading', 'failed', 'complete'
    }
  }

  // Override to use different extraction type
  // Default for ReportCards is json
  get extractType() {
    return 'json';
  }

  // Show the data file and its path used for this data card
  renderFooter() {
    const { output } = this.props;
    const dataPath = output.data;
    return (
      <div className='data-card-footer'>Data Source: <span className='data-source-path'>{dataPath}</span></div>
    );
  }

  renderAddResultsButton() {
    const { job } = this.props;
    return (
      <div className='btn-add-job-results'>
        <ConnectedAddJobResultsButton id={job.id} text='Add Features to Map' width={170} />
      </div>
    );
  }
  
  // If the tool created an "error" file, show the contents here
  renderReportErrors(title) {
    const { job } = this.props;
    // console.log(job)
    let errors = [];
    const rawErrors = job.error?.tool;
    if (!rawErrors) return;

    const errorList = job.error.tool.trim().split('\n').map(e => <li>{e}</li>);

    const logBtn = <ImageButton text='Open Log' imageName='log' width={105} className='failed-open-btns'
      onClick={() => {this.openLog()}}
    />

    const supportHref = `mailto:info@proksee.ca?subject=Proksee%20Job%20Problem.%20Project-Job%3A%20${this.cgv?.id}-${job.id}`;

    //<p className='center'><strong>Job Failed</strong>: Results and featured files may not be available.</p>
    const failed = (
      <div>
        <div className='center'>
          <strong style={{fontSize: 'larger'}}>Job Errors</strong>
        </div>
        <ul className='troubleshoot-list'>
          <li>
            <div className='ps-space-between'><strong>The job finished with the following errors:</strong></div>
            <ul>
              {errorList}
            </ul>
          </li>
          <li>
            <div className='ps-space-between'><strong>Check the Log</strong>{logBtn}</div>
          </li>
          <li>
            <div><strong> For help please contact support</strong></div>
            <ul>
              <li><a href={supportHref}>info@proksee.ca</a></li>
              <li>Please provide your project and job ids as well as any relevant information. The email link above will automatically include the project and job ids in the subject line.</li>
              <ul>
                <li>Project ID: <span className='failed-id'>{this.cgv?.id}</span></li>
                <li>Job ID: <span className='failed-id'>{job.id}</span></li>
              </ul>
            </ul>
          </li>
        </ul>
      </div>
    );

    return this.renderAlert(failed)
  }

  renderReportHeader(title) {
    const { job } = this.props;
    const tool = Tools.get(job.tool.publicID);
    const headerTitle = title || `${tool.name} Report`;
    return (
      <div className='report-header'>
        {headerTitle}
      </div>
    );
  }

  // - mapData: if false, nothing is rendered
  // - mapData: if undefined/null, "Loading..." is rendered
  // - mapData: if object conatins features property, the table is rendered
  // - mapData: if object does not conatin features property, "No Map Data Returned" is rendered
  renderMapResultsTable(mapData) {
    console.log(mapData)
    if (mapData === false) return null;
    const { job } = this.props;
    const tool = Tools.get(job.tool.publicID);
    const comma = helpers.commaNumber;
    // const featureCount = mapData && mapData.features && mapData.features.length;
    const featureCount = mapData?.features?.length;

    let tableBody;
    if (mapData) {
      tableBody = mapData.features ? (
          <tbody>
            <tr><td className='first-column'>Features found</td><td className='right'><strong>{comma(featureCount)}</strong></td></tr>
            <tr><td colSpan='2'>{this.renderAddResultsButton()}</td></tr>
          </tbody>
      ) : (
          <tbody>
            <tr><td className='center muted' colSpan='2'><strong>No Map Data Returned</strong></td></tr>
          </tbody>
      );
    } else {
      tableBody = (
          <tbody>
            <tr><td className='center muted' colSpan='2'><strong>Loading...</strong></td></tr>
          </tbody>
      );
    }

    // const tableBody = mapData?.features ? (
    //     <tbody>
    //       <tr><td className='first-column'>Features found</td><td className='right'><strong>{comma(featureCount)}</strong></td></tr>
    //       <tr><td colSpan='2'>{this.renderAddResultsButton()}</td></tr>
    //     </tbody>
    // ) : (
    //     <tbody>
    //       <tr><td className='center muted' colSpan='2'><strong>No Map Data Returned</strong></td></tr>
    //     </tbody>
    // );
    return (
      <table className='p-table more-width'>
        <thead>
          <tr className='table-title'><th colSpan='2'>{tool.name} Results</th></tr>
        </thead>
        { tableBody }
      </table>
    );
  }

  // Returns a button for file with the provided path
  renderFeaturedFile(path, description) {
    const { job } = this.props;
    console.log(job)

    const fileName = path.split('/').pop();
    const displayPath = path.replace('ROOT/', '');

    // Setup DataCard link for this file (if there is one)
    const tool = Tools.get(job.tool.publicID);
    // Find first output that uses this path (skipping reports)
    const output = tool && tool.dataCardOutputs().find( out => (out.data === displayPath && out.id !== 'report'));
    const dataCardBtn = output && (
      <ImageButton text='Data Card' imageName='dataCard' width={100}
        onClick={() => {this.openDataCard(output.id)}}
      />
    );

    return (
      <div className='featured-file'>
        <div className='file-name'>{fileName}</div>
        <div className='path'>{displayPath}</div>
        <div className='description'>{description}</div>
        <div className='file-action-buttons'>
          <ImageButton text='View File' imageName='file' width={100}
            onClick={() => {this.openFiles([path], {viewerPosition: 'full'})}}
          />
          <ImageButton text='Download' imageName='download' width={100}
            onClick={() => {this.downloadFile(displayPath)}}
          />
          {dataCardBtn}
        </div>
      </div>
    );
  }

  // By default this will use the "featuredFiles" key in the output
  // fileListArg: array of objects with 2 keys:
  //  - path (e.g. output_Prokka/prokka.gff)
  //  - descritpion
  renderFeaturedFilesTable(fileListArg) {
    const { output } = this.props;
    const fileList = fileListArg || output.featuredFiles || [];
    if (fileList.length === 0) return null;
    const featuresFilesHeader = (
      <div className='featured-files-header'>
        <div>Featured Files</div>
        <ImageButton text='All Files' imageName='file' width={90} className='btn-all-files'
          onClick={() => {this.openFiles([], {viewerPosition: 'bottom'})}}
        />
      </div>
    );
    const rows = fileList.map( f => <tr key={f.path}><td><ConnectedFeaturedFile path={`ROOT/${f.path}`} description={f.description} dataCard={this} jobID={this.props.job.id} /></td></tr> );
    // const rows = fileList.map( f => <tr key={f.path}><td>{this.renderFeaturedFile(`ROOT/${f.path}`, f.description)}</td></tr> );
    return (
      <table className='p-table more-width featured-files-table'>
        <thead><tr className='table-title'><th>{featuresFilesHeader}</th></tr></thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  // links: array of obejcts with 2 keys:
  // - url
  // - name
  renderHelpfulLinks(linksArg) {
    const { output } = this.props;
    const links = linksArg || output.helpfulLinks || [];
    if (links.length === 0) return null;
    const rows = links.map( l => <tr key={l.name}><td><ExternalLink name={l.name} link={l.url} size={12} /></td></tr> );
    return (
      <table className='p-table more-width'>
        <thead><tr className='table-title'><th>Helpful Links</th></tr></thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  renderCitation(citation) {
    const { job } = this.props;
    const tool = Tools.get(job.tool.publicID);
    if (!tool?.citation) return null;
    return (
      <table className='p-table more-width'>
        <thead><tr className='table-title'><th>Tool Citation</th></tr></thead>
        <tbody><tr><td>
          <div className='report-citation'>{tool.renderCitation()}</div>
        </td></tr></tbody>
      </table>
    );
  }

  renderReportFailed() {
    const { job } = this.props;

    const logBtn = <ImageButton text='Open Log' imageName='log' width={105} className='failed-open-btns'
      onClick={() => {this.openLog()}}
    />
    const filesBtn = <ImageButton text='Open Files' imageName='file' width={105} className='failed-open-btns'
      onClick={() => {this.openFiles([], {viewerPosition: 'bottom'})}}
    />

    const supportHref = `mailto:info@proksee.ca?subject=Proksee%20Job%20Problem.%20Project-Job%3A%20${this.cgv?.id}-${job.id}`;

    //<p className='center'><strong>Job Failed</strong>: Results and featured files may not be available.</p>
    const failed = (
      <div>
        <div className='center'>
          <strong style={{fontSize: 'larger'}}>Job Failed</strong>
          <p>Results and featured files may not be available</p>
        </div>
        <div className='troubleshoot-heading'>Troubleshooting Steps</div>
        <ul className='troubleshoot-list'>
          <li>
            <div className='ps-space-between'><strong>Check the Log</strong>{logBtn}</div>
            <ul>
              <li>The log card shows the output of Proksee as well as the underlying tool software.</li>
              <li>The log (especially the end) may offer hints as to what went wrong.</li>
            </ul>
          </li>
          <li>
            <div className='ps-space-between'><strong>Check the Files</strong>{filesBtn}</div>
            <ul>
              <li>The files card shows all the input and output files from the job.</li>
              <li>If you provided input files for this job, are they in the correct format?</li>
              <li>Some jobs may have additional logs that can be checked.</li>
            </ul>
          </li>
          <li>
            <div><strong>Rerun the Tool</strong></div>
            <ul>
              <li>There may have been an issue with the Proksee job server.</li>
            </ul>
          </li>
          <li>
            <div><strong>Contact Support</strong></div>
            <ul>
              <li><a href={supportHref}>info@proksee.ca</a></li>
              <li>Please provide your project and job ids as well as any relevant information. The email link above will automatically include the project and job ids in the subject line.</li>
              <ul>
                <li>Project ID: <span className='failed-id'>{this.cgv?.id}</span></li>
                <li>Job ID: <span className='failed-id'>{job.id}</span></li>
              </ul>
            </ul>
          </li>
        </ul>
      </div>
    );
    if (job?.status === 'failed') {
      return this.renderAlert(failed)
    }
  }

  renderDefaultReport(mapData) {
    return (
      <div className='report-card'>
        { this.renderReportHeader() }
        { this.renderReportErrors() }
        { this.renderReportFailed() }
        { this.renderMapResultsTable(mapData) }
        { this.renderFeaturedFilesTable() }
        { this.renderHelpfulLinks() }
        { this.renderCitation() }
      </div>
    );
  }


  // Override with method to diplay data in output card
  renderData(data) {
  }

  // // Centers 'data' message in DataCard
  // renderEmptyData(data) {
  //   return <div className='data-empty'>{data}</div>;
  // }

  // For reports the empty data will still render the report
  renderEmptyData(data) {
    return this.renderData();
  }

  render() {
    const { job } = this.props;
    const { data, status } = this.state;
    // console.log(status, data)

    if (job.finalized && status === 'waiting') {
      this.getData();
    }

    let contents;
    try {
      contents = (status === 'complete') ? this.renderData(data) : this.renderEmptyData(data);
    } catch(exception) {
      contents = this.renderEmptyData("This DataCard could not be displayed");
      console.error(exception);
    }

    return (
      <div className='DataCard'>
        {contents}
        {this.renderFooter()}
      </div>
    );
  }

}

export default ReportCardBase;

