import Output from './Output';

class Outputs {

  constructor(tool, outputs) {
    this.tool = tool;
    this.createOutputs(outputs);
  }

  createOutputs(outputs) {
    this._outputs = {};
    if (outputs) {
      for (const key of Object.keys(outputs)) {
        this._outputs[key] = new Output(key, outputs[key], this.tool);
      }
    }
  }

  get(id) {
    if (!this._outputs[id]) {
      console.error(`ERROR: No Output with id '${id}'`);
    }
    return this._outputs[id]
  }

  get ids() {
    return Object.keys(this._outputs);
  }

  get list() {
    return Object.values(this._outputs);
  }

}

export default Outputs;
