import React from 'react';
import Prism from 'prismjs';
import DataElement from '../../presenters/DataElement';
import DataElementGroup from '../../presenters/DataElementGroup';
import Font from '../../presenters/Font';
import Switch from '../../presenters/Switch';
import { Select, Option } from '../../presenters/Select';
import './CSVFileViewer.css';
import Papa from 'papaparse'
import * as helpers from '../../support/Helpers';

class CSVFileViewer {

  constructor(fileViewer) {
    this._fileViewer = fileViewer;
    // this._settings = settings;
    // SET settings with provided settings or default
  }

  get fileViewer() {
    return this._fileViewer;
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'csv';
  }

  static get displayFileType() {
    return 'CSV';
  }

  get settingsHeight() {
    return 110;
  }

  defaultSettings() {
    return {
      font: 'monospace, plain, 12',
      header: true,
      delimiter: 'auto',
    }
  }

  onChangeSettings({value, attribute}) {
    this.fileViewer.onChangeSettings({value, attribute});
  }

  displaySettings(settings=this.defaultSettings()) {
    return (
      <div>
        <DataElement label='Font'>
          <Font
            fontString={settings.font}
            onChange={(value) => this.onChangeSettings({value, attribute: 'font'})}
          />
        </DataElement>
        <DataElementGroup>
          <DataElement label='Header Row'>
            <Switch
              value={settings.header}
              onChange={(value) => this.onChangeSettings({value, attribute: 'header'})}
            />
          </DataElement>
          <DataElement label='Delimiter'>
            <Select value={settings.delimiter}
              onChange={(value) => this.onChangeSettings({value, attribute: 'delimiter'})}>
              <Option value="auto">Auto</Option>
              <Option value="comma">Comma</Option>
              <Option value="tab">Tab</Option>
            </Select>
          </DataElement>
        </DataElementGroup>
      </div>
    );
  }

  displayData(file, data, settings=this.defaultSettings()) {
    const style = {font: Font.stringToCSS(settings.font)};
    const delimiterMap = {auto: '', tab: '\t', comma: ','}
    const csv = Papa.parse(data, {
      // header: settings.header,
      delimiter: delimiterMap[settings.delimiter],
      skipEmptyLines: true,
      comments: '#',
      error: (error, file) => {console.log(error)}
    });

    console.log(csv.data)
    let header;
    if (settings.header) {
      const headerItems = csv.data.shift();
      header = <thead><tr>{headerItems.map( (h,i) => <th key={i}>{h}</th> )}</tr></thead>
    }
    const body = csv.data.map( (r,i1) => <tr key={i1}>{r.map( (d,i2) => <td key={i2}>{d}</td> )}</tr> );

    return (
      <div className='CSVFileViewer file-data-text' style={style}>
        <table>
          {header}
          <tbody>
            {body}
          </tbody>
        </table>
      </div>
    );
  }

}

export default CSVFileViewer;
