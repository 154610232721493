import React from 'react';
import ToolComponents from '../constants/ToolComponents';
import DefaultDialogAdd from '../presenters/DefaultDialogAdd';
import DefaultDialogStart from '../presenters/DefaultDialogStart';
import * as helpers from '../support/Helpers';

// NOTE: This needs to match up with with Tool::DEFAULT_COMPONENTS in tool.rb
const defaults = {DefaultDialogStart, DefaultDialogAdd};

class Component {

  constructor(tool, id, data) {
    this.tool = tool;
    this.id = id;
    if (ToolComponents[tool.id][id]) {
      this.react = ToolComponents[tool.id][id];
    } else {
      const capitalizedID = helpers.capitalize(id);
      const defaultComponents = Object.keys(defaults).map( d => d.replace('Default', ''));
      if (defaultComponents.includes(capitalizedID)) {
        this.react = defaults[`Default${capitalizedID}`];
      }

    }
    if (typeof data === 'object' && data !== null) {
      for (const key of Object.keys(data)) {
        this[key] = data[key];
      }
    }
    if (this.valid === undefined) {
      this.valid = true;
    }
    if (this.mapDataSummary === undefined) {
      this.mapDataSummary = true;
    }
    this.updateDefaults();
  }

  updateDefaults() {
    if (!this.title) {
      const name = this.tool.short_name || this.tool.name;
      switch (this.id) {
        case 'DialogStart':
          this.title = `Start ${name} Job`;
          break;
        case 'DialogAdd':
          this.title = `Add ${name} Results`;
          break;
      }
    }
  }

  // renderTitle() {
  //   return <div>{this.title}</div>
  // }

  renderDescription() {
    return <div>{this.description}</div>
  }

}

export default Component

