// NOTES:
// - A DataElement is a single element (e.g text input, select, swtich, etc) with a label and optional help
// - A DataElementGroup is a row of of DataElements, filled to take the available space (by width)
// - A DataElementContainer contains one or more DataElements and/or DataElementGroups
//   - The container has a label
//   - A dashed box around the elements and groups
//   - Optional help (right below the label?)
//   - Optional control element ot hide/show the box
//   - Props:
//     - label
//     - help
//     - children
//     - maxHeight - default 100px (allows adjusting for better animation)
//     - closeable - default false
//     - closed - default false
//     - helpBottom - default false NOT IMPLEMENTED YET (have hlep at bottom of container in stead of at top
//
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HelpLink from './HelpLink'
import './DataElementContainer.css';

DataElementContainer.propTypes = {
  className: PropTypes.string,
  label:     PropTypes.node,
  children:  PropTypes.node.isRequired,
  maxHeight:  PropTypes.string,
  help:      PropTypes.string,
  helpPath:  PropTypes.string,
  closable:  PropTypes.bool,
  closed:    PropTypes.bool,
}

DataElementContainer.defaultProps = {
  maxHeight: '100px',
  closable: false,
  closed: false,
}

function DataElementContainer(props) {
  const {
    className,
    label,
    children,
    maxHeight,
    help,
    helpPath,
    closable,
    closed,
  } = props;

  const [isClosed, setIsClosed] = useState(closed);

  const elementClass = classNames(
    'DataElementContainer', className,
    {closable},
    {closed: isClosed},
  );

  const childClass = classNames(
    'container-children',
    'scroll-skinny',
  );

  // Appears below element
  const renderHelp = help ? <div className='help-text' dangerouslySetInnerHTML={{__html: help}}></div> : '';

  // Appears beside element label
  const renderHelpLink = helpPath ? <HelpLink  helpPath={helpPath} /> : '';

  // Note: The max-height style may be removed entirely for not closable elements
  const height = isClosed ? 0 : maxHeight;

  return (
    <div className={elementClass}>
      <div className='label'
        onClick={() => closable && setIsClosed(!isClosed)}
      >
        {label}
        {renderHelpLink}
        {renderHelp}
      </div>
      <div className={childClass} style={{maxHeight: height}}>
        {children}
      </div>
    </div>
  )
}

export default DataElementContainer


