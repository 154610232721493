import React from 'react';

import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';

class BaktaDataCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  renderData(data) {
    const header = ['SequenceID', 'Type', 'Start', 'Stop', 'Strand', 'Locus Tag', 'Gene', 'Product', 'DbXrefs']
    return (
      <VirtualGrid
        data={data}
        header={header}
        alternateRowColors
        rowHeight={30}
        defaultColumnWidth={80}
      >
        <Column index={0} width={150} />
        <Column index={2} width={80} type='number' />
        <Column index={3} width={80} type='number' />
        <Column index={4} width={50}  />
        <Column index={5} width={150}  />
        <Column index={7} width={180}  />
        <Column index={8} width={350} linkerProps={{ join: ', ', delimiter: ',', url: 'https://psos-staging.computational.bio/api/v1/dbxref/redirect/{VALUE}'}} />
      </VirtualGrid>
    );
  }

}

export default BaktaDataCard;

