import { UI_SQUARE_LOCK_CHANGE, UI_THEME_UPDATE } from '../constants/ActionTypes';

export const changeSquareLock = (squareLock) => ({
  type: UI_SQUARE_LOCK_CHANGE,
  payload: {
    squareLock,
  }
});

export const updateTheme = (theme) => ({
  type: UI_THEME_UPDATE,
  payload: {
    theme,
  }
});
