import React from 'react'
import ReactDOM from 'react-dom'
import './NewProject.css';
import PropTypes from 'prop-types'
import FileInput from "../proksee-viewer/presenters/FileInput";
import Button from "../proksee-viewer/presenters/Button";
import Tabs from '../proksee-viewer/presenters/Tabs';
import GenomeProject from './projects/GenomeProject';
import ReadsProject from './projects/ReadsProject';
import JSONProject from './projects/JSONProject';
// import GenomeProjectOLD from './projects/GenomeProjectOLD';

import { Tabs as AntTabs } from 'antd';
// import 'antd/dist/antd.css'; 
const TabPane = AntTabs.TabPane;

// class NewProject extends Tabs {
class NewProject extends React.Component {

  static propTypes = {
    role: PropTypes.oneOf(['admin', 'tester', '']),
    environment: PropTypes.oneOf(['development', 'production']),
  }

  static defaultProps = {
    environment:  'production',
  }

  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1', // Production value
      // activeKey: '2', // Testing
      // activeKey: '3', // Testing
      // activeKey: '4', // Testing
    };
  }


  onChange = (activeKey) => {
    this.setState({
      activeKey,
    });
  }

  // Only the active pane will send fieldName and source project values in form
  // This prevent duplicates from over riding each other
  render() {
    const { activeKey } = this.state;
    const { role, environment } = this.props;
    return (
      <Tabs activeKey={activeKey} onChange={this.onChange}>
        <TabPane tab='Genome' key='1' closable={false}>
          <GenomeProject Project active={activeKey === '1'} environment={environment} />
        </TabPane>
        <TabPane tab='Reads' key='2' closable={false}>
          <ReadsProject active={activeKey === '2'} role={role} environment={environment} />
        </TabPane>
        <TabPane tab='Map JSON' key='3' closable={false}>
          <JSONProject active={activeKey === '3'} environment={environment} />
        </TabPane>
        {/* <TabPane tab='OLDGenome' key='4' closable={false}>
          <GenomeProjectOLD active={activeKey === '4'} environment={environment} />
        </TabPane> */}
      </Tabs>
    )
  }
  //<TabPane tab='Reads' key='2' closable={false}>
  //<TabPane tab=<div>Reads <span className='beta-badge'>Beta</span></div> key='2' closable={false}>

}
export default NewProject;

