import React from 'react';
import PropTypes from 'prop-types';
import './ImageDownload.css';
import { CGViewContext } from '../../app/CGViewContext';
import { ListItem } from '../../presenters/ListItem';
import Button from '../../presenters/Button';
import ImageButton from '../../presenters/ImageButton';
import NumericInput from '../../presenters/NumericInput';
import * as helpers from '../../support/Helpers';
import iconLockClosed from '../../images/icon-lock-closed.png';
import iconLockOpen from '../../images/icon-lock-open.png';
import ServerAPI from '../../models/ServerAPI';
// Connected
import { connect } from 'react-redux';

class ImageDownload extends React.Component {

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    pixelRatio: PropTypes.number,
  }

  static defaultProps = {
    pixelRatio: 1,
    width: 100,
    height: 100,
  }

  constructor(props) {
    super(props);

    this.state = {
      infoOpen: true,
      lockAspectRatio: true,
      downloadWidth: this.viewerWidth,
      downloadHeight: this.viewerHeight,
      downloadPercent: 100,
    }

    this.maxDimensionSize = 8000;

    this.clickDownload = this.clickDownload.bind(this);
    this.clickLock = this.clickLock.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.lockAspectRatio) {
      const width = props.width * props.pixelRatio;
      const height = props.height * props.pixelRatio;
      return {
        downloadWidth: Math.round(width * state.downloadPercent / 100),
        downloadHeight: Math.round(height * state.downloadPercent / 100),
      }
    }
  }

  get viewerWidth() {
    const { width, pixelRatio } = this.props;
    return width * pixelRatio;
  }

  get viewerHeight() {
    const { height, pixelRatio } = this.props;
    return height * pixelRatio;
  }

  get maxPercent() {
    return this.maxDimensionSize / Math.max(this.viewerWidth, this.viewerHeight) * 100;
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    return (
      <div className='list-item-content'>
        <div className='list-item-title'>
          Image - PNG
        </div>
         <ImageButton
            imageName='download'
            title='Download PNG'
            className='download-button'
            onClick={ this.clickDownload }
          / >
      </div>
    );
  }

  infoRenderer() {
    const { downloadWidth, downloadHeight, downloadPercent, lockAspectRatio } = this.state;
    const viewerWidth = this.viewerWidth;
    const viewerHeight = this.viewerHeight;
    const maxPercent = this.maxPercent;

    const lockImage = lockAspectRatio ?
      <img alt='' src={iconLockClosed} /> :
      <img alt='' src={iconLockOpen} />;

    return (
      <div className='info-download-image'>
        <div>
          Download the map as a png image. The maximum size for the width/height is {this.maxDimensionSize}px.
        </div>
        <div className='download-grid'>
          <div></div><div className='header'>Download Size</div><div></div><div className='header'>Current Size</div>

          <label>Width:</label>
          <div>
              <NumericInput value={downloadWidth}
                min={1}
                max={this.maxDimensionSize}
                suffix=' px'
                onChange={(value) => this.adjustDimensions(value, 'downloadWidth')}
              />
          </div>
          <div className='lock-area'
            onClick={this.clickLock}
          >
            {lockImage}
            <div className='lock-lines'></div>
            <div className='lock-image-background'></div>
          </div>
          <div className='current-value'>{viewerWidth} px</div>

          <label>Height:</label>
          <div>
              <NumericInput value={downloadHeight}
                min={1}
                max={this.maxDimensionSize}
                suffix=' px'
                onChange={(value) => this.adjustDimensions(value, 'downloadHeight')}
              />
          </div>
          <div className='current-value'>{viewerHeight} px</div>

          <label>Percent:</label>
          <div>
              <NumericInput value={helpers.round(downloadPercent, 2)}
                min={1}
                max={maxPercent}
                suffix=' %'
                presision={2}
                onChange={(value) => this.adjustDimensions(value, 'downloadPercent')}
                disabled={!lockAspectRatio}
              />
          </div>
          <div></div>
          <div></div>

        </div>

        <div className='download-image'>
          <ImageButton
            className='btn-download-image'
            imageName='download'
            title='Download PNG'
            size='large'
            text='Download'
            width={105}
            onClick={ this.clickDownload }
          />
        </div>
      </div>
    );
  }

  // onChange({attribute, value}) {
  //   // this.setState( (state) => ({...state, [attribute]:value }));
  //   this.adjustDimensions(value, attribute);
  // }

  clickDownload() {
    const { downloadWidth: width, downloadHeight: height } = this.state;
    const cgv = this.context.cgv;
    const name = `proksee-${width}x${height}.png`;
    const Server = new ServerAPI();
    Server.get(Server.URL.downloadAction, {source: 'image_png', width, height});
    cgv.io.downloadImage(width, height, name);
  }

  clickLock() {
    const locked = !this.state.lockAspectRatio;
    this.setState( (state) => ({...state, lockAspectRatio: !state.lockAspectRatio }));
    if (locked) {
      this.adjustDimensions(this.state.downloadPercent, 'downloadPercent', true);
    }
  }

  adjustDimensions(value, dimensionType, locked) {
    const lockAspectRatio = (locked === undefined) ? this.state.lockAspectRatio : locked;
    let newWidth, newHeight, newPercent;
    const viewerWidth = this.viewerWidth;
    const viewerHeight = this.viewerHeight;
    const maxPercentFraction = this.maxPercent / 100;

    if (dimensionType === 'downloadWidth') {
      newWidth = Math.min(value, this.maxDimensionSize);
      newPercent = Math.min( (newWidth / viewerWidth), maxPercentFraction);
    }
    if (dimensionType === 'downloadHeight') {
      newHeight = Math.min(value, this.maxDimensionSize);
      newPercent = Math.min( (newHeight / viewerHeight), maxPercentFraction);
    }
    if (dimensionType === 'downloadPercent') {
      newPercent = Math.min(value/100, maxPercentFraction);
    }
    if (lockAspectRatio) {
      // NOTE: This is now handled by getDerivedStateFromProps
      // newWidth = Math.round(newPercent * viewerWidth);
      // newHeight = Math.round(newPercent * viewerHeight);
    }

    if (newHeight) {
      this.setState( (state) => ({ ...state, downloadHeight: newHeight }));
    }
    if (newWidth) {
      this.setState( (state) => ({ ...state, downloadWidth: newWidth }));
    }

    this.setState( (state) => ({ ...state, downloadPercent: (newPercent * 100) }));
  }


  render() {
    return (
      <div className='ImageDownload'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

ImageDownload.contextType = CGViewContext;

//Connected
const imageDownloadMapStateToProps = (state) => ({
  width: state.viewer.width,
  height: state.viewer.height,
  pixelRatio: state.viewer.pixelRatio
});
const ConnectedImageDownload = connect(imageDownloadMapStateToProps)(ImageDownload);

// export default ImageDownload;
export { ImageDownload, ConnectedImageDownload };

