// This class will let me load Proksee buttons for the help document. They will not have any function.
import React from 'react'
import ImageButton from "../proksee-viewer/presenters/ImageButton";

class ProxyButton extends React.Component {

  render() {
    const { imageName, size } = this.props;
    return (
      <ImageButton
        imageName={imageName}
        size={size}
        inline
      / >
    )
  }

}
export default ProxyButton;

