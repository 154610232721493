import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import TextElement from './TextElement';
import classNames from 'classnames';
import './Color.css';
import * as helpers from '../support/Helpers';
import { Color as CGColor, ColorPicker } from '../../CGView.js';

Color.propTypes = {
  colorString:       PropTypes.string,
  className:         PropTypes.string,
  onChange:          PropTypes.func,
  showColorString:   PropTypes.bool,
  undefinedString:   PropTypes.string,
  onDefaultClick:    PropTypes.func,
  allowDefault:      PropTypes.bool,
  isDefault:         PropTypes.bool,
  // disabled:    PropTypes.bool,
}

Color.defaultProps = {
  onClick:  () => {},
  showColorString: true,
  undefinedString: 'None',
  // disabled: false,
}

function Color(props) {
  const {
    className,
    colorString,
    showColorString,
    onChange,
    allowDefault,
    isDefault,
    onDefaultClick,
    undefinedString,
  } = props;

  const colorClass = classNames(
    'Color', className,
    {'default-on': isDefault}
  );

  // Create unique ID for color picker;
  const [id] = useState(helpers.uniqueID('color-picker-'));

  // TODO: this will be part of ColorPicker
  const borderColor = (color) => {
    // const rgb = color.rgb;
    const rgb = color.rgb;
    if (rgb.r > 200 && rgb.g > 200 && rgb.b > 200) {
      const min = Math.min(rgb.r, rgb.g, rgb.b);
      // B4B4B4 = 180, 180, 180
      // swatchEl.style.border = '1px solid #B4B4B4';
      // FIXME: Need fix for themes
      const borderColor = helpers.scaleValue(min, {min: 200, max: 255}, {min: 235, max: 210})
      return `rgb(${borderColor},${borderColor},${borderColor})`;
      // swatchEl.style.border = `1px solid rgb(${borderColor},${borderColor},${borderColor})`;
    } else {
      return color.rgbString;
    }
  }

  const onSwatchClick = (e) => {
    const swatchEl = e.target.classList.contains('color-swatch') ? e.target : e.target.closest('.color-swatch');

    // const cp = new window.CGV.ColorPicker(id);
    const cp = new ColorPicker(id);

    // Set position
    const rect = swatchEl.getBoundingClientRect()
    const x = ( (rect.left + cp.width) <= window.innerWidth ) ? 0 : ( rect.width - cp.width );
    const pos = {x, y: rect.height + 1};
    cp.setPosition(pos);

    // Initial color
    const swatchColor = swatchEl.classList.contains('color-undefined') ?
      'rgba(125,125,125,1)' :
       getComputedStyle(swatchEl)['background-color'];

    // Handle color changes
    cp.onChange = (color) => {
      onChange(color.rgbaString);
      swatchEl.style.border = `1px solid ${borderColor(color)}`;
    }
    cp.setColor(swatchColor);
    // Reset the opacity unless the color contains an alpha value
    // i.e. rgba instead of rgb
    if (!swatchColor.includes('rgba')) {
      cp.opacity = 1;
      cp.updateColor();
    }
    cp.open();

    // Handle document clicks to close ColorPicker
    const clickEventListner = function(e) {
      if (!e.target.closest(`#${id}`) && e.target !== swatchEl && !e.target.classList.contains('theme-button')) {
        cp.close();
        document.removeEventListener("click", clickEventListner);
      }
    }
    document.addEventListener("click", clickEventListner);
  }

  const colorUndefined = (colorString === undefined);

  // const color = new window.CGV.Color(colorString || 'white');
  const color = new CGColor(colorString || 'white');
  const borderColorString = borderColor(color);
  const style = {};
  if (!colorUndefined) {
    style.backgroundColor = color.rgbaString;
    style.border = `1px solid ${borderColorString}`;
  }

  const label = colorUndefined ? undefinedString : colorString;
  const renderedColorString = showColorString ? 
      <div className='color-name'>{label}</div> : '';
  // const renderedColorString = showColorString ? 
  //   <TextElement className='color-name'>{label}</TextElement> : '';

  let renderedDefault;
  if (allowDefault) {
    renderedDefault = isDefault ?
      <div className='default-text'>Default</div> :
      <Button onClick={ onDefaultClick }>Default</Button>
  }

  const swatchCross = colorUndefined && 
    <svg className='color-undefined' height='24' width='24' >
      <path d='M 0 0 L 22 22 M 22 0 L 0 22' />
    </svg>

  const swatchClass = classNames(
    'color-swatch',
    {'color-undefined': colorUndefined}
  )

  return (
    <div className={colorClass} >
      <div className='swatch-and-name'>
        <div className={swatchClass}
          style={style}
          onClick={onSwatchClick}
        >{swatchCross}</div>
        {renderedColorString}
      </div>
      {renderedDefault}
      <div className='color-picker-container' id={id} />
    </div>
  )
}

export default Color;

