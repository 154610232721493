import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import { CGViewContext } from '../app/CGViewContext';
// import classNames from 'classnames';

// NOTE: all subclasses will have access to 'cgv' through 'this.cgv'

class BasicDialog extends React.Component {

  static contextType = CGViewContext;

  static propTypes = {
    onClose: PropTypes.func,
    options: PropTypes.object,
    title:   PropTypes.string,
    valid:   PropTypes.bool,
  }

  // This can be overwritten to chagne the default props
  static defaultProps = {
    options: {},
    valid: true,
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      options: props.options,
      valid: props.valid,
    };
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onValidate = this.onValidate.bind(this);

    this.cgv = this.context.cgv;
  }

  onChange({attribute, value}, callback) {
    // console.log(attribute, value)
    this.setState((state) => ({
      options: {
        ...state.options,
        [attribute]: value,
      }
    }), callback);
  }

  // Add any options to the provided onClose function
  onClose({action, dialog} = {}) {
    const { options } = this.state;
    // console.log(options)
    this.onLocalClose({action, options});
    this.props.onClose({action, options, dialog});
  }

  onValidate(valid) {
    this.setState({valid});
  }

  // Override in subclasses deal with the closed dialog directly in the sub classed dialog
  onLocalClose({action, options}) {
  }

  // Override in subclasses
  renderTitle() {
  }

  // Override in subclasses
  renderContents() {
  }

  // It's also possible to override 'render' for even more control
  render() {
    const { valid } = this.state;
    return (
      <Dialog
        {...this.props}
        onClose={this.onClose}
        okButtonProps={{disabled: !valid}}
        title={this.props.title || this.renderTitle()}
      >
        {this.props.contents || this.renderContents()}
      </Dialog>
    );
  }
}

export default BasicDialog;
