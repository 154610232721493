import React from 'react';
// import File from './File';
import * as helpers from '../../support/Helpers';
import * as validations from '../../support/Validations';

class CGViewFile {

  constructor(file) {
    this._file = file;
  }

  get file() {
    return this._file;
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'cgview';
  }

  get metaKeys() {
    return ['version', 'name', 'id', 'featureCount', 'trackCount', 'contigCount'];
    return [];
  }

  get validationKeys() {
    return ['allowedColumns', 'requiredColumns', 'rowCount'];
  }

  processText(text) {
    const json = JSON.parse(text);
    if (json.cgview) {
      const cgv = json.cgview;
      this.version = cgv.version;
      this.id = cgv.id;
      this.name = cgv.name;
      this.featureCount = cgv.features ? cgv.features.length : 0;
      this.trackCount = cgv.tracks ? cgv.tracks.length : (cgv.layout && cgv.layout.tracks && cgv.layout.tracks.length || 0);
      // this.contigCount = cgv.sequence &&  ? cgv.tracks.length : 0;
    }
    console.log(json)
  }

  // No validation done at this time
  validate(rules={}) {
    const validationErrors = [];
    return {ok: (validationErrors.length === 0), errors: validationErrors};
  }

  // Default:
  // - number of rows
  // - column names
  // Required/allowable columns provided:
  // - number of rows
  // - allowable/required columns
  // - ignored columns
  static detailsRenderer(details={}, rules={}) {
    const v = validations;
    // const allColumns = details.meta.columns;
    // let columns = [];
    // const ignored = [];
    // let ignoredRow;
    // if (rules && rules.allowedColumns) {
    //   for (const column of allColumns) {
    //     v.validateString(column, rules.allowedColumns) ? columns.push(column) : ignored.push(column);
    //   }
    //   if (ignored.length > 0) {
    //     ignoredRow = <tr><td>Ignored Columns:</td><td>{ignored.join(', ')}</td></tr>;
    //   }
    // } else {
    //   columns = allColumns;
    // }
    return (
      <table>
        <tbody>
          <tr><td>JSON Version:</td><td>{details.meta.version}</td></tr>
          <tr><td>Map Name:</td><td>{details.meta.name}</td></tr>
          <tr><td>Map ID:</td><td>{details.meta.id} [This will change to a new project ID]</td></tr>
          <tr><td>Features:</td><td>{details.meta.featureCount}</td></tr>
          <tr><td>Tracks:</td><td>{details.meta.trackCount}</td></tr>
        </tbody>
      </table>
    )
  }

}

export default CGViewFile;
