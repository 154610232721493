export default function add({inputs, mapData}) {

  // TODO:- evidence-skip level 1: false

  // Filter mapData based on DialogAdd inputs

  if (!inputs.add_crisprs) {
    mapData.features = mapData.features.filter( f => f.type !== 'CRISPR');
  }
  if (!inputs.add_cas_clusters) {
    mapData.features = mapData.features.filter( f => f.type !== 'Cas Cluster');
  }
  if (!inputs.add_cas_genes) {
    mapData.features = mapData.features.filter( f => f.type !== 'CDS');
  }

  return { ok: true, mapData };
}

