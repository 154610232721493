import { DIVIDER_UPDATE } from '../constants/ActionTypes'


export const updateDivider = (divider, attributes) => ({
  type: DIVIDER_UPDATE,
  payload: {
    divider,
    attributes,
  }
});


