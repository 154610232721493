import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import TextElement from './TextElement';
import ImageButton from './ImageButton';
import * as helpers from '../support/Helpers';
import './ItemBar.css';

class ItemBar extends React.Component {

  static propTypes = {
    title : PropTypes.string,
    totalItemCount: PropTypes.number,
    filteredItemCount: PropTypes.number,
    selectedItemCount: PropTypes.number,
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    addAction: PropTypes.func,
    otherActions: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.node,
      imageName: PropTypes.string,
      position: PropTypes.oneOf(['start', 'end']),
      onClick: PropTypes.func,
    })),
  }

  static defaultProps = {
    title: 'Rows',
    otherActions: [],
    // filteredItemCount: 0,
    // selectedItemCount: 0,
  }

  renderAddAction() {
    const { addAction } = this.props;
    if (addAction) {
      return (
        <ImageButton imageName='add' size='default'
          title='Add Item' onClick={ addAction } />
      );
    }
  }

  renderEditAction() {
    const { selectedItemCount, editAction } = this.props;
    if (editAction && selectedItemCount > 0) {
      return (
        <ImageButton imageName='edit' size='default'
          title='Edit Items' onClick={ editAction } />
      );
    }
  }

  renderDeleteAction() {
    const { selectedItemCount, deleteAction } = this.props;
    if (deleteAction && selectedItemCount > 0) {
      return (
        <ImageButton className='delete-btn' imageName='delete' size='default'
          title='Delete Items' onClick={ deleteAction } />
      );
    }
  }

  itemBarText() {
    const { title, totalItemCount, filteredItemCount, selectedItemCount } = this.props;
    let description = '';
    let totalText = '';
    let count = totalItemCount;
    if (selectedItemCount !== undefined && filteredItemCount !== undefined) {
      description = (selectedItemCount > 0) ? 'Selecting' : 'Showing';
      if (selectedItemCount > 0 || filteredItemCount < totalItemCount) {
        totalText = ` (${helpers.commaNumber(totalItemCount)} total)`;
        count = (selectedItemCount > 0) ? selectedItemCount : filteredItemCount;
      }
    }
    return `${title}: ${description} ${helpers.commaNumber(count)}${totalText}`;
  }

  renderOtherStartActions() {
    const { otherActions } = this.props;
    const startActions = otherActions
      .filter( a => a.position == 'start')
      .map( a => (
        <ImageButton
          key={a.title}
          image={a.image}
          imageName={a.imageName}
          size='default'
          title={a.title}
          onClick={a.onClick} />
      ));
    return <div>{startActions}</div>
  }

  renderOtherEndActions() {
    const { otherActions } = this.props;
    const endActions = otherActions
      .filter( a => a.position == 'end')
      .map( a => (
        <ImageButton
          className='end-button'
          key={a.title}
          image={a.image}
          imageName={a.imageName}
          size='default'
          title={a.title}
          onClick={a.onClick} />
      ));
    return <div>{endActions}</div>
  }

  render() {
    return  (
      <div className='ItemBar'>
        {this.renderOtherStartActions()}
        {this.renderAddAction()}
        {this.renderEditAction()}
        <div className='item-summary'>
          {this.itemBarText()}
        </div>
        {this.renderDeleteAction()}
        {this.renderOtherEndActions()}
      </div>
    );
  }

  //<TextElement>{this.itemBarText()}</TextElement>
}
export default ItemBar;
