import React from 'react';
import ReactDOM from 'react-dom';
import './UserIndex.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ServerAPI from '../proksee-viewer/models/ServerAPI';
import DataElement from '../proksee-viewer/presenters/DataElement';
import DataElementGroup from '../proksee-viewer/presenters/DataElementGroup';
import TextElement from '../proksee-viewer/presenters/TextElement';
import { VirtualTable, Column } from '../proksee-viewer/presenters/VirtualTable';
import * as helpers from "../proksee-viewer/support/Helpers";
import iconOK from '../proksee-viewer/images/icon-ok.png';
import ImageButton from '../proksee-viewer/presenters/ImageButton';

class UserIndex extends React.Component {

  static propTypes = {
    users:   PropTypes.array,
  }

  constructor(props) {
    super(props);
    console.log(props.users)
    this.state = {
      virtualTableRef: React.createRef(),
      // projects: [],
    }
    this.infoBoxRenderer = this.infoBoxRenderer.bind(this);
    this.dateCreatedCellRenderer = this.dateCreatedCellRenderer.bind(this);
  }

  nameCellRenderer({ rowData: user }) {
    const name = user.name || 'Untitled';
    const klass = classNames( 'btn-user', `role-${user.role}`);
    return (
      <a className={klass} onClick={(e) => e.stopPropagation() } href={`/users/${user.id}`}>
        <div className='column-value'>
          <div className='column-value-main'>
            {name}
            {user.confirmed &&
              <ImageButton
                className='user-confirmed-check'
                image={iconOK}
                size='small'
                type='plain'
                disabled
              />
            }
          </div>
          <div className='column-value-sub monospace user-role user-rol'>{user.role}</div>
          <div className='column-value-sub monospace'>{user.email}</div>
        </div>
      </a>
    )
  }

  dateRenderer(date) {
    const dt = helpers.dateTime(date);
    return (
      <div className='column-value'>
        <div className='column-value-main'>{dt.friendlyDate}</div>
        <div className='column-value-sub'>{dt.time}</div>
      </div>
    )
  }

  dateCreatedCellRenderer({ rowData: user }) {
    return this.dateRenderer(user.created_at);
  }

  sizeCellRenderer({ rowData: user }) {
    return (
      <div className='column-value column-storage'>
        <div className='column-value-main storage-quota-used'>{helpers.formatBytes(user.storage_used)}</div>
        <div className='column-value-sub column-storage-quota'>{helpers.formatBytes(user.storage_quota)}</div>
      </div>
      // <div className='list-storage-container'>
      //   <div>{helpers.formatBytes(user.storage_used)}</div>
      //   <div class='list-storage-quota'>{helpers.formatBytes(user.storage_quota)}</div>
      // </div>
    )
    return (helpers.formatBytes(user.storage_used));
  }

  infoBoxRenderer(user) {
    return (
      <div>
        <DataElementGroup>
          <DataElement label='Name'>
            <TextElement>{user.name}</TextElement>
          </DataElement>
          <DataElement label='Role'>
            <TextElement>{user.role}</TextElement>
          </DataElement>
          <DataElement label='ID'>
            <TextElement>{user.id}</TextElement>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Storage Used'>
            <TextElement>{helpers.formatBytes(user.storage_used)}</TextElement>
          </DataElement>
          <DataElement label='Storage Quota (Total)'>
            <TextElement>{helpers.formatBytes(user.storage_quota)}</TextElement>
          </DataElement>
          <DataElement label='Storage Quota (Bonus)'>
            <TextElement>{helpers.formatBytes(user.storage_quota_bonus)}</TextElement>
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Email'>
            <TextElement>{user.email}</TextElement>
          </DataElement>
          <DataElement label='Confirmed'>
            <TextElement>{user.confirmed ? 'Yes' : 'No'}</TextElement>
          </DataElement>
          <DataElement label='Project Count'>
            <TextElement>{user.project_count}</TextElement>
          </DataElement>
        </DataElementGroup>
      </div>
    )
  }

  render() {
    const { users } = this.props;
    return (
      <div className='UserIndex'>
        <VirtualTable
          headerHeight={20}
          rowHeight={36}
          infoHeight={170}
          data={users}
          dataName='Users'
          idKey='id'
          infoRenderer={this.infoBoxRenderer}
          ref={this.state.virtualTableRef}
        >
          <Column
            label='ID'
            dataKey='id'
            hidden={true}
          />
          <Column
            label='Email'
            dataKey='email'
            hidden={true}
          />
          <Column
            label='Role'
            dataKey='role'
            hidden={true}
          />
          <Column
            label='Name'
            dataKey='name'
            width={135}
            flexGrow={1}
            cellRenderer={this.nameCellRenderer}
          />
          <Column
            label='Date Created'
            dataKey='created_at'
            width={60}
            flexGrow={1}
            cellRenderer={this.dateCreatedCellRenderer}
          />
          <Column
            width={80}
            className='column-right'
            headerStyle={{textAlign: 'right'}}
            label='Storage'
            dataKey='storage_used'
            search='number'
            cellRenderer={this.sizeCellRenderer}
          />
          <Column
            label='Quota'
            dataKey='storage_quota'
            search='number'
            hidden={true}
          />
          <Column
            label='Bonus'
            dataKey='storage_quota_bonus'
            search='number'
            hidden={true}
          />
          <Column
            label='Project Count'
            dataKey='project_count'
            search='number'
            hidden={true}
          />
        </VirtualTable>
      </div>
    )
  }

}
export default UserIndex;

