import React from 'react';
import ImageButton from '../../presenters/ImageButton';
import * as helpers from '../../support/Helpers';
import * as validations from '../../support/Validations';
import CGParse from '../../../CGParse.esm';
import FileInput from '../../presenters/FileInput';
// import './FeatureFile.css';
import { initialSettings, fileChangedSettings, settingsRenderer, defaultSettingsManagerKey } from './CGFeatureFileSettings.js'

// This class handle taking a feature file (e.g. GFF3, GTF, CSV, BED) as the file input
// - parses the file and convertes it to CGView JSON Features
class CGFeatureFile {

  constructor(file) {
    this._file = file;
    this.maxLogCount = 5;
  }

  get file() {
    return this._file;
  }

  get settings() {
    return this.file?.settings || {};
  }

  //////////////////////////////////////////////////////////////////////////
  // Static properties
  //////////////////////////////////////////////////////////////////////////

  static get initialSettings() {
    // From CGParseFileSettings.js
    return initialSettings();
  }

  static get fileChangedSettings() {
    // From CGParseFileSettings.js
    return fileChangedSettings();
  }
  
  static settingsAreNotDefault(settings = {}) {
    return false
  }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'feature';
  }

  get metaKeys() {
    return [
      'fileFormat', 'detectedFormat', 'count',
      'parseStatus', 'log', 'errorCodes', 'builderStatus',
      'columnIndexMap',
      // We use data to hold the features.
      // The attribute 'data' is replaces in the file inputs meta data with a
      // summary so we don't include it all the database
      'data', 
    ];
  }

  get validationKeys() {
    return ['validFileFormats', 'validCount'];
  }

  // TODO: this can become more general when needed for other file types
  // saveSettings(settings={}) {
  //   const storageKey = defaultSettingsManagerKey();
  //   const parseTool = Tools.get('features');
  //   const inputs = parseTool.inputsForTarget('Settings');
  //   const options = {};
  //   for (const input of inputs) {
  //     console.log(input)
  //     if (!input.autoSave) { continue; }
  //     if (Array.isArray(input.default)) {
  //       if (!helpers.arraysEqual(settings[input.id], input.default)) {
  //         options[input.id] = settings[input.id];
  //       }
  //     } else if (settings[input.id] !== input.default) {
  //       options[input.id] = settings[input.id];
  //     }
  //   }
  //   if (Object.keys(options).length > 0) {
  //     localStorage.setItem(storageKey, JSON.stringify(options));
  //   } else {
  //     localStorage.removeItem(storageKey);
  //   }
  // }

  processText(text) {
    console.log("Settings:", this.settings);
    // Save the Setting in LocalStorage
    // NOTE: we may want to generalize this when we have settings in other file types
    // this.saveSettings(this.settings);
    // console.log(text)

    // Process Feature File
    const format = this.settings?.format || 'auto';
    const columnIndexMap = this.settings?.columnIndexMap || {};
    // console.log('COLUMN INDEX MAP', columnIndexMap)
    const columnMap = CGParse.helpers.invertObject(columnIndexMap);
    // convert value to integers if possible
    for (const key in columnMap) {
      const value = parseInt(columnMap[key]);
      if (!isNaN(value)) {
          columnMap[key] = value;
      }
    }
    // console.log('COLUMN MAP', columnMap)

    const featureFile = new CGParse.FeatureFile(text, {format, nameKeys: this.settings?.nameKeys, noHeader: !this.settings?.header, columnMap, maxLogCount: this.maxLogCount});
    // console.log('FEATURE FILE', featureFile);
    // Data from the FeatureFile
    this.parseStatus = featureFile.status;
    this.errorCodes = featureFile.errorCodes;
    // console.log('ERROR CODES', this.errorCodes)
    this.fileFormat = featureFile.inputFormat;
    this.detectedFormat = featureFile.detectedFormat;

    this.columnIndexMap = featureFile.delegate?.columnIndexToKeyMap;
    this.log = featureFile.logger.history({showIcons: true});

    // Stop here if there are errors
    if (!featureFile.passed) { return; }

    // Build CGview Features
    const builder = new CGParse.FeatureBuilder(featureFile, {logger: featureFile.logger, includeQualifiers: true, maxLogCount: this.maxLogCount});
    const featureJSON = builder.toJSON();
    // Data from the FeatureBuilder
    this.builderStatus = builder.status;
    this.log = builder.logger.history({showIcons: true});
    if (featureJSON) {
      this.count = Array.isArray(featureJSON) ? featureJSON.length : 0;
      this.data = featureJSON;
    }
  }

  validate(rules={}) {
    const validationErrors = [];
    const { validFileFormats, validLength, validCount } = rules;
    const details = this.file.details();
    const v = validations;
    let continueValidation = true;
    const settingsButton = <ImageButton imageName='settings' inline size='small' onClick={() => this.file.toggleSettings()} />;

    // console.log(errorCodes)
    if (continueValidation && this.errorCodes?.includes('binary')) {
      validationErrors.push('ERROR: The file contains non-text characters. Is it a binary file?');
      continueValidation = false;
    }
    if (continueValidation && this.errorCodes?.includes('empty')) {
      validationErrors.push('ERROR: File is empty');
      continueValidation = false;
    }

    const fileFormat = details.meta.fileFormat;
    if (continueValidation && !v.validateString(fileFormat, validFileFormats)) {
      const note = (this.settings?.format === 'auto') ? ' (auto-detection failed)' : `: ${fileFormat}`;
      validationErrors.push(`File Format Unknown${note}`);
      validationErrors.push(<div>Try manually selecting the format in settings {settingsButton} </div>);
      const formats = validFileFormats.map( f => f.toUpperCase() );
      validationErrors.push(`Must be ${helpers.toSentence([formats].flat(), {conjunction: 'or'})}`);

      continueValidation = false;
    }

    // Check if columns need to be set: ie a csv/tsv file
    if (continueValidation) {
      const columnIndexMap = details?.meta?.columnIndexMap;
      const columnKeys = new Set(Object.values(columnIndexMap));
      if (columnKeys.size == 1 && columnKeys.has('ignored')) {
        validationErrors.push(`No recognized columns found`);
        validationErrors.push(<div>Try manually selecting selecting columns in settings {settingsButton} </div>);
      }
      continueValidation = false;
    }
    // console.log('SETTINGS', this.settings)
    // console.log('FILE DETAILS', details)

    const count = details.meta.count;
    if (continueValidation && !v.validateNumericRange(count, validCount)) {
      if (isNaN(count)) {
        validationErrors.push(`No Features Found`);
      } else {
        validationErrors.push(`Feature Count (${helpers.commaNumber(count)}) is not valid. ${v.rangeValidationString(validCount)}`);
      }
    }

    this.file.validationErrors = validationErrors;
    return {ok: (validationErrors.length === 0), errors: validationErrors};
  }

  static settingsRenderer(settings = {}, onChange = () => {}, details = {}, fileText = '', source = '') {
    // From CGParseFileSettings.js
    return settingsRenderer(settings, onChange, details, fileText, source);
  }

  // - CGParse.FeatureFile returns an array of error codes that we can parse
  //   - Error code examples: binary, empty, unknown_format
  static detailsRenderer(details={}, rules={}) {
    const meta = details.meta || {};
    const countText = (meta.count == 1) ? "1 feature" : `${helpers.commaNumber(meta.count)} features`;
    const errorCodes = meta.errorCodes;
    // console.log('FILE DETAILS', details)

    const showLog = meta.parseStatus === 'failed' || meta.builderStatus === 'failed';
    const fileFormat = meta.fileFormat?.toUpperCase();
    let summary = <div>{fileFormat} ({countText})</div>;
    if (errorCodes && errorCodes.length > 0) {
      if (errorCodes.includes('binary')) {
        summary = <div>Binary File?</div>;
      } else if (errorCodes.includes('empty')) {
        summary = <div>Empty File</div>;
      } else if (errorCodes.includes('unknown_format')) {
        summary = <div>Unknown Format</div>;
      } else {
        let message = `${fileFormat} (Errors)`;
        const detectedFormat = meta.detectedFormat?.toUpperCase();
        if (detectedFormat && detectedFormat !== fileFormat) {
          message += ` - Is this a ${detectedFormat} file?`;
        }
        summary = <div>{message}</div>;
      }
    } else if (isNaN(meta.count)) {
      summary = <div>{fileFormat}? (No Features)</div>;
    }
    const statusMessage = FileInput.statusFor(meta.parseStatus, meta.builderStatus);
    const logBtn = FileInput.renderLogButton({statusMessage, active: showLog});
    return (
      <div className='parse-details'>
        <div className='summary-line'>{summary}<div className='parse-buttons'>{ logBtn }
        </div></div>
        { FileInput.renderLog({text: meta.log, open: (meta.parseStatus === 'failed' || meta.buildStatus === 'failed')}) }
      </div>
    );
  }
}

export default CGFeatureFile;
