import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './PopupBox.css';

PopupBox.propTypes = {
  className:   PropTypes.string,
  dismissOnBlur: PropTypes.func,
  width:       PropTypes.number,
  height:      PropTypes.number,
}

PopupBox.defaultProps = {
  dismissOnBlur: () => {},
  width:    250,
  height:   200,
}

function PopupBox(props) {
  const {
    dismissOnBlur,
    className,
    children,
    width,
    height,
    targetNode, // node of object this popup should be coming from
    boundaryNode, // node of object that the popup should be contained to
  } = props;


  const popupClass = classNames(
    'PopupBox', className,
  );

  let top = 0;
  let left = 0;
  let arrowLeft = '50%'

  if (targetNode) {
    // const width = 250; // TODO COME FROM PROPS
    top = targetNode.offsetTop + targetNode.offsetHeight;
    const center = targetNode.offsetLeft + (targetNode.offsetWidth / 2);
    left = center - ( width / 2 );
    if (boundaryNode) {
      let diff;
      // const boundaryLeft = boundaryNode.offsetLeft;
      const boundaryWidth = boundaryNode.offsetWidth;
      const margin = 5;
      // console.log(left, boundaryLeft)
      // console.log(boundaryNode)
      // if (left < boundaryLeft) {
      if (left < margin) {
        diff = (width / 2) + left;
        // left = boundaryLeft;
        left = margin;
      // } else if ((left + width) > (boundaryLeft + boundaryWidth - margin)) {
      } else if ((left + width) > (boundaryWidth - margin)) {
        diff = (width / 2) + (width + left) - boundaryWidth + margin;
        left = boundaryWidth - width - margin;
      }
			arrowLeft = (diff/width*100) + '%';
    }
  }

  const style = {left, top, width, height};
  // console.log(left, top)

  const popupEl = useRef(null);
  useEffect(() => {
    function handleClick(e) {
      // console.log(e)
      // console.log(e.target)
      if (popupEl && !popupEl.current.contains(e.target)) {
        if (!targetNode || !targetNode.contains(e.target)) {
          // HACK: so that selecting item in Select works
          if (e.target.className && e.target.className.includes('ant-select-item-option-content')) {return}
          dismissOnBlur();
        }
      }
    }
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    }
  }, []);


  // width is 250px


  const arrowStyle={left: arrowLeft}

  return (
    <div className={popupClass} ref={popupEl} style={style} >
      <div className='popupbox-before' style={arrowStyle}></div>
        {children}
      <div className='popupbox-after' style={arrowStyle}></div>
    </div>
  )
}

export default PopupBox;

