import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';
// import * as helpers from '../../../support/Helpers';

class BlastDataCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  renderData(data) {
    const header = ['Query', 'Subject', 'Percent Identity', 'Alignment Length', 'Mismatches', 'Gap Openings', 'Query Start', 'Query End', 'Subject Start', 'Subject End', 'E Value', 'Bit Score'];
    return (
      <VirtualGrid
        data={data}
        header={header}
        rowHeight={30}
        headerRowHeight={50}
        defaultColumnWidth={80}
        alternateRowColors
      >
        <Column index={0} width={150} />
        <Column index={1} width={120} />
        <Column index={2} type='number' />
        <Column index={3} type='number' />
        <Column index={4} type='number' />
        <Column index={5} type='number' />
        <Column index={6} type='number' />
        <Column index={7} type='number' />
        <Column index={8} type='number' />
        <Column index={9} type='number' />
        <Column index={10} widht={100} type='number' />
        <Column index={11} type='number' />
      </VirtualGrid>
    );
  }
        // <Column index={6} transform={ (v) => helpers.commaNumber(v) } />

}

export default BlastDataCard;

