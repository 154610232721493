import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './JobStatus.css';
import * as helpers from '../support/Helpers';

// Connected
import { connect } from 'react-redux';

JobStatus.propTypes = {
  jobs: PropTypes.shape({
    ids:  PropTypes.array,
    byID: PropTypes.object,
  }).isRequired,
}

// JobStatus.defaultProps = {
//   jobs: {},
// }

function JobStatus({ jobs: jobsObject }) {
  const jobs = helpers.itemsAsArray(jobsObject);
  const runningOrQueuedCount = jobs.filter( j => j.status === 'running' || j.status === 'queued').length;
  const elementClass = classNames('JobStatus', 'badge', {hidden: (runningOrQueuedCount < 1)});
  const status = <div className={elementClass}>{runningOrQueuedCount}</div>
  return status;
}

//Connected
const jobStatusMapStateToProps = (state) => ({ jobs: state.jobs });
const ConnectedJobStatus = connect(jobStatusMapStateToProps, null, null, {forwardRef: true})(JobStatus);

// export default JobStatus;
export { JobStatus, ConnectedJobStatus};
