import React from 'react';
import Prism from 'prismjs';
import DataElement from '../../presenters/DataElement';
import Font from '../../presenters/Font';
import Switch from '../../presenters/Switch';
import * as helpers from '../../support/Helpers';

class TextFileViewer {

  constructor(fileViewer) {
    this._fileViewer = fileViewer;
    // this._settings = settings;
    // SET settings with provided settings or default
  }

  get fileViewer() {
    return this._fileViewer;
  }

  // get settings() {
  //   return this._settings;
  // }

  //////////////////////////////////////////////////////////////////////////
  // Required Delegate Methods
  //////////////////////////////////////////////////////////////////////////

  get fileType() {
    return 'text';
  }

  static get displayFileType() {
    return 'Text';
  }

  get settingsHeight() {
    return 110;
  }

  defaultSettings() {
    return {
      font: 'monospace, plain, 12',
      lineWrap: true,
    }
  }

  onChangeSettings({value, attribute}) {
    this.fileViewer.onChangeSettings({value, attribute});
  }

  displaySettings(settings=this.defaultSettings()) {
    return (
      <div>
        <DataElement label='Font'>
          <Font
            fontString={settings.font}
            onChange={(value) => this.onChangeSettings({value, attribute: 'font'})}
          />
        </DataElement>
        <DataElement label='Wrap Lines'>
          <Switch
            value={settings.lineWrap}
            onChange={(value) => this.onChangeSettings({value, attribute: 'lineWrap'})}
          />
        </DataElement>
      </div>
    );
  }

  displayData(file, data, settings=this.defaultSettings()) {
    const style = {font: Font.stringToCSS(settings.font)};
    if (settings.lineWrap) {
      style.whiteSpace = 'pre-wrap';
      style.wordBreak = 'break-all';
    }
    return <div className='file-data-text' style={style}>{data}</div>;
  }

}

export default TextFileViewer;
