// export const BP_CHANGE = 'BP_CHANGE';
export const LOCATION_CHANGE = 'LOCATION_CHANGE';
// export const FORMAT_CHANGE = 'FORMAT_CHANGE';
export const RESET_STATE = 'RESET_STATE';
export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';
export const VIEWER_UPDATE = 'VIEWER_UPDATE';
export const LOAD_JSON = 'LOAD_JSON';

export const TRACKS_ADD = 'TRACKS_ADD';
export const TRACKS_REMOVE = 'TRACKS_REMOVE';
export const TRACKS_UPDATE = 'TRACKS_UPDATE';
export const TRACKS_MOVED = 'TRACKS_MOVED';
export const TRACKS_UPDATE_STATS = 'TRACKS_UPDATE_STATS';

export const CAPTIONS_ADD = 'CAPTIONS_ADD';
export const CAPTIONS_REMOVE = 'CAPTIONS_REMOVE';
export const CAPTIONS_UPDATE = 'CAPTIONS_UPDATE';
export const CAPTIONS_MOVED = 'CAPTIONS_MOVED';

export const FEATURES_ADD = 'FEATURES_ADD';
export const FEATURES_REMOVE = 'FEATURES_REMOVE';
export const FEATURES_UPDATE = 'FEATURES_UPDATE';
export const FEATURES_UPDATE_STATS = 'FEATURES_UPDATE_STATS';

export const PLOTS_ADD = 'PLOTS_ADD';
export const PLOTS_REMOVE = 'PLOTS_REMOVE';
export const PLOTS_UPDATE = 'PLOTS_UPDATE';
export const PLOTS_UPDATE_STATS = 'PLOTS_UPDATE_STATS';

export const BOOKMARKS_ADD = 'BOOKMARKS_ADD';
export const BOOKMARKS_REMOVE = 'BOOKMARKS_REMOVE';
export const BOOKMARKS_UPDATE = 'BOOKMARKS_UPDATE';

export const CONTIGS_ADD = 'CONTIGS_ADD';
export const CONTIGS_REMOVE = 'CONTIGS_REMOVE';
export const CONTIGS_UPDATE = 'CONTIGS_UPDATE';
export const CONTIGS_MOVED = 'CONTIGS_MOVED';

export const LEGEND_UPDATE = 'LEGEND_UPDATE';
export const LEGEND_ITEMS_ADD = 'LEGEND_ITEMS_ADD';
export const LEGEND_ITEMS_REMOVE = 'LEGEND_ITEMS_REMOVE';
export const LEGEND_ITEMS_UPDATE = 'LEGEND_ITEMS_UPDATE';
export const LEGEND_ITEMS_MOVED = 'LEGEND_ITEMS_MOVED';

export const ANNOTATION_UPDATE = 'ANNTOATION_UPDATE';
export const RULER_UPDATE = 'RULER_UPDATE';
export const BACKBONE_UPDATE = 'BACKBONE_UPDATE';
export const SEQUENCE_UPDATE = 'SEQUENCE_UPDATE';
export const DIVIDER_UPDATE = 'DIVIDER_UPDATE';
export const CENTERLINE_UPDATE = 'CENTERLINE_UPDATE';

export const UI_SQUARE_LOCK_CHANGE = 'UI_SQUARE_LOCK_CHANGE';
export const UI_THEME_UPDATE = 'UI_THEME_UPDATE';

export const JOBS_ADD = 'JOBS_ADD';
export const JOBS_REMOVE = 'JOBS_REMOVE';
export const JOBS_UPDATE = 'JOBS_UPDATE';
export const LOG_UPDATE = 'LOG_UPDATE';
export const FILETREE_UPDATE = 'FILETREE_UPDATE';

export const PROJECT_UPDATE = 'PROJECT_UPDATE';


// REMOVE??
// export const LOAD_CGV = 'LOAD_CGV'; // IS THIS USED?
