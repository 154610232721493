import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Slider.css'; 
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import ImageButton from './ImageButton';
import classNames from 'classnames';

Slider.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  onDefaultClick:    PropTypes.func,
  allowDefault:      PropTypes.bool,
  isDefault:         PropTypes.bool,
}

Slider.defaultProps = {
  disabled: false,
  min: 1,
  max: 100,
  step: 1,
  defaultValue: 0,
}

function Slider(props) {

  const {
    value,
    defaultValue,
    min,
    max,
    step,
    onChange,
    allowDefault,
    isDefault,
    onDefaultClick,
  } = props;

  const [currentValue, setCurrentValue] = useState( (value === undefined) ? defaultValue : value );

  if (value !== undefined) {
    useEffect(() => {
      setCurrentValue(value);
    }, [value]);
  }


  let inputRef;

  function handleFocus() {
    inputRef && inputRef.focus();
  }

  function handleChange(newValue) {
    setCurrentValue(newValue);
    onChange(newValue);
  }

  function roundTo(num, roundTo) {
    roundTo = 1 / roundTo;
    return Math.round(num * roundTo) / roundTo;
  }

  function incrementValue() {
    const newValue = roundTo(Math.min((currentValue + step), max), step);
    handleChange(newValue);
  }

  function decrementValue() {
    const newValue = roundTo(Math.max((currentValue - step), min), step);
    handleChange(newValue);
  }

  function maxBoundsValue() {
    handleChange(max);
  }

  function minBoundsValue() {
    handleChange(min);
  }

  function onSliderChange(e) {
    handleChange(Number(e.target.value));
  }

  const sliderClass = classNames(
    'Slider',
    {'default-on': isDefault}
  );

  let renderedDefault;
  if (allowDefault) {
    renderedDefault = isDefault ?
      <div className='default-text'>Default</div> :
      <Button className='default-button' onClick={ onDefaultClick }>Default</Button>
  }

  return (
    <div className={sliderClass}
      onClick={handleFocus}
    >
      <div className='slider-value'>{currentValue}</div>
      <ButtonGroup className='slider-buttons'>
        <ImageButton imageName='up' onClick={incrementValue} />
        <ImageButton imageName='down' onClick={decrementValue} />
      </ButtonGroup>
      <Button width='32px' className='slider-bounds' onClick={minBoundsValue}>
        {min}
      </Button>
      <input className='slider-input' type='range' 
        ref={(input) => { inputRef = input; }}
        min={min}
        max={max}
        step={step}
        value={currentValue}
        onChange={onSliderChange}
      />
      <Button width='32px' className='slider-bounds' onClick={maxBoundsValue}>
        <div>{max}</div>
      </Button>
      {renderedDefault}
    </div>
  )
}

export default Slider;


