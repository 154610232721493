import { RESET_STATE, CONTIGS_ADD, CONTIGS_UPDATE, CONTIGS_REMOVE, CONTIGS_MOVED } from '../constants/ActionTypes'
import { removeItems } from './ReduxHelpers';
import * as helpers from '../support/Helpers';

const initialState = {
  byID: {},
  ids: [],
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:    return reset(state, payload);
    case CONTIGS_ADD:    return addContigs(state, payload);
    case CONTIGS_UPDATE: return updateContigs(state, payload);
    case CONTIGS_REMOVE: return removeItems(state, payload.contigs);
    case CONTIGS_MOVED:  return moveContigs(state, payload);
    default:             return state;
  }
};

const reset = () => { return (initialState) }

const addContigs = (state, { contigs }) => {
  // Normalize contig data
  const data = {};
  const ids = [];
  for (var i = 0, len = contigs.length; i < len; i++) {
    const contig = contigs[i];
    const json = contig.toJSON({includeDefaults: true});
    json.cgvID = contig.cgvID;
    json.order = contig.index;
    data[json.cgvID] = json;
    ids.push(json.cgvID);
  }

  return ({
    byID: {...state.byID, ...data},
    ids: [...state.ids, ...ids],
  })
}

// const removeContigs = (state, { contigs }) => {
//   const data = {};
//   const ids = [];
//   const idsToRemove = contigs.map( f => f.cgvID );
//   for (var i = 0, len = state.ids.length; i < len; i++) {
//     const id = state.ids[i];
//     if (!idsToRemove.includes(id)) {
//       ids.push(id);
//       data[id] = state.byID[id];
//     }
//   }
//   return ({
//     byID: data,
//     ids: ids,
//   })
// }

const updateContigs = (state, { contigs, attributes }) => {
  const contigData = {};
  for (var i = 0, len = contigs.length; i < len; i++) {
    const contig = contigs[i];
    // console.log(contig)
    const json = contig.toJSON({includeDefaults: true});
    json.cgvID = contig.cgvID;
    json.order = contig.index;
    contigData[json.cgvID] = json;
  }

  return ({
    byID: {...state.byID, ...contigData},
    ids: state.ids,
  })
}

const moveContigs = (state, { oldIndex, newIndex }) => {
  // Don't need to do anything here at this time. 
  // updateContigs is now called when moving contigs,
  // since 2 or more contigs order/index will change.
  return state;
}

