import { SEQUENCE_UPDATE } from '../constants/ActionTypes'

const initialState = { };

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case SEQUENCE_UPDATE: return updateSequence(state, payload);
    default:              return state;
  }
};

const updateSequence = (state,  { attributes }) => {
  return {...state, ...attributes}
}


