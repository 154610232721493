import * as d3 from 'd3';

let instance = null;

// Keys
// ↓↑← →
//
// NEXT:
// - have map of keys to functions (this would let us customize in the future)

class ProkseeEvents {

  constructor(cgv, tabsRef) {
    if(!instance){
      instance = this;
    }
    this._cgv = cgv;
    this._tabsRef = tabsRef;

    this.keyDownArrowLeft = false;
    this.keyDownArrowRight = false;
    this.keyDownArrowUp = false;
    this.keyDownArrowDown = false;
    this.keyRepeat;

    // this.keyMap = {
    //
    // }

    this.initialize();
    return instance;
  }

  // direction: Left, Right
  // options:
  // - ease
  // - fastStop: fast stop after repeating a key down
  // - noCallback: stop callbacks (key no longer pressed)
  move(direction, options={}) {
    const cgv = this._cgv;
    // Stop quicker if the button has been held down (repeated)
    if (cgv.zoomFactor < 2) return;
    const moveFactor = options?.fastStop ? 0.05 : 0.25;
    const moveDuration = options?.fastStop ? 50 : 250;
    const ease = options?.ease || d3.easeCubicOut;
    cgv[`move${direction}`](moveFactor, {
      duration: moveDuration,
      ease: ease,
      callback: () => {
        if (options?.noCallback) {
          cgv.drawFull();
          return;
        }
        // Check if button is still down, repeat
        if (this[`keyDownArrow${direction}`]) {
          this.keyRepeat = true;
          this.move(direction, {ease: d3.easeLinear});
        } else {
          // Key no longer down, ease to a stop
          if (this.keyRepeat) {
            // Ending repeat press
            this.move(direction, {ease: d3.easeLinear, noCallback: true, fastStop: true});
          } else {
            // Was single press
            this.move(direction, {ease: d3.easeCubicOut, noCallback: true});
          }
          this.keyRepeat = false;
        }
      }
    });
  }

  zoom(direction, options={}) {
    const cgv = this._cgv;
    // Stop quicker if the button has been held down (repeated)
    let moveFactor = options?.fastStop ? 1.1 : 2;
    if (cgv.zoomFactor < 1) {
      // Reduce zoomIn when zoomed all the way out
      // This makes it so, if at zoomfactor 0.5, pressing once will zoom to 1x
      moveFactor = Math.sqrt(2);
    }
    const moveDuration = options?.fastStop ? 50 : 250;
    const ease = options?.ease || d3.easeCubicOut;
    const key = (direction === 'In') ? 'Down' : 'Up';
    cgv[`zoom${direction}`](moveFactor, {
      duration: moveDuration,
      ease: ease,
      callback: () => {
        if (options?.noCallback) {
          cgv.drawFull();
          return;
        }
        // Check if button is still down, repeat
        if (this[`keyDownArrow${key}`]) {
          this.keyRepeat = true;
          this.zoom(direction, {ease: d3.easeLinear});
        } else {
          // Key no longer down, ease to a stop
          if (this.keyRepeat) {
            // Ending repeat press
            this.zoom(direction, {ease: d3.easeLinear, noCallback: true, fastStop: true});
          } else {
            // Was single press
            this.zoom(direction, {ease: d3.easeCubicOut, noCallback: true});
          }
          this.keyRepeat = false;
        }
      }
    });
  }
              // onMouseDown={ () => this.move('right') }

  // TESTING: keyboard events for moving map
  initialize() {
    const ignoredTagsRegex = /^(input|textarea|select|button)$/i;
    const cgv = this._cgv;

    // Key Down Events
    document.addEventListener('keydown', (e) => {
      if (ignoredTagsRegex.test(e.target.tagName)) { return; }
      if (e.target.isContentEditable) { return; }
      if (!this._tabsRef.mapTabOpen()) { return; }
      // console.log(e)
      const key = e.key;
      if (key === 'ArrowLeft') {
        if (!this.keyDownArrowLeft) {
          this.keyDownArrowLeft = true;
          this.keyDownArrowRight = false;
          this.move('Left', {ease: d3.easeCubicIn});
        }
      }
      if (key === 'ArrowRight') {
        if (!this.keyDownArrowRight) {
          this.keyDownArrowRight = true;
          this.keyDownArrowLeft = false;
          this.move('Right', {ease: d3.easeCubicIn});
        }
      }
      if (key === 'ArrowDown') {
        if (!this.keyDownArrowDown) {
          this.keyDownArrowDown = true;
          this.keyDownArrowUp = false;
          this.zoom('In', {ease: d3.easeCubicIn});
        }
      }
      if (key === 'ArrowUp') {
        if (!this.keyDownArrowUp) {
          this.keyDownArrowUp = true;
          this.keyDownArrowDown = false;
          this.zoom('Out', {ease: d3.easeCubicIn});
        }
      }
      if (key === '.') {
        cgv.reset();
      }
      if (key === '/') {
        const format = (cgv.format == 'circular') ? 'linear' : 'circular';
        cgv.settings.update({ format: format });
        cgv.draw();
      }
    });

    // Key Up Events
    document.addEventListener('keyup', (e) => {
      const key = e.key;
      if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(key)) {
        this[`keyDown${key}`] = false;
      }
    });
  }

}

export default ProkseeEvents;
