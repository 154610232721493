import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CGViewContext } from '../../app/CGViewContext';
// import './SequenceSettings.css';
import { ListItem } from '../../presenters/ListItem';
import DataElement from '../../presenters/DataElement';
import Color from '../../presenters/Color';
import Font from '../../presenters/Font';
import VisibilityButton from '../../presenters/VisibilityButton';
// import * as helpers from '../../support/Helpers';
// Connected
import { connect } from 'react-redux';

class SequenceSettings extends React.Component {

  static propTypes = {
    sequence: PropTypes.shape({
      color:             PropTypes.string,
      font:              PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {infoOpen: false}

    this.onChange = this.onChange.bind(this);
    this.infoClicked = this.infoClicked.bind(this);
    this.mainRenderer = this.mainRenderer.bind(this);
    this.infoRenderer = this.infoRenderer.bind(this);
  }

  infoClicked() {
    this.setState( (state) => ({ ...state, infoOpen: !state.infoOpen }));
  }

  mainRenderer() {
    const { sequence } = this.props;
    const titleClass = classNames('list-item-title', {fade: !sequence.visible});
    return (
      <div className='list-item-content'>
        <div className={titleClass}>
          Sequence
        </div>
        <div className='list-item-options'>
          <VisibilityButton visible={sequence.visible} onClick={ (value) => this.onChange({value, attribute: 'visible'}) } />
        </div>
      </div>
    );
  }

  infoRenderer() {
    const { sequence } = this.props;
    return (
      <div>
        <DataElement label='Color' helpPath='help:sidebar:display:settings:sequence:font'>
          <Color
            colorString={sequence.color}
            onChange={(value) => this.onChange({value, attribute: 'color'})}
          / >
        </DataElement>
        <DataElement label='Font' helpPath='help:sidebar:display:settings:sequence:font'>
          <Font
            fontString={sequence.font}
            onChange={(value) => this.onChange({value, attribute: 'font'})}
          />
        </DataElement>
      </div>
    );
  }

  onChange({attribute, value, redraw=true}) {
    const cgv = this.context.cgv;
    cgv.sequence.update({[attribute]: value});
    redraw && cgv.draw();
  }

  render() {
    return (
      <div className='SequenceSettings'>
        <ListItem
          mainRenderer={this.mainRenderer}
          infoRenderer={this.infoRenderer}
          infoOpen={this.state.infoOpen}
          infoClicked={this.infoClicked}
        />
      </div>
    );
  }
}

SequenceSettings.contextType = CGViewContext;

//Connected
const sequenceSettingsMapStateToProps = (state) => ({ sequence: state.sequence });
const ConnectedSequenceSettings = connect(sequenceSettingsMapStateToProps)(SequenceSettings);

// export default SequenceSettings;
export { SequenceSettings, ConnectedSequenceSettings };

