import React from 'react'

import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import ServerAPI from '../proksee-viewer/models/ServerAPI';
import {ConnectedJobCards} from "../proksee-viewer/tabs/JobCards";
import store from '../proksee-viewer/app/store'
import { updateJobs } from '../proksee-viewer/actions/jobs';

class JobCardsViewer extends React.Component {

  static propTypes = {
    jobID: PropTypes.number,
    projectID: PropTypes.string,
  }

  static defaultProps = {
    initialKey: 'report',

    type: 'line',
    tabPosition: 'left',
    className: 'JobCards',
  }

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    }
  }

  componentDidMount() {
    this.refreshJobs();
  }


  // This originally came from JobPane. It could become a class method on JobPane
  // - Note the project id is set here too
  refreshJobs() {
    const { projectID } = this.props;
    const Server = new ServerAPI(projectID);
    Server.get(Server.URL.jobList)
    .then( response => {
      if (response.ok) {
        store.dispatch(updateJobs(response.json))
        this.setState({loaded: true})
      }
    });
  }

  render() {
    const { jobID } = this.props;
    const { loaded } = this.state;

    const jobCards = loaded && <ConnectedJobCards id={jobID} / >;
    return (
      <Provider store={store}>
        {jobCards}
      </Provider>
    );
  }

}
export default JobCardsViewer;

