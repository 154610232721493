import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Progress.css';

Progress.propTypes = {
  className:   PropTypes.string,
  type:        PropTypes.oneOf(['dots', 'spinner']),
  size:        PropTypes.oneOf(['default', 'small']),
  // width:       PropTypes.number,
  // height:      PropTypes.number,
}

Progress.defaultProps = {
  type: 'dots',
  help:    '',
  size:     'default',
  // width:    50,
  // height:   24,
}

function Progress(props) {
  const {
    className,
    type,
    size,
    // width,
    // height,
  } = props;

  // const style = { width: width, height: height, lineHeight: `${(height - 2)}px` };

  const progressClass = classNames(
    'Progress', className, size, `progress-${type}`
  );
  return (
    <div className={progressClass}>
      <p className='loading'><span>.</span><span>.</span><span>.</span></p>
    </div>
  )
}

export default Progress;

