import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ServerAPI from '../models/ServerAPI';
import Progress from '../presenters/Progress';
import './FileViewer.css';
import { Select, Option } from '../presenters/Select';
import ImageButton from '../presenters/ImageButton';
import ButtonGroup from '../presenters/ButtonGroup';
import Button from '../presenters/Button';
import PopupBox from '../presenters/PopupBox';
import Message from '../presenters/Message';
import iconViewerRight from '../images/icon-viewer-right';
import iconViewerBottom from '../images/icon-viewer-bottom';
import iconViewerFull from '../images/icon-viewer-full';
import iconSettings from '../images/icon-settings';
import ReactResizeDetector from 'react-resize-detector';
import * as helpers from '../support/Helpers';
import TextFileViewer from './file_viewer_delegates/TextFileViewer';
import CodeFileViewer from './file_viewer_delegates/CodeFileViewer';
import CSVFileViewer from './file_viewer_delegates/CSVFileViewer';
import JSONFileViewer from './file_viewer_delegates/JSONFileViewer';
import PDFFileViewer from './file_viewer_delegates/PDFFileViewer';
import HTMLFileViewer from './file_viewer_delegates/HTMLFileViewer';

// File Viewer Delegates (based on fileType)
// Each file type has it's own delegate that is responsible for
// settings and data presentation for a particular file type.
// Each delegate must have the following methods:
// - displaySettings(settings)
// - displayData(file, data, settings

const _fileTypeMap = {
  'text': TextFileViewer,
  'code': CodeFileViewer,
  'csv': CSVFileViewer,
  'json': JSONFileViewer,
  'pdf': PDFFileViewer,
  'html': HTMLFileViewer,
}

// Data Structures:
// file:
// {key: "5", name: "log", line_count: 134, isLeaf: true, size: 12974}
// fileData:
// {data: "file contents", lines: 134, offset: 12974, status: "complete"}
// - add 'fileType', 'settings', 'scrollPosition'

// NOTE: the file keys could collide if different file trees were provided
// - To prevent this we could interanally prefix the keys with the jobID (or something else)
class FileViewer extends React.Component {

  static propTypes = {
    files: PropTypes.array,
    job: PropTypes.object,
    positionFunc: PropTypes.func,
    position: PropTypes.oneOf(['bottom', 'right', 'full']),
  }

  static defaultProps = {
    files: [],
    position: 'bottom',
  }

  constructor(props) {
    super(props);

    const files = this.organizedFiles(this.props.files);
    this.state = {
      // currentFileIndex: props.initialFileIndex,
      // Object of files using the file key as key
      filesByID: files.byID,
      // List of file keys in the order they will appear
      fileIDs: files.IDs,
      // The key of the currently selected file to view
      selectedFileID: files.selectedID,
      // The data downloaded for a file. This data will remain if the filesByID changes
      // This allows us to not redownload data as long as this component exists.
      // e.g. { '1-3': { data: 'Some data would be here', linesDownloaded: 13, bytesDownloaded: 1300, status: 'complete'}}
      // status: can be 'complete', 'partial'
      fileData: {},
      fileTypeSettings: {},
      tallHeader: false,
      showSettings: false,
    }
    this.onResize = this.onResize.bind(this);
  }

  static get fileTypeMap() {
    return _fileTypeMap;
  }

  get delegate() {
    return this._delegate;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.files !== this.props.files) {
      const files = this.organizedFiles(this.props.files);
      this.setState({ filesByID: files.byID, fileIDs: files.IDs, selectedFileID: files.selectedID });
      // this.setState({ filesByID: files.byID, fileIDs: files.IDs }, () => this.onFileSelectChange(files.selectedID));
    }
  }

  onChangeSettings({value, attribute}) {
    const { fileTypeSettings } = this.state;
    const fileType = this.delegate.fileType;
    const settings = fileTypeSettings[fileType] || {};
    const newSettings = {...settings, [attribute]: value};
    console.log(newSettings);
    this.setState({fileTypeSettings: {...fileTypeSettings, [fileType]: newSettings}});
  }

  showSettings(show) {
    this.setState( (state) => {return {showSettings: show}} );
  }


  organizedFiles(files, selectedIndex=0) {
    // console.log(files)
    const byID = {};
    const IDs = [];
    for (let f of files) {
      // NOTE: this is where we could change the keys to include job id as well
      byID[f.key] = f;
      IDs.push(f.key);
    }
    const selectedID = files[selectedIndex] && files[selectedIndex].key;
    return {byID, IDs, selectedID}
  }

  // Default will get more of the file
  // downloadAll: will retreive the rest of the file
  getFile(downloadAll = false) {
    const { job } = this.props;
    const { fileData, filesByID, selectedFileID } = this.state;
    const file = filesByID[selectedFileID];
    const fData = fileData[selectedFileID];
    if (!file) {return}
    const Server = new ServerAPI();
    if (!fData || fData.status !== 'complete') {
      const offset = (fData && fData.offset) || 0;
      const downloadType = downloadAll ? 'all' : 'block';
      if (downloadAll) {
        Message.open({content:'Downloading...'});
      }
      Server.get(Server.jobFile(job), {key: selectedFileID, offset, downloadType})
      .then( response => {
        // console.log(response)
        if (response.ok) {
          const data = response.json;
          if (fData) {
            if (downloadAll) {
              Message.close();
            }
            data.data = fData.data + data.data;
            data.lines = fData.lines + data.lines - 1;
            this.setState({fileData: {...fileData, [selectedFileID]: {...fData, ...data, fileType: fData.fileType}}});
          } else {
            this.setState({fileData: {...fileData, [selectedFileID]: data}}, () => {
              const fileType = file.fileType || 'text';
              this.setFileType(fileType);
            });
          }
        }
      });
    }
  }

  onFileSelectChange(id) {
    this.setState({selectedFileID: id});
  }

  setFileType(fileType) {
    // const defaultFileType = 'text';
    const { selectedFileID, fileData, fileTypeSettings } = this.state;
    const fileTypes = Object.keys(FileViewer.fileTypeMap);
    if (fileTypes.includes(fileType)) {
      this._delegate = new FileViewer.fileTypeMap[fileType](this);
      const fData = fileData[selectedFileID];
      const settings = fileTypeSettings[fileType] || this._delegate.defaultSettings();
      this.setState({
        fileData: {...fileData, [selectedFileID]: {...fData, fileType}},
        fileTypeSettings: {...fileTypeSettings, [fileType]: settings},
      });
    } else {
      console.error(`FileViewer: Unknown file type '${fileType}'. Using default 'text'. Available types: ${fileTypes.join(', ')}`);
    }
  }

  renderFileSelection() {
    const { filesByID, fileIDs, selectedFileID } = this.state;
    const options = fileIDs.map( id => <Option key={id} value={id}>{filesByID[id].name}</Option> );
    const selectedIndex = fileIDs.indexOf(selectedFileID);
    const lastIndex = fileIDs.length - 1;
    const nextIndex = (selectedIndex === lastIndex) ? 0 : selectedIndex + 1;
    const prevIndex = (selectedIndex === 0) ? lastIndex : selectedIndex - 1;
    return (
      <div className='select-file'>
        <Select size='xsmall' value={selectedFileID}
          onChange={(id) => this.onFileSelectChange(id)}>
          {options}
        </Select>
        <ButtonGroup className='option-buttons'>
          <ImageButton
            imageName='backward'
            title='Previous File'
            size='small'
            onClick={ () => this.onFileSelectChange(fileIDs[prevIndex]) }
          / >
          <ImageButton
            imageName='forward'
            title='Next File'
            size='small'
            onClick={ () => this.onFileSelectChange(fileIDs[nextIndex]) }
          / >
        </ButtonGroup>
      </div>
    )
  }

  renderFileTypeSelection(file) {
    const { selectedFileID, fileData, showSettings } = this.state;
    const fData = fileData[selectedFileID];
    if (!fData) {return}
    // console.log(fData)
    const fileType = fData.fileType || 'text';

    const fileTypes = Object.keys(FileViewer.fileTypeMap);
    const options = fileTypes.map( type => <Option key={type} value={type}>{FileViewer.fileTypeMap[type].displayFileType}</Option> );
    const settings = showSettings ? <PopupBox targetNode={this.settingsNode} boundaryNode={this.fileViewerNode} dismissOnBlur={() => this.showSettings(false)} height={this.settingsHeight}>{this.displaySettings()}</PopupBox> : '';
    return (
    <div className='select-file-type'>
      <Select size='xsmall' value={fileType} style={{width: 'auto'}}
        onChange={(type) => this.setFileType(type)}>
        {options}
      </Select>
      <ButtonGroup className='quick-select-file-type'>
        <Button
          onClick={ () => this.setFileType('text') }
          height={18}>Text</Button>
        <Button
          onClick={ () => this.setFileType(file.fileType) }
          height={18}>Default</Button>
      </ButtonGroup>
      <ImageButton
        ref={(node) => this.settingsNode = node}
        image={iconSettings}
        title='Settings'
        size='small'
        onClick={ () => this.showSettings(!showSettings) }
      / >
      {settings}
    </div>
    )
  }

  onResize(width, height) {
    console.log('HEADER RESIZE', width, height)
    this.setState({tallHeader: width < 400});
  }

  renderPositionButtons() {
    const { position, positionFunc } = this.props;
    if (positionFunc) {
      const allPositions = ['bottom', 'right', 'full'];
      const btnImages = {
        full: iconViewerFull,
        bottom: iconViewerBottom,
        right: iconViewerRight,
      }
      const showPositions = allPositions.filter( p => p !== position );
      const positionButtons = showPositions.map( p => {
        return (
          <ImageButton
            key={p}
            className='btn-viewer-position'
            image={btnImages[p]}
            title={`Viewer on ${p}`}
            size='small'
            onClick={ () => positionFunc(p) }
          / >
        );
      });
      return <div className='position-buttons'>{positionButtons}</div>;
    }
    // let positionButton;
    // if (positionFunc) {
    //   const btnImage = (position === 'bottom') ? iconViewerRight : iconViewerBottom;
    //   const newPosition = (position === 'bottom') ? 'right' : 'bottom';
    //   positionButton = (
    //     <ImageButton
    //       className='btn-viewer-position'
    //       image={btnImage}
    //       title={`Viewer on ${newPosition}`}
    //       size='small'
    //       onClick={ () => positionFunc(newPosition) }
    //     / >
    //   )
    // }
    // return positionButton;
  }

  // Contains
  // - Name of file or select list of files
  // - File type selection
  // - Settings
  // - Button to move viewer to side/bottom
  renderHeader() {
    const { filesByID, fileIDs, selectedFileID, tallHeader } = this.state;
    const file = filesByID[selectedFileID];
    const fileName = file ? file.name : '';

    const klass = classNames('file-header', {'tall-header': tallHeader});
    return (
      <div className={klass}>
        <ReactResizeDetector handleWidth onResize={this.onResize} refreshMode='throttle' />
        <div className='file-section header-element'>
          { (fileIDs.length > 1) ? this.renderFileSelection() : <div className='file-name'>{fileName}</div> }
        </div>
        <div className='option-section header-element'>
          {this.renderPositionButtons()}
          {file ? this.renderFileTypeSelection(file) : ''}
        </div>
      </div>
    )
  }

  // Contains:
  // - Number of lines/bytes (Total and currently downloaded)
  // - Buttons to download more/all of the file
  // - Status 'complete' if all downloaded
  renderFooter() {
    const { filesByID, selectedFileID, fileData } = this.state;
    const file = filesByID[selectedFileID];
    const fData = fileData[selectedFileID];
    if (!file || !fData) {return}

    const status = fData.status;
    let lineCount, byteCount, displayStatus;
    if (status === 'complete') {
      lineCount = helpers.commaNumber(fData.lines);
      byteCount = `${helpers.commaNumber(file.size)} B`;
      displayStatus = 'Complete';
    } else {
      lineCount = `~${helpers.commaNumber(fData.lines)}/${helpers.commaNumber(file.line_count)}`;
      byteCount = `${helpers.commaNumber(fData.offset)}/${helpers.commaNumber(file.size)} B`;
      displayStatus = (
        <ButtonGroup separated >
          <Button
            onClick={ () => this.getFile() }
            height={14}>More</Button>
          <Button
            onClick={ () => this.getFile(true) }
            height={14}>All</Button>
        </ButtonGroup>
      )
    }

    const output = this.isEmbedded ? (
        <div className='file-footer'>
          <div className='footer-lines-title'>
            <div>Embedded File</div>
          </div>
        </div>
      ) : (
        <div className='file-footer'>
          <div className='file-footer-left'>
            <div className='footer-lines-title'>Lines:</div>
            <div className='footer-lines-value'>{lineCount}</div>
            <div className='footer-size-title'>Size:</div>
            <div className='footer-size-value'>{byteCount}</div>
          </div>
          <div className='file-footer-right'>
            <div className='file-footer-left'>
            {displayStatus}
            </div>
          </div>
        </div>
      );
    return output;
  }

  renderFileViewer() {
    const { fileData, filesByID, selectedFileID } = this.state;
    let data = 'No File Selected'
    let fileType = 'empty';
    let file;
    if (selectedFileID) {
      if (fileData[selectedFileID]) {
        const fData = fileData[selectedFileID];

        if (fData.status !== 'binary_file') {
          data = (fData.status === 'complete') ? fData.data : `${fData.data}...`;
        } else {
          data = 'BINARY FILE';
        }
        fileType = fData.fileType || 'text';
        if (!this.delegate || this.delegate.fileType !== fileType) {
          this._delegate = new FileViewer.fileTypeMap[fileType](this);
        }
        file = filesByID[selectedFileID];

        // if (fData.status !== 'binary_file') {
        //   data = (fData.status === 'complete') ? fData.data : `${fData.data}...`;
        //   fileType = fData.fileType || 'text';
        //   if (!this.delegate || this.delegate.fileType !== fileType) {
        //     this._delegate = new FileViewer.fileTypeMap[fileType](this);
        //   }
        //   file = filesByID[selectedFileID];
        // } else {
        //   data = 'BINARY FILE';
        // }
      } else {
        data = 'No File Data'
        this.getFile()
      }
    }
    const klass = classNames({
      'file-data-empty': (fileType === 'empty' || (!this.isEmbedded && data === 'BINARY FILE')),
      'file-data-embedded': this.isEmbedded,
    });

    const fadeKlass = classNames({
      'fade-edges': !this.isEmbedded,
    })
    return (
      <div>
        {this.renderHeader()}
        <div>
          <div className='file-data'>
            <div className={klass}>
              {this.displayData(file, data)}
            </div>
          </div>
          <div className={fadeKlass}/>
        </div>
        {this.renderFooter()}
      </div>
    )
  }

  // Delegate requires a displayData(file, data, settings) methods
  displayData(file, data) {
    const { fileTypeSettings } = this.state;
    if (this.delegate) {
      const fileType = this.delegate.fileType;
      const settings = fileTypeSettings[fileType];
      return this.delegate.displayData(file, data, settings);
    } else {
      return data;
    }
  }

  // Embed URL for the selected file
  // Used by pdf, html, and image viewers
  get embedURL() {
    const { selectedFileID } = this.state;
    const { job } = this.props;
    const Server = new ServerAPI();
    const dataKey = encodeURIComponent(selectedFileID);
    const url = `${Server.embeddedJobFile(job)}?key=${dataKey}`;
    return url;
  }

  // Delegate requires a displaySettings(settings) methods
  displaySettings() {
    const { fileTypeSettings } = this.state;
    if (this.delegate && this.delegate.displaySettings) {
      const settings = fileTypeSettings[this.delegate.fileType];
      return this.delegate.displaySettings(settings);
    } else {
      return <div className='empty-settings'>No Settings Available</div>;
    }
  }

  // Delegate requires a settingsHeight() methods
  get settingsHeight() {
    return (this.delegate && this.delegate.settingsHeight) ? this.delegate.settingsHeight : 100;
  }

  // Returns false, unless delgate method overrides.
  // This is only used to properly position embedded files.
  get isEmbedded() {
    return (this.delegate && this.delegate.isEmbedded) || false;
  }

  render() {
    return (
      <div className='FileViewer' ref={ (node) => this.fileViewerNode = node }>
        {this.renderFileViewer()}
      </div>
    );
  }

}

export default FileViewer;

