import React from 'react';
import DataCard from '../../../cards/DataCard';
import * as helpers from '../../../support/Helpers';
import './IcarusCard.css';

class IcarusCard extends DataCard {

  // get extractType() {
  //   return 'json';
  // }

  renderData(data) {
    return (
      <div style={{height: '100%', width: '100%'}}>
        <p className='embed-warning'>Note: links (e.g. "Main menu") in the embeded html do not work</p>
        {data}
      </div>
    );
  }

}

export default IcarusCard;

