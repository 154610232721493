export default {
  "ruler": {
    "font": "sans-serif, plain, 10",
    "color": "black"
  },
  "backbone": {
    "color": "grey",
    "thickness": 5
  },
  "dividers": {
    "slot": {
      "thickness": 1,
      "color": "black"
    }
  },
  "sequence": {
    "font": "sans-serif, plain, 14",
    "color": "black"
  },
  "captions": [
    {
      "name": "Title",
      "position": "bottom-center",
      "fontColor": "orange",
      "backgroundColor": "rgba(255,255,255,0.4)",
      "font": "sans-serif, plain, 24"
    }
  ],
  "legend": {
    "position": "top-right",
    "alignment": "left",
    "backgroundColor": "rgba(255,255,255,0.75)",
    "defaultFont": "sans-serif, plain, 14",
    "defaultFontColor": "black",
    "items": [
      {
        "name": "CDS",
        "swatchColor": "rgba(0,0,153,0.5)",
        "decoration": "arrow"
      },
      {
        "name": "tRNA",
        "swatchColor": "rgba(153,0,153,0.5)"
      },
      {
        "name": "rRNA",
        "swatchColor": "rgba(0,153,53,0.5)"
      },
      {
        "name": "Other",
        "swatchColor": "rgba(153,153,153,0.5)"
      }
    ]
  }
}