import { RESET_STATE, FEATURES_ADD, FEATURES_UPDATE } from '../constants/ActionTypes'

// FIXME: need to monitor PLOTs for sources (when implemented)
const initialState = {
  sources: [],
  featureTypes: [],
};

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case RESET_STATE:     return reset(state, payload);
    case FEATURES_ADD:    return addStats(state, payload);
    case FEATURES_UPDATE: return updateStats(state, payload);
    default: return state;
  }
};

const reset = () => { return (initialState) }

const addStats = (state, { features }) => {
  const newSources = features.map( f => f.source );
  const newTypes = features.map( f => f.type );
  return ({
    ...state,
    sources: [...new Set([...state.sources, ...newSources])],
    featureTypes: [...new Set([...state.featureTypes, ...newTypes])],
  })
}

// FIXME: will be used when removing features as well
// FIXME: using global cgv object...STOP IT
const updateStats = (state, { features }) => {
  // const sources = features[0].viewer.sources();
  // const featureTypes = features[0].viewer.featureTypes();
  const sources = window.cgv.sources();
  const featureTypes = window.cgv.featureTypes();
  return ({
    ...state,
    sources,
    featureTypes,
  })
}



