import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sortableContainer } from 'react-sortable-hoc';
import { List, AutoSizer, CellMeasurerCache } from 'react-virtualized';
import ItemBar from './ItemBar';
import './VirtualList.css';
import 'react-virtualized/styles.css';
import { VirtualListItem } from './ListItem';


class VirtualListNoSort extends React.Component {

  static propTypes = {
    getRef:               PropTypes.func,
    setRef:               PropTypes.func,
    sortable:             PropTypes.bool,
    itemCount:            PropTypes.number.isRequired,
    dataName:             PropTypes.string,
    className:            PropTypes.string,
    // items is used to check if a render is required (see componentWillReceiveProps)
    items:                PropTypes.array.isRequired,
    listItemID:           PropTypes.func.isRequired,
    listItemMainRenderer: PropTypes.func.isRequired,
    listItemInfoRenderer: PropTypes.func.isRequired,
    onRowMouseOver:       PropTypes.func,
    onRowMouseOut:        PropTypes.func,
    otherActions:         PropTypes.array,
  }

  static defaultProps = {
    sortable: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      infoOpenByID: {},
    };

    this.cellMeasurerCache = new CellMeasurerCache({
      defaultHeight: 20,
      fixedWidth: true,
      // Use our item ids (e.g. cgvIDs) instead of the index
      // The index ended up being wrong when using a sortable list
      keyMapper: (index) => this.props.listItemID(index),
    });

    this.registerRef = this.registerRef.bind(this);
    this.itemRenderer = this.itemRenderer.bind(this);
  }

  registerRef(listInstance) {
    this.listRef = listInstance;
    this.props.getRef && this.props.getRef(listInstance);
    const {setRef} = this.props;
    // setRef && setRef(listInstance);
    setRef && setRef(this);
  };

  recomputeRowHeights(index) {
    this.cellMeasurerCache.clear(index);
    // console.log(this.listRef, this.listRef.recomputeRowHeights)
    // console.log("RECOM", index)
    this.listRef.recomputeRowHeights(index);
  }

  scrollToRow(index) {
    this.listRef.scrollToRow(index);
  }

  // infoClicked(item) {
  infoClicked(id) {
    this.setState({
      infoOpenByID: {
        ...this.state.infoOpenByID,
        [id]: !this.state.infoOpenByID[id]
      }
    });
  }

  openInfoFor(id) {
    this.setState({
      infoOpenByID: {
        ...this.state.infoOpenByID,
        [id]: true,
      }
    });
    // this.listRef.recomputeRowHeights(index);
  }

  itemRenderer({ index, isScrolling, key, parent, style }) {
    const {
      sortable,
      listItemInfoRenderer,
      listItemMainRenderer,
      listItemID,
      onRowMouseOver,
      onRowMouseOut,
    } = this.props;

    const itemID = listItemID(index);
    const isOpen = this.state.infoOpenByID[itemID];
    // console.log(index, itemID)
    // const isOpen = true;
    // NOTE: the key provided to the renderer does not change after sorting
    //       so use the item id instead.
    return (
      <VirtualListItem
        key={itemID}
        parent={parent}
        cellMeasurerCache={this.cellMeasurerCache}
        style={style}
        index={index}
        infoOpen={isOpen}
        sortable={sortable}
        mainRenderer={ () => listItemMainRenderer(index) }
        infoRenderer={ () => listItemInfoRenderer(index) }
        infoClicked={ () => this.infoClicked(itemID) }
        recomputeRowHeights={ () => this.recomputeRowHeights(index) }
        onRowMouseOver={ () => onRowMouseOver(index) }
        onRowMouseOut={onRowMouseOut}
      />
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.items !== this.props.items) {
      this.listRef.forceUpdate();
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.items !== this.props.items) {
  //     this.cellMeasurerCache.clearAll();
  //     this.listRef.forceUpdate();
  //   }
  // }


  itemBar() {
    // const { filteredData, selectedIDs } = this.state;
    const { itemCount, dataName, addAction, deleteAction, editAction, otherActions } = this.props;
    return (
      <ItemBar title={dataName}
        totalItemCount={itemCount}
        deleteAction={ deleteAction && (() => this.deleteAction()) }
        editAction={ editAction && (() => this.editAction()) }
        addAction={ addAction }
        otherActions={ otherActions }
      />
    )
  }

  render() {
    // JUST GET LENGTH
    const {itemCount, className} = this.props;
    // console.log('List Rendered')
    const klass = classNames('VirtualList', className);

    // FIXME:
    // This fixes the List Items bug where after adding a new track/legend item,
    // the item overlaps the previous item. This may not be very efficient, but it works.
    // NOPE: This causes a new issue where only the first 10 items in a list are
    // rendered when moving between panels
    // It was odd how the number was always the first "10" items, which is the
    // default value for the overscanRowCount. So I've upped the overscanRowCount
    // to 30 for now. This will fix the issue up to 30 items, but it's not a
    // real fix. We need to replace react-virtualized with AG Grid.
    this.cellMeasurerCache.clearAll();

    return (
      <div className={klass}>
        {this.itemBar()}
        <div className='auto-sized-div'>
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={this.registerRef}
                items={this.props.items}
                deferredMeasurementCache={this.cellMeasurerCache}
                rowHeight={this.cellMeasurerCache.rowHeight}
                rowCount={itemCount}
                height={height}
                width={width}
                rowRenderer={this.itemRenderer}
                overscanRowCount={30}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

const VirtualListSortableContainer = sortableContainer(VirtualListNoSort);


class VirtualListWithSort extends React.Component {

  static propTypes = {
    onSortEnd: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.registerListRef = this.registerListRef.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  registerListRef(listInstance) {
    this.List = listInstance;
  };

  onSortEnd({oldIndex, newIndex}) {
    if (oldIndex === newIndex) { return; }
    this.props.onSortEnd({oldIndex, newIndex});
    // We need to inform React Virtualized that the items have changed heights
    // This can either be done by imperatively calling the recomputeRowHeights and
    // forceUpdate instance methods on the `List` ref, or by passing an additional prop
    // to List that changes whenever the order changes to force it to re-render
    this.List.recomputeRowHeights();
  };

  render() {
    return (
      <VirtualListSortableContainer
        {...this.props}
        onSortEnd={this.onSortEnd}
        getRef={this.registerListRef}
        lockAxis='y'
        useDragHandle
        lockToContainerEdges
        lockOffset='0%'
        onSortStart={ (_, event) => event.preventDefault() }
        helperClass='sort-helper'
      />
    );
  }
}

class VirtualList extends React.Component {

  static propTypes = {
    sortable: PropTypes.bool,
  }

  static defaultProps = {
    sortable: false,
  }

  render() {
    const {sortable} = this.props;

    if (sortable) {
      return <VirtualListWithSort {...this.props} />;
    } else {
      return <VirtualListNoSort {...this.props} />;
    }
  }

}

export default VirtualList;

