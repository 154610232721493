import React from 'react';
import DataElement from '../../presenters/DataElement';
import DataElementGroup from '../../presenters/DataElementGroup';
import DataElementContainer from '../../presenters/DataElementContainer';
// import ButtonGroup from '../../presenters/ButtonGroup';
// import Button from '../../presenters/Button';
import Switch from '../../presenters/Switch';
import { Select, Option } from '../../presenters/Select';
// import { DragTagSelect } from '../../presenters/DragTagSelect';
import Tools from '../Tools.js';
// import DefaultSettingsManager from '../../presenters/DefaultSettingsManager';
// import ProkseeFile from '../ProkseeFile';
import FileInput from '../../presenters/FileInput';
import CGParse from '../../../CGParse.esm';
import * as helpers from '../../support/Helpers';

export function defaultSettingsManagerKey() {
  return 'dsm.features.AddDialog';
}

// Used to add the default settings to the FileInput state
// - This will use localStorage values if they exist (via defaultValue())
// - NOTE: This is locked to the 'Features' tool (not sure if this is an issue)
export function initialSettings() {
  const featureTool = Tools.get('features');
  const inputs = featureTool.inputsForTarget('Settings');
  const settings = {};
  settings.header = true;
  for (const input of inputs) {
    // console.log(input.id, input.default, input.defaultValue());
    settings[input.id] = input.defaultValue();
  }
  // This is how we keep track of changes to settings so we can reparse the file
  // This is now directly part of FileInput settings state
  // settings.settingsHaveChanged = false;
  return settings;
}


// Used to reset settings that should be reset for a new file (ie the file has changed.
export function fileChangedSettings() {
  return { columnIndexMap: {} };
}

// FIXME: this should use a options object instead of a bunch of arguments
// - settings: object of settings values from the FileInput state
// - onChange: function to update the settings in the FileInput state
export function settingsRenderer(settings = {}, onChange = () => {}, details = {}, fileText = '') {
  // console.log('SETTINGS', settings);
  // console.log('DETAIL', details);
  // Feature File Format
  let fileFormat = settings.format || 'auto';
  // Name Keys: TODO
  // const defaultNameKeys = ['locus_tag', 'gene', 'product', 'note', 'label', 'tags'];
  // let nameKeys = settings.nameKeys || defaultNameKeys;
  // Get unique set of named keys
  // const nameOptions = Array.from(new Set(nameKeys.concat(defaultNameKeys)));

  // For Default Settings Manager
  const featureTool = Tools.get('features');
  const formatOptions = featureTool.inputs?.get('format')?.values || ["NONE"];

  // console.log(featureTool)
  // console.log(featureTool.inputs)
  // console.log(featureTool.inputs?.get('format'))
  // console.log(formatOptions)

  // Parse Button
  let parseButtonEnabled = settings.settingsHaveChanged;
  if (document.querySelector('.my-file-input')?.files?.length === 0) {
    parseButtonEnabled = false;
  }

  const meta = details.meta || {};
  return (
    <div>
      <DataElement label='Feature File Format' help='[Default: Auto Detect]'>
        <Select value={fileFormat}
          onChange={(value) => {
            onChange({attribute: 'format', value});
            // onChange({attribute: 'settingsHaveChanged', value: true});
          }}>
          { Object.entries(formatOptions).map( ([key, value]) => {
            if (value?.toLowerCase().includes('auto') && meta.detectedFormat) {
              value = `${value} (${meta.detectedFormat.toUpperCase()})`;
            }
            return <Option key={key}>{value}</Option>
          })}
        </Select>
      </DataElement>

      { renderCSVColumnSelection(settings, onChange, details, fileText) }

      <div style={{display: 'flex'}}>
        { FileInput.renderReparseButton(parseButtonEnabled) }
        {/* <DefaultSettingsManager className='dsm-cgparse' tool={featureTool} componentID='Settings' toolOptions={settings} resetFunc={ () => resetSettings(onChange) }/> */}
      </div>
    </div>
  );
}

// FIXME: this should use a options object instead of a bunch of arguments
function renderCSVColumnSelection(settings = {}, onChange = () => {}, details = {}, fileText = '') {
  // console.log(fileText)
  // console.log(settings)
  // console.log(details)
  const meta = details.meta || {};
  const columnIndexMap = {...meta.columnIndexMap, ...settings.columnIndexMap};
  const hasHeader = settings.header;

  // console.log(meta.columnIndexMap)
  // console.log(settings.columnIndexMap)

  const columnKeys = CGParse.CSVFeatureFile.columnKeys;
  columnKeys.unshift('ignored');

  // Use the meta.columnIndexMap to get the column count of the file (may be different from settings, if the file has changed)
  const columnCount = Object.keys(meta?.columnIndexMap || {}).length;
  // Unique Keys in use (minus ignored)
  const usedKeys = new Set(Object.values(columnIndexMap))
  usedKeys.delete('ignored');

  // console.log("*************** RENDER TIME ***************");
  const separator = (meta.fileFormat === 'csv') ? ',' : '\t';
  const columnData = CGParse.CSVFeatureFile.columnData(fileText, separator, 5);

  const rows = [];
  for (let i = 0; i < columnCount; i++) {
    const header = hasHeader ? <td className='csv-data-header'>{columnData?.[i]?.[0]}</td> : undefined;
    // Join data for the index together with a comma.
    // but if there is no header, skip the first row
    if (hasHeader) { columnData?.[i]?.shift(); }
    const data = columnData?.[i]?.join(', ');

    const disabledKeys = new Set(usedKeys);
    disabledKeys.delete(columnIndexMap[i]);
    const options = columnKeys.map((key) => <Option value={key} key={key} disabled={disabledKeys.has(key)}>{helpers.capitalize(key)}</Option>);

    const colKeySelect = <Select
      value={columnIndexMap[i]} size='xsmall' className='' dropdownMatchSelectWidth={120}
      // Need to update columMap when ever this changes and update settings with it
      onChange={(value) => {
        const newColumnIndexMap = getCSVColumnIndexMap(i, value);
        console.log("***************");
        console.log(newColumnIndexMap);
        console.log("***************");
        // onChange({attribute: 'settingsHaveChanged', value: true});
        onChange({attribute: 'columnIndexMap', value: newColumnIndexMap });
       }}
      >{options}</Select>;
    // rows.push(<tr key={i}><td>{i}</td><td className='select-key'>{colKeySelect}</td><td className='csv- data-col'><span className='csv-data-header'>{header}</span>: {data}</td></tr>);
    rows.push(<tr key={i}><td className='column-number'>{i}</td><td className='select-key'>{colKeySelect}</td>{header}<td className='csv-data-line'>{data}</td></tr>);
  };

  const headerTableColumn = hasHeader ? <th className='col-header-header left'>Header</th> : undefined;
  if (['csv', 'tsv'].includes(meta.fileFormat)) {
    const format = meta.fileFormat.toUpperCase();
    return (
      <DataElementContainer className='csv-column-table-container' maxHeight='300px' label={`${format} Options`}>
        <DataElementGroup>
          <DataElement label={`Does the ${format} file have a header row?`}>
            <Switch value={hasHeader}
              onChange={(value) => {
                // onChange({attribute: 'settingsHaveChanged', value: true});
                onChange({attribute: 'header', value});
              }}
            />
          </DataElement>
        </DataElementGroup>
        <DataElementGroup>
          <DataElement label='Select feature attribute for each column:'>
            <table className='csv-column-selection'>
              <thead><tr><th className='col-header-number'>#</th><th className='col-header-attribute'>Attribute</th>{headerTableColumn}<th className='left'>File Data</th></tr></thead>
              <tbody id="csv-column-rows">{rows}</tbody>
            </table>
          </DataElement>
        </DataElementGroup>
      </DataElementContainer>
    )
  }
}

function getCSVColumnIndexMap(index, value) {
  const csvColumns = document.getElementById('csv-column-rows');
  const rows = csvColumns.querySelectorAll('tr');
  const columnIndexMap = {};
  rows.forEach((row) => {
    // const select = row.querySelector('select');
    const select = row.querySelector('.ant-select-selection-item');
    const columnIndex = parseInt(row.querySelector('td').textContent);
    // const columnKey = select.value;
    if (select) {
      columnIndexMap[columnIndex] = helpers.upcapitalizeFirstLetter(select.textContent);
    }
  });
  // Add new/changed value to the columnMap
  columnIndexMap[index] = value;
  return columnIndexMap;
}




// function getCSVColumnMap(index, value) {
//   const csvColumns = document.getElementById('csv-column-rows');
//   const rows = csvColumns.querySelectorAll('tr');
//   const columnMap = {};
//   rows.forEach((row) => {
//     // const select = row.querySelector('select');
//     const select = row.querySelector('.ant-select-selection-item');
//     const columnIndex = parseInt(row.querySelector('td').textContent);
//     // const columnKey = select.value;
//     if (select) {
//       // columnMap[columnIndex] = select.textContent;
//       columnMap[select.textContent] = columnIndex
//     }
//     // columnMap[columnKey] = columnIndex;
//   });
//   // Remove entry that has a value of the current index
//   const previousKey = Object.keys(columnMap).find(key => columnMap[key] === index);
//   delete columnMap[previousKey];

//   // Add new/changed value to the columnMap
//   columnMap[value] = index;
//   return columnMap;
// }


        // <DataElement label='Feature Names (From Genbank/EMBL Feature Qualifiers)' help='Feature names are taken from the first feature qualifier in the list that has a value. Choose/reorder feature qualifiers as needed. [Default: gene, locus_tag, gene, product, note, db_xref]'>
        //   <DragTagSelect mode='tags' value={nameKeys}
        //     placeholder='Select qualifiers to use for feature names'
        //     onChange={(value) => {
        //       onChange({attribute: 'nameKeys', value});
        //     }}
        //   >
        //     { nameOptions.map( s => <Option key={s}>{s}</Option> ) }
        //   </DragTagSelect>
        // </DataElement>
        // { renderInExcludeSelect(settings, onChange, {
        //     includeKey: 'includeFeatures',
        //     includeValue: settings.includeFeatures || 'exclude',
        //     itemsKey: 'featureTypes',
        //     allItems: allFeatureTypes,
        //     selectPlaceholder: 'Select feature types to include/exclude',
        //     help: 'Choose which feature types to include or exclude. Keep in mind that having more features may affect performance. [Default: Exclude: gene, source, exon]',
        //     labelName: 'Features Type Selection (from GenBank/EMBL)',
        // })}
        // { renderInExcludeSelect(settings, onChange, {
        //     includeKey: 'includeQualifiers',
        //     includeValue: settings.includeQualifiers || 'exclude',
        //     itemsKey: 'qualifierTypes',
        //     allItems: allQualifiers,
        //     selectPlaceholder: 'Select qualifiers to include/exclude',
        //     help: 'Choose which qualifiers to include or exclude. Keep in mind that having more qualifiers may affect performance. [Default: None]',
        //     labelName: 'Qualifier Selection (from GenBank/EMBL)',
        // })}






// Renders the buttons: All, None, Include, Exclude
// And a Select for the items (e.g. feature types or qualifiers)
// Options provide the keys/attribute getting data from settings and changing calues with onChange
// - includeKey: key used to determine if all, none, include, or exclude (e.g. includeFeatures, includeQualifiers)
// - itemsKey: key used to get the list of items to select from (e.g. featureTypes, qualifierTypes)
// - allItems: list of all possible items to select from (e.g. [allFeatureTypes], [allQualifiers])
// - labelName: value to dislay in the label (e.g. Features, Qualifiers)
// - help: help text to display under the label
// - selectPlaceholder: placeholder text for the select
// function renderInExcludeSelect(settings ={}, onChange = () => {}, options = {}) {
//   const includeKey = options.includeKey || 'includeItems';
//   const includeValue = settings[includeKey] || 'exclude';
//   const itemsKey = options.itemsKey || 'items';
//   const itemsValue = settings[itemsKey] || []
//   const allItems = options.allItems || [];
//   const labelName = options.labelName || 'Items';
//   const includeKlass = (includeValue === 'all' || includeValue === 'none') ? 'hidden' : '';
//   // const includeLabel = (includeValue === 'exclude') ? <span>{labelName} to <s>Include</s> Exclude</span> : <span>{labelName} to Include</span>;
//   const itemOptions = Array.from(new Set(itemsValue.concat(allItems)));
//   const placeholder = options.selectPlaceholder || 'Select items to include/exclude';

//   return (
//     <DataElement
//       label={labelName}
//       help={options.help}
//     >
//       <div style={{display: 'flex'}}>
//         <ButtonGroup className='inexclude-btn-group'>
//           <Button width='60px'
//             active={includeValue === 'all'}
//             onClick={() => onChange({attribute: includeKey, value: 'all'})}
//           >All</Button>
//           <Button width='60px'
//             active={includeValue === 'none'}
//             onClick={() => onChange({attribute: includeKey, value: 'none'})}
//           >None</Button>
//           <Button width='60px'
//             active={includeValue === 'include'}
//             onClick={() => onChange({attribute: includeKey, value: 'include'})}
//           >Include</Button>
//           <Button width='60px'
//             active={includeValue === 'exclude'}
//             onClick={() => onChange({attribute: includeKey, value: 'exclude'})}
//           >Exclude</Button>
//         </ButtonGroup>
//         <Select mode='tags' value={itemsValue}
//           allowClear
//           className={includeKlass}
//           disabled={includeValue === 'all' || includeValue === 'none'}
//           placeholder={placeholder}
//           onChange={(value) => {
//             onChange({attribute: itemsKey, value});
//           }}
//         >
//           { itemOptions.map( s => <Option key={s}>{s}</Option> ) }
//         </Select>
//       </div>
//     </DataElement>
//   );
// }
