// import React from 'react';
// import Tools from './Tools';
// import * as helpers from '../support/Helpers';

class Output {


  constructor(id, data, tool) {
    this.id = id;
    this.tool = tool;
    for (const key of Object.keys(data)) {
      if (key === 'component') { continue; }
      this[key] = data[key];
    }
    // The following properties are removed to prevent any conflicts: id, component
    // const { id: myID, type, target, ...props } = data;
    const { id: myID, type, ...props } = data;
    this._props = props;
  }

  // Returns the props to use for a component.
  get props() {
    return this._props || {};
  }

  get component() {
    // console.log(this.tool)
    return this.tool.components[this.props.component];
  }

  displayName() {
    return this.name || helpers.capitalize(this.id);
  }

}

export default Output;

