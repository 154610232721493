// import { local } from 'd3';
import { CENTERLINE_UPDATE } from '../constants/ActionTypes'
import * as helpers from '../support/Helpers';

const initialState = { };

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case CENTERLINE_UPDATE: return updateCenterLine(state, payload);
    default:                return state;
  }
};

const updateCenterLine = (state,  { attributes }) => {
  helpers.localStorageSet('settings.centerLine', {...state, ...attributes} );
  return {...state, ...attributes}
}


