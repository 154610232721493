import React from 'react';
import PropTypes from 'prop-types';
import ServerAPI from '../models/ServerAPI';
import Progress from '../presenters/Progress';
import FileViewer from '../presenters/FileViewer';
import Message from '../presenters/Message';
import Toast from '../presenters/Toast';
import ImageButton from '../presenters/ImageButton';
import { Tree } from 'antd';
import store from '../app/store';
import { updateFileTree } from '../actions/jobs';
import './FilesCard.css';
import * as helpers from '../support/Helpers';

// Connected
import { connect } from 'react-redux';

const { DirectoryTree } = Tree;

const _defaultFileType = 'text';
const _inverseExtensionMap = {
  code: ['yaml', 'yml', 'rb', 'py', 'py3', 'pl', 'sh'],
  json: ['json', 'cgv'],
  csv: ['csv', 'tsv'],
  pdf: ['pdf'],
  html: ['html'],
};

// const treeData = [
//   key: "root",
//   line_count: 0,
//   name: "ROOT",
//   size: 1055182,
//   childred: [
//     { isLeaf: true, key: "root-0", line_count: 6, name: "inputs_raw.yaml", size: 150 },
//     {
//       title: 'Folder_1',
//       key: 'root-1',
//       children: [
//         { title: 'File 1', key: 'root-1-0', isLeaf: true, line_count: 11, size: 123 },
//         { title: 'File 2', key: 'root-1-1', isLeaf: true, line_count: 11, size: 123 },
//       ],
//     },
//     {
//       title: 'Folder_2',
//       key: 'root-2',
//       children: [
//         { title: 'File 3', key: 'root-2-0', isLeaf: true, line_count: 11, size: 123 },
//         { title: 'File 4', key: 'root-2-1', isLeaf: true, line_count: 11, size: 123 },
//       ],
//     },
//   ]
// ];

class FilesCard extends React.Component {

  static propTypes = {
    id: PropTypes.number,
    job: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      // serverTimerID: undefined,
      selectedFileKeys: [],
      viewerPosition: 'bottom',
    }
    // this.getFiles(props.job);
    this.getFiles = this.getFiles.bind(this);
  }

  openFiles(paths=[], options={}) {
    const { job } = this.props;
    const newState = {};
    console.log(job.fileTree)
    if (options.viewerPosition) {
      newState.viewerPosition = options.viewerPosition;
    }

    const keys = [];
    for (const path of paths) {
      keys.push(this.keyForPath(path));
    }
    newState.selectedFileKeys = keys;

    this.setState(newState);
  }

  keyForPath(path) {
    const { job } = this.props;
    return FilesCard.keyForJobPath(job, path);
  }

  static keyForJobPath(job, path) {
    const components = path.split('/');
    let key;
    let node = job.fileTree;
    for (const component of components) {
      const element = node?.find( i => i.name === component);
      if (element) {
        if (component === components[components.length-1]) {
          return element.key
        } else if (element.children) {
          node = element.children
        }
      } else {
        break;
      }
    }
  }

  // Duplicates most of keyForJobPath code
  static sizeForJobPath(job, path) {
    const components = path.split('/');
    let key;
    let node = job.fileTree;
    for (const component of components) {
      const element = node?.find( i => i.name === component);
      if (element) {
        if (component === components[components.length-1]) {
          return element.size
        } else if (element.children) {
          node = element.children
        }
      } else {
        break;
      }
    }
  }

  static getFiles(job) {
    const Server = new ServerAPI();
    // console.log(Server.jobFileTree(job));
    Server.get(Server.jobFileTree(job))
    .then( response => {
      if (response.ok) {
        const data = response.json;
        // TODO: consider moving this to a new dispatch file to keep redux events together
        store.dispatch(updateFileTree(job.id, data.fileTree));
      }
    });
  }

  getFiles() {
    const { job } = this.props;
    FilesCard.getFiles(job);
  }

  // Returns true if the path exists in the file tree
  // Actually checks if a key exists for the path
  static fileTreeIncludesJobPath(job, path) {
    if (!job?.fileTree) return false;
    // console.log(job.fileTree)
    return !!this.keyForJobPath(job, path);
  }

  fileTreeIncludesPath(path) {
    const { job } = this.props;
    return FilesCard.fileInTree(job, path);
  }


  downloadItem(item) {
    const { job } = this.props;
    const {key, name} = item;
    FilesCard.downloadJobFile(job, {key, name});
  }


  // static downloadJobFileByPath(job, path) {
  //   const key = FilesCard.keyForJobPath(job, path);
  //   const name = path.split('/').pop();
  //   FilesCard.downloadJobFileByKey(job, key, name);
  // }

  // static downloadJobFileByKey(job, key, name='proksee_file') {
  //
  // Options needs to contain a key or path for the file
  // options:
  // - key: key to the file (e.g. ROOT-1-2)
  // - path: path to the file (e.g. ??)
  // - name: name to call the downloaded file (e.g. my_file.txt)
  //         If path is provided, defaults to file name
  static downloadJobFile(job, options={}) {
    const downloadArgs = options.key ? {key: options.key} : {path: options.path};
    const name = options.name || (options.path && options.path.split('/').pop()) || 'proksee_download';

    const Server = new ServerAPI();
    Message.open({content:'Downloading...'});
    // Server.get(Server.downloadJobFile(job), {key})
    Server.get(Server.downloadJobFile(job), downloadArgs)
    .then( response => {
      // console.log(response)
      if (response) {
        if (response.ok && response.blob) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(response.blob);
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          // a.download = item.name;
          a.download = name;
          a.click();
          document.body.removeChild(a);
        } else {
          Toast.create('Download Error', 2000);
        }
      } else {
        Toast.create('Download Error', 2000);
      }
      Message.close();
    });
  }

  // downloadFile(job, item) {
  //   const Server = new ServerAPI();
  //   Message.open({content:'Downloading...'});
  //   Server.get(Server.downloadJobFile(job), {key: item.key})
  //   .then( response => {
  //     // console.log(response)
  //     if (response) {
  //       if (response.ok && response.blob) {
  //         var a = document.createElement("a");
  //         var url = window.URL.createObjectURL(response.blob);
  //         document.body.appendChild(a);
  //         a.style = "display: none";
  //         a.href = url;
  //         a.download = item.name;
  //         a.click();
  //         document.body.removeChild(a);
  //       } else {
  //         Toast.create('Download Error', 2000);
  //       }
  //     } else {
  //       Toast.create('Download Error', 2000);
  //     }
  //     Message.close();
  //   });
  // }

  fileTypeForExtension(extension) {
    // Initialize only once
    if (!this._extensionMap) {
      this._extensionMap = {};
      for (const [type, ext] of Object.entries(_inverseExtensionMap)) {
        ext.map( e => this._extensionMap[e] = type );
      }
    }
    const fileType = this._extensionMap[extension] || _defaultFileType;
    return fileType;
  }

  fileTypeForFile(file) {
    const extension = file.split('.').pop();
    return this.fileTypeForExtension(extension);
  }

  addFileTypes(tree=[], tool) {
    const adjustedTree = [];

    for (const item of tree) {
      const { ...newItem } = item;
      newItem.fileType = this.fileTypeForFile(item.name);
      if (item.children) {
        newItem.fileType = 'folder';
        newItem.children = this.addFileTypes(item.children);
      }
      adjustedTree.push(newItem);
    }
    return adjustedTree;
  }

// TODO:
// - need state to see if log is open or closed

  onSelect = (keys, event) => {
    const { job } = this.props;

    // console.log('Trigger Select', keys, event);

    // const key = keys[0];

    this.setState({
      selectedFileKeys: keys,
    });

    // const fileTree = job.fileTree;
    // const file = fileTree.find( f => f.key === key );
    // console.log(file);
  };

  onExpand = () => {
    // console.log('Trigger Expand');
  };

  renderProgress() {
    return (
      <div className='job-not-finalized'>
        Job not finished...
        <Progress type='dots' />
      </div>
    );
  }

  // Directories come first then sort by name
  sortByTypeAndName(a, b) {
    if (!a.isLeaf && b.isLeaf) {
      return -1;
    } else if (!b.isLeaf && a.isLeaf) {
      return 1;
    } else {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
  }

  sortTreeData(tree=[]) {
    const sorted = tree.sort(this.sortByTypeAndName);
    for (const item of sorted) {
      if (!item.isLeaf) {
        item.children = this.sortTreeData(item.children);
        // item.children = item.children.sort(this.sortByTypeAndName);
      }
    }
    return sorted;
  }

  adjustTreeData(tree=[]) {
    const { job } = this.props;
    const fileTypeMap = {
      text: 'Text',
      code: 'Code',
      json: 'JSON',
      csv:  'CSV',
      pdf:  'PDF',
      html:  'HTML',
      folder: 'Folder',
    }
    const adjustedTree = [];
    for (const item of tree) {
      const { ...newItem } = item;
      if (newItem.name === 'ROOT') {
        newItem.name = `${job.name} [${job.tool.publicID}-${job.id}]`;
      }
      const size = helpers.formatBytes(item.size);
      const fileType = fileTypeMap[item.fileType];
      const downloadButton = <ImageButton
          imageName='downloadSmall'
          title='Download File'
          size='small'
          className='download-button'
          onClick={ () => this.downloadItem(item) }
        / >
      // const downloadButton = item.isLeaf ? <span className='file-download' onClick={() => this.downloadFile(job, item)}>⬇️</span> : <span className='file-download'></span>;
      const downloadSpan = item.isLeaf ? <span className='file-download'>{downloadButton}</span> : <span className='file-download'></span>;
      newItem.title = (
        <span className='title-row' title={`${newItem.name} [${size}][${fileType}]`}>
          <span className='file-name'>{newItem.name}</span>
          <span className='file-type'>{fileType}</span>
          <span className='file-size'>{size}</span>
          {downloadSpan}
        </span>
      );
      if (item.children) {
        newItem.children = this.adjustTreeData(item.children);
      }
      adjustedTree.push(newItem);
    }
    return adjustedTree;
  }


  // Return list of the currently selected files (ignores directories)
  getSelectedFiles(files, dirBasePath) {
    const { selectedFileKeys } = this.state;
    // const { job } = this.props;
    // const files = selectedFileKeys.map( k => job.fileTree.find( f => f.key === k ) );
    let selectedFiles = [];
    // FIXME 
    if (selectedFileKeys.length === 0) {return selectedFiles;}
    const fileTree = files || this.props.job.fileTree || [];

    // console.log(fileTree)
    for (let file of fileTree) {
      if (selectedFileKeys.includes(file.key) && file.isLeaf) {
        if (dirBasePath) {
          // FIXME: make sure this doesn't affect original file
          file.directory = dirBasePath;
        }
        selectedFiles.push(file);
      }
      if (file.children) {
        let dirPath = dirBasePath ? `${dirBasePath}/${file.name}`: file.name;
        const selectedChildrenFiles = this.getSelectedFiles(file.children, dirPath);
        selectedFiles = [...selectedFiles, ...selectedChildrenFiles];
      }
    }
    return selectedFiles;
  }

  onChangeViewerPosition(viewerPosition) {
    this.setState({viewerPosition});
  }

  renderFileTree() {
    const { job } = this.props;
    const { viewerPosition } = this.state;
    const sortedTree = this.sortTreeData(job.fileTree);
    const treeWithFileTypes = this.addFileTypes(sortedTree);
    const adjustedTree = this.adjustTreeData(treeWithFileTypes);
    // console.log(adjustedTree)

    // Ignore root directory when getting selected files
    const adjustedTreeChildren=  adjustedTree[0] && adjustedTree[0].children || [];
    const files = this.getSelectedFiles(adjustedTreeChildren);

    const directoryTree = job.fileTree ? (
      <DirectoryTree
        multiple
        blockNode
        defaultExpandedKeys={['root']}
        onSelect={this.onSelect}
        onExpand={this.onExpand}
        treeData={adjustedTree}
        expandAction='doubleClick'
      />
    ) : <div className='.job-not-finalized'>Loading File Tree...</div>;

    return (
      <div className={`FileListAndViewer viewer-position-${viewerPosition}`}>
        <div className='FileList'>
          <div className='tree-header'>
            <span className='file-name'>Name</span>
            <span className='file-type'>Kind</span>
            <span className='file-size'>Size</span>
            <span className='file-download'></span>
          </div>
          {directoryTree}
        </div>
        <FileViewer files={files} job={job} position={viewerPosition} positionFunc={ (p) => this.onChangeViewerPosition(p) } />
      </div>
    )
  }

  render() {
    const { job } = this.props;
    // console.log(job)
    if (job.finalized && !job.fileTree) {
      this.getFiles();
    }
    return (
      <div className='FileCard'>
        {job.finalized ? this.renderFileTree() : this.renderProgress()}
      </div>
    );
  }

}

//Connected
const filesCardMapStateToProps = (state, ownProps) => ({ job: state.jobs.byID[ownProps.id] });
const ConnectedFilesCard = connect(filesCardMapStateToProps, null, null, {forwardRef: true})(FilesCard);

// export default FilesCard;
export { FilesCard, ConnectedFilesCard};

