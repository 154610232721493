import React from 'react';
import DefaultDialog from '../../../presenters/DefaultDialog';

class DialogStart extends DefaultDialog {

  componentDidMount() {
    const seqLength = this.cgv.sequence.length;
    const contigs = this.cgv.sequence.contigs();
    const lengths = contigs.map( c => c.length );
    this.largestLength = Math.max.apply(0, lengths);
    if ( this.largestLength >= 20000 ) {
      this.onValidate(true)
    }
  }

  renderContents() {
    const { tool } = this.props;
    const { valid } = this.state;
    return (
      <div>
        {this.renderHeader()}
        {this.renderInput('jobName')}

        {valid || this.renderStop(`The largest contig is ${this.helpers.commaNumber(this.largestLength)} bp. At least one contig must be larger than 20,000 bp.`)}
        {this.renderDefaultSettingsManager()}
      </div>
    );
  }
}

export default DialogStart;

