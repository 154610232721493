export default function add({inputs, mapData}) {

  if (inputs.skip_enclosed) {
    // Remove enclosed features 
    mapData.features = mapData.features.filter( f => !f.meta?.enclosed);
  } else {
    // Remove enclosed key from feature meta data
    mapData.features.forEach( f => delete f.meta?.enclosed);
  }

  return {ok: true, mapData};
}

