import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';

class CARDDataCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  renderData(data) {
    const header = true;
    return (
      <VirtualGrid
        data={data}
        header={header}
        alternateRowColors
        rowHeight={50}
        defaultColumnWidth={100}
      >
        <Column index={0} width={400} />
        <Column index={1} width={100} />
        <Column index={2} type='number' />
        <Column index={3} type='number' />
        <Column index={4} align='center' />
        <Column index={6} type='number' />
        <Column index={7} type='number' />
        <Column index={9} type='number' />
        <Column index={20} type='number' />
      </VirtualGrid>
    );
  }

}

export default CARDDataCard;

