import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tools from '../models/Tools';
import ImageButton from '../presenters/ImageButton';
import {FilesCard} from './FilesCard';
import './FeaturedFile.css';
import * as helpers from '../support/Helpers';

// Connected
import { connect } from 'react-redux';

FeaturedFile.propTypes = {
  path: PropTypes.string,
  description: PropTypes.string,
  jobID: PropTypes.number,
  job: PropTypes.object,
  dataCard: PropTypes.object,
  // fileTree: PropTypes.string,
  className: PropTypes.string,
}

FeaturedFile.defaultProps = {
}

function FeaturedFile(props) {

  const {
    path,
    description,
    job,
    dataCard,
    className,
  } = props;

  const fileExists = FilesCard.fileTreeIncludesJobPath(job, path);
  const fileSize = FilesCard.sizeForJobPath(job, path);
  const displaySize = (fileSize !== undefined) ? helpers.formatBytes(fileSize) : 'n/a';

  const fileName = path.split('/').pop();
  const displayPath = path.replace('ROOT/', '');

  const tool = Tools.get(job.tool.publicID);
  // Find first output that uses this path (skipping reports)
  const output = tool && tool.dataCardOutputs().find( out => (out.data === displayPath && out.id !== 'report'));

  const dataCardBtn = output && (
    <ImageButton text='Data Card' imageName='dataCard' width={100} disabled={!fileExists}
      onClick={() => {dataCard.openDataCard(output.id)}}
    />
  );
  const elementClass = classNames( 'FeaturedFile', className);
  const nameClass = classNames('file-name', {muted: !fileExists});
  const descrtipionClass = classNames('description', {muted: !fileExists});
  const sizeClass = classNames('file-size', {muted: !fileExists});
  return (
    <div className={elementClass}>
      <div className='ps-space-between'>
        <div className={nameClass}>{fileName}</div>
        <div className={sizeClass}>{displaySize}</div>
      </div>
      <div className='path'>{displayPath}</div>
      <div className={descrtipionClass}>{description}</div>
      <div className='file-action-buttons'>
        <ImageButton text='View File' imageName='file' width={100} disabled={!fileExists}
          onClick={() => {dataCard.openFiles([path], {viewerPosition: 'full'})}}
        />
        <ImageButton text='Download' imageName='download' width={100} disabled={!fileExists}
          onClick={() => {dataCard.downloadFile(displayPath)}}
        />
        {dataCardBtn}
      </div>
    </div>
  );
}

//Connected
const featuredFileMapStateToProps = (state, ownProps) => ({ job: state.jobs.byID[ownProps.jobID] });
const ConnectedFeaturedFile = connect(featuredFileMapStateToProps)(FeaturedFile);

// export default FeaturedFile;
export { FeaturedFile, ConnectedFeaturedFile };



