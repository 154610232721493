import React from 'react';
import DataCard from '../../../cards/DataCard';
import * as helpers from '../../../support/Helpers';

class PhigaroCard extends DataCard {

  // get extractType() {
  //   return 'json';
  // }

  renderData(data) {
    return (
      <div style={{height: '100%', width: '100%'}}>
        <p className='embed-warning'>Note: Blast buttons in the embeded html are inactive</p>
        {data}
      </div>
    );
  }

}

export default PhigaroCard;

