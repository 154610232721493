import React from 'react';
import BasicDialog from '../../presenters/BasicDialog';
import ServerAPI from '../../models/ServerAPI';
import Toast from '../../presenters/Toast';

// EXPECTED PROPS:
// - jobIDs: array of cgvIDs for the jobs to delete
// - jobsByID: object of all jobs. Keys are the jobID
// - jobTableRef: reference to the job Pane VirtualTable
//    - access underlying Table ref with: jobTableRef.current.tableRef

class JobsDeleteDialog extends BasicDialog {

  constructor(props, context) {
    super(props, context);
    this.state = { valid: this.filteredIDs.length > 0 };
  }

  renderTitle() {
    const {jobIDs} = this.props;
    const extra = this.cgviewBuilderIncluded() ? ' (Ignoring CGView Builder Job)' : '';
    return `Delete Jobs: ${this.filteredIDs.length} selected${extra}`;
  }

  cgviewBuilderIncluded() {
    const { jobIDs  } = this.props;
    return (this.filteredIDs.length != jobIDs.length);
  }

  get filteredIDs() {
    const { jobIDs, jobsByID  } = this.props;
    return jobIDs.filter( j => jobsByID[j].tool.publicID !== 'cgview_builder' );
  }

  onLocalClose({action, options}) {
    const {jobTableRef} = this.props;
    const ids = this.filteredIDs;
    if (action === 'ok') {
      ids.map( id => this.context.tabsRef.removeByID(`job-${id}`) );
      const Server = new ServerAPI();
      Server.deleteJobs(ids)
      .then( response => {
        jobTableRef.current.tableRef.recomputeRowHeights();
        setTimeout( () => jobTableRef.current.refreshSelectedIDs());
        Toast.create(`Jobs Deleted: ${ids.length}`, 1000);
      });
    }
  }

  renderContents() {
    const { jobIDs, jobsByID  } = this.props;


    let content;
    if (this.filteredIDs.length === 0) {
      content = <p>No Jobs selected (CGView Builder jobs are ignored).</p>
    } else if (this.filteredIDs.length === 1) {
      content = <p>Are you sure you want to delete this job?</p>;
    } else {
      content = <p>Are you sure you want to delete the selected {jobIDs.length} jobs?</p>;
    }

    return (
      <div>
        {content}
        {this.filteredIDs.length > 0 && this.cgviewBuilderIncluded() && <p>Note that CGView Builder jobs will not be deleted</p>}
      </div>
    );
  }
}

export default JobsDeleteDialog;
