import { JOBS_ADD, JOBS_UPDATE, LOG_UPDATE, FILETREE_UPDATE } from '../constants/ActionTypes'

export const addJobs = (jobs) => ({
  type: JOBS_ADD,
  payload: {
    jobs,
  }
});

export const updateJobs = (jobs, attributes) => ({
  type: JOBS_UPDATE,
  payload: {
    jobs,
    attributes,
  }
});

export const updateLog = (id, log, offset) => ({
  type: LOG_UPDATE,
  payload: {
    id,
    log,
    offset,
  }
});

export const updateFileTree = (id, fileTree) => ({
  type: FILETREE_UPDATE,
  payload: {
    id,
    fileTree,
  }
});


