// import { BP_CHANGE } from '../constants/ActionTypes'
import { LOCATION_CHANGE } from '../constants/ActionTypes'

// export const changeBp = (bp) => ({
//   type: BP_CHANGE,
//   payload: bp,
// });

export const changeLocation = (bp, zoomFactor) => ({
  type: LOCATION_CHANGE,
  payload: {
    bp: bp,
    zoomFactor: zoomFactor,
  }
});
