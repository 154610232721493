import React from 'react';
import DataCard from '../../../cards/DataCard';
import { VirtualGrid, Column } from '../../../presenters/VirtualGrid';

class HGTRegionsCard extends DataCard {

  get extractType() {
    return 'csv';
  }

  renderData(data) {
    return (
      <VirtualGrid
        data={data}
        header
        alternateRowColors
        rowHeight={30}
        defaultColumnWidth={80}
      >
        <Column index={0} width={150} />
        <Column index={1} width={100} type='number' />
        <Column index={2} width={100} type='number' />
        <Column index={3} width={100} type='number' />
        <Column index={4} width={100} type='number' />
      </VirtualGrid>
    );
  }

}

export default HGTRegionsCard;

